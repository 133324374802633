import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Download, Eye, FilePdf, FilePpt, Video } from "@phosphor-icons/react";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";

import { useFetchSectionFilesQuery } from "../../../redux/api/taskSlice";

const IndividualFileSection = ({ Section, User, BatchId, onVideoSelect, onFileSelect, alignment }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [sectionToUpload, setSectionToUpload] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileExtension, setFileExtention] = useState("");
  const [sectionFilesInThisSession, setSectionFilesInThisSession] = useState(Section?.pre_session || []);
  const { data: FetchedSectionFiles, isFetching: isFetchingSectionFiles } = useFetchSectionFilesQuery({
    schoolId: User.school_id,
    batchId: BatchId
  });
  const [file, setFile] = useState(null);
  const inputRefFile = useRef();
  const theme = useTheme();

  const HandleVideoClick = async fileKey => {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/task/file-download/presigned`, { fileKey });
    const downloadUrl = response.data.url;
    onVideoSelect(downloadUrl);
    // eslint-disable-next-line
    onFileSelect(fileKey?.split("/")?.pop()?.split(".")?.slice(0, -1)?.join(" "));
  };

  const handleFileClick = async fileKey => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/task/file-download/presigned`, { fileKey });
      const downloadUrl = response.data.url;
      const fileName = fileKey.split("/").pop();
      const fileExtension = fileName.split(".").pop().toLowerCase();

      if (fileExtension === "mp4") {
        window.location.href = `/view-batch/${BatchId}/tasks/stream-video?url=${encodeURIComponent(downloadUrl)}`;
      } else {
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("Error downloading file");
    }
  };

  useEffect(() => {
    if (alignment === "Pre Session") {
      setSectionFilesInThisSession(Section?.pre_session || []);
    } else if (alignment === "Post Session") {
      setSectionFilesInThisSession(Section?.post_session || []);
    } else if (alignment === "In Session") {
      setSectionFilesInThisSession(Section?.in_session || []);
    }
  }, [alignment]);

  if (isFetchingSectionFiles) {
    return (
      <Box display="flex" justifyContent="center" sx={{ display: "flex", width: "100%" }}>
        <CircularProgress sx={{ alignSelf: "center", color: "#3267ff" }} />
      </Box>
    );
  } else {
    return (
      <Box width={isNonMobile ? "100%" : "110%"} marginLeft={isNonMobile ? "0" : "-5%"}>
        <Accordion
          elevation={0}
          sx={{ backgroundColor: "#fafafb", borderRadius: "2rem", border: "1px dashed rgba(0,0,0,0.3)" }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
            <Box>
              <Typography variant="h5">Lectures</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <Typography variant="h6" gutterBottom>
                Media and Notes
              </Typography>
              <List dense={false}>
                {FetchedSectionFiles?.contents
                  ?.filter(
                    file =>
                      file.Key.includes(Section._id) &&
                      file.Key.includes(BatchId) &&
                      sectionFilesInThisSession?.includes(file.Key.split("/").pop())
                  )
                  .map((file, index) => (
                    <ListItem key={index}>
                      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                        <Box display="flex" alignItems="center" marginLeft={isNonMobile ? "0" : "-2%"}>
                          <ListItemIcon>
                            {(file.Key.split("/").pop().split(".").pop() === "ppt" ||
                              file.Key.split("/").pop().split(".").pop() === "pptx") && (
                              <FilePpt size={28} style={{ padding: "0.2rem" }} color="#3267ff" />
                            )}
                            {file.Key.split("/").pop().split(".").pop() === "mp4" && (
                              <Video size={28} style={{ padding: "0.2rem" }} color="#3267ff" />
                            )}
                            {file.Key.split("/").pop().split(".").pop() === "pdf" && (
                              <FilePdf size={28} style={{ padding: "0.2rem" }} color="#3267ff" />
                            )}
                          </ListItemIcon>
                          <ListItemText sx={{ marginLeft: "-1.75rem" }} primary={`${file.Key.split("/").pop()}`} />
                        </Box>
                        <Box marginLeft={isNonMobile ? "0" : "10%"}>
                          <ListItemIcon>
                            <Box>
                              {file.Key.split("/").pop().split(".").pop() !== "mp4" && (
                                <Tooltip title="Download">
                                  <Download
                                    size={28}
                                    className="hover:bg-[#f2f2f2] hover:rounded-lg"
                                    style={{ cursor: "pointer", padding: "0.2rem" }}
                                    onClick={() => handleFileClick(file.Key)}
                                  />
                                </Tooltip>
                              )}
                              {file.Key.split("/").pop().split(".").pop() === "mp4" && (
                                <Tooltip title="Watch Video">
                                  <Eye
                                    size={28}
                                    className="hover:bg-[#f2f2f2] hover:rounded-lg"
                                    style={{ cursor: "pointer", padding: "0.2rem" }}
                                    onClick={() => HandleVideoClick(file.Key)}
                                  />
                                </Tooltip>
                              )}
                            </Box>
                          </ListItemIcon>
                        </Box>
                      </Box>
                    </ListItem>
                  ))}
              </List>
              <List dense={false} sx={{ width: "100%", marginTop: "-1rem" }}>
                {Array.isArray(Section?.links) &&
                  Section?.links.length > 0 &&
                  Section?.links
                    .filter(link => sectionFilesInThisSession.includes(link))
                    .map((Individuallink, index) => (
                      <ListItem key={index}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                          <Box display="flex" alignItems="center" marginLeft={isNonMobile ? "0" : "-2%"}>
                            <ListItemIcon>
                              <Video size={28} style={{ padding: "0.2rem" }} color="#3267ff" />
                            </ListItemIcon>
                            <ListItemText
                              sx={{ marginLeft: "-1.75rem" }}
                              primary={`${Individuallink?.split(" ")?.slice(0, -1)?.join(" ")}`}
                            />
                          </Box>
                          <ListItemIcon>
                            <Box marginLeft={isNonMobile ? "0" : "10%"}>
                              <Tooltip title="Watch Video">
                                <Eye
                                  size={28}
                                  className="hover:bg-[#f2f2f2] hover:rounded-lg"
                                  style={{ cursor: "pointer", padding: "0.2rem" }}
                                  onClick={() => {
                                    onVideoSelect(Individuallink?.split(" ")?.pop());
                                    onFileSelect(Individuallink?.split(" ")?.slice(0, -1)?.join(" "));
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          </ListItemIcon>
                        </Box>
                      </ListItem>
                    ))}
              </List>
            </div>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }
};

export default IndividualFileSection;
