import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ArrowLeft, Plus } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import FacultyAdder from "../../../components/Supervisor/ManageFaculty/FacultyAdder";
import FacultyManager from "../../../components/Supervisor/ManageFaculty/FacultyManager";
import CButton from "../../../components/ui/Button";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";

const ManageFaculty = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();

  useEffect(() => {
    document.title = "Insights | Manage Faculty";
  }, []);

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Manage Faculty", link: "" }
  ];

  const [isManageOpen, setIsManageOpen] = useState(false);
  return (
    <Box
      padding="1rem 2rem"
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <div style={{ padding: "1rem 0", marginTop: "-1rem" }}>
        <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
      </div>
      <Box display="flex" gap="0.75rem" flexDirection={isNonMobile ? "row" : "column"} justifyContent="space-between">
        <Box>
          <Typography variant={isNonMobile ? "h2" : "h3"} fontWeight={600}>
            Manage Faculty
          </Typography>
          <Typography variant="h6" color={theme.palette.primary.light}>
            Add and manage faculty here
          </Typography>
        </Box>
        <Box>
          {!isManageOpen ? (
            <CButton
              buttonText="Register Faculty"
              variant="primary"
              icon={<Plus color="#fff" size={18} weight="bold" />}
              onClickFun={() => setIsManageOpen(true)}
            />
          ) : (
            <CButton
              buttonText="View Faculty"
              variant="primary"
              icon={<ArrowLeft weight="bold" color="#fff" size={18} />}
              onClickFun={() => setIsManageOpen(false)}
            />
          )}
        </Box>
      </Box>
      {!isManageOpen ? <FacultyManager /> : <FacultyAdder />}
    </Box>
  );
};

export default ManageFaculty;
