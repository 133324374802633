import { Box, Button, Divider, Step, StepLabel, Stepper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ArrowRight, Check } from "@phosphor-icons/react";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEditResultsMutation, usePublishResultsMutation } from "../../../../redux/api/resultsSlice";
import { excelData } from "../../../../utils/excelData";
import CButton from "../../../ui/Button";
import ResultDetails from "./ResultDetails";
import ResultMarksSetter from "./ResultMarksSetter";

// import { useFetchStaffQuery } from "../../../redux/services/api";

const steps = ["Result Details", "Marks Allocation"];

const ResultStepperEdit = ({ resultById }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const publishButtonRef = useRef();
  const saveResultButtonRef = useRef();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Redux Store Variables
  const { user } = useSelector(state => state.auth);
  const [publishResults] = usePublishResultsMutation();
  const [editResults] = useEditResultsMutation();

  const { pathname } = useLocation();

  const [resultInEdit, setResultInEdit] = useState("");
  const [resultDetailState, setResultDetailState] = useState({
    resultName: resultById?.resultName,
    resultFor: resultById?.resultFor,
    resultDate: resultById?.resultDate,
    examDate: resultById?.examDate,
    resultDescription: resultById?.resultDescription,
    _id: resultById?._id || 0
  });

  const [finalPublishData, setFinalPublishData] = useState(
    resultById?.rawResult.length ? resultById?.rawResult : excelData
  );

  const publishFinal = async finalPublishData => {
    try {
      console.log("finalPublishData", finalPublishData);
      const finalArray = Object.values(finalPublishData);

      const data = await publishResults({
        results: finalArray,
        result_id: resultInEdit,
        school_id: user.school_id
      }).unwrap();
      navigate("/view-results");
    } catch (error) {
      console.log(error);
    }
  };

  const handleApi = async step => {
    if (step === 0) {
      try {
        const { _id, ...resultWithoutId } = resultDetailState;
        const data = await editResults({ resultsData: resultWithoutId, result_id: _id }).unwrap();
        setResultInEdit(data.result?._id || "");
      } catch (error) {
        console.log(error);
      }
    }
  };

  const checkSubmission = activeStep => {
    if (activeStep === 0) {
      const { resultName, resultFor, resultDate, examDate, resultDescription } = resultDetailState;
      // if (!resultName || !resultFor || !resultDate || !resultDescription) {
      //   toast.error("Please fill all the fields");
      //   return false;
      // }
      handleApi(activeStep);
      return true;
    } else if (activeStep === 1) {
      publishButtonRef.current.click();
    }
  };

  // to handle step submissions and step progression
  const isStepSkipped = step => {
    return skipped.has(step);
  };

  // to handle step submissions and step progression
  const handleNext = () => {
    const flag = checkSubmission(activeStep);
    if (flag) {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep(prevActiveStep => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  // to handle step submissions and step progression
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const showStep = actStep => {
    switch (actStep) {
      case 0:
        return <ResultDetails resultDetailState={resultDetailState} setResultDetailState={setResultDetailState} />;
      case 1:
        return (
          <ResultMarksSetter
            publishButtonRef={publishButtonRef}
            publishFinal={publishFinal}
            finalPublishData={finalPublishData}
            setFinalPublishData={setFinalPublishData}
          />
        );
      default:
        return <Box>Not found</Box>;
    }
  };

  return (
    <Box px={isNonMobile ? "2rem" : "0.75rem"} py="0.5rem">
      <Box
        display="flex"
        flexDirection="column"
        sx={{ width: "100%", height: "100%" }}
        gap="0.5rem"
        alignItems="center"
      >
        <Stepper activeStep={activeStep} sx={isNonMobile ? { width: "100%" } : { width: "100%", ml: "-1.5rem" }}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};

            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }

            return (
              <Step key={label} {...stepProps} sx={{ mb: "0.5rem" }}>
                <StepLabel {...labelProps} StepIconProps={{ style: { color: "#2970FF" } }}>
                  <Typography variant="h6">{label}</Typography>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Divider />
        <Box width="100%">
          <Box py="0.5rem">{showStep(activeStep)}</Box>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {activeStep === steps.length - 1 ? (
              <CButton
                onClickFun={handleNext}
                variant="primary"
                buttonText={
                  <Box display="flex" gap="5px" alignItems="center">
                    Publish Results
                    <Check weight="bold" size={18} />
                  </Box>
                }
              />
            ) : (
              <CButton
                onClickFun={handleNext}
                variant="primary"
                buttonText={
                  <Box display="flex" alignItems="center" gap="5px">
                    Next
                    <ArrowRight
                      color="#fff"
                      weight="fill"
                      size={18}
                      style={{ "&:hover": { color: theme.palette.secondary.main } }}
                    />
                  </Box>
                }
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ResultStepperEdit;
