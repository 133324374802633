import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Box, Button, useMediaQuery } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import {
  aiAssistantImage1,
  arrow12,
  arrow23,
  arrow34,
  arrow45,
  heroBackground,
  heroBackgroundM,
  heroImage1,
  studentAchievementIcon1,
  studentAchievementIcon2,
  studentAchievementIcon3,
  studentAchievementIcon4,
  studentAchievementImage1,
  whyChooseUsIcon1,
  whyChooseUsIcon2,
  whyChooseUsIcon3,
  whyChooseUsIcon4,
  whyChooseUsIcon5
} from "../../Assets/landing";
import DownloadAppNow from "./DownloadAppNow";
import Footer from "./Footer";
import Header from "./Header";

const Landing = () => {
  const navigate = useNavigate();
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  // useEffect(() => {
  //   navigate("/login");
  // }, []);

  return (
    <main className="flex flex-col justify-center items-center w-full gap-12 lg:gap-0 overscroll-x-none overflow-x-hidden bg-[#FAFAFB]">
      <Helmet>
        <title>ScaleYou Insights - AI-Enabled LMS for Competitive Exams.</title>
        <meta
          name="description"
          content="Start Your Journey with AI-Enabled LMS. Boost Performance by 10X Ideal for JEE, NEET, KCET, UPSC, and
              other competitive exams."
        />
        <meta
          name="keywords"
          content="ScaleYou, ScaleYou Insights, Insights, Kcet, Jee, neet, institutions and coaching centers, Institutions, coaching centers"
        />
        <meta property="og:title" content="ScaleYou" />
        <meta property="og:type" content="Dashboard" />
        <meta
          property="og:description"
          content="Start Your Journey with AI-Enabled LMS. Boost Performance by 10X Ideal for JEE, NEET, KCET, UPSC, and
              other competitive exams."
        />
        <meta property="og:image" content="http://euro-travel-example.com/thumbnail.jpg" />
        <meta property="og:url" content={window.location.href + window.location.search} />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href={window.location.href + window.location.search} />
      </Helmet>
      <Box
        sx={{
          backgroundImage: isNonMobile ? `url(${heroBackground})` : `url(${heroBackgroundM})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: isNonMobile ? "120vh" : "80vh"
        }}
      >
        <Header home={true} />
        <HeroSection />
      </Box>
      <Box sx={{ paddingTop: "10%" }}>
        <WhyChooseUs />
        <StudentAchievement />
        <DownloadAppNow />
        <AIAssistant />
      </Box>
      {/* <DataAnalytics /> */}
      {/* <SocialMedia /> */}
      {/* <OurArticle /> */}
      {/* <Pricing /> */}
      {/* <Solutions /> */}
      {/* <Jobs /> */}
      <Footer />
    </main>
  );
};

export default Landing;

const HeroSection = () => {
  return (
    <div className="top-0 flex flex-col justify-center items-center overscroll-contain">
      <div className="absolute top-0 w-full -z-10">
        <div className=" w-full">
          {/* <img src="/hero-background.webp" alt="" className="object-contain w-full hidden lg:block" /> */}
          {/* <img src={heroBackgroundM} alt="" className="object-contain sm:h-[40vh] sm:object-cover w-full lg:hidden" /> */}
        </div>
      </div>
      <div className="space-y-4 lg:space-y-8 w-full flex flex-col items-center justify-center">
        <div className="container px-4 lg:px-20 flex flex-col justify-center items-center gap-8 lg:gap-12 w-full">
          <div className="flex flex-col justify-center items-center gap-2 lg:gap-4">
            <h1 className="m-0 font-semibold text-2xl lg:text-5xl text-white text-center w-full lg:w-5/6">
              <span className="text-[#FF981F]">Insights</span> the Next-Gen Test Analysis & Management System
            </h1>
            <h2 className="m-0 font-normal text-white text-sm lg:text-lg w-full lg:w-2/3 text-center opacity-80">
              Start Your Journey with AI-Enabled LMS. Boost Performance by 10X Ideal for JEE, NEET, KCET, UPSC, and
              other competitive exams.
            </h2>
          </div>

          <div className="flex justify-center items-center gap-4">
            <Button
              LinkComponent={Link}
              to="/start-now"
              variant="text"
              className="px-6 py-2 lg:py-3 bg-[#FF981F] text-white text-base capitalize rounded-lg"
            >
              Start Now
            </Button>
            <Button
              LinkComponent={Link}
              to="/"
              variant="outlined"
              className="px-6 py-2 lg:py-3 text-white text-base border border-white capitalize rounded-lg"
            >
              Book a Trial <ArrowRightAltIcon className="ms-2" />
            </Button>
          </div>

          <div>
            <div className="w-full">
              <img
                src={heroImage1}
                title="Insights-dashboard-demo-page"
                alt="A demo page image from ScaleYou Insights Dashboard"
                height="100%"
                width="100%"
                className="object-contain w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const WhyChooseUs = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full md:pt:[0%] lg:pt-10">
      <div className="container px-4 lg:px-20 flex flex-col justify-center items-center w-full gap-8 lg:gap-12">
        <div className="flex flex-col justify-center items-center w-full gap-4">
          <h2 className="m-0 font-semibold text-2xl lg:text-4xl text-[#14358A] text-center w-full lg:w-1/2">
            Why <span className="text-[#FF981F]"> Institutes </span> Choose Us
          </h2>
          <h3 className="m-0 font-normal text-sm lg:text-base text-[#006FBA] text-center">
            Fully utilizing Scaleyou Insights for your institute brings great benefits. Here are the key <br />{" "}
            <span className="text-[#FF981F]">Advantages</span>
          </h3>
        </div>

        <section className="relative flex flex-col justify-center items-center w-full gap-4 lg:gap-16">
          <section className="relative flex flex-col lg:flex-row justify-center items-center w-full gap-4 lg:gap-16">
            <div className="absolute top-0 left-[20%] w-full h-full z-10">
              <img
                src={arrow12}
                title="arrow-from-1-to-2"
                alt="Arrow depicting the flow from student performance to top achievers"
                height="100%"
                width="100%"
                className="object-contain hidden lg:block w-[24%] h-[24%]"
              />
            </div>
            <div className="flex flex-col justify-center items-center w-full lg:w-1/3">
              <div className="w-16">
                <img
                  src={whyChooseUsIcon1}
                  title="Student-performance-icon"
                  alt="Icon for Student Performance"
                  height="100%"
                  width="100%"
                  className="w-full object-contain"
                />
              </div>
              <div className="flex flex-col justify-center items-center text-[#006FBA] space-y-1">
                <h3 className="m-0 p-0 text-base lg:text-lg font-semibold w-full text-center">
                  Student performance up 55%
                </h3>
                <h4 className="m-0 p-0 font-normal text-xs lg:text-sm text-[#68718B] text-center">
                  Experience unparalleled growth with tailored learning paths and comprehensive analytics, boosting
                  student performance by 55%
                </h4>
              </div>
            </div>
            <div className="absolute top-5 left-[55%] w-full h-full z-10">
              <img
                src={arrow23}
                title="arrow-from-2-to-3"
                alt="Arrow depicting the flow from top achievers to faculty performance"
                height="100%"
                width="100%"
                className="object-contain hidden lg:block w-[25%] h-[25%]"
              />
            </div>
            <div className="flex flex-col justify-center items-center w-full lg:w-1/3">
              <div className="w-16">
                <img
                  src={whyChooseUsIcon2}
                  title="Top-achievers-icon"
                  alt="Icon for top achievers"
                  height="100%"
                  width="100%"
                  className="object-contain w-full"
                />
              </div>
              <div className="flex flex-col justify-center items-center text-[#006FBA] space-y-1">
                <h3 className="m-0 p-0 text-base lg:text-lg font-semibold w-full text-center">
                  More top achievers and Rankers
                </h3>
                <h4 className="m-0 p-0 font-normal text-xs lg:text-sm text-[#68718B] text-center">
                  Empower students with advanced resources and personalized support, resulting in more top achievers and
                  rankers
                </h4>
              </div>
            </div>

            <div className="flex flex-col justify-center items-center w-full lg:w-1/3">
              <div className="w-16">
                <img
                  src={whyChooseUsIcon3}
                  title="Faculty-Performance-icon"
                  alt="Icon for increased faculty Performance"
                  height="100%"
                  width="100%"
                  className="object-contain w-full"
                />
              </div>
              <div className="flex flex-col justify-center items-center text-[#006FBA] space-y-1">
                <h3 className="m-0 p-0 text-base lg:text-lg font-semibold w-full text-center">
                  Faculty performance up 63%
                </h3>
                <h4 className="m-0 p-0 font-normal text-xs lg:text-sm text-[#68718B] text-center">
                  Enhanced tools and data-driven insights along with AI assistance enable faculty to excel, boosting
                  their performance by 63%
                </h4>
              </div>
            </div>
          </section>
          <div className="absolute top-[42%] left-[65%] w-full h-full -z-1">
            <img
              src={arrow34}
              title="arrow-from-3-to-4"
              alt="Arrow depicting the flow from faculty performance to increased student satisfaction"
              height="100%"
              width="100%"
              className="object-contain hidden lg:block w-[28%] h-[28%]"
            />
          </div>
          <section className=" relative flex flex-col lg:flex-row justify-center items-center w-full gap-4 lg:gap-16">
            <div className="flex flex-col justify-center items-center w-full lg:w-1/3">
              <div className="w-16">
                <img
                  src={whyChooseUsIcon4}
                  title="student-performance-icon"
                  alt="Icon for Increase Student performance"
                  height="100%"
                  width="100%"
                  className="object-contain w-full"
                />
              </div>
              <div className="flex flex-col justify-center items-center text-[#006FBA] space-y-1">
                <h3 className="m-0 p-0 text-base lg:text-lg font-semibold w-full text-center">
                  Better results, better business
                </h3>
                <h4 className="m-0 p-0 font-normal text-xs lg:text-sm text-[#68718B] text-center">
                  Enhancing outcomes through strategic insights and optimized processes leads to improved business
                  performance and greater success.
                </h4>
              </div>
            </div>
            <div className="absolute top-0 left-[37%] w-full h-full -z-1">
              <img
                src={arrow45}
                title="arrow-from-4-to-5"
                alt="Arrow depicting the flow from Increase student satisfaction to better results and better business"
                height="100%"
                width="100%"
                className="object-contain hidden lg:block w-[28%] h-[26%]"
              />
            </div>
            <div className="flex flex-col justify-center items-center w-full lg:w-1/3">
              <div className="w-16">
                <img
                  src={whyChooseUsIcon5}
                  title="better-results-and-better-business-icon"
                  alt="Icon for Better results and better business"
                  height="100%"
                  width="100%"
                  className="object-contain w-full -z-10"
                />
              </div>
              <div className="flex flex-col justify-center items-center text-[#006FBA] space-y-1">
                <h3 className="m-0 p-0 text-base lg:text-lg font-semibold w-full text-center">
                  Increased student satisfaction by 78%
                </h3>
                <h4 className="m-0 font-normal p-0 text-xs lg:text-sm text-[#68718B] text-center">
                  Personalized learning experiences and continuous support drive a 78% increase in student satisfaction
                </h4>
              </div>
            </div>
          </section>
        </section>
      </div>
    </div>
  );
};

const StudentAchievement = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full lg:pt-20">
      <div className="container px-4 lg:px-20 flex flex-col justify-center items-center w-full gap-8 lg:gap-12">
        <div className="flex justify-center items-center w-full">
          <h2 className="m-0 font-semibold text-2xl lg:text-4xl text-[#14358A] text-center w-full lg:w-1/2">
            Fostering <span className="text-[#FF981F]"> student </span> achievement in competitive landscapes.
          </h2>
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 lg:gap-0">
            <section className="flex flex-col justify-center items-start w-full gap-4 lg:gap-16">
              <div className="bg-[#DBE7FF] rounded-lg">
                <div className="flex flex-col justify-start lg:justify-center items-center p-3 lg:items-start gap-2">
                  <div className="w-16 self-center">
                    <img
                      src={studentAchievementIcon1}
                      title="increase-in-student-performance"
                      alt="Image depicting the increase of student performance"
                      height="100%"
                      width="100%"
                      className="object-contain w-full"
                    />
                  </div>
                  <div className="text-[#006FBA] space-y-2">
                    <h3 className="m-0 text-xl lg:text-2xl font-semibold w-full text-center lg:text-start">
                      Offline/Online Test Platform
                    </h3>
                    <h4 className="m-0 font-normal text-xs lg:text-sm text-[#68718B] text-center lg:text-start">
                      Our hybrid test platform offers online exams with automated proctoring and instant results, and
                      offline exams using scannable OMR sheets for quick analysis.
                    </h4>
                  </div>
                </div>
              </div>
              <div className="bg-[#DBE7FF] rounded-lg">
                <div className="flex flex-col justify-start lg:justify-center items-center p-3 lg:items-start gap-2">
                  <div className="w-16 self-center">
                    <img
                      src={studentAchievementIcon2}
                      title="Offline-and-online-platform-icon"
                      alt="Icon depicting Offline and online platform"
                      height="100%"
                      width="100%"
                      className="object-contain w-full"
                    />
                  </div>
                  <div className="text-[#006FBA] space-y-2">
                    <h3 className="m-0 text-xl lg:text-2xl font-semibold w-full text-center lg:text-start">
                      Report Automation
                    </h3>
                    <h4 className="m-0 font-normal text-xs lg:text-sm text-[#68718B] text-center lg:text-start">
                      Automated reports are crucial for student-parent-teac forher meetings, offering a detailed
                      overview of a student's academic progress.."
                    </h4>
                  </div>
                </div>
              </div>
            </section>
            <section className="hidden lg:block col-span-2 w-full">
              <div className="w-full">
                <img
                  src={studentAchievementImage1}
                  title="Automated-report-generation-icon"
                  alt="Icon depicting automated report generation"
                  height="100%"
                  width="100%"
                  className="object-contain w-full"
                />
              </div>
            </section>
            <section className="flex flex-col justify-start items-start w-full gap-4 lg:gap-16">
              <div className="bg-[#DBE7FF] rounded-lg flex items-start ">
                <div className="flex flex-col justify-start lg:justify-center items-center p-3 lg:items-start gap-2">
                  <div className="w-16 self-center">
                    <img
                      src={studentAchievementIcon3}
                      title="Scaleyou-Insights-is-one-Stop-Solution-icon"
                      alt="Icon depicting Scaleyou Insights is a One stop solution"
                      height="100%"
                      width="100%"
                      className="object-contain w-full"
                    />
                  </div>
                  <div className="text-[#006FBA] space-y-2">
                    <h3 className="m-0 text-xl lg:text-2xl font-semibold w-full text-center lg:text-start">
                      One stop Solution
                    </h3>
                    <h4 className="m-0 font-normal text-xs lg:text-sm text-[#68718B] text-center lg:text-start">
                      A one-stop solution streamlines workflows for both students and educators, fostering efficiency
                      and enabling greater focus on academic achievement.
                    </h4>
                  </div>
                </div>
              </div>
              <div className="bg-[#DBE7FF] rounded-lg">
                <div className="flex flex-col justify-start lg:justify-center items-center p-3 lg:items-start gap-2">
                  <div className="w-16 self-center">
                    <img
                      src={studentAchievementIcon4}
                      title="Advance-analytics-on-student"
                      alt="Icon to indicate advanced analytics on students"
                      height="100%"
                      width="100%"
                      className="object-contain w-full"
                    />
                  </div>
                  <div className="text-[#006FBA] space-y-2">
                    <h3 className="m-0 text-xl lg:text-2xl font-semibold w-full text-center lg:text-start">
                      Advanced Analytics
                    </h3>
                    <h4 className="m-0 font-normal text-xs lg:text-sm text-[#68718B] text-center lg:text-start">
                      Utilizing advanced analytics, helps educators customize support strategies to meet individual
                      student needs, leading to enhanced achievement in competitive settings.
                    </h4>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="mt-8 lg:mt-0">
            <Button
              LinkComponent={Link}
              to="/solutions"
              variant="text"
              className="px-6 py-2 bg-[#FF981F] text-white text-base capitalize"
            >
              Learn More
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const AIAssistant = () => {
  return (
    <div className="relative flex flex-col justify-center items-center w-full h-full lg:pt-20">
      <div className="container px-4 lg:px-20  flex flex-col justify-center items-center w-full gap-8">
        <div className="space-y-2">
          <h2 className="m-0 font-semibold text-2xl lg:text-4xl text-[#FFFFFF] lg:text-[#14358A] text-center w-full">
            Integrated with <span className="text-[#FF981F]"> Scaleyou </span> AI assistant
          </h2>
        </div>
        <div className="flex justify-center items-center w-full">
          <div className="w-full lg:w-3/4">
            <img
              src={aiAssistantImage1}
              title="ScaleYou-Insights-AI-Assistant-preview"
              alt="Image is a preview of ScaleYou insights AI assistant"
              height="100%"
              width="100%"
              className="object-contain w-full rounded-md"
            />
          </div>
        </div>

        <div className="flex flex-col justify-center items-center w-full gap-4">
          <p className="m-0 text-xs lg:text-sm text-[#68718B] text-center lg:w-3/4">
            Scaleyou's AI chat assistant provides instant, personalized support and information to enhance students'
            study experiences, while supervisors can access comprehensive analytics and faculty can obtain detailed
            analyses of their classes and students.
          </p>

          <Button
            LinkComponent={Link}
            to="/"
            variant="text"
            className="px-6 py-2 bg-[#FF981F] text-white text-base capitalize"
          >
            Coming Soon!
          </Button>
        </div>
      </div>
    </div>
  );
};
