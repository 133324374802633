import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

const initialState = {
  announcement: {},
  announcements: [],
  individualAnnouncement: {},
  loading: false,
  error: false,
  success: false,
  message: ""
};

import announcementService from "../../services/announcement/announcement";

// fetch all announcements by batch _id
export const fetchAnnouncementsByBatchId = createAsyncThunk(
  "announcements/fetchByBatchIdStatus",
  async (batchId, thunkAPI) => {
    try {
      return await announcementService.fetchAnnouncementsForBatch(batchId);
    } catch (error) {
      const message = error.response.data.message || error.toString();

      thunkAPI.rejectWithValue(message);
    }
  }
);

// fetch all announcements by school _id
export const fetchAnnouncementsBySchoolId = createAsyncThunk(
  "announcements/fetchBySchoolIdStatus",
  async (schoolId, thunkAPI) => {
    try {
      return await announcementService.fetchAnnouncementsForSchool(schoolId);
    } catch (error) {
      const message = error.response.data.message || error.toString();

      thunkAPI.rejectWithValue(message);
    }
  }
);

// fetch individual announcement by _id
export const fetchAnnouncementById = createAsyncThunk("announcements/fetchByIdStatus", async (_id, thunkAPI) => {
  try {
    return await announcementService.fetchAnnouncementById(_id);
  } catch (error) {
    const message = error.response.data.message || error.toString();

    thunkAPI.rejectWithValue(message);
  }
});

// create announcement
export const createAnnouncement = createAsyncThunk("announcements/createnew", async (announcement, thunkAPI) => {
  try {
    const response = await announcementService.createNewAnnouncement(announcement);

    toast.success("announcement created successfully 🎉");

    return response;
  } catch (error) {
    const message = error.response.data.message || error.toString();

    thunkAPI.rejectWithValue(message);
  }
});

// update announcement
export const updateAnnouncement = createAsyncThunk(
  "announcments/update",
  async ({ _id, ...updatedFields }, thunkAPI) => {
    try {
      const response = await announcementService.updateAnnouncement(_id, updatedFields);

      toast.success("announcement updated successfully ✅");

      return response;
    } catch (error) {
      const message = error.response.data.message || error.toString();

      thunkAPI.rejectWithValue(message);
    }
  }
);

// delete announcement
export const deleteAnnouncement = createAsyncThunk("announcements/delete", async (_id, thunkAPI) => {
  try {
    const response = await announcementService.deleteAnnouncement(_id);

    toast.success("announcement deleted successfully 🗑️");

    return response;
  } catch (error) {
    const message = error.response.data.message || error.toString();

    thunkAPI.rejectWithValue(message);
  }
});

// announcement slice
const announcementSlice = createSlice({
  name: "announcments",

  initialState,

  reducers: {
    reset: state => {
      state.loading = false;

      state.message = "";

      state.error = false;

      state.success = false;
    }
  },

  extraReducers: builder => {
    builder
      .addCase(fetchAnnouncementsByBatchId.fulfilled, (state, action) => {
        state.announcements = action.payload.announcements;

        state.loading = false;

        state.success = true;

        state.message = action.payload.message;
      })
      .addCase(fetchAnnouncementsByBatchId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchAnnouncementsByBatchId.rejected, (state, action) => {
        state.loading = false;

        state.error = true;

        state.success = false;

        state.message = action.payload;
      })
      .addCase(fetchAnnouncementsBySchoolId.fulfilled, (state, action) => {
        state.announcements = action.payload.announcements;

        state.loading = false;

        state.success = true;

        state.message = action.payload.message;
      })
      .addCase(fetchAnnouncementsBySchoolId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchAnnouncementsBySchoolId.rejected, (state, action) => {
        state.loading = false;

        state.error = true;

        state.success = false;

        state.message = action.payload.message;
      })
      .addCase(fetchAnnouncementById.fulfilled, (state, action) => {
        state.individualAnnouncement = action.payload.announcement;

        state.loading = false;

        state.success = true;

        state.message = action.payload.message;
      })
      .addCase(fetchAnnouncementById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchAnnouncementById.rejected, (state, action) => {
        state.loading = false;

        state.error = true;

        state.success = false;

        state.message = action.payload.message;
      })
      .addCase(createAnnouncement.fulfilled, (state, action) => {
        state.announcement = action.payload.newAnnouncement;

        state.loading = false;

        state.success = true;

        state.message = action.payload.message;
      })
      .addCase(createAnnouncement.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createAnnouncement.rejected, (state, action) => {
        state.loading = false;

        state.error = true;

        state.success = false;

        state.message = action.payload.message;
      })
      .addCase(updateAnnouncement.fulfilled, (state, action) => {
        state.announcement = action.payload.updatedAnnouncement;

        state.loading = false;

        state.success = true;

        state.message = action.payload.message;
      })
      .addCase(updateAnnouncement.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateAnnouncement.rejected, (state, action) => {
        state.loading = false;

        state.error = true;

        state.success = false;

        state.message = action.payload.message;
      })
      .addCase(deleteAnnouncement.fulfilled, (state, action) => {
        state.announcement = action.payload.deletedAnnouncement;

        state.loading = false;

        state.success = true;

        state.message = action.payload.message;
      })
      .addCase(deleteAnnouncement.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteAnnouncement.rejected, (state, action) => {
        state.error = true;

        state.success = false;

        state.message = action.payload.message;

        state.loading = false;
      });
  }
});

export const { reset } = announcementSlice.actions;

export default announcementSlice.reducer;
