import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
  useMediaQuery,
  Menu,
  MenuItem,
  Button,
  CircularProgress
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useSelector } from "react-redux";
import { useGetASectionsQuery, useUpdateAdescriptionMutation } from "../../../../redux/api/taskSlice";

import SessionDetails from "../../../../components/Teacher/ViewBatchTeacher/SessionDetails";

const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left"
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    "borderRadius": 6,
    "marginTop": theme.spacing(1),
    "minWidth": 180,
    "color": theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    "boxShadow":
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0"
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0
    }
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]: {
    marginLeft: -1,
    borderLeft: "1px solid transparent"
  }
}));

const MyCoursesPage = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const { user } = useSelector(state => state.auth);
  const params = useParams();
  const navigate = useNavigate();
  const { batchId, sectionId } = params;
  const { data: sectionDetails, isLoading: LoadingSectionFiles } = useGetASectionsQuery({ batchId, sectionId });
  const [updateDescription, { isLoadingDescription }] = useUpdateAdescriptionMutation();
  const [alignment, setAlignment] = useState("Course Details");
  const [menuItem, setMenuItem] = useState("pre_session");
  const [anchorEl, setAnchorEl] = useState(null);
  const [preSessionData, setPreSessionData] = useState("");
  const [inSessionData, setInSessionData] = useState("");
  const [postSessionData, setPostSessionData] = useState("");
  const [currentDescription, setCurrentDescription] = useState("");
  const [fileKeys, setFileKeys] = useState([]);

  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (sectionDetails) {
      if (menuItem) {
        setCurrentDescription(sectionDetails?.section?.description[menuItem] || "");
      } else {
        setCurrentDescription("");
      }
    }
  }, [sectionDetails, menuItem]);

  const handleFileChange = fileKey => {
    setFileKeys(prevKeys => [...prevKeys, fileKey]);
  };

  const handlePublishButton = async () => {
    // Logic to save the session data

    const description = {};

    description[menuItem] = currentDescription;

    // Implement your save logic here (e.g., API call to save the data)
    await updateDescription({
      section_id: sectionId,
      description
    });

    navigate(-1);
  };

  const handleCancelButton = () => {
    // Logic to cancel the changes
    navigate(-1);
  };
  return (
    <Box display={"flex"} flexDirection={"column"} gap={"2rem"} bgcolor="#f6f6f6" height="100%">
      <Box margin="1rem">
        <Typography variant="h5" gutterBottom>
          My Section
        </Typography>

        <Box
          display="flex"
          justifyContent="space-between"
          px="1rem"
          py="1rem"
          width="100%"
          bgcolor="#fff"
          borderRadius="1rem"
          border="1px solid #E6EDFF"
        >
          {/* <OutlinedInput fullWidth value={sectionDetails?.section?.name} disabled />
          {console.log("SectionDetails: ", sectionDetails)} */}
          <Box display={"flex"} flexDirection="row" gap="0.5rem" sx={{ width: "100%" }}>
            <Typography variant="h3" fontWeight="300" color="#707070">
              Topic :{" "}
            </Typography>
            <Typography variant="h3" fontWeight="300">
              <div> {` ${sectionDetails?.section?.name}`}</div>
            </Typography>
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            <ArrowDownwardIcon fontSize="large" />
          </Box>
        </Box>

        <Box
          px="1rem"
          py="1rem"
          width="100%"
          bgcolor="#fff"
          borderRadius="1rem"
          border="1px solid #E6EDFF"
          marginTop={"1rem"}
        >
          <Box marginTop="1rem">
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
              sx={{
                "backgroundColor": "#fff",
                "border": "2px solid #000",
                "color": "#000",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#000"
                }
              }}
            >
              {menuItem}
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button"
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setMenuItem("pre_session");
                  handleClose();
                }}
                disableRipple
              >
                Pre Session
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setMenuItem("in_session");
                  handleClose();
                }}
                disableRipple
              >
                In Session
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setMenuItem("post_session");
                  handleClose();
                }}
                disableRipple
              >
                Post Session
              </MenuItem>
            </StyledMenu>
          </Box>
          <Box>
            {LoadingSectionFiles ? (
              <Box display="flex" justifyContent="center" sx={{ display: "flex", width: "100%" }}>
                <CircularProgress sx={{ alignSelf: "center", color: "#3267ff" }} />
              </Box>
            ) : (
              <>
                {sectionDetails && (
                  <SessionDetails
                    sectionDetails={sectionDetails}
                    session_name={menuItem}
                    currentDescription={currentDescription}
                    setCurrentDescription={setCurrentDescription}
                    onFileChange={handleFileChange}
                  />
                )}
              </>
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" bgcolor="#fff" marginTop="1rem" height="4rem">
          <Box display="flex" justifyContent="space-between" sx={{ width: "95%" }}>
            <Button
              variant="outlined"
              sx={{
                "width": "20%",
                "height": "2.5rem",
                "border": "1px solid #2970ff",
                "&:hover": { border: "1px solid #2970ff" }
              }}
              onClick={handleCancelButton}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handlePublishButton}
              sx={{
                "width": "20%",
                "height": "2.5rem",
                "color": "#000000",
                "display": "flex",
                "gap": "0.5rem",
                "backgroundColor": "#ffd929",
                "&:hover": { backgroundColor: "#ffd929" }
              }}
            >
              <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.5 2.0625V18.5625C16.5 19.7017 15.5767 20.625 14.4375 20.625H14.1749C14.7524 20.1204 15.125 19.3875 15.125 18.5625V2.0625C15.125 1.2375 14.7524 0.504625 14.1749 0H14.4375C15.5767 0 16.5 0.923313 16.5 2.0625ZM14.4375 18.5625C14.4375 19.7017 13.5142 20.625 12.375 20.625H2.0625C0.923313 20.625 0 19.7017 0 18.5625V2.0625C0 0.923313 0.923313 0 2.0625 0H12.375C13.5142 0 14.4375 0.923313 14.4375 2.0625V18.5625ZM5.15625 5.84375C5.15625 6.0335 5.31025 6.1875 5.5 6.1875H8.9375C9.12725 6.1875 9.28125 6.0335 9.28125 5.84375C9.28125 5.654 9.12725 5.5 8.9375 5.5H5.5C5.31025 5.5 5.15625 5.654 5.15625 5.84375ZM9.28125 8.59375C9.28125 8.404 9.12725 8.25 8.9375 8.25H5.5C5.31025 8.25 5.15625 8.404 5.15625 8.59375C5.15625 8.7835 5.31025 8.9375 5.5 8.9375H8.9375C9.12725 8.9375 9.28125 8.7835 9.28125 8.59375ZM9.96875 7.21875C9.96875 7.029 9.81475 6.875 9.625 6.875H4.8125C4.62275 6.875 4.46875 7.029 4.46875 7.21875C4.46875 7.4085 4.62275 7.5625 4.8125 7.5625H9.625C9.81475 7.5625 9.96875 7.4085 9.96875 7.21875Z"
                  fill="#0B1719"
                />
              </svg>
              Publish Course
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MyCoursesPage;
