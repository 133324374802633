// inbuilt modules
import React, { useEffect, useState } from "react";

// external modules
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { LockSimple } from "@phosphor-icons/react";
import toast from "react-hot-toast";
import Carousel from "react-material-ui-carousel";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

// internal modules
import { loginPageImage, logo, logoPlain } from "../../Assets/index";
import CButton from "../ui/Button";
import { useLoginMutation } from "../../redux/api/authSlice";
import { setCredentials } from "../../redux/features/auth/authSlice";
import TypewriterEffect from "./TypeWriteEffect";

// functions and variables
const InputBox = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
}));

const LoginCarousel = () => {
  const items = [
    {
      id: 1,
      name: "Scaleyou Insights",
      description:
        "Precision Analysis, Performance Enhancement, ScaleYou Insights for students teachers and Institutes!"
    },
    {
      id: 2,
      name: "Detailed Reports and Analysis",
      description: "Access detailed reports and analysis instantly with just one click using Scaleyou Insights!"
    },
    {
      id: 3,
      name: " Test and Courses Management System",
      description:
        "Experience the best versatile offline/online test format and manage all your courses on Scaleyou Insights."
    }
  ];

  return (
    <Box marginTop="1rem" marginBottom="1rem" color="#fff">
      <Carousel
        autoPlay="true"
        animation="slide"
        loop
        duration="2000"
        interval="5000"
        activeIndicatorIconButtonProps={{
          style: {
            color: "#fff"
          }
        }}
      >
        {items.map(item => (
          <Box
            key={item.id}
            display="flex"
            flexDirection="column"
            gap="0.5rem"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h4" fontWeight={500} textAlign="center">
              {item.name}
            </Typography>
            <Typography variant="h5" textAlign="center">
              {item.description}
            </Typography>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

const LoginDesktop = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  // theme
  const theme = useTheme();

  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [login, { isLoading, isError, isSuccess }] = useLoginMutation();

  // useState
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [passwordVisible, setPasswordVisible] = useState(false);

  // functions and variables
  const handleChange = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleLogin = async () => {
    const userData = {
      email: formData.email,
      password: formData.password
    };
    try {
      const response = await login(userData);
      dispatch(setCredentials(response?.data));
      if (response?.data) {
        navigate("/");
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (formData.email.trim() === "") {
      return toast.error("Please enter your email");
    }

    if (formData.password.trim() === "") {
      return toast.error("Please enter your password");
    }

    handleLogin();
  };

  useEffect(() => {
    document.title = "Insights | LoginDesktop";
  }, []);

  return (
    <Box width="100vw" height="100vh" padding="0.75rem" display="flex">
      {isLoading && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <TypewriterEffect />
        </Box>
      )}
      <Box width="100%">
        <img src={logo} alt="logoImage" />
        <Box
          width="100%"
          display="flex"
          height="80%"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            width={isNonMobile ? "60%" : "90%"}
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Box display="flex" justifyContent="end" height="100%" flexDirection="column" alignItems="start">
              <Typography variant="h3" mb="0.5rem">
                LoginDesktop
              </Typography>
              {/* <Typography variant="h5">
                Welcome to Scaleyou’s exam analysis software, please put your login credentials below to start using the
                software
              </Typography> */}
              <FormControl fullWidth>
                <Box mt="3rem" display="flex" flexDirection="column" gap="0.75rem">
                  <InputBox>
                    <Typography variant="h5" fontWeight={500}>
                      Email
                    </Typography>
                    <TextField
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      size="small"
                      required
                      placeholder="Enter email"
                      type="email"
                      sx={{ width: isNonMobile ? "50%" : "60%", color: theme.palette.secondary.main }}
                      InputProps={{ inputProps: { style: { color: theme.palette.secondary.main } } }}
                    />
                  </InputBox>
                  <InputBox>
                    <Typography variant="h5" fontWeight={500}>
                      Password
                    </Typography>
                    <TextField
                      id="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      size="small"
                      required
                      placeholder="Enter password"
                      type={passwordVisible ? "text" : "password"}
                      className="sentry-mask"
                      sx={{ width: isNonMobile ? "50%" : "60%", color: theme.palette.secondary.main }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockSimple color={theme.palette.secondary.main} weight="fill" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Checkbox
                              checked={passwordVisible}
                              onChange={() => setPasswordVisible(!passwordVisible)}
                              sx={{
                                "color": theme.palette.secondary.main,
                                "p": 0,
                                "&.Mui-checked": {
                                  color: theme.palette.secondary.main
                                }
                              }}
                            />
                          </InputAdornment>
                        ),
                        inputProps: { style: { color: theme.palette.secondary.main } }
                      }}
                    />
                  </InputBox>
                </Box>
                <Box
                  display="flex"
                  mt="0.5rem"
                  justifyContent="flex-end"
                  color={theme.palette.primary.main}
                  component={Link}
                  to="/forgot-password"
                >
                  Forgot Password?
                </Box>
                <Divider sx={{ mt: "1rem", color: theme.palette.primary.main }} />
                {/* <Box display="flex" mt="1rem" alignItems="center" justifyContent="space-between">
                <Checkbox />
              </Box> */}
                <Box display="flex" mt="1rem" justifyContent="flex-end">
                  <CButton buttonText={"Sign In"} variant={"primary"} onClickFun={handleSubmit} />
                </Box>
              </FormControl>
              <Box width="100%" display="flex" alignItems="center" justifyContent="center" gap="0.7rem" mt="1rem">
                <Typography variant="h5" textAlign="center">
                  Don't have an account?
                </Typography>
                <Link to="/signup" color="#fff" variant="h5" fontWeight={500} sx={{ height: "0" }}>
                  Sign Up
                </Link>
              </Box>
            </Box>

            <Box display="flex" justifyContent="end" height="50%" flexDirection="column" alignItems="center">
              <Typography variant="h6" textAlign="center">
                For sales and queries visit{" "}
                <Link to="/https://www.scaleyou-insights.com/" target="_blank">
                  www.scaleyou-insights.com
                </Link>
              </Typography>
              <Typography variant="h6" textAlign="center">
                © 2023 Scaleyou. Powered and secured by Scaleyou
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {isNonMobile && (
        <Box
          // className="analysis"
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          <Box position="absolute" width="100%" height="100%">
            <Box
              position="absolute"
              width="100%"
              height="100%"
              bgcolor="#3266fc"
              zIndex="-1"
              borderRadius="1rem"
              style={{ opacity: "0.7" }}
            />
            <Box position="absolute" width="100%" height="100%" zIndex="-2">
              <img
                src={loginPageImage}
                alt="LoginDesktop"
                style={{
                  zIndex: "4",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  animation: "moveRight 30s linear infinite"
                }}
              />
            </Box>
          </Box>

          <Box position="absolute" display="flex" justifyContent="center" alignItems="center" width="100%">
            <img src={logoPlain} alt="logoImage" width="60%" />

            {/* <Typography variant="h5" textAlign="center" color="#fff">
              1 Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa illo fugiat aut quisquam ex dolorum
              obcaecati ratione expedita, iste soluta porro saepe at facilis numquam provident minus fuga ipsam
              laudantium.
            </Typography> */}
          </Box>
          <Box width="90%" position="absolute" bottom="2rem">
            <LoginCarousel />
          </Box>
        </Box>
      )}
    </Box>
  );
};

// render
export default LoginDesktop;
