import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React from "react";

const ExamDetails = ({ examDetailState, setExamDetailState }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();

  const onTypeChange = event => {
    setExamDetailState(prevState => ({
      ...prevState,
      examType: event.target.value
    }));
  };

  const onForChange = event => {
    setExamDetailState(prevState => ({
      ...prevState,
      examFor: event.target.value
    }));
  };

  const onNameChange = event => {
    setExamDetailState(prevState => ({
      ...prevState,
      examName: event.target.value
    }));
  };

  const onDateChange = event => {
    setExamDetailState(prevState => ({
      ...prevState,
      examDate: event.target.value
    }));
  };

  const onTotalQuestionsChange = event => {
    setExamDetailState(prevState => ({
      ...prevState,
      totalQuestions: event.target.value
    }));
  };

  const onDescriptionChange = event => {
    setExamDetailState(prevState => ({
      ...prevState,
      exam_description: event.target.value
    }));
  };

  return (
    <Box py="2rem" display="flex" flexDirection="column" gap="3rem">
      <Box width="100%" display="flex" flexDirection="column" gap="1.5rem">
        <Box
          width="100%"
          display="flex"
          gap={isNonMobile ? "5rem" : "2rem"}
          flexDirection={isNonMobile ? "row" : "column"}
        >
          <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
            <Typography variant="h4" mb="0.2rem">
              Type of Exam
            </Typography>
            <Divider sx={{ width: "80%" }} />
            <FormControl>
              <InputLabel id="examType" sx={{ color: theme.palette.secondary.main }}>
                Select
              </InputLabel>
              <Select
                labelId="examType"
                id="examType"
                value={examDetailState?.examType}
                onChange={onTypeChange}
                label="Type"
                sx={{
                  width: "80%",
                  borderRadius: "1rem",
                  bgcolor: "#F7F6F9",
                  color: theme.palette.secondary.main
                }}
              >
                <MenuItem value="" sx={{ color: theme.palette.primary.light }}>
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Chapter Test" sx={{ color: theme.palette.secondary.main }}>
                  Chapter Test
                </MenuItem>
                <MenuItem value="Unit Test" sx={{ color: theme.palette.secondary.main }}>
                  Unit Test
                </MenuItem>
                <MenuItem value="Part Test" sx={{ color: theme.palette.secondary.main }}>
                  Part Test
                </MenuItem>
                <MenuItem value="Full Test" sx={{ color: theme.palette.secondary.main }}>
                  Full Test
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
            <Typography variant="h4" mb="0.2rem">
              Exam for
            </Typography>
            <Divider sx={{ width: "80%" }} />
            <FormControl>
              <InputLabel id="examFor" sx={{ color: theme.palette.secondary.main }}>
                Select
              </InputLabel>
              <Select
                labelId="examFor"
                id="examFor"
                value={examDetailState?.examFor}
                onChange={onForChange}
                label="Exam for"
                sx={{
                  width: "80%",
                  borderRadius: "1rem",
                  bgcolor: "#F7F6F9",
                  color: theme.palette.secondary.main
                }}
              >
                <MenuItem value="" sx={{ color: theme.palette.primary.light }}>
                  <em>None</em>
                </MenuItem>
                <MenuItem value="KCET" sx={{ color: theme.palette.secondary.main }}>
                  KCET
                </MenuItem>
                <MenuItem value="JEE" sx={{ color: theme.palette.secondary.main }}>
                  JEE
                </MenuItem>
                <MenuItem value="NEET" sx={{ color: theme.palette.secondary.main }}>
                  NEET
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          gap={isNonMobile ? "5rem" : "2rem"}
          flexDirection={isNonMobile ? "row" : "column"}
        >
          <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
            <Typography variant="h4" mb="0.2rem">
              Name of the exam
            </Typography>
            <Divider sx={{ width: "80%" }} />
            <FormControl>
              <TextField
                placeholder="Enter exam name"
                value={examDetailState?.examName}
                onChange={onNameChange}
                type="text"
                sx={{
                  width: "80%",
                  bgcolor: "#F7F6F9"
                }}
                InputProps={{ inputProps: { style: { color: theme.palette.secondary.main } } }}
              />
            </FormControl>
          </Box>
          <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
            <Typography variant="h4" mb="0.2rem">
              Date of exam
            </Typography>
            <Divider sx={{ width: "80%" }} />
            <input
              className="border-2  px-2 py-1 w-60 rounded-md bg-[#F7F6F9] text-[#3266FC]"
              value={examDetailState?.examDate}
              onChange={onDateChange}
              style={{
                color: theme.palette.secondary.main,
                width: "80%",
                height: "52px",
                padding: "10px",
                backgroundColor: "#F7F6F9",
                border: "1px solid rgba(0,0,0,0.2)",
                borderRadius: "5px"
              }}
              placeholder="Select a Date"
              type="date"
            />
          </Box>
        </Box>
        <Box width="100%" display="flex" gap="5rem">
          <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
            <Typography variant="h4" mb="0.2rem">
              Total Number of questions
            </Typography>
            <Divider sx={{ width: "80%" }} />
            <FormControl>
              <TextField
                value={examDetailState?.totalQuestions}
                onChange={onTotalQuestionsChange}
                placeholder="Total Questions"
                type="number"
                sx={{
                  width: "80%",
                  bgcolor: "#F7F6F9"
                }}
                InputProps={{ inputProps: { style: { color: theme.palette.secondary.main } } }}
              />
            </FormControl>
          </Box>
          <Box width="100%" display={isNonMobile ? "flex" : "none"} flexDirection="column" gap="0.75rem" />
        </Box>
      </Box>
      <Box>
        <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
          <Typography variant="h4" mb="0.2rem">
            Description of exam
          </Typography>
          <Divider sx={{ width: "100%" }} />
          <FormControl>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={4}
              placeholder="Enter description of exam"
              value={examDetailState?.exam_description}
              onChange={onDescriptionChange}
              type="text"
              sx={{
                width: "100%",
                bgcolor: "#F7F6F9"
              }}
              InputProps={{ inputProps: { style: { color: theme.palette.secondary.main } } }}
            />
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default ExamDetails;
