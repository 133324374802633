import { Box, Chip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ArrowSquareOut, CaretDown } from "@phosphor-icons/react";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetMeetingsQuery } from "../../../redux/api/meetManagerSlice";
import RecordMeeting from "./RecordMeeting";

const formatMeetingTime = meetingTime => {
  const options = { year: "numeric", month: "long", day: "numeric", hour: "2-digit", minute: "2-digit" };
  return new Date(meetingTime).toLocaleString(undefined, options);
};

const AddRecordClass = ({ sectionId }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");
  const theme = useTheme();
  const { batchId } = useParams();
  const { data: facultyMeetings, isSuccess } = useGetMeetingsQuery(batchId);

  console.log("MEETINGS: ", facultyMeetings);

  return (
    <Box
      padding="1rem 2rem"
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <Box
        display="flex"
        gap="0.75rem"
        flexDirection={isNonMobile ? "row" : "column"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Typography variant="h3" color="#fffff">
            Create and manage recordings here
          </Typography>
        </Box>
        <Box>
          <RecordMeeting sectionId={sectionId} meetings={facultyMeetings} />
        </Box>
      </Box>
      <Box
        sx={{
          maxHeight: "85vh", // Set a maximum height for the scrollable container
          overflowY: "auto", // Enable vertical scrolling
          position: "relative",
          overflowX: "auto", // Enable horizontal scrolling if needed
          maxWidth: "100%"
        }}
      >
        <table style={{ width: "100%", textAlign: "center", borderCollapse: "seperate", borderSpacing: "0 1rem" }}>
          <thead>
            <tr>
              <th
                style={{
                  width: "50%",
                  padding: isNonMobile ? "0.5rem" : "1rem",
                  gap: "8px"
                }}
              >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant="subtitle2" fontWeight={400}>
                    Meeting Name
                  </Typography>
                  <CaretDown weight="fill" />
                </div>
              </th>
              <th
                style={{
                  width: "50%",
                  padding: isNonMobile ? "0.5rem" : "1rem",
                  gap: "8px"
                }}
              >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant="subtitle2" fontWeight={400}>
                    Go
                  </Typography>
                  <CaretDown weight="fill" />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {isSuccess ? (
              facultyMeetings.meetings
                ?.filter(meet => meet.recordings.length > 0)
                .map(meet => (
                  <tr
                    key={meet._id}
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: "1rem",
                      borderSpacing: "1rem",
                      cursor: "pointer", // Ensure the cursor is always a pointer
                      transition: "box-shadow 0.3s ease" // Smooth transition for the shadow
                    }}
                    onMouseEnter={e => {
                      e.currentTarget.style.boxShadow = "0 0 15px rgba(0, 0, 0, 0.2)";
                    }}
                    onMouseLeave={e => {
                      e.currentTarget.style.boxShadow = "0 0 0 rgba(0, 0, 0, 0)";
                    }}
                  >
                    <td style={{ width: "50%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                      {meet.meeting_name}
                    </td>
                    <td style={{ width: "50%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                      <ArrowSquareOut
                        aria-label="redirect"
                        weight="fill"
                        size={20}
                        color="blue"
                        onClick={() => {
                          const { driveLink } = meet.recordings[0];
                          const isFullyQualified = driveLink.startsWith("http://") || driveLink.startsWith("https://");
                          window.open(isFullyQualified ? driveLink : `https://${driveLink}`, "_blank");
                        }}
                      />
                    </td>
                  </tr>
                ))
            ) : (
              <>No Recordings found</>
            )}
          </tbody>
        </table>
      </Box>
    </Box>
  );
};

export default AddRecordClass;
