// inbuilt modules
import React, { useEffect, useState } from "react";

// external modules
import { Box, Checkbox, FormControl, InputAdornment, TextField, Typography, styled, useTheme } from "@mui/material";
import { ArrowLeft, LockSimple } from "@phosphor-icons/react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

// internal modules
import { logo, logoPlain } from "../../../Assets/index";
import CButton from "../../../components/ui/Button";
import { useChangePasswordMutation } from "../../../redux/api/authSlice";

// functions and variables
const InputBox = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
}));

const PasswordChangePage = () => {
  // theme
  const theme = useTheme();

  const { token } = useParams();

  // hooks
  const dispatch = useDispatch();

  // useState
  const [formData, setFormData] = useState({ password: "", confirmPassword: "" });
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [changePassword, { isLoading }] = useChangePasswordMutation();

  // destructured the auth state

  // functions and variables
  const handleChange = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleLogin = async () => {
    const userData = {
      token: token,
      password: formData.password
    };
    changePassword(userData);
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (formData.password.trim() === "") {
      return toast.error("Please enter your password");
    } else if (formData.confirmPassword.trim() === "") {
      return toast.error("Please enter your confirm password");
    } else if (formData.password !== formData.confirmPassword) {
      return toast.error("Passwords do not match");
    }

    handleLogin();
  };

  useEffect(() => {
    document.title = "Insights | Change Password";
  }, []);

  return (
    <Box width="100vw" height="100vh" padding="0.75rem" display="flex">
      <Box width="100%">
        <img src={logo} alt="logoImage" />
        <Box
          width="100%"
          display="flex"
          height="80%"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box width="60%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Typography variant="h3" mb="0.75rem" fontWeight={500}>
              Set New Password
            </Typography>
            <Typography variant="h5" textAlign="center">
              Your password must be different from previously used passwords
            </Typography>
            <FormControl fullWidth>
              <Box mt="3rem" display="flex" flexDirection="column" gap="0.75rem">
                <InputBox>
                  <Typography variant="h5" fontWeight={500}>
                    Password
                  </Typography>
                  <TextField
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    size="small"
                    required
                    placeholder="Enter password"
                    type={passwordVisible ? "text" : "password"}
                    sx={{ width: "50%", color: theme.palette.secondary.main }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockSimple color={theme.palette.secondary.main} weight="fill" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Checkbox
                            checked={passwordVisible}
                            onChange={() => setPasswordVisible(!passwordVisible)}
                            sx={{
                              "color": theme.palette.secondary.main,
                              "p": 0,
                              "&.Mui-checked": {
                                color: theme.palette.secondary.main
                              }
                            }}
                          />
                        </InputAdornment>
                      ),
                      inputProps: { style: { color: theme.palette.secondary.main } }
                    }}
                  />
                </InputBox>
                <InputBox>
                  <Typography variant="h5" fontWeight={500}>
                    Confirm Password
                  </Typography>
                  <TextField
                    id="confirmPassword"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    size="small"
                    required
                    placeholder="Confirm password"
                    type={passwordVisible ? "text" : "password"}
                    sx={{ width: "50%", color: theme.palette.secondary.main }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockSimple color={theme.palette.secondary.main} weight="fill" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Checkbox
                            checked={passwordVisible}
                            onChange={() => setPasswordVisible(!passwordVisible)}
                            sx={{
                              "color": theme.palette.secondary.main,
                              "p": 0,
                              "&.Mui-checked": {
                                color: theme.palette.secondary.main
                              }
                            }}
                          />
                        </InputAdornment>
                      ),
                      inputProps: { style: { color: theme.palette.secondary.main } }
                    }}
                  />
                </InputBox>
              </Box>
              <Box display="flex" mt="1rem" justifyContent="flex-end">
                <CButton
                  buttonText={"Set New Password"}
                  variant={"primary"}
                  sx={{ width: "100%", height: "2.5rem" }}
                  onClickFun={handleSubmit}
                />
              </Box>
            </FormControl>
            <Box width="100%" display="flex" alignItems="center" justifyContent="center" gap="0.7rem" mt="1rem">
              <ArrowLeft />
              <Link to="/" color="#fff" variant="h5" fontWeight={500} sx={{ height: "0" }}>
                Back to login
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        className="analysis"
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box width="60%">
          <img src={logoPlain} alt="logoImage" />
          <Typography variant="h5" textAlign="center" color="#fff">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa illo fugiat aut quisquam ex dolorum obcaecati
            ratione expedita, iste soluta porro saepe at facilis numquam provident minus fuga ipsam laudantium.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

// render
export default PasswordChangePage;
