import { Box, ToggleButton, styled, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import FacultyListUploader from "./FacultyListUploader";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff"
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const AddMultyFaculty = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");
  const { batchId } = useParams();

  const [alignment, setAlignment] = useState("selectFaculties");
  const [facultyFile, setFacultyFile] = useState(null);

  const handleToggle = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  return (
    <Box width="100%" p="1rem 2rem">
      <Box width="100%">
        <FacultyListUploader batchId={batchId} file={facultyFile} setFile={setFacultyFile} />
      </Box>
    </Box>
  );
};

export default AddMultyFaculty;
