import { Box, Button, CircularProgress, Typography, useTheme } from "@mui/material";
import { CaretRight, Check, Folder, Prohibit } from "@phosphor-icons/react";
import axios from "axios";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const getRowClassName = params => {
  return "row-color";
};

const CircularProgressWithLabel = props => {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" size={250} thickness={1} sx={{ color: "#3267FF" }} {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired
};

const FilesUpload = ({ omrFolder, setOmrFolder, examId, startAnalysisRef, questionPaper, answerKey }) => {
  const theme = useTheme();
  const inputRef = useRef();
  const allowedFileTypesRegex = /\.(png|jpg)$/i; // Regex for csv, xlsx, xls
  const [confirmFile, setConfirmFile] = useState(false);
  const { user } = useSelector(state => state.auth);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);

  const checkFileExtension = selectedFile => {
    if (selectedFile) {
      const fileName = selectedFile.name;
      if (allowedFileTypesRegex.test(fileName)) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleDrop = e => {
    e.preventDefault();

    const droppedFiles = e.dataTransfer.files;

    if (droppedFiles.length > 0) {
      const isValidImage = Array.from(droppedFiles).every(file => checkFileExtension(file));

      if (isValidImage && droppedFiles.length) {
        // Handle multiple image files drop
        setOmrFolder(droppedFiles);
      } else {
        toast.error(
          "Please drop an image file (png/jpg), a directory containing image files, or multiple image files."
        );
        setOmrFolder(null); // Reset the state to remove the previous files
      }
    }
  };

  const handleFileChange = e => {
    const selectedFiles = e.target.files;

    if (selectedFiles.length > 0) {
      const isValidImage = Array.from(selectedFiles).every(file => checkFileExtension(file));

      if (isValidImage && selectedFiles.length) {
        // Handle multiple image files upload
        setOmrFolder(selectedFiles);
      } else {
        toast.error(
          "Please upload an image file (png/jpg), a directory containing image files, or multiple image files."
        );
        setOmrFolder(null); // Reset the state to remove the previous files
      }
    }
  };

  const handleFileConfirm = () => {
    setConfirmFile(true);
  };

  const handleSubmit = async () => {
    if (!answerKey || !questionPaper) {
      return toast.error("Please upload question paper and answer key");
    }

    const toastId = toast.loading("Uploading... Please wait until completion");

    const examQPA = new FormData();
    examQPA.append("exam_id", examId);
    examQPA.append("school_id", user.school_id);
    examQPA.append("question_paper", questionPaper);
    examQPA.append("answer_key", answerKey);

    try {
      const qpaResponse = await axios.post(`${process.env.REACT_APP_BASE_URL}/omr/qpa-upload`, examQPA);
    } catch (error) {
      toast.dismiss(toastId);
      return toast.error("Error uploading Question Paper and Answer Key");
    }

    if (omrFolder && omrFolder.length > 0) {
      const formData = new FormData();

      Array.from(omrFolder).forEach(file => {
        formData.append("omr-sheets", file);
      });

      formData.append("exam_id", examId);
      formData.append("school_id", user.school_id);

      try {
        setUploading(true);
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/omr/upload`, formData, {
          onUploadProgress: progressEvent => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgress(percentCompleted);

            if (percentCompleted === 100) {
              setTimeout(() => {
                navigate("/test-history");
                toast.dismiss(toastId);
                toast.success("Exam created successfully and analysis started! The status will be updated soon!");
              }, 1000);
            }
          }
        });
      } catch (error) {
        setUploading(false);
        toast.dismiss(toastId);
        toast.error("Error uploading OMR sheets");
        console.error(error);
      }
    } else {
      toast.dismiss(toastId);
      toast.success("Question Paper and Answer Key uploaded successfully! No OMR sheets provided.");
    }
  };

  return (
    <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
      <Box width="100%" display="flex" flexDirection="column" alignItems="center" gap="2rem">
        <Box width="100%" display="flex" alignItems="center" gap="5px">
          <CaretRight />
          <Typography variant="h4" fontWeight={500}>
            Select and upload all images of OMRs
          </Typography>
        </Box>
        {confirmFile ? (
          !uploading ? (
            <Box>
              Selected files:{" "}
              {omrFolder &&
                omrFolder.length > 0 &&
                Array.from(omrFolder)
                  .slice(0, 10)
                  .map((omr, index) => (
                    <Typography key={index} variant="subtitle2" color={theme.palette.primary.light}>
                      {omr.name}
                    </Typography>
                  ))}
              <Typography variant="subtitle2" color={theme.palette.primary.light}>
                {omrFolder.length > 10 ? `and ${omrFolder.length - 10} more` : null}
              </Typography>
            </Box>
          ) : (
            <Box>
              <CircularProgressWithLabel value={progress} />
            </Box>
          )
        ) : (
          <Box
            width="100%"
            height="40vh"
            padding="1rem"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="#F7F6F9"
            border="1px dashed #AFAFAF"
            borderRadius="1rem"
            onDragOver={e => e.preventDefault()}
            onDrop={handleDrop}
          >
            <Folder size={100} color={theme.palette.primary.light} weight="thin" />
            {omrFolder ? (
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap="3px">
                <Typography variant="h6">Number of images selected: {omrFolder?.length}</Typography>
                <Box display="flex" gap="10px">
                  <Button
                    variant="contained"
                    sx={{
                      "bgcolor": theme.palette.secondary.main,
                      "height": "24px",
                      "display": "flex",
                      "gap": "5px",
                      "padding": "15px",
                      "&:hover": { bgcolor: theme.palette.secondary.light }
                    }}
                    onClick={() => setOmrFolder(null)}
                  >
                    <Prohibit weight="bold" size={24} color="black" />
                    {"Change"}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      "bgcolor": theme.palette.secondary.main,
                      "height": "24px",
                      "display": "flex",
                      "gap": "5px",
                      "padding": "15px",
                      "&:hover": { bgcolor: theme.palette.secondary.light }
                    }}
                    onClick={handleFileConfirm}
                  >
                    <Check weight="bold" size={24} color="black" />
                    {"Select"}
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box display="flex" alignItems="center" gap="3px">
                <Typography variant="h6">Drop you Folder here, or </Typography>
                <Link
                  href="#"
                  underline="always"
                  color={theme.palette.secondary.main}
                  onClick={() => inputRef.current.click()}
                >
                  {"browse"}
                </Link>
                <input type="file" ref={inputRef} onChange={handleFileChange} hidden multiple />
              </Box>
            )}
          </Box>
        )}
        <Box width="100%" display="flex" justifyContent="flex-end">
          <button onClick={handleSubmit} type="button" ref={startAnalysisRef} hidden>
            Start Analysis
          </button>
        </Box>
      </Box>
    </Box>
  );
};

export default FilesUpload;
