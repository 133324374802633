// inbuilt modules
import React, { useEffect } from "react";

// external modules
import { Box, useMediaQuery } from "@mui/material";

// internal modules
import SignupDesktop from "../../../components/Auth/SignupDesktop";
import SignupMobile from "../../../components/Auth/SignupMobile";

const SignupMain = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  useEffect(() => {
    document.title = "Insights | Signup";
  }, []);
  // render
  return (
    <Box>
      {isNonMobile ? (
        <Box width="100vw">
          <SignupDesktop />
        </Box>
      ) : (
        <Box width="100vw">
          <SignupMobile />
        </Box>
      )}
    </Box>
  );
};

// render
export default SignupMain;
