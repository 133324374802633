import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  students: [],
  faculties: [],
  supervisors: [],
  bulkUsersUploaded: [],
  syllabus: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: ""
};

export const supervisorSlice = createSlice({
  name: "supervisor",
  initialState,
  reducers: {
    setStudents: (state, action) => {
      state.students = action.payload;
    },
    setFaculties: (state, action) => {
      state.faculties = action.payload;
    },
    setSupervisors: (state, action) => {
      state.supervisors = action.payload;
    }
  }
});

export const { setStudents, setFaculties, setSupervisors } = supervisorSlice.actions;
export default supervisorSlice.reducer;
