import { Box, Divider, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FilePdf } from "@phosphor-icons/react";
import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const QuestionAnswerPaper = ({ questionPaper, setQuestionPaper, answerKey, setAnswerKey }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();
  const inputRefQuestionPaper = useRef();
  const inputRefAnswerKey = useRef();

  const [questionPaperFileName, setQuestionPaperFileName] = useState("");
  const [answerKeyFileName, setAnswerKeyFileName] = useState("");

  const allowedFileTypesRegex = /\.(pdf)$/i;

  const checkFileExtension = selectedFile => {
    if (selectedFile) {
      const fileName = selectedFile.name;
      if (allowedFileTypesRegex.test(fileName)) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleDropQuestionPaper = e => {
    e.preventDefault();
    if (checkFileExtension(e.dataTransfer.files[0])) {
      setQuestionPaper(e.dataTransfer.files[0]);
      setQuestionPaperFileName(e.dataTransfer.files[0].name);
    } else {
      toast.error("Please upload a PDF file");
    }
  };

  const handleFileChangeQuestionPaper = e => {
    if (checkFileExtension(e.target.files[0])) {
      setQuestionPaper(e.target.files[0]);
      setQuestionPaperFileName(e.target.files[0].name);
    } else {
      toast.error("Please upload a PDF file");
    }
  };

  const handleDropAnswerKey = e => {
    e.preventDefault();
    if (checkFileExtension(e.dataTransfer.files[0])) {
      setAnswerKey(e.dataTransfer.files[0]);
      setAnswerKeyFileName(e.dataTransfer.files[0].name);
    } else {
      toast.error("Please upload a PDF file");
    }
  };

  const handleFileChangeAnswerKey = e => {
    if (checkFileExtension(e.target.files[0])) {
      setAnswerKey(e.target.files[0]);
      setAnswerKeyFileName(e.target.files[0].name);
    } else {
      toast.error("Please upload a PDF file");
    }
  };

  return (
    <Box width="100%" display="flex" gap={isNonMobile ? "5rem" : "2rem"} flexDirection={isNonMobile ? "row" : "column"}>
      <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
        <Typography variant="h5" mb="0.2rem" fontWeight={500}>
          Upload Question Paper Image
        </Typography>
        <Divider sx={{ width: "80%" }} />
        <Box
          width="100%"
          padding="1rem"
          display="flex"
          alignItems="center"
          gap="15px"
          bgcolor="#F7F6F9"
          border="1px dashed #AFAFAF"
          borderRadius="1rem"
          onDragOver={e => e.preventDefault()}
          onDrop={handleDropQuestionPaper}
        >
          <FilePdf size={36} color={theme.palette.secondary.main} />
          <Box display="flex" flexDirection="column">
            {questionPaperFileName ? (
              <Typography variant="h6">{questionPaperFileName}</Typography>
            ) : (
              <Box display="flex" alignItems="center" gap="3px">
                <Typography variant="h6">Drop your file here, or </Typography>
                <Link
                  href="#"
                  underline="always"
                  color={theme.palette.secondary.main}
                  onClick={() => inputRefQuestionPaper.current.click()}
                  style={{ marginBottom: "-2.5px" }}
                >
                  {"browse"}
                </Link>
                <input type="file" ref={inputRefQuestionPaper} onChange={handleFileChangeQuestionPaper} hidden />
              </Box>
            )}
            <Typography variant="subtitle2" color={theme.palette.primary.light}>
              PDF only (Max 5MB)
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
        <Typography variant="h5" mb="0.2rem" fontWeight={500}>
          Upload Answer Scheme
        </Typography>
        <Divider sx={{ width: "80%" }} />
        <Box
          width="100%"
          padding="1rem"
          display="flex"
          alignItems="center"
          gap="15px"
          bgcolor="#F7F6F9"
          border="1px dashed #AFAFAF"
          borderRadius="1rem"
          onDragOver={e => e.preventDefault()}
          onDrop={handleDropAnswerKey}
        >
          <FilePdf size={36} color={theme.palette.secondary.main} />
          <Box display="flex" flexDirection="column">
            {answerKeyFileName ? (
              <Typography variant="h6">{answerKeyFileName}</Typography>
            ) : (
              <Box display="flex" alignItems="center" gap="3px">
                <Typography variant="h6">Drop your file here, or </Typography>
                <Link
                  href="#"
                  underline="always"
                  color={theme.palette.secondary.main}
                  onClick={() => inputRefAnswerKey.current.click()}
                  style={{ marginBottom: "-2.5px" }}
                >
                  {"browse"}
                </Link>
                <input type="file" ref={inputRefAnswerKey} onChange={handleFileChangeAnswerKey} hidden />
              </Box>
            )}
            <Typography variant="subtitle2" color={theme.palette.primary.light}>
              PDF only (Max 5MB)
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default QuestionAnswerPaper;
