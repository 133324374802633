import { Button } from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { logo } from "../../Assets";
import { headerLogo } from "../../Assets/landing";

const Header = ({ home }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  return (
    <header className="flex flex-col lg:flex-row justify-center items-center w-full z-50 ">
      <div className="container px-4 lg:px-20 py-4 flex justify-center items-center w-full">
        <div className="flex justify-between items-center w-full">
          <section>
            <div className="w-28">
              <Link to={"/"}>
                <img
                  src={home ? headerLogo : logo}
                  title="ScaleYou-log-for-navbar"
                  alt="Image appropriately set as per the section"
                  height="100%"
                  width="100%"
                  className="object-contain w-full"
                />
              </Link>
            </div>
          </section>
          <section className="hidden lg:block">
            <NavBar home={home} />
          </section>
          <section>
            <div className="hidden lg:flex justify-center items-center gap-4">
              <Button
                LinkComponent={Link}
                to="/signup"
                variant="text"
                className={`${home ? "text-white" : "text-black"} text-base capitalize border-none`}
              >
                Sign up
              </Button>

              <Button
                LinkComponent={Link}
                to="/login"
                variant="text"
                className={`${
                  home ? "text-white border-solid border-white border-[1px]" : "text-black border-black border-[1px]"
                } pl-4 pr-4 pt-[-1] pb-[-1] text-base capitalize border-solid`}
              >
                Login
              </Button>
            </div>
            <div className="lg:hidden" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
              <MenuIcon isMobileMenuOpen={isMobileMenuOpen} home={home} />
            </div>
          </section>
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className="lg:hidden flex flex-col justify-start items-center w-full h-screen border-t border-black/20">
          <div className="mt-8" />
          <NavBar home={home} />
          <div className="flex flex-col justify-center items-center gap-4 mt-4">
            <Button
              variant="text"
              LinkComponent={Link}
              to="/signup"
              className={`${home ? "text-white" : "text-black"} text-base capitalize`}
            >
              Sign Up
            </Button>
            <Button
              variant="outlined"
              LinkComponent={Link}
              to="/login"
              className={`${
                home ? "text-white border-solid border-white border-[1px]" : "text-black border-black border-[1px]"
              } text-base border-solid capitalize`}
            >
              Login
            </Button>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;

const MenuIcon = ({ isMobileMenuOpen, home }) => {
  const spans = [
    {
      id: 1,
      extraClass: isMobileMenuOpen ? "rotate-45 translate-y-1" : "-translate-y-0.5"
    },
    {
      id: 2,
      extraClass: isMobileMenuOpen ? "opacity-0 my-0.5" : "opacity-100 my-0.5"
    },
    {
      id: 3,
      extraClass: isMobileMenuOpen ? "-rotate-45 -translate-y-1" : "translate-y-0.5"
    }
  ];

  return (
    <div className="flex flex-col justify-center items-center w-6 cursor-pointer">
      {spans.map(item => (
        <span
          key={item.id}
          className={`${home ? "bg-white" : "bg-black"} block transition-all duration-300 ease-linear 
              h-0.5 w-full rounded-lg ${item.extraClass}`}
        />
      ))}
    </div>
  );
};

const NavBar = ({ home }) => {
  const { pathname } = useLocation();

  const navItems = [
    { text: "Home", link: "/" },
    { text: "Solutions", link: "/solutions" },
    { text: "Pricing", link: "/pricing" },
    // { text: "Blog", link: "blog" },
    { text: "Jobs", link: "/jobs" }
    // { text: "Pages", link: "pages" }
  ];

  return (
    <nav className="flex flex-col lg:flex-row justify-start lg:justify-center items-start lg:items-center gap-6 lg:gap-10 xl:gap-12">
      {navItems.map((item, index) => (
        <Link
          key={index}
          to={item.link}
          className={`no-underline cursor-pointer text-base lg:text-lg hover:opacity-100 ${
            pathname === item.link ? "opacity-100" : "opacity-70"
          } ${home ? "text-white" : "text-black"}`}
        >
          {item.text}
        </Link>
      ))}
    </nav>
  );
};
