import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Bar } from "react-chartjs-2";
import { BarChart } from "@mui/x-charts/BarChart";
import "chart.js/auto";

const ScoreAnalysis = ({ analysis }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const correct = analysis?.correct ?? 0;
  const wrong = analysis?.wrong ?? 0;
  const totalQuestions = analysis?.total_questions ?? 0;
  const unanswered = totalQuestions - correct - wrong;

  const data = {
    labels: ["Correct", "Incorrect", "Unanswered"],
    datasets: [
      {
        label: "Score Analysis",
        backgroundColor: ["green", "red", "gray"],
        borderColor: ["green", "red", "gray"],
        borderWidth: 0.5,
        hoverBackgroundColor: ["lightgreen", "lightcoral", "lightgray"],
        hoverBorderColor: ["lightgreen", "lightcoral", "lightgray"],
        data: [correct, wrong, unanswered],
        barThickness: 30
      }
    ]
  };

  // Chart options
  const options = {
    indexAxis: "y",
    scales: {
      x: {
        ticks: {
          beginAtZero: true
        },
        grid: {
          display: false // Remove vertical gridlines
        }
      }
    }
  };

  return (
    <Box
      width="100%"
      height={isNonMobile ? "500px" : "100%"} // Adjust the height as needed
      bgcolor="#fff"
      borderRadius="1rem"
      border="3px solid #E6EDFF"
      p="1rem"
      gap="2rem"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box
        display="flex"
        flexDirection={isNonMobile ? "row" : "column"}
        justifyContent="space-between"
        width="100%"
        alignItems={isNonMobile ? "center" : "flex-start"}
        gap="1rem"
      >
        <Typography variant="h3">Score Analysis</Typography>
        <Typography variant="h5" fontWeight="bold">
          Total Questions: {totalQuestions}
        </Typography>
      </Box>
      <Box
        width={isNonMobile ? "80%" : "100%"}
        height={isNonMobile ? "80%" : "100%"}
        display="flex"
        justifyContent="center"
      >
        {/* <Bar data={data} options={options} /> */}
        <BarChart
          width={700}
          sx={{ marginLeft: 10 }}
          yAxis={[{ scaleType: "band", data: ["Correct\n‎\n‎\n‎\nIncorrect\n‎\n‎\n‎\nUnanswered"] }]}
          series={[
            {
              label: "Correct",
              color: ["green"],
              data: [correct]
            },
            {
              label: "Incorrect",
              color: ["red"],
              data: [wrong]
            },
            {
              label: "Unanswered",
              color: ["grey"],
              data: [unanswered]
            }
          ]}
          grid={{ vertical: true }}
          layout="horizontal"
        />
      </Box>
    </Box>
  );
};

export default ScoreAnalysis;
