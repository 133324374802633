export const getId = () => {
  const today = new Date();

  // Extract year, month, and day components
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = today.getDate().toString().padStart(2, "0");

  // Generate a random 2-digit number
  const random = Math.floor(Math.random() * 90) + 10;

  // Concatenate components and convert to a number
  const uniqueId = parseInt(`${year}${month}${day}${random}`, 10);
  return uniqueId;
};
