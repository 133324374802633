import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useGetAllQuestionsQuery } from "../../../redux/api/examSlice";
import { useGetEntireSyllabusQuery } from "../../../redux/api/syllabusSlice";
import CustomSubjectPanel from "./SubjectPanels/CustomSubjectPanel";

const CustomTabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const a11yProps = index => ({
  "id": `simple-tab-${index}`,
  "aria-controls": `simple-tabpanel-${index}`
});

const QuestionSetter = () => {
  const [value, setValue] = useState(0);
  const { user } = useSelector(state => state.auth);
  const { examInEdit } = useSelector(state => state.exam);
  const { data: syllabus, isSuccess: syllabusSuccess } = useGetEntireSyllabusQuery(user.school_id);
  const { data: questionData } = useGetAllQuestionsQuery({
    school_id: user.school_id,
    exam_unique_code: examInEdit?.unique_code
  });

  const totalQuestions = examInEdit?.total_questions;
  const [questionsRemaining, setQuestionsRemaining] = useState(totalQuestions);
  const [subjectQuestions, setSubjectQuestions] = useState({});
  const [numQuestions, setNumQuestions] = useState({});

  const subjects = useMemo(
    () => (syllabusSuccess && syllabus ? Object.keys(syllabus).map(key => ({ _id: key, name: key })) : []),
    [syllabus, syllabusSuccess]
  );

  const questionsBySubject = useMemo(
    () =>
      questionData?.questions?.reduce((acc, question) => {
        const subjectId = question.subject;
        if (!acc[subjectId]) {
          acc[subjectId] = [];
        }
        acc[subjectId].push(question);
        return acc;
      }, {}),
    [questionData]
  );

  useEffect(() => {
    if (subjects.length && questionData?.questions) {
      const initialSubjectQuestions = subjects.reduce((acc, subject) => {
        acc[subject._id] = questionData.questions.filter(q => q.subject === subject._id).length;
        return acc;
      }, {});

      setSubjectQuestions(initialSubjectQuestions);

      const totalAllocated = Object.values(initialSubjectQuestions).reduce((sum, q) => sum + q, 0);
      setQuestionsRemaining(totalQuestions - totalAllocated);

      const initialNumQuestions = subjects.reduce((acc, subject) => {
        acc[subject._id] = initialSubjectQuestions[subject._id] || 0;
        return acc;
      }, {});
      setNumQuestions(initialNumQuestions);
    }
  }, [subjects, questionData, totalQuestions]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateSubjectQuestions = useCallback(
    (subject, numQuestions) => {
      setSubjectQuestions(prev => {
        const updatedSubjectQuestions = { ...prev, [subject]: numQuestions };
        const totalAllocated = Object.values(updatedSubjectQuestions).reduce((sum, q) => sum + q, 0);
        setQuestionsRemaining(totalQuestions - totalAllocated);
        return updatedSubjectQuestions;
      });
    },
    [totalQuestions]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {subjects.map((subject, index) => (
            <Tab label={subject.name} key={subject._id} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>
      {subjects.map((subject, index) => {
        const startNumber = Object.values(subjectQuestions)
          .slice(0, index)
          .reduce((sum, num) => sum + num, 1);

        return (
          <CustomTabPanel value={value} index={index} key={subject._id}>
            {questionData?.questions && (
              <CustomSubjectPanel
                subject={subject.name}
                totalQuestions={totalQuestions}
                allQuestions={questionsBySubject[subject.name]}
                questionsRemaining={questionsRemaining}
                subjectQuestions={subjectQuestions[subject._id]}
                updateSubjectQuestions={updateSubjectQuestions}
                startNumber={startNumber}
                numQuestions={numQuestions[subject._id]}
                setNumQuestions={
                  newNumQuestions =>
                    setNumQuestions(prev => ({
                      ...prev,
                      [subject._id]: newNumQuestions
                    })) // eslint-disable-next-line react/jsx-curly-newline
                }
              />
            )}
          </CustomTabPanel>
        );
      })}
    </Box>
  );
};

export default QuestionSetter;
