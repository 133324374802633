import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { ArrowRight } from "@phosphor-icons/react";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import SingleFacultyAnalysis from "../../../components/Supervisor/ScaleYouAiSupervisor/SingleFacultyAnalysis";
import ExamAnalysis from "../../../components/Teacher/ScaleyouAiTeacher/ExamAnalysis";
import { useGetBatchesByFacultyQuery } from "../../../redux/api/batchSlice";
import {
  useCreateNewStudentSessionMutation,
  useFacultyChatMutation,
  useGetAllHistoryFacultyQuery
} from "../../../redux/api/chatAiSlice";
import { useGetFacultiesQuery } from "../../../redux/api/supervisorSlice";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";

const placeholderStyle = {
  color: "black"
};

const InputComponent = () => {
  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
          input::placeholder {
            color: ${placeholderStyle.color};
          }
          input:focus {
            outline: none;
            border-color: grey; /* Keeps the border color grey when focused */
          }
        `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);
};

const breadcrumbItems = [
  { name: "Dashboard", link: "/" },
  { name: "ScaleYouAI", link: "#" }
];

const ScaleyouAiSupervisor = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");
  const theme = useTheme();

  const [selectedChoice, setSelectedChoice] = useState(null);

  const [selectedButton, setSelectedButton] = useState("");

  const [question, setQuestion] = useState("");
  const { user } = useSelector(state => state.auth);

  const { isSuccess, isError, error, data: faculties, refetch } = useGetFacultiesQuery(user.school_id);
  // console.log(faculties);

  const { data: batchData } = useGetBatchesByFacultyQuery({
    school_id: user.school_id,
    faculty_id: user._id
  });

  const [studentChat, { isLoading: isSendingChat }] = useFacultyChatMutation();
  const { data, isLoading } = useGetAllHistoryFacultyQuery(user?._id);
  const [createNewStudentSession] = useCreateNewStudentSessionMutation();

  const [loading, setLoading] = useState(false);
  const [isNewSessionDialogOpen, setIsNewSessionDialogOpen] = useState(false);

  const chatContainerRef = useRef(null);

  const [facultyArray, setfacultyArray] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState(null);

  const [batchesArray, setBatchesArray] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState(null);

  // Inside your component, add this useEffect to update the facultyArray when the students data is available
  useEffect(() => {
    if (faculties && faculties.faculties) {
      const formattedFaculties = faculties.faculties.map(faculty => ({
        name: faculty.name,
        _id: faculty._id
      }));
      setfacultyArray(formattedFaculties);
    }
    if (batchData && batchData.batches) {
      const formattedBatches = batchData.batches.map(batch => ({
        name: batch.batch_name,
        _id: batch._id
      }));
      setBatchesArray(formattedBatches);
      console.log("BATCH:J ", formattedBatches);
    }
  }, [faculties]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [data]);

  const handleButtonClick = button => {
    setSelectedButton(button);
  };

  const isButtonSelected = !!selectedButton;

  const getButtonStyles = button => ({
    "backgroundColor": selectedButton === button ? "#3267FF" : "inherit",
    "color": selectedButton === button ? "white" : "inherit",
    "&:hover": {
      backgroundColor: selectedButton === button ? "#3267FF" : "#EAECF0",
      color: selectedButton === button ? "white" : "inherit"
    }
  });

  const handleSendMessage = async () => {
    if (!question) {
      toast.error("Please enter a query");
      return;
    }

    const data = {
      school_id: user?.school_id,
      user_id: user?._id,
      selected_option: selectedButton.replace(/\s+/g, "").toLowerCase(),
      question
    };
    console.log(data);

    setLoading(true);

    try {
      const res = await studentChat(data).unwrap();
      // Process the response if needed
    } catch (error) {
      console.log(error);
      // Handle the error if needed
    } finally {
      setLoading(false);
      setQuestion("");
    }

    console.log(data);
  };

  const handleOpenNewSessionDialog = () => {
    setIsNewSessionDialogOpen(true);
  };

  const handleCloseNewSessionDialog = () => {
    setIsNewSessionDialogOpen(false);
  };

  const handleCreateNewSession = async () => {
    // Implement logic to create a new session
    console.log("user :", user);
    await createNewStudentSession({
      school_id: user?.school_id,
      user_id: user?._id,
      active_user: user?._id,
      session_type: "supervisor"
    });
    handleCloseNewSessionDialog();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor="white"
      width="100%"
      marginRight={"5rem"}
      position={"relative"}
      padding={3}
      gap={"0.75rem"}
    >
      <div style={{ padding: "0 0 0 0" }}>
        <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
      </div>

      <Box display={"flex"} flexDirection={"column"}>
        <Typography variant="h3" fontWeight={600}>
          ScaleYou AI
        </Typography>
        <Box display={"flex"} flexDirection={"row"}>
          <Typography variant="h6" color={theme.palette.primary.light}>
            Know more about your analysis with
          </Typography>
          <Typography variant="h6" color={"blue"} sx={{ marginLeft: "0.3rem" }}>
            ScaleYou AI
          </Typography>
        </Box>
      </Box>
      <Box
        elevation={3}
        sx={{
          "zIndex": 10,
          "cursor": "default",
          "& *": {
            cursor: "default"
          },
          "border": "1px solid rgba(0,0,0,0.3)",
          "borderRadius": "1rem"
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", height: "76vh", overflow: "hidden", borderRadius: "1rem" }}>
          {!selectedChoice && !selectedFaculty && (
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h4" fontWeight={600} color={"black"}>
                What do you want to do?
              </Typography>
              <Box display={"flex"} flexDirection={"row"} gap={"1rem"} marginTop={"1rem"}>
                <Button
                  sx={{
                    "display": "flex",
                    "backgroundColor": "#3267FF",
                    "&:hover": { bgcolor: "#3267FF", opacity: "0.8" },
                    "px": "1rem",
                    "color": "white"
                  }}
                  onClick={() => setSelectedChoice("singleAnalysis")}
                >
                  Single Faculty Analysis
                </Button>
                <Button
                  sx={{
                    "display": "flex",
                    "backgroundColor": "#3267FF",
                    "&:hover": { bgcolor: "#3267FF", opacity: "0.8" },
                    "px": "1rem",
                    "color": "white"
                  }}
                  onClick={() => setSelectedChoice("batchAnalysis")}
                >
                  Batch Analysis (last 5 exams)
                </Button>
              </Box>
            </Box>
          )}
          {selectedChoice === "singleAnalysis" && !selectedFaculty && (
            <Box width={"100%"} height={"100%"} display={"flex"} flexDirection={"column"} padding={"2rem"}>
              {faculties && faculties.faculties && faculties.faculties.length ? (
                <Box display="flex" flexDirection="column" gap="1rem">
                  <Typography variant="h4">Select a faculty from the list:</Typography>
                  <Box display={"flex"} flexDirection={"row"} gap={"1rem"}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={facultyArray}
                      getOptionLabel={option => option.name}
                      sx={{ width: 300 }}
                      renderInput={params => <TextField {...params} label="Faculties" />}
                      onChange={(event, newValue) => {
                        setSelectedFaculty(newValue);
                      }}
                      value={selectedFaculty}
                    />
                    <Button
                      variant="outlined"
                      sx={{ gap: "10px" }}
                      onClick={() => {
                        if (selectedFaculty) {
                          setSelectedChoice("singleAnalysis");
                        } else {
                          toast.error("Please select a faculty");
                        }
                      }}
                    >
                      Go
                      <ArrowRight />
                    </Button>
                  </Box>
                </Box>
              ) : (
                <>hi</>
              )}
            </Box>
          )}
          {selectedChoice === "singleAnalysis" && selectedFaculty && (
            <SingleFacultyAnalysis
              facultyId={selectedFaculty?._id || null}
              handleOpenNewSessionDialog={handleOpenNewSessionDialog}
              setSelectedChoice={setSelectedChoice}
              setSelectedFaculty={setSelectedFaculty}
            />
          )}
          {selectedChoice === "batchAnalysis" && (
            <ExamAnalysis
              batchId={selectedBatch?._id || null}
              handleOpenNewSessionDialog={handleOpenNewSessionDialog}
              setSelectedChoice={setSelectedChoice}
              setSelectedBatch={setSelectedBatch}
            />
          )}
        </Box>
      </Box>
      <Dialog open={isNewSessionDialogOpen} onClose={handleCloseNewSessionDialog}>
        <DialogTitle>Create New Session</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Creating a new session will <b>delete the current one</b>. Are you sure you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewSessionDialog} color="error">
            Cancel
          </Button>
          <Button onClick={handleCreateNewSession} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ScaleyouAiSupervisor;
