import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import React from "react";

const ResultDetails = ({ resultDetailState, setResultDetailState }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();

  const onForChange = event => {
    setResultDetailState(prevState => ({
      ...prevState,
      resultFor: event.target.value
    }));
  };

  const onNameChange = event => {
    setResultDetailState(prevState => ({
      ...prevState,
      resultName: event.target.value
    }));
  };

  const onDateChange = value => {
    setResultDetailState(prevState => ({
      ...prevState,
      resultDate: value
    }));
  };

  const onExamDateChange = event => {
    setResultDetailState(prevState => ({
      ...prevState,
      examDate: event.target.value
    }));
  };

  const onDescriptionChange = event => {
    setResultDetailState(prevState => ({
      ...prevState,
      resultDescription: event.target.value
    }));
  };

  return (
    <Box py="2rem" display="flex" flexDirection="column" gap="3rem">
      <Box width="100%" display="flex" flexDirection="column" gap="1.5rem">
        <Box
          width="100%"
          display="flex"
          gap={isNonMobile ? "5rem" : "2rem"}
          flexDirection={isNonMobile ? "row" : "column"}
        >
          <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
            <Typography variant="h4" mb="0.2rem">
              Name of the result
            </Typography>
            <Divider sx={{ width: "80%" }} />
            <FormControl>
              <TextField
                placeholder="Enter result name"
                value={resultDetailState.resultName || ""}
                onChange={onNameChange}
                type="text"
                sx={{
                  width: "80%",
                  bgcolor: "#F7F6F9"
                }}
                InputProps={{ inputProps: { style: { color: theme.palette.secondary.main } } }}
              />
            </FormControl>
          </Box>
          <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
            <Typography variant="h4" mb="0.2rem">
              Result for
            </Typography>
            <Divider sx={{ width: "80%" }} />
            <FormControl>
              <InputLabel id="resultFor" sx={{ color: theme.palette.secondary.main }}>
                Select
              </InputLabel>
              <Select
                labelId="resultFor"
                id="resultFor"
                value={resultDetailState.resultFor || ""}
                onChange={onForChange}
                label="Result for"
                sx={{
                  width: "80%",
                  borderRadius: "1rem",
                  bgcolor: "#F7F6F9",
                  color: theme.palette.secondary.main
                }}
              >
                <MenuItem value="" sx={{ color: theme.palette.primary.light }}>
                  <em>None</em>
                </MenuItem>
                <MenuItem value="IA" sx={{ color: theme.palette.secondary.main }}>
                  IA
                </MenuItem>
                <MenuItem value="MIDTERM" sx={{ color: theme.palette.secondary.main }}>
                  MIDTERM
                </MenuItem>
                <MenuItem value="ENDSEM" sx={{ color: theme.palette.secondary.main }}>
                  ENDSEM
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          gap={isNonMobile ? "5rem" : "2rem"}
          flexDirection={isNonMobile ? "row" : "column"}
        >
          <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
            <Typography variant="h4" mb="0.2rem">
              Date of the test
            </Typography>
            <Divider sx={{ width: "80%" }} />
            <input
              className="border-2  px-2 py-1 w-60 rounded-md bg-[#F7F6F9] text-[#3266FC]"
              value={resultDetailState.examDate || ""}
              onChange={onExamDateChange}
              style={{
                color: theme.palette.secondary.main,
                width: "80%",
                height: "52px",
                padding: "10px",
                backgroundColor: "#F7F6F9",
                border: "1px solid rgba(0,0,0,0.2)",
                borderRadius: "5px"
              }}
              placeholder="Select a Date"
              type="date"
            />
          </Box>
          <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
            <Typography variant="h4" mb="0.2rem">
              Date of result
            </Typography>
            <Divider sx={{ width: "80%" }} />
            <DateTimePicker
              className="text-[#3266FC]"
              value={dayjs(resultDetailState.resultDate) || null}
              onChange={value => onDateChange(value)}
              sx={{
                width: "80%",
                borderRadius: "1rem",
                bgcolor: "#F7F6F9",
                color: "#3266FC"
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box>
        <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
          <Typography variant="h4" mb="0.2rem">
            Description of result
          </Typography>
          <Divider sx={{ width: "100%" }} />
          <FormControl>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={4}
              placeholder="Enter description of result"
              value={resultDetailState.resultDescription || null}
              onChange={onDescriptionChange}
              type="text"
              sx={{
                width: "100%",
                bgcolor: "#F7F6F9"
              }}
              InputProps={{ inputProps: { style: { color: theme.palette.secondary.main } } }}
            />
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default ResultDetails;
