import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { BatchCover1 } from "../../../Assets";
import { useGetFacultiesQuery, useGetStudentsQuery } from "../../../redux/api/supervisorSlice";

const BatchStats = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const { user } = useSelector(state => state.auth);

  const { data: students } = useGetStudentsQuery(user.school_id);
  const { data: faculties } = useGetFacultiesQuery(user.school_id);

  const { batch: individualBatch } = useSelector(state => state.batch);
  const { batchUserDetails } = useSelector(state => state.batch);

  return (
    <>
      <Box width="100%" borderRadius="1rem" bgcolor="#fff" border="1px solid #E6EDFF" position="relative">
        <Box
          sx={{
            width: "100%",
            height: "70px",
            position: "relative",
            overflow: "hidden",
            marginBottom: "10px"
          }}
        >
          <Box
            sx={{
              width: "100%",
              paddingTop: "20.25%", // 16:9 aspect ratio
              position: "relative"
            }}
          >
            <img
              src={BatchCover1}
              alt="Batch Cover"
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "0",
                left: "0",
                objectFit: "cover",
                borderRadius: "1rem"
              }}
            />
          </Box>
        </Box>
        <Box
          width="100%"
          p="1rem"
          display="grid"
          gridTemplateColumns={!isNonMobile ? "repeat(2, 1fr)" : "repeat(auto-fit, minmax(250px, 1fr))"}
          gap="1rem"
        >
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap="4px">
            <Typography variant="h6" fontWeight={400}>
              Name
            </Typography>
            <Typography variant={isNonMobile ? "h3" : "h4"} fontWeight={500}>
              {individualBatch?.batch_name}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap="4px">
            <Typography variant="h6" fontWeight={400}>
              Type of batch
            </Typography>
            <Typography variant="h3" fontWeight={500} color="#3267FF">
              {individualBatch?.batch_tag}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap="4px">
            <Typography variant="h6" fontWeight={400}>
              Number of students
            </Typography>
            <Typography variant="h3" fontWeight={500}>
              {individualBatch?.students?.length}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap="4px">
            <Typography variant="h6" fontWeight={400}>
              Number of faculties
            </Typography>
            <Typography variant="h3" fontWeight={500}>
              {individualBatch?.faculties?.length}
            </Typography>
          </Box>
        </Box>
        {/* <IndividualBatchTable /> */}
      </Box>
      <Box width="100%" borderRadius="1rem" bgcolor="#fff" border="1px solid #E6EDFF" position="relative" p="1rem">
        <Typography variant="h4" fontWeight={500} mb="8px">
          Batch Description
        </Typography>
        {individualBatch?.batch_description}
      </Box>
    </>
  );
};

export default BatchStats;
