import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {
  DataGrid,
  GridToolbar,
  gridFilteredSortedRowIdsSelector,
  selectedGridRowsSelector,
  useGridApiRef
} from "@mui/x-data-grid";
import { PencilSimple, Trash } from "@phosphor-icons/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useDeleteAUserMutation,
  useGetStudentsQuery,
  useUpdateAUserMutation
} from "../../../redux/api/supervisorSlice";
import CButton from "../../ui/Button";

const getSelectedRowsToExport = ({ apiRef }) => {
  const selectedRowIds = selectedGridRowsSelector(apiRef);
  if (selectedRowIds.size > 0) {
    return Array.from(selectedRowIds.keys());
  }

  return gridFilteredSortedRowIdsSelector(apiRef);
};

const StudentTable = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const apiRef = useGridApiRef();
  const theme = useTheme();
  const { user } = useSelector(state => state.auth);
  const [users, setUsers] = useState([]);

  const { isSuccess, isError, error, data, refetch } = useGetStudentsQuery(user.school_id);

  const [updateAUser] = useUpdateAUserMutation();
  const [deleteAUser] = useDeleteAUserMutation();

  const [open, setOpen] = useState(false);
  const [editUser, setEditUser] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const getRowId = row => row._id;

  // Define a function to get the class for each row
  const getRowClassName = params => {
    return "row-color";
  };

  const [deleteUserId, setDeleteUserId] = React.useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);

  useEffect(() => {
    isSuccess && data.students ? setUsers(data.students) : setUsers([]);
  }, [data, isSuccess]);

  const handleOpenDeleteDialog = userId => {
    setDeleteUserId(userId);
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteUserId(null);
    setIsDeleteDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (deleteUserId) {
      deleteAUser(deleteUserId);
      handleCloseDeleteDialog();
    }
  };

  const handleEdit = userId => {
    const userToEdit = users.find(stu => stu._id === userId);

    setEditUser(userToEdit);
    setName(userToEdit.name);
    setEmail(userToEdit.email);

    setOpen(true); // Open the dialog
  };

  const handleCloseEditDialog = () => {
    setOpen(false);
    setEditUser(null);
  };

  const handleEditSubmit = () => {
    const { _id, userWithoutId } = editUser;
    const updatedUser = {
      ...userWithoutId,
      name: name,
      email: email
    };
    const body = { id: editUser._id, updatedUser };
    updateAUser(body);
  };

  const logicalColumns = [
    { field: "_id", headerName: "ID", flex: 0.4, headerClassName: "datagrid-header-color", hide: true },
    { field: "roll_no", headerName: "Roll No", flex: 0.2, headerClassName: "datagrid-header-color" },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
      headerClassName: "datagrid-header-color"
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.5,
      headerClassName: "datagrid-header-color"
    },
    { field: "signup_key", headerName: "Signup Key", flex: 0.5, headerClassName: "datagrid-header-color" },
    {
      field: "actions",
      headerName: "Actions",
      headerClassName: "datagrid-header-color",
      flex: 0.4,
      align: "center",
      headerAlign: "center",
      renderCell: params => (
        <div>
          <Tooltip title="Edit">
            <Button onClick={() => handleEdit(params.row._id)}>
              <PencilSimple size={20} weight="bold" color={theme.palette.secondary.main} />
            </Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Button onClick={() => handleOpenDeleteDialog(params.row._id)}>
              <Trash size={20} weight="bold" color={theme.palette.alerts.error} />{" "}
            </Button>
          </Tooltip>
        </div>
      )
    }
  ];

  const visibleColumns = logicalColumns.slice(1);

  return (
    <div className="datagrid-container">
      <Box
        sx={{
          "& .datagrid-header-color": {
            fontSize: "1rem"
          }
        }}
      >
        <DataGrid
          className="custom-datagrid"
          autoHeight
          rows={users}
          disableRowSelectionOnClick
          apiRef={apiRef}
          columns={visibleColumns}
          getRowId={getRowId}
          getRowClassName={getRowClassName} // Add this line
          components={{
            Toolbar: props => (
              <Box display="flex" justifyContent="space-between" p="15px">
                <GridToolbar {...props} />
              </Box>
            )
          }}
          slotProps={{
            toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } }
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10
              }
            }
          }}
          pageSizeOptions={[10, 20, 50]}
          sx={!isNonMobile ? { width: "800px" } : {}}
        />
      </Box>
      {/* Delete confirmation dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this user?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="error">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
          <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
            <Typography variant="h4">Name of the Student</Typography>
            <Divider sx={{ width: "100%" }} />
            <FormControl>
              <TextField
                placeholder="Enter name here"
                value={name}
                onChange={e => setName(e.target.value)}
                type="text"
                sx={{
                  width: "100%",
                  bgcolor: "#F7F6F9"
                }}
                InputProps={{ inputProps: { style: { color: theme.palette.secondary.main } } }}
              />
            </FormControl>
          </Box>
          <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
            <Typography variant="h4">Email</Typography>
            <Divider sx={{ width: "100%" }} />
            <FormControl>
              <TextField
                placeholder="Enter email here"
                value={email}
                onChange={e => setEmail(e.target.value)}
                type="email"
                sx={{
                  width: "100%",
                  bgcolor: "#F7F6F9"
                }}
                InputProps={{ inputProps: { style: { color: theme.palette.secondary.main } } }}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>Cancel</Button>
          <CButton buttonText="Edit Student Details" variant="primary" onClickFun={handleEditSubmit} />
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default StudentTable;
