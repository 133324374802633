import { Box, Divider, MenuItem, Select, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useGetEntireSyllabusQuery } from "../../../../redux/api/syllabusSlice";
import SectionAnalysis from "./Sections/SectionAnalysis";

// const sections = [{ name: "Organic IndividualSubjectAnalysis", component: SectionAnalysis }];

const IndividualSubjectAnalysis = ({ analysis, alignment }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const [selectedItem, setSelectedItem] = useState("");
  const { user } = useSelector(state => state.auth);
  const { data: syllabus, isSuccess: isGetSyllabusSuccess } = useGetEntireSyllabusQuery(user.school_id);

  useEffect(() => {
    if (analysis && analysis.sectionAnalysis) {
      if (Object.keys(analysis.sectionAnalysis).length && alignment !== "") {
        setSelectedItem(Object.keys(analysis.sectionAnalysis)[0]);
      } else {
        setSelectedItem("");
      }
    }
  }, [analysis, alignment]);

  const handleMenuItemChange = event => {
    setSelectedItem(event.target.value);
  };

  return (
    <Box display="flex" flexDirection="column" gap="0.75rem" paddingBottom="3rem">
      <Typography variant="h3" fontSize={32}>
        {alignment}
      </Typography>
      <Divider style={{ backgroundColor: "grey", width: "10%" }} />
      <Typography variant="h4">Section Wise Analysis</Typography>
      <Divider style={{ backgroundColor: "grey", width: "15%" }} />
      <Box display="flex" padding="1rem" width="100%">
        <Box width={"fit-content"} display="flex" alignItems="center" gap="1rem">
          <Typography variant="h5">Select a section: </Typography>
          <Select
            id="section"
            name="section"
            size="medium"
            displayEmpty
            value={selectedItem}
            onChange={handleMenuItemChange}
            sx={{ width: isNonMobile ? "fit-content" : "100%" }}
            // Your existing styles for the Select component
          >
            <MenuItem value="" disabled>
              {alignment}
            </MenuItem>
            {syllabus && Object.keys(syllabus).length && alignment !== "" ? (
              Object.keys(syllabus[alignment]).map(section => (
                <MenuItem key={section} value={section}>
                  {section}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="Loading" disabled>
                Loading
              </MenuItem>
            )}
          </Select>
        </Box>
      </Box>
      <SectionAnalysis analysis={analysis} subject={alignment} section={selectedItem} />
    </Box>
  );
};

export default IndividualSubjectAnalysis;
