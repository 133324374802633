import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { Plus } from "@phosphor-icons/react";
import React, { useState } from "react";
import CollegeManager from "../../../components/SuperAdmin/CollegeTable/ClgManager";
import CButton from "../../../components/ui/Button";
import { useCreateASchoolMutation } from "../../../redux/api/schoolSlice";
import { toastError } from "../../../utils/ToastHandler";

const CreateCollege = () => {
  const theme = useTheme();

  //college
  const [openDialog, setOpenDialog] = useState(false);

  const [collegeDetails, setCollegeDetails] = useState({
    name: "",
    address: "",
    contact_info: ""
  });

  const handleNameChange = event => {
    setCollegeDetails({ ...collegeDetails, name: event.target.value });
  };

  const handleAddressChange = event => {
    setCollegeDetails({ ...collegeDetails, address: event.target.value });
  };

  const handleContactChange = event => {
    setCollegeDetails({ ...collegeDetails, contact_info: event.target.value });
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [createASchool, { isloading }] = useCreateASchoolMutation();
  const handleAddCollege = () => {
    createASchool(collegeDetails);
  };

  const handleCreateCollege = () => {
    // For demonstration purposes, just showing a toast with the name
    if (collegeDetails.name === "" || collegeDetails.address === "" || collegeDetails.contact_info === "") {
      return toastError("Please fill all the fields");
    }
    handleAddCollege();
    setOpenDialog(false);
  };

  return (
    <Box
      padding="1rem 2rem"
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="0.75rem">
        <Box>
          <Typography variant="h2" fontWeight={600}>
            Colleges
          </Typography>
          <Typography variant="h6" color={theme.palette.primary.light}>
            View and manage all colleges in one place
          </Typography>
        </Box>
        <CButton
          buttonText="Register New College"
          variant="primary"
          icon={<Plus color="#fff" size={18} weight="bold" />}
          onClickFun={handleOpenDialog}
        />
      </Box>
      <CollegeManager />
      {/* Register New College dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            width: "700px", // Adjust width as needed
            height: "400px" // Adjust height as needed
          }
        }}
      >
        <DialogTitle sx={{ fontSize: "24px", paddingBottom: "3px" }}>Create New School</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "18px", paddingBottom: "15px" }}>
            Please enter the name of the School:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={collegeDetails.name} // Connect value to the name state
            onChange={handleNameChange}
            sx={{
              // Adjust styles here
              "& .MuiInputBase-root": {
                // Modify input base styles
                fontSize: "16px", // Adjust font size
                backgroundColor: "#fff" // Adjust background color
              },
              "& .MuiInputLabel-root": {
                // Modify input label styles
                fontSize: "15px" // Adjust font size
              }
            }}
          />
          <TextField
            margin="dense"
            label="Address"
            fullWidth
            value={collegeDetails.address}
            onChange={handleAddressChange}
            sx={{
              "marginTop": "15px",
              "& .MuiInputBase-root": {
                fontSize: "16px",
                backgroundColor: "#fff"
              },
              "& .MuiInputLabel-root": {
                fontSize: "15px"
              }
            }}
          />
          <TextField
            margin="dense"
            label="Contact Info"
            fullWidth
            value={collegeDetails.contact_info}
            onChange={handleContactChange}
            sx={{
              "marginTop": "15px",
              "& .MuiInputBase-root": {
                fontSize: "16px",
                backgroundColor: "#fff"
              },
              "& .MuiInputLabel-root": {
                fontSize: "15px"
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCloseDialog}
            color="primary"
            sx={{
              "height": "44px", // Adjust the height
              "width": "98px",
              "borderRadius": "7px", // Adjust the width
              "backgroundColor": "rgba(0,0,0,0.2)",
              "color": "#000",
              "&:hover": {
                backgroundColor: "rgba(0,0,0,0.4)"
              }
            }}
          >
            Cancel
          </Button>
          {/* Set the color to black (rgba(0, 0, 0, 0)) */}
          <CButton buttonText="Create" variant="primary" onClickFun={handleCreateCollege} />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreateCollege;
