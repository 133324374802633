import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import Jobs from "./Jobs";

const JobsPage = () => {
  return (
    <main className="flex flex-col justify-center items-center w-full gap-12 lg:gap-0">
      <Header home={false} />
      <Jobs />
      <Footer />
    </main>
  );
};

export default JobsPage;
