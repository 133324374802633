import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";
import BatchManager from "../../../components/Teacher/ManageBatch/BatchManager";

const ManageBatch = () => {
  const theme = useTheme();

  useEffect(() => {
    document.title = "Insights | View batches";
  }, []);
  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Batches", link: "#" }
  ];
  return (
    <Box
      padding="1rem 2rem"
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <div style={{ padding: "0 0 0 0" }}>
        <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
      </div>
      <Box display="flex" gap="0.75rem" justifyContent="space-between">
        <Box>
          <Typography variant="h2" fontWeight={600}>
            Your Batches
          </Typography>
          <Typography variant="h6" color={theme.palette.primary.light}>
            View batch, send announcements and manage students here
          </Typography>
        </Box>
      </Box>
      <BatchManager />
    </Box>
  );
};

export default ManageBatch;
