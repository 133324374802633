import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import * as React from "react";

function handleClick(event) {
  // event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const BreadcrumbsComp = ({ items: { arr } }) => {
  const breadcrumbs = arr.map((item, index) => {
    return (
      <Link
        key={index}
        underline="none"
        sx={{
          ":hover": { color: "#0066CC" },
          "color": `${index === arr.length - 1 ? "#0066CC" : "black"}`,
          "fontWeight": "600"
        }}
        href={item.link}
        onClick={handleClick}
      >
        {item.name}
      </Link>
    );
  });

  return (
    <Stack spacing={2}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
};

export default BreadcrumbsComp;
