import { Box, Divider, Typography, useMediaQuery } from "@mui/material";
import React from "react";
// import { Doughnut } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import { PieChart } from "@mui/x-charts/PieChart";
import "chart.js/auto";

const DifficultyAnalysis = ({ analysis }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const data = [
    { id: 0, value: analysis.easy_questions, label: "Easy", color: "#007300" },
    { id: 1, value: analysis.medium_questions, label: "Medium", color: "#e69500" },
    { id: 3, value: analysis.hard_questions, label: "Hard", color: "#e60000" }
  ];

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "20%",
    plugins: {
      legend: {
        position: "bottom"
      },
      tooltip: {
        usePointStyle: true,
        bodyFont: {
          size: 16
        }
      }
    }
  };

  const chartOptions2 = {
    responsive: true,
    maintainAspectRatio: true,
    cutout: "43%",
    plugins: {
      legend: {
        position: "bottom"
      },
      tooltip: {
        usePointStyle: true,
        bodyFont: {
          size: 16
        }
      }
    }
  };

  // For last three circles
  const getSubjectDoughnutData = subject => {
    const correctAnswers = analysis.subjectAnalysis[subject].correct;
    const wrongAnswers = analysis.subjectAnalysis[subject].wrong;
    const notAttempted = analysis.subjectAnalysis[subject].total - (correctAnswers + wrongAnswers);

    const data = [
      { id: 0, value: correctAnswers, label: "correctAnswers", color: "#007300" },
      { id: 1, value: wrongAnswers, label: "wrongAnswers", color: "#e60000" },
      { id: 3, value: notAttempted, label: "notAttempted", color: "#e69500" }
    ];

    return data;
  };

  const getSubjectMeanAccuracy = subject => {
    const correctAnswers = analysis.subjectAnalysis[subject].correct;
    const totalAnswers = analysis.subjectAnalysis[subject].total;
    const meanAccuracy = (correctAnswers / totalAnswers).toFixed(2) * 100;
    return meanAccuracy;
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" gap="2rem">
      {/* last three circles */}
      <Box display="flex" width="100%" gap="1rem" mt="1rem" flexDirection="column">
        <Typography variant="h4">Subject Wise Analysis</Typography>
        <Divider style={{ backgroundColor: "grey", width: "10%" }} />
        {/* <Typography variant="h5" gutterBottom style={{ color: "grey" }}>
          Chemistry analysis is categorized into '3' sections, each containing a specific number of chapters. These
          chapters determine the quantity of questions and provide a detailed breakdown of the subtopics from which
          questions are sourced. Additionally, the analysis includes class-wide average correctness percentages for each
          chapter as references.
        </Typography> */}

        <Box display="flex" justifyContent="space-evenly" flexWrap="wrap" gap="1rem" width="100%">
          {analysis ? (
            analysis.subjectAnalysis && Object.keys(analysis.subjectAnalysis) ? (
              Object.keys(analysis.subjectAnalysis).map((subject, index) => (
                <Box
                  key={index}
                  textAlign="center"
                  width="max-content"
                  height="max-content"
                  display="flex"
                  flexDirection="column"
                  gap="0.75rem"
                  justifyContent="center"
                  alignItems="center"
                >
                  {/* <Doughnut data={getSubjectDoughnutData(subject)} options={chartOptions2} width={150} height={150} /> */}
                  <PieChart
                    margin={{ top: 30, bottom: 30, left: 30, right: 30 }}
                    slotProps={{
                      legend: {
                        direction: "row",
                        position: {
                          vertical: "bottom",
                          horizontal: "middle"
                        },
                        itemMarkWidth: 20,
                        itemMarkHeight: 5,
                        markGap: 5,
                        itemGap: 10
                      }
                    }}
                    series={[
                      {
                        data: getSubjectDoughnutData(subject),
                        paddingAngle: 1,
                        cornerRadius: 8,
                        highlightScope: { faded: "global", highlighted: "item" },
                        faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
                        innerRadius: 30
                      }
                    ]}
                    height={350}
                  />
                  <Typography variant="h4">{subject}</Typography>
                  <Divider style={{ backgroundColor: "grey", width: "50%" }} />
                  <Typography variant="h4">{getSubjectMeanAccuracy(subject)}%</Typography>
                  <Typography variant="h4" style={{ fontSize: "14px", color: "grey" }}>
                    is the mean accuracy for hard questions
                  </Typography>
                </Box>
              ))
            ) : (
              <Box>Loading</Box>
            )
          ) : (
            <Box>Loading</Box>
          )}
        </Box>
      </Box>{" "}
      <Box display="flex" width="100%" gap="1rem" mt="1rem" flexDirection="column">
        <Typography variant="h4">Difficulty Wise Analysis</Typography>
        <Divider style={{ backgroundColor: "grey", width: "10%" }} />
        {/* <Typography variant="h5" gutterBottom style={{ color: "grey" }}>
          Chemistry analysis is categorized into '3' sections, each containing a specific number of chapters. These
          chapters determine the quantity of questions and provide a detailed breakdown of the subtopics from which
          questions are sourced. Additionally, the analysis includes class-wide average correctness percentages for each
          chapter as references.
        </Typography> */}

        {/* LAST GRAPH PART STARTS HERE */}
        {/* Box with proper padding */}
        <Box
          border="1px solid #E6EDFF"
          width="100%"
          boxSizing="border-box"
          height="max-content"
          bgcolor="white"
          borderRadius="1rem"
          display="flex"
          padding="1.5rem"
          flexDirection={isNonMobile ? "row" : "column"}
          justifyContent="center"
          gap="0.75rem"
        >
          {/* Pie chart inside the box */}
          <Box width={isNonMobile ? "50%" : "100%"} height="400px">
            {/* <Pie data={data} options={chartOptions} /> */}
            <PieChart
              margin={{ top: 30, bottom: 35, left: 30, right: 30 }}
              slotProps={{
                legend: {
                  direction: "row",
                  position: {
                    vertical: "bottom",
                    horizontal: "middle"
                  },
                  itemMarkWidth: 20,
                  itemMarkHeight: 5,
                  markGap: 5,
                  itemGap: 10
                }
              }}
              series={[
                {
                  data: data,
                  paddingAngle: 1,
                  cornerRadius: 12,
                  highlightScope: { faded: "global", highlighted: "item" },
                  faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
                  innerRadius: 30
                }
              ]}
              height={400}
            />
          </Box>

          <Box
            width={isNonMobile ? "50%" : "100%"}
            display="flex"
            gap="3rem"
            flexDirection={isNonMobile ? "row" : "column"}
            justifyContent="center"
          >
            <Box
              width={isNonMobile ? "25%" : "100%"}
              bgcolor="#F7F7F7"
              borderRadius="1rem"
              display="flex"
              flexDirection={isNonMobile ? "column" : "row"}
              gap="1rem"
              justifyContent="center"
              alignItems="center"
              padding="0.5rem"
            >
              <Box display="flex" flexDirection="column" gap="2px" width="90%">
                <Typography variant="body1" style={{ fontWeight: "540", fontSize: "23px", textAlign: "center" }}>
                  {analysis.exam_for}
                </Typography>
                <Typography variant="body1" style={{ fontSize: "14px", textAlign: "center" }}>
                  Total {analysis.total_questions} questions
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" gap="2px" width="90%">
                <Typography variant="body1" style={{ fontWeight: "540", fontSize: "23px", textAlign: "center" }}>
                  {analysis.hard_questions}
                </Typography>
                <Typography variant="body1" style={{ fontSize: "14px", textAlign: "center" }}>
                  Hard Questions
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" gap="2px" width="90%">
                <Typography variant="body1" style={{ fontWeight: "540", fontSize: "23px", textAlign: "center" }}>
                  {analysis.medium_questions}
                </Typography>
                <Typography variant="body1" style={{ fontSize: "14px", textAlign: "center" }}>
                  Medium Questions{" "}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" gap="2px" width="90%">
                <Typography variant="body1" style={{ fontWeight: "540", fontSize: "23px", textAlign: "center" }}>
                  {analysis.easy_questions}{" "}
                </Typography>
                <Typography variant="body1" style={{ fontSize: "14px", textAlign: "center" }}>
                  Easy Questions{" "}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DifficultyAnalysis;
