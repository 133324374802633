import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import toast from "react-hot-toast";

export const authApiSlice = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/auth`,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["Auth"],
  endpoints: builder => ({
    signup: builder.mutation({
      query: user => ({
        url: "/signup",
        method: "POST",
        body: user
      }),
      invalidatesTags: ["Auth"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Loading...", { id: "signup" });
          await queryFulfilled;
          toast.success("Signup Successful", { id: "signup" });
        } catch (error) {
          const message = error.data?.message || error.toString();
          toast.error(message, { id: "signup" });
        }
      }
    }),
    login: builder.mutation({
      query: user => ({
        url: "/login",
        method: "POST",
        body: user
      }),
      invalidatesTags: ["Auth"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Loading...", { id: "login" });
          await queryFulfilled;
          toast.success("Login Successful", { id: "login" });
        } catch ({ error }) {
          console.log(error);
          const message = error.data?.message || "Internal server error";
          toast.error(message, { id: "login" });
        }
      }
    }),
    logout: builder.mutation({
      query: () => ({
        url: "/logout",
        method: "POST"
      }),
      invalidatesTags: ["Auth"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Logging Out...", { id: "logout" });
          await queryFulfilled;
          toast.success("Logout Successful", { id: "logout" });
        } catch ({ error }) {
          const message = error.data?.message || error.toString();
          toast.error(message, { id: "logout" });
        }
      }
    }),
    forgotPassword: builder.mutation({
      query: email => ({
        url: "/forgot-password",
        method: "POST",
        body: { email }
      }),
      invalidatesTags: ["Auth"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Loading...", { id: "forgot-password" });
          await queryFulfilled;
          toast.success("Password Reset Email Sent", { id: "forgot-password" });
        } catch ({ error }) {
          const message = error.data?.message || error.toString();
          toast.error(message, { id: "forgot-password" });
        }
      }
    }),
    changePassword: builder.mutation({
      query: userData => ({
        url: `/change-password/${userData?.token}`,
        method: "POST",
        body: { password: userData.password }
      }),
      invalidatesTags: ["Auth"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Loading...", { id: "change-password" });
          await queryFulfilled;
          toast.success("Password Changed Successfully", { id: "change-password" });
        } catch ({ error }) {
          const message = error.data?.message || error.toString();
          toast.error(message, { id: "change-password" });
          console.log(error);
        }
      }
    })
  })
});

export const {
  useSignupMutation,
  useLoginMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation
} = authApiSlice;
