import { Box, Chip, Divider, Typography, useMediaQuery } from "@mui/material";
import { CaretDown, CursorClick } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useFetchStudentsOfFacultyQuery } from "../../../redux/api/facultySlice";

const ExamStudentAnalysis = ({ analysis }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const [selectedValue, setSelectedValue] = useState("all");
  const [studentAnalysis, setStudentAnalysis] = useState([]);

  const { user } = useSelector(state => state.auth);

  const { data: studentsOfFaculty, isSuccess: isGetStudentsSuccess } = useFetchStudentsOfFacultyQuery({
    school_id: user.school_id,
    faculty_id: user._id
  });

  useEffect(() => {
    if (isGetStudentsSuccess) {
      if (studentsOfFaculty?.students?.length) {
        const examStudents = studentsOfFaculty?.students?.filter(student => {
          return student.exam_id === analysis.exam_id;
        });
        setStudentAnalysis(examStudents);
      }
    }
  }, [studentsOfFaculty, selectedValue]);

  return (
    <Box height="91vh" bgcolor="rgb(250,250,251,1)">
      <Box
        padding={isNonMobile ? "1rem 2rem" : "1rem"}
        display="flex"
        flexDirection="column"
        gap="1rem"
        bgcolor="rgb(250,250,251,1)"
        width="100%"
        height="max-content"
      >
        <Box display="flex" gap="2rem" flexDirection="column" width="100%">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems={isNonMobile ? "center" : "flex-start"}
            gap="1rem"
          >
            <Typography variant="h4">Student Test Report</Typography>
            <Divider style={{ backgroundColor: "grey", width: "10%" }} />
          </Box>
          {/* Table Headers */}
          <Box
            sx={{
              position: "relative",
              overflowX: "auto",
              maxWidth: "100%"
            }}
          >
            {studentAnalysis.length ? (
              <table style={{ width: "100%", textAlign: "center", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th style={{ width: "10%", padding: isNonMobile ? "0.5rem" : "1rem", gap: "8px" }}>
                      Sr. No
                      <CaretDown weight="fill" />
                    </th>
                    <th style={{ width: "10%", padding: isNonMobile ? "0.5rem" : "1rem", gap: "8px" }}>
                      Roll Number
                      <CaretDown weight="fill" />
                    </th>
                    <th style={{ width: "20%", padding: isNonMobile ? "0.5rem" : "1rem", gap: "8px" }}>
                      Name
                      <CaretDown weight="fill" />
                    </th>
                    <th style={{ width: "20%", padding: isNonMobile ? "0.5rem" : "1rem", gap: "8px" }}>
                      ID
                      <CaretDown weight="fill" />
                    </th>
                    <th style={{ width: "20%", padding: isNonMobile ? "0.5rem" : "1rem", gap: "8px" }}>
                      Date
                      <CaretDown weight="fill" />
                    </th>
                    <th style={{ width: "20%", padding: isNonMobile ? "0.5rem" : "1rem", gap: "8px" }}>
                      Overall Score
                      <CaretDown weight="fill" />
                    </th>
                    <th style={{ width: "60%", padding: isNonMobile ? "0.5rem" : "1rem", gap: "8px" }}>
                      Analysis
                      <CaretDown weight="fill" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {studentAnalysis.length &&
                    studentAnalysis.map((student, index) => (
                      <tr
                        key={student._id}
                        style={{
                          backgroundColor: "#fff",
                          borderRadius: "0.75rem"
                        }}
                      >
                        <td style={{ width: "10%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                          {index + 1}
                        </td>
                        <td style={{ width: "10%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                          {student.roll_no}
                        </td>
                        <td style={{ width: "20%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                          {student.student_name}
                        </td>
                        <td style={{ width: "20%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                          {student.exam_for}/{student.exam_name}/{student.unique_code}
                        </td>
                        <td style={{ width: "20%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                          {student.exam_date}
                        </td>
                        <td style={{ width: "20%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                          {student.student_score}
                        </td>
                        <td style={{ width: "60%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                          <Chip
                            variant="large"
                            sx={
                              !isNonMobile
                                ? {
                                    "bgcolor": "rgba(0,0,0,0.05)",
                                    "color": "#F48C06",
                                    "&:hover": { bgcolor: "rgba(0,0,0,0.09)", cursor: "pointer" },
                                    "borderRadius": "3rem",
                                    "cursor": "pointer"
                                  }
                                : {
                                    "bgcolor": "rgba(0,0,0,0.05)",
                                    "color": "#F48C06",
                                    "&:hover": { bgcolor: "rgba(0,0,0,0.09)", cursor: "pointer" },
                                    "borderRadius": "3rem",
                                    "cursor": "pointer",
                                    "fontSize": "14px",
                                    "fontWeight": 500,
                                    "height": "3rem",
                                    "width": "100%"
                                  }
                            }
                            label="View Analysis"
                            icon={<CursorClick size={isNonMobile ? 24 : 18} />}
                            component={Link}
                            to={`/analyse-student/${student._id}`}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            ) : (
              <Typography variant="h5" width="100%" textAlign="center" py="0.5rem" px="0.6rem">
                No Students in this batch
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ExamStudentAnalysis;
