import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import toast from "react-hot-toast";

export const supervisorApiSlice = createApi({
  reducerPath: "supervisorApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/supervisor`,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["Students", "Faculties", "Supervisors"],
  endpoints: builder => ({
    getStudents: builder.query({
      query: schoolId => ({
        url: `/students/${schoolId}`
      }),
      providesTags: ["Students"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    getFaculties: builder.query({
      query: schoolId => ({
        url: `/faculties/${schoolId}`
      }),
      providesTags: ["Faculties"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    createAUser: builder.mutation({
      query: user => ({
        url: "/create",
        method: "POST",
        body: user
      }),
      invalidatesTags: ["Faculties", "Students", "Supervisors"],
      // user =>
      //   user.role === Roles.STUDENT ? ["Students"] : user.role === Roles.FACULTY ? ["Faculties"] : ["Supervisors"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Creating User...", { id: "createUser" });
          await queryFulfilled;
          toast.success("User Created Successfully", { id: "createUser" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "createUser" });
        }
      }
    }),
    updateAUser: builder.mutation({
      query: user => ({
        url: `/${user.id}`,
        method: "PUT",
        body: { userData: user.updatedUser }
      }),
      invalidatesTags: ["Faculties", "Students", "Supervisors"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Updating User...", { id: "updateUser" });
          await queryFulfilled;
          toast.success("User Updated Successfully", { id: "updateUser" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "updateUser" });
        }
      }
    }),
    deleteAUser: builder.mutation({
      query: id => ({
        url: `/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Faculties", "Students", "Supervisors"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Deleting User...", { id: "deleteUser" });
          await queryFulfilled;
          toast.success("User Deleted Successfully", { id: "deleteUser" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "deleteUser" });
        }
      }
    }),
    bulkUserUpload: builder.mutation({
      query: data => ({
        url: "/create-from-excel",
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Faculties", "Students", "Supervisors"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Creating Users...", { id: "bulkUserUpload" });
          await queryFulfilled;
          toast.success("Users Created Successfully", { id: "bulkUserUpload" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "bulkUserUpload" });
        }
      }
    })
  })
});

export const {
  useGetStudentsQuery,
  useGetFacultiesQuery,
  useCreateAUserMutation,
  useUpdateAUserMutation,
  useDeleteAUserMutation,
  useBulkUserUploadMutation
} = supervisorApiSlice;
