import { Box } from "@mui/material";
import React from "react";

const Error = () => {
  return (
    <Box width="100%" height="100vh" display="flex" flexDirection="row" alignItems="center" justifyContent="center">
      You are not authorized or not logged in! Please{" "}
      <a href="/login" style={{ margin: "0 5px" }}>
        login
      </a>{" "}
      again or go to the{" "}
      <a href="/" style={{ margin: "0 5px" }}>
        dashboard
      </a>
    </Box>
  );
};

export default Error;
