// inbuilt modules
import React from "react";

// external modules
import { Box, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { CalendarDots, CaretDown, CheckCircle } from "@phosphor-icons/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetAllResultsQuery } from "../../../redux/api/resultsSlice";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";

const tableStyle = {
  width: "100%",
  minWidth: "900px", // Set a minimum width for the table
  textAlign: "center",
  borderCollapse: "separate",
  borderSpacing: "0 1rem"
};

const thStyle = {
  padding: "0.5rem",
  whiteSpace: "nowrap" // Prevent text wrapping in header
};

const tdStyle = {
  height: "4rem",
  whiteSpace: "nowrap" // Prevent text wrapping in cells
};

const StatusIndicator = ({ color, text }) => (
  <Box display="flex" gap="5px" alignItems="center">
    <div style={{ height: "15px", width: "15px", borderRadius: "50%", backgroundColor: color }} />
    <Typography variant="body2">{text}</Typography>
  </Box>
);

// functions and variables
const CTypography = styled(Typography)(({ theme }) => ({
  width: "100%",
  textAlign: "center",
  display: "flex",
  gap: "3px",
  alignItems: "center",
  justifyContent: "center"
}));

const ViewResults = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");
  const theme = useTheme();
  const navigate = useNavigate();

  const { user } = useSelector(state => state.auth);
  const { data, isSuccess: isGetResultsSuccess } = useGetAllResultsQuery(user.school_id);

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "View Result", link: "" }
  ];

  return (
    <Box width="100%" p="2rem" bgcolor="rgb(250,250,251,1)" height="91vh">
      <div style={{ padding: "1rem 0", marginTop: "-2rem" }}>
        <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
      </div>
      <Box width="100%" display="flex" flexDirection="column" gap="2rem">
        <Box>
          <Box
            width="100%"
            display="flex"
            marginTop="1rem"
            flexDirection={isNonMobile ? "row" : "column"}
            justifyContent="space-between"
            alignItems={isNonMobile ? "center" : "start"}
            gap="1rem"
          >
            <Box>
              <Typography variant="h2" fontWeight={600}>
                Test Results
              </Typography>
              <Typography variant="h6" color={theme.palette.primary.light}>
                View Test Results here
              </Typography>
            </Box>
            <Box display="flex" gap="1rem" alignItems="center">
              <Box display="flex" gap="5px" alignItems="center">
                <div style={{ height: "15px", width: "15px", borderRadius: "50%", backgroundColor: "#3A974C" }} />{" "}
                Published
              </Box>
              <Box display="flex" gap="5px" alignItems="center">
                <div style={{ height: "15px", width: "15px", borderRadius: "50%", backgroundColor: "#F48C06" }} />{" "}
                Scheduled
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            maxHeight: "75vh",
            overflowY: "auto",
            overflowX: "auto",
            width: "100%"
          }}
        >
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={{ ...thStyle, width: "60px" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Sl. No
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th style={{ ...thStyle, width: "150px" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Test Name
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th style={{ ...thStyle, width: "120px" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Test Type
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th style={{ ...thStyle, width: "100px" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Date of test
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th style={{ ...thStyle, width: "60px" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      No. of Students
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th style={{ ...thStyle, width: "220px" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Status
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data?.resultsData?.map((test, index) => {
                  const resultDate = new Date(test.resultDate);
                  const now = new Date();
                  return (
                    <tr
                      key={test._id}
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: "1rem",
                        cursor: "pointer", // Ensure the cursor is always a pointer
                        transition: "box-shadow 0.3s ease" // Smooth transition for the shadow
                      }}
                      onClick={() => navigate(`/edit-results/${test._id}`)}
                      onMouseEnter={e => {
                        e.currentTarget.style.boxShadow = "0 0 15px rgba(0, 0, 0, 0.2)";
                      }}
                      onMouseLeave={e => {
                        e.currentTarget.style.boxShadow = "0 0 0 rgba(0, 0, 0, 0)";
                      }}
                    >
                      <td
                        style={{
                          ...tdStyle,
                          width: "60px",
                          borderLeft: `5px solid ${resultDate < now ? "#3A974C" : "red"}`,
                          borderRadius: "0.5rem"
                        }}
                      >
                        {index + 1}
                      </td>
                      <td style={{ ...tdStyle, width: "150px" }}>{test.resultName}</td>
                      <td style={{ ...tdStyle, width: "120px" }}>{test.resultFor}</td>
                      <td style={{ ...tdStyle, width: "100px" }}>
                        {new Date(test.examDate).toLocaleDateString("en-IN")}
                      </td>
                      <td style={{ ...tdStyle, width: "60px" }}>{test.numOfStudents}</td>
                      <td style={{ ...tdStyle, width: "220px", textAlign: "center" }}>
                        {resultDate < now ? (
                          <Box
                            display="flex"
                            gap="1rem"
                            width="100%"
                            margin="auto"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <CheckCircle size={28} weight="fill" color="#3A974C" />
                            Published
                          </Box>
                        ) : (
                          <Box
                            display="flex"
                            gap="1rem"
                            width="100%"
                            margin="auto"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <CalendarDots size={28} weight="fill" color="#3A974C" />
                            <Typography variant="subtitle2" fontWeight={400}>
                              Scheduled
                              <br />
                              <span style={{ fontWeight: 500 }}>{resultDate.toLocaleString("en-IN")}</span>
                            </Typography>
                          </Box>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </Box>
      </Box>
    </Box>
  );
};

// render
export default ViewResults;
