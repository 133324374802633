// inbuilt modules
import Divider from "@mui/material/Divider";
import React from "react";

// external modules
import { useTheme } from "@emotion/react";
import { Box, Typography, styled, useMediaQuery } from "@mui/material";
import { ChalkboardTeacher, Download, Exam, ToteSimple, Users } from "@phosphor-icons/react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { s3 } from "../../../utils/AWS_S3";

// functions and variables
const HeroBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "3px"
}));

const Stats = ({ analysis }) => {
  const { user } = useSelector(state => state.auth);
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const bucketName = "insights-omr";

  // theme
  const theme = useTheme();

  // functions and variables

  const onQuestionPaperDownload = async () => {
    const qpaAnswerKey = `${user.school_id}-${analysis.exam_id}/${user.school_id}-${analysis.exam_id}-questionPaper.pdf`;

    const params = {
      Bucket: bucketName,
      Key: qpaAnswerKey
    };

    const toastId = toast.loading("Downloading Question Paper...");

    try {
      const data = await s3.getObject(params).promise();
      const blob = new Blob([data.Body], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = "questionPaper.pdf";
      document.body.appendChild(link);
      toast.dismiss(toastId);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      toast.dismiss(toastId);
      console.log(err);
      toast.error("Error downloading file");
    }
  };

  const onAnswerKeyDownload = async () => {
    const qpaAnswerKey = `${user.school_id}-${analysis.exam_id}/${user.school_id}-${analysis.exam_id}-answerKey.pdf`;
    const params = {
      Bucket: bucketName,
      Key: qpaAnswerKey
    };

    const toastId = toast.loading("Downloading Answer Key...");

    try {
      const data = await s3.getObject(params).promise();
      const blob = new Blob([data.Body], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "answerKey.pdf";
      document.body.appendChild(link);
      toast.dismiss(toastId);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.dismiss(toastId);
      console.log(error);
      toast.error("Error downloading file");
    }
  };

  const heroBoxDetails = [
    {
      icon: <ToteSimple size={24} color={theme.palette.secondary.main} />,
      value: analysis.exam_for,
      label: "Exam For"
    },
    {
      icon: <Users size={24} color={theme.palette.secondary.main} />,
      value: analysis.total_students,
      label: "Total Students"
    },
    {
      icon: <ChalkboardTeacher size={24} color={theme.palette.secondary.main} />,
      value: analysis.highest_score,
      label: "Highest Score"
    },
    {
      icon: <Exam size={24} color={theme.palette.secondary.main} />,
      value: Math.floor(analysis.average_score),
      label: "Average Score"
    }
  ];

  return (
    <Box width="100%" bgcolor="#fff" borderRadius="1rem" border="3px solid #E6EDFF" p="1.5rem 1.2rem">
      {isNonMobile && (
        <Box
          width="100%"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          {heroBoxDetails.map((box, index) => (
            <HeroBox key={box.label} sx={{ flexGrow: 1 }}>
              <Box>
                <Box display="flex" gap="10px">
                  <Box bgcolor="#fff" padding="0.5rem" borderRadius="0.75rem" boxShadow="0 0 10px 2px rgba(0,0,0,0.05)">
                    {box.icon}
                  </Box>
                  <Box>
                    <Typography variant="h2" fontWeight={500}>
                      {box.value}
                    </Typography>
                    <Typography variant="subtitle2" color={theme.palette.primary.light}>
                      {box.label}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {box.label === "Average Score" && (
                <Box display="flex" alignItems="center" paddingLeft="60px">
                  <Box>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{
                        height: "65px",
                        backgroundColor: "1px solid #E6EDFF",
                        margin: "0 16px",
                        marginRight: "30px"
                      }}
                    />
                  </Box>
                  <Box
                    mr={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    onClick={onQuestionPaperDownload}
                    sx={{ cursor: "pointer" }}
                  >
                    {/* Download symbol for 'Download Question' */}
                    <Download size={24} color={theme.palette.secondary.main} />
                    <Box mt={1}>
                      <Typography variant="subtitle2" color={theme.palette.primary.light}>
                        Download Question Paper
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    paddingLeft="80px"
                    onClick={onAnswerKeyDownload}
                    sx={{ cursor: "pointer" }}
                  >
                    {/* Download symbol for 'Download Answer Sheet' */}
                    <Download size={24} color={theme.palette.secondary.main} />
                    <Box mt={1}>
                      <Typography variant="subtitle2" color={theme.palette.primary.light}>
                        Download Answer Paper
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </HeroBox>
          ))}
        </Box>
      )}
      {!isNonMobile && (
        <Box width="100%" display="flex" flexDirection="column" gap="1.5rem">
          <Box
            width="100%"
            sx={{
              display: "grid",
              gap: "2rem",
              gridTemplateColumns: !isNonMobile ? "repeat(2, 1fr)" : "repeat(auto-fit, minmax(250px, 1fr))"
            }}
          >
            {heroBoxDetails.map((box, index) => (
              <HeroBox key={box.label} sx={{ flexGrow: 1 }}>
                <Box>
                  <Box display="flex" gap="1rem">
                    <Box
                      bgcolor="#fff"
                      padding="0.5rem"
                      borderRadius="0.75rem"
                      boxShadow="0 0 10px 2px rgba(0,0,0,0.05)"
                    >
                      {box.icon}
                    </Box>
                    <Box>
                      <Typography variant="h2" fontWeight={500}>
                        {box.value}
                      </Typography>
                      <Typography variant="subtitle2" color={theme.palette.primary.light}>
                        {box.label}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </HeroBox>
            ))}
          </Box>
          <Box display="flex" alignItems="center" width="100%" justifyContent="space-between">
            <Box display="flex" flexDirection="column" alignItems="center">
              {/* Download symbol for 'Download Question' */}
              <Download size={24} color={theme.palette.secondary.main} />
              <Box mt={1}>
                <Typography variant="subtitle2" color={theme.palette.primary.light}>
                  Download Question Paper
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              {/* Download symbol for 'Download Answer Sheet' */}
              <Download size={24} color={theme.palette.secondary.main} />
              <Box mt={1}>
                <Typography variant="subtitle2" color={theme.palette.primary.light}>
                  Download Answer Paper
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

// render
export default Stats;
