import { Box, Typography } from "@mui/material";
import React from "react";
import AddCollege from "./Addcollege";

const CollegeManager = () => {
  return (
    <Box width="100%" borderRadius="1rem" bgcolor="#fff" border="1px solid #E6EDFF">
      <Typography variant="h4" fontWeight={500} p="1rem">
        All Colleges
      </Typography>
      <AddCollege />
    </Box>
  );
};

export default CollegeManager;
