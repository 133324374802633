import { Box, ToggleButton, ToggleButtonGroup, Typography, styled, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TestHistory from "../../../pages/Teacher/StudentAnalysis";
import { useGetSyllabusAnalysisQuery } from "../../../redux/api/analysisSlice";
import SubjectAnalysis from "../SubjectAnalysis/Analysis";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff"
  }
}));

const Choice = ({ analysis, examId }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const [alignment, setAlignment] = useState("chapter");
  const [examSyllabusAnalysis, setExamSyllabusAnalysis] = useState({});
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);

  const { data: syllabusAnalysis, isLoading: isSyllabusAnalysisLoading } = useGetSyllabusAnalysisQuery({
    school_id: user.school_id,
    exam_id: examId
  });

  const handleToggle = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  useEffect(() => {
    if (syllabusAnalysis && syllabusAnalysis?.syllabusAnalysis) {
      setExamSyllabusAnalysis(syllabusAnalysis?.syllabusAnalysis);
    }
  }, [syllabusAnalysis]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      <Box display="flex" width={isNonMobile ? "80%" : "100%"} paddingTop="20px" justifyContent="center">
        <ToggleButtonGroup
          sx={{
            bgcolor: "#fff",
            width: isNonMobile ? "80%" : "100%"
          }}
          value={alignment}
          exclusive
          onChange={handleToggle}
        >
          <StyledToggleButton value="chapter" sx={{ width: "100%" }}>
            <Typography variant={isNonMobile ? "h5" : "h6"} fontWeight={500}>
              Detailed Chapter Report
            </Typography>
          </StyledToggleButton>
          <StyledToggleButton value="student" sx={{ width: "100%" }}>
            <Typography variant={isNonMobile ? "h5" : "h6"} fontWeight={500}>
              Student Analysis Report
            </Typography>
          </StyledToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box width="100%">
        {alignment === "student" ? (
          <TestHistory />
        ) : (
          <SubjectAnalysis analysis={analysis} syllabusAnalysis={examSyllabusAnalysis} />
        )}
      </Box>
    </Box>
  );
};

export default Choice;
