import React from "react";
import { useLocation } from "react-router-dom";

const StreamVideo = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const videoUrl = queryParams.get("url");

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      {videoUrl ? (
        <video controls style={{ width: "80%", height: "auto" }}>
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <p>Invalid video URL</p>
      )}
    </div>
  );
};

export default StreamVideo;
