import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import commentService from "../../services/AnnouncementComment/commentService";

export const fetchCommentsByAnnouncementId = createAsyncThunk(
  "comments/fetchByAnnouncementId",
  async (announcementId, thunkAPI) => {
    try {
      return await commentService.fetchCommentsForAnnouncement(announcementId);
    } catch (error) {
      const message = error.response.data.message || error.toString();
      thunkAPI.rejectWithValue(message);
    }
  }
);

export const addComment = createAsyncThunk("comments/create", async ({ text, userId, announcementId }, thunkAPI) => {
  try {
    return await commentService.addComment({ text, userId, announcementId });
  } catch (error) {
    const message = error.response.data.message || error.toString();
    thunkAPI.rejectWithValue(message);
  }
});

export const removeComment = createAsyncThunk("comments/delete", async (commentId, thunkAPI) => {
  try {
    return await commentService.removeComment(commentId);
  } catch (error) {
    const message = error.response.data.message || error.toString();
    thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  comment: {},
  comments: [],
  loading: false,
  success: false,
  error: false,
  message: ""
};
const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    reset: state => {
      state.loading = false;
      state.success = false;
      state.error = false;
      state.message = "";
      state.comment = {};
      state.comments = [];
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCommentsByAnnouncementId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchCommentsByAnnouncementId.fulfilled, (state, action) => {
        state.comments = action.payload.comments;
        state.message = action.payload.message;
        state.loading = false;
        state.success = true;
      })
      .addCase(fetchCommentsByAnnouncementId.rejected, (state, action) => {
        state.error = true;
        state.message = action.payload.message;
        state.loading = false;
      })
      .addCase(addComment.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.comment = action.payload.comment;
        state.loading = false;
        state.message = action.payload.message;
        state.success = true;
      })
      .addCase(addComment.rejected, (state, action) => {
        state.error = true;
        state.message = action.payload.message;
        state.loading = false;
      })
      .addCase(removeComment.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(removeComment.fulfilled, (state, action) => {
        state.comment = action.payload.comment;
        state.loading = false;
        state.message = action.payload.message;
        state.success = true;
      })
      .addCase(removeComment.rejected, (state, action) => {
        state.message = action.payload.message;
        state.error = true;
        state.loading = false;
      });
  }
});

export const { reset } = commentsSlice.actions;

export default commentsSlice.reducer;
