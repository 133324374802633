import { Avatar, Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Circle } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import TimeAgo from "react-timeago";

import { useFetchAnnouncementsByBatchIdQuery } from "../../../redux/api/announcementSlice";
import { useGetBatchesByFacultyQuery } from "../../../redux/api/batchSlice";

const AnnouncementList = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();
  const { batchId } = useParams();
  const navigate = useNavigate();

  const [name, setName] = useState("");

  const {
    batch: { batches },
    auth: { user },
    announcements: { announcements }
  } = useSelector(state => state);

  const { data: batchesData, isSuccess: isGetBatchesSuccess } = useGetBatchesByFacultyQuery({
    school_id: user.school_id,
    faculty_id: user._id
  });

  const { data: announcementData, isSuccess: isGetAnnouncementsSuccess } = useFetchAnnouncementsByBatchIdQuery(batchId);

  useEffect(() => {
    if (isGetBatchesSuccess && batchesData.batches) {
      const batchName = batchesData.batches.find(batch => batch._id === batchId);
      setName(batchName?.batch_name || "");
    }
  }, [batchesData?.batches, batchId, isGetBatchesSuccess]);

  if (!announcementData) {
    return <Typography variant="h5">Loading...</Typography>;
  }

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
        margin={"1rem"}
        width={"100%"}
      >
        <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"} width={"100%"}>
          <Box display={"flex"} flexDirection={"column"} gap={"0.688rem"} alignItems={"center"} width={"100%"}>
            {announcementData.announcements.length ? (
              announcementData.announcements.map((announcement, index) => (
                <Box
                  key={index}
                  sx={{
                    "backgroundColor": "#F6F6F6",
                    "width": "95%",
                    "paddingTop": "1rem",
                    "paddingBottom": "1rem",
                    "borderRadius": "1rem",
                    "marginTop": "0.25rem",
                    "cursor": "pointer",
                    "transition": "transform 0.2s ease-in-out", // Add transition for smooth scaling
                    "&:hover": {
                      transform: "scale(1.02)" // Scale up effect on hover
                    }
                  }}
                  onClick={() => navigate(`/view-batch/${batchId}/announcements/${announcement._id}`)}
                >
                  <Box sx={{ marginLeft: "2rem", marginBottom: "1rem" }}>
                    <Box display={"flex"} flexDirection={"row"} gap={"0.5rem"}>
                      <Avatar>
                        {announcement.author.name.split(" ")[0][0] +
                          (announcement.author.name.split(" ")[1] ? announcement.author.name.split(" ")[1][0] : "")}
                      </Avatar>
                      {/* <User
                      size={36}
                      style={{
                        backgroundColor: theme.palette.secondary.main,
                        borderRadius: 100,
                        padding: 5
                      }}
                    /> */}
                      <Box>
                        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                          <Typography>{announcement.author.name}</Typography>
                          <Box display={"flex"} flexDirection={"row"} gap={"0.25rem"}>
                            <Typography variant="caption">{announcement.author.email}</Typography>
                            <Circle
                              size={6}
                              color="none"
                              style={{
                                alignSelf: "center",
                                backgroundImage:
                                  "linear-gradient(to right top, #a4a1ff, #918dff, #7e79fe, #6a64fc, #534ffa)",
                                borderRadius: 100
                              }}
                            />
                            <Typography variant="caption">
                              <TimeAgo sx={{ textAlign: "center" }} date={announcement.createdAt} />
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Typography variant="h6" marginTop={"1rem"}>
                      {announcement.topic.length > 150
                        ? `${announcement.topic.substring(0, 150)}...`
                        : announcement.topic}
                    </Typography>
                  </Box>
                </Box>
              ))
            ) : (
              <Typography variant="h5">No announcements found for this batch.</Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AnnouncementList;
