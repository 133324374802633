import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import LoginDesktop from "../../../components/Auth/LoginDesktop";
import LoginMobile from "../../../components/Auth/LoginMobile";

const LoginMain = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  return <Box width="100%">{isNonMobile ? <LoginDesktop /> : <LoginMobile />}</Box>;
};

export default LoginMain;
