import axios from "axios";

const API_URL = `${process.env.REACT_APP_BASE_URL}/announcements`;

const fetchAnnouncementsForBatch = async batchId => {
  const { data } = await axios.get(`${API_URL}/batches/${batchId}`);

  return data;
};

const fetchAnnouncementsForSchool = async schoolId => {
  const { data } = await axios.get(`${API_URL}/schools/${schoolId}`);

  return data;
};

const createNewAnnouncement = async announcement => {
  const { data } = await axios.post(`${API_URL}`, announcement);

  return data;
};

const fetchAnnouncementById = async _id => {
  const { data } = await axios.get(`${API_URL}/${_id}`);

  return data;
};

const updateAnnouncement = async (_id, updatedFields) => {
  const { data } = await axios.put(`${API_URL}/${_id}`, updatedFields);

  return data;
};

const deleteAnnouncement = async _id => {
  const { data } = await axios.delete(`${API_URL}/${_id}`);

  return data;
};

const announcementService = {
  fetchAnnouncementsForBatch,
  fetchAnnouncementsForSchool,
  fetchAnnouncementById,
  createNewAnnouncement,
  updateAnnouncement,
  deleteAnnouncement
};

export default announcementService;
