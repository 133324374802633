import { Divider, Typography, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { ArrowRight, Check } from "@phosphor-icons/react";
import * as React from "react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  useAddFacultiesFileMutation,
  useAddFacultiesMutation,
  useAddStudentsFileMutation,
  useAddStudentsMutation,
  useCreateABatchMutation,
  useUpdateABatchMutation
} from "../../../../redux/api/batchSlice";
import { setBatch, setBatchInEdit } from "../../../../redux/features/batch/batchSlice";
import CButton from "../../../ui/Button";
import BatchDetails from "./BatchDetails";
import TagFaculty from "./TagFaculty";
import TagStudents from "./TagStudents";

const steps = ["Batch Details", "Tag Students", "Tag Faculty"];

const BatchStepper = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const { pathname } = useLocation();

  const { user } = useSelector(state => state.auth);
  const { batchInEdit } = useSelector(state => state.batch);

  const [createABatch] = useCreateABatchMutation();
  const [updateABatch] = useUpdateABatchMutation();
  const [addFaculties] = useAddFacultiesMutation();
  const [addFacultiesFile] = useAddFacultiesFileMutation();
  const [addStudents] = useAddStudentsMutation();
  const [addStudentsFile] = useAddStudentsFileMutation();

  const dispatch = useDispatch();

  const [batchName, setBatchName] = useState("");
  const [batchTag, setBatchTag] = useState("JEE");
  const [batchDescription, setBatchDescription] = useState("");

  const [batchStudentList, setBatchStudentList] = useState([]);
  const [batchFacultyList, setBatchFacultyList] = useState([]);

  const [studentListOrFile, setStudentListOrFile] = useState("none");
  const [facultyListOrFile, setFacultyListOrFile] = useState("none");

  const [studentFile, setStudentFile] = useState(null);
  const [facultyFile, setFacultyFile] = useState(null);

  let batchDataObj = {
    school_id: user.school_id,
    batch_name: batchName,
    batch_tag: batchTag,
    batch_description: batchDescription,
    students: [],
    faculties: []
  };

  const [dynamicBatchDataObj, setDynamicBatchDataObj] = useState({
    school_id: user.school_id,
    batch_name: "",
    batch_tag: "",
    batch_description: "",
    students: [],
    faculties: []
  });

  useEffect(() => {
    if (batchInEdit) {
      setDynamicBatchDataObj(batchInEdit);
    }
  }, [batchInEdit]);

  useEffect(() => {
    if (pathname === "/manage-batch" || pathname === "/create-batch") {
      setDynamicBatchDataObj({
        school_id: user.school_id,
        batch_name: "",
        batch_tag: "",
        batch_description: "",
        students: [],
        faculties: []
      });
    }
  }, [pathname]);

  const showStep = actStep => {
    switch (actStep) {
      case 0:
        return (
          <BatchDetails
            batchName={batchName}
            setBatchName={setBatchName}
            batchTag={batchTag}
            setBatchTag={setBatchTag}
            batchDescription={batchDescription}
            setBatchDescription={setBatchDescription}
          />
        );
      case 1:
        return (
          <TagStudents
            batchStudentList={batchStudentList}
            setBatchStudentList={setBatchStudentList}
            studentListOrFile={studentListOrFile}
            setStudentListOrFile={setStudentListOrFile}
            file={studentFile}
            setFile={setStudentFile}
          />
        );
      case 2:
        return (
          <TagFaculty
            batchFacultyList={batchFacultyList}
            setBatchFacultyList={setBatchFacultyList}
            facultyListOrFile={facultyListOrFile}
            setFacultyListOrFile={setFacultyListOrFile}
            file={facultyFile}
            setFile={setFacultyFile}
          />
        );
      default:
        return <Box>Not found</Box>;
    }
  };
  const isStepSkipped = step => {
    return skipped.has(step);
  };

  const handleApi = async step => {
    if (step === 0) {
      if (dynamicBatchDataObj._id) {
        updateABatch({ id: dynamicBatchDataObj._id, batchData: batchDataObj });
      } else {
        const { data: createdBatch } = await createABatch(batchDataObj);
        dispatch(setBatchInEdit(createdBatch?.batches));
      }
    } else if (step === 1) {
      batchDataObj = dynamicBatchDataObj;
      batchDataObj = {
        ...batchDataObj,
        students: batchStudentList,
        status_step: 2
      };
      if (batchDataObj._id) {
        if (studentListOrFile === "list") {
          const batchStudentIdList = batchStudentList.map(student => student._id);
          addStudents({ id: batchDataObj._id, studentData: batchStudentIdList });
        } else if (studentListOrFile === "file") {
          const formData = new FormData();
          formData.append("batchFile", studentFile);
          addStudentsFile({ id: batchDataObj._id, studentData: formData });
        }
      }
      setDynamicBatchDataObj(batchDataObj);
    } else if (step === 2) {
      batchDataObj = dynamicBatchDataObj;
      batchDataObj = {
        ...batchDataObj,
        faculties: batchFacultyList,
        status_step: 3
      };
      if (batchDataObj._id) {
        if (facultyListOrFile === "list") {
          const batchFacultyIdList = batchFacultyList.map(faculty => faculty._id);
          addFaculties({ id: batchDataObj._id, facultyData: batchFacultyIdList });
        } else if (facultyListOrFile === "file") {
          const formData = new FormData();
          formData.append("batchFile", facultyFile);
          await axios.post(`${process.env.REACT_APP_BASE_URL}/batch/addFacultiesFile/${batchDataObj._id}`, formData, {
            withCredentials: true
          });
        }
      }
      setDynamicBatchDataObj(batchDataObj);
      navigate("/manage-batch");
    }
  };

  const checkSubmission = actStep => {
    if (actStep === 0) {
      if (batchName === "" || batchTag === "" || batchDescription === "") {
        toast.error("Please fill all the fields");
        return false;
      }
      handleApi(actStep);
      return true;
    } else if (actStep === 1) {
      if (batchStudentList.length === 0 && !studentFile) {
        toast.error("Please click the green 'Select Students' button");
        return false;
      }
      handleApi(actStep);
      return true;
    } else if (actStep === 2) {
      if (batchFacultyList.length === 0 && !facultyFile) {
        toast.error("Please add faculty");
        return false;
      }
      handleApi(actStep);
    }
  };

  const handleNext = () => {
    const flag = checkSubmission(activeStep);
    if (flag) {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep(prevActiveStep => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSkip = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  return (
    <Box
      px={isNonMobile ? "3rem" : "1rem"}
      py="1rem"
      width="100%"
      bgcolor="#fff"
      borderRadius="1rem"
      border="1px solid #E6EDFF"
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{ width: "100%", height: "100%" }}
        alignItems="center"
        gap="0.5rem"
      >
        <Stepper activeStep={activeStep} sx={{ width: isNonMobile ? "60%" : "100%" }}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};

            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }

            return (
              <Step key={label} {...stepProps} sx={{ mb: "0.5rem" }}>
                <StepLabel {...labelProps} StepIconProps={{ style: { color: "#2970FF" } }}>
                  <Typography variant="h6">{label}</Typography>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Divider />
        <Box width="100%">
          <Box py="0.5rem">{showStep(activeStep)}</Box>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <CButton
              onClickFun={handleNext}
              variant="primary"
              buttonText={
                activeStep === steps.length - 1 ? (
                  <Box display="flex" gap="5px" alignItems="center">
                    Create Batch
                    <Check weight="bold" size={18} color="#fff" />
                  </Box>
                ) : (
                  <Box display="flex" alignItems="center" gap="5px">
                    Next
                    <ArrowRight
                      color="#fff"
                      weight="fill"
                      size={18}
                      style={{ "&:hover": { color: theme.palette.secondary.main } }}
                    />
                  </Box>
                )
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BatchStepper;
