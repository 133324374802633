import { Grid } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import MultiSelectAll from "./MultiSelectAll";

const Selection = ({ batches, setCurrentSelection, initialValue }) => {
  const { batchId } = useParams();
  let departmentNames = batches.map((batch, index) => {
    return { label: `${batch.batch_name}`, value: `${batch._id}` };
  });
  const handleSelectionChange = result => {
    const valueToSave = result.map(itm => itm.value).join(";");
    setCurrentSelection(valueToSave);
  };

  return (
    <Grid container flexDirection="column" alignItems="center">
      <Grid item xs={12} sx={{ p: 4, width: "100%" }}>
        <MultiSelectAll
          sx={{ maxheight: "700px", minHeight: "500px" }}
          items={departmentNames}
          selectAllLabel="Select All"
          value={initialValue}
          onChange={handleSelectionChange}
        />
      </Grid>
    </Grid>
  );
};

export default Selection;
