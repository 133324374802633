// internal modules
import React, { useRef, useState } from "react";

// external modules
import { Box, Button, Link, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CaretRight, Check, Folder, Prohibit } from "@phosphor-icons/react";
import toast from "react-hot-toast";
import { read, utils } from "xlsx";

// constants and variables
const columns = [
  { field: "id", headerName: "ID", editable: false, flex: 0.2, headerClassName: "datagrid-header-color" },
  {
    field: "name",
    headerName: "Name",
    editable: true,
    flex: 1,
    headerClassName: "datagrid-header-color"
  },
  { field: "email", headerName: "Email", editable: true, flex: 1, headerClassName: "datagrid-header-color" }
];

const getRowClassName = params => {
  return "row-color";
};

const StudentListUploader = ({ file, setFile, setListOrFile }) => {
  // hooks
  const theme = useTheme();
  const inputRef = useRef();
  const allowedFileTypesRegex = /\.(csv|xls[x]?)$/i; // Regex for csv, xlsx, xls

  const [confirmFile, setConfirmFile] = useState(false);
  const [excelData, setExcelData] = useState([]);

  // functions and variables
  const checkFileExtension = selectedFile => {
    if (selectedFile) {
      const fileName = selectedFile.name;
      if (allowedFileTypesRegex.test(fileName)) {
        return true;
      } else {
        return false;
      }
    }
  };

  const readXlsxFile = fileD => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        const data = e.target.result;
        const workbook = read(data, { type: "binary" });
        resolve(workbook);
      };

      reader.onerror = error => {
        reject(error);
      };

      reader.readAsBinaryString(fileD);
    });
  };

  const parseExcel = async selectedFile => {
    try {
      const workbook = await readXlsxFile(selectedFile);
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const data = utils.sheet_to_json(sheet, { header: 1 });

      const firstRow = data[0].map(element => element.toLowerCase());

      // Check if "name" and "email" are present in the first element
      if (!firstRow || !firstRow.includes("roll no") || !firstRow.includes("name") || !firstRow.includes("email")) {
        toast.error("Please upload a file with 'Name' and 'Email' as headings");
        setFile(null);
        setConfirmFile(false);
        return;
      }

      // Convert array of arrays to array of objects with lowercase keys
      const convertedData = data.slice(1).map((row, ind) => {
        const obj = { id: ind + 1 }; // Add an "id" field with a unique value
        firstRow.forEach((key, index) => {
          obj[key.toLowerCase()] = row[index];
        });
        return obj;
      });
      setExcelData(convertedData);
      setConfirmFile(true);

      // Your logic for processing the valid file can go here
    } catch (error) {
      setConfirmFile(false);
      console.error("Error reading Excel file:", error);
      toast.error("Error reading the Excel file.");
    }
  };

  const handleDrop = e => {
    e.preventDefault();
    if (checkFileExtension(e.dataTransfer.files[0])) {
      setFile(e.dataTransfer.files[0]);
    } else {
      toast.error("Please upload an xlsx, xls or csv file");
    }
  };

  const handleFileChange = e => {
    if (checkFileExtension(e.target.files[0])) {
      setFile(e.target.files[0]);
    } else {
      toast.error("Please upload an xlsx, xls or csv file");
    }
  };

  const handleFileConfirm = () => {
    setConfirmFile(true);
    parseExcel(file);
    setListOrFile("file");
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center" gap="2rem">
      <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" gap="5px">
        <Box display="flex" alignItems="center" gap="10px">
          <CaretRight />
          <Typography variant="h4" fontWeight={500}>
            Upload the student list
          </Typography>
        </Box>
        <Button
          variant="contained"
          disabled={!confirmFile}
          sx={{
            "bgcolor": theme.palette.secondary.main,
            "height": "24px",
            "display": "flex",
            "gap": "5px",
            "padding": "15px",
            "&:hover": { bgcolor: theme.palette.secondary.light }
          }}
          onClick={() => {
            setFile(null);
            setListOrFile("none");
            setConfirmFile(false);
            setExcelData([]);
          }}
        >
          <Prohibit weight="bold" size={24} color="black" />
          {"Change"}
        </Button>
      </Box>
      {confirmFile ? (
        <Box>
          <div className="datagrid-batch-container">
            <DataGrid
              className="custom-datagrid"
              autoHeight
              rows={excelData}
              editMode="row"
              columns={columns}
              getRowClassName={getRowClassName}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10 } // Set initial page size to 10 rows
                }
              }}
              pageSizeOptions={[10, 20, 50]}
              getRowId={row => row.id}
            />
          </div>
        </Box>
      ) : (
        <Box
          width="100%"
          height="40vh"
          padding="1rem"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bgcolor="#F7F6F9"
          border="1px dashed #AFAFAF"
          borderRadius="1rem"
          onDragOver={e => e.preventDefault()}
          onDrop={handleDrop}
        >
          <Folder size={100} color={theme.palette.primary.light} weight="thin" />
          {file ? (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap="3px">
              <Typography variant="h6">SELECTED FILE: {file.name}</Typography>
              <Box display="flex" gap="10px">
                <Button
                  variant="contained"
                  sx={{
                    "bgcolor": theme.palette.secondary.main,
                    "height": "24px",
                    "display": "flex",
                    "gap": "5px",
                    "padding": "15px",
                    "&:hover": { bgcolor: theme.palette.secondary.light }
                  }}
                  onClick={() => {
                    setFile(null);
                    setListOrFile("none");
                    setExcelData([]);
                    setConfirmFile(false);
                  }}
                >
                  <Prohibit weight="bold" size={24} color="black" />
                  {"Change"}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    "bgcolor": theme.palette.secondary.main,
                    "height": "24px",
                    "display": "flex",
                    "gap": "5px",
                    "padding": "15px",
                    "&:hover": { bgcolor: theme.palette.secondary.light }
                  }}
                  onClick={handleFileConfirm}
                >
                  <Check weight="bold" size={24} color="black" />
                  {"Select"}
                </Button>
              </Box>
            </Box>
          ) : (
            <Box display="flex" alignItems="center" gap="3px">
              <Typography variant="h6">Drop you Folder here, or </Typography>
              <Link
                href="#"
                underline="always"
                color={theme.palette.secondary.main}
                onClick={() => inputRef.current.click()}
              >
                {"browse"}
              </Link>
              <input type="file" ref={inputRef} onChange={handleFileChange} hidden />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default StudentListUploader;
