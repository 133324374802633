import { Box, Chip, Typography, styled, useMediaQuery } from "@mui/material";
import { CaretDown, CursorClick } from "@phosphor-icons/react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";
import { useGetAllStudentAnalysisQuery } from "../../../redux/api/analysisSlice";

const CTypography = styled(Typography)(({ theme }) => ({
  width: "100%",
  textAlign: "center",
  display: "flex",
  gap: "3px",
  alignItems: "center",
  justifyContent: "center"
}));

const TestHistoryStudent = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const navigate = useNavigate();
  const { user } = useSelector(state => state.auth);
  const { data: analysisArray, isSuccess: isGetAnalysisSuccess } = useGetAllStudentAnalysisQuery(user._id);

  useEffect(() => {
    document.title = "Insights | Test History";
  }, []);

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Test History", link: "#" }
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
      mt="1rem"
    >
      <Box width="100%" display="flex" flexDirection="column">
        <Typography variant="h4" fontWeight={600}>
          Test History
        </Typography>
        <Box
          sx={{
            maxHeight: "85vh", // Set a maximum height for the scrollable container
            overflowY: "auto", // Enable vertical scrolling
            position: "relative",
            overflowX: "auto", // Enable horizontal scrolling if needed
            maxWidth: "100%"
          }}
        >
          <table style={{ width: "100%", textAlign: "center", borderCollapse: "seperate", borderSpacing: "0 1rem" }}>
            <thead>
              <tr>
                <th
                  style={{
                    width: "15%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={400}>
                      Exam Name
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "15%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={400}>
                      Exam Type
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "15%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={400}>
                      Date
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "15%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={400}>
                      Your Score
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "15%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={400}>
                      Class Average
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "15%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={400}>
                      View
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isGetAnalysisSuccess ? (
                analysisArray?.analysisArray?.length ? (
                  analysisArray.analysisArray.slice(0, 3).map((analysis, index) => (
                    <tr
                      key={analysis.exam_unique_code}
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: "1rem",
                        borderSpacing: "1rem",
                        cursor: "pointer", // Ensure the cursor is always a pointer
                        transition: "box-shadow 0.3s ease" // Smooth transition for the shadow
                      }}
                      onClick={() => navigate(`/analyse-exam/${analysis._id}`)}
                      onMouseEnter={e => {
                        e.currentTarget.style.boxShadow = "0 0 15px rgba(0, 0, 0, 0.2)";
                      }}
                      onMouseLeave={e => {
                        e.currentTarget.style.boxShadow = "0 0 0 rgba(0, 0, 0, 0)";
                      }}
                    >
                      <td
                        style={{
                          width: "15%",
                          gap: "8px",
                          height: "4rem",
                          borderLeft: "5px solid  #3A974C",
                          borderRadius: "0.5rem"
                        }}
                      >
                        {String(analysis.exam_name).toUpperCase()}
                      </td>
                      <td style={{ width: "10%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                        {analysis.exam_for}
                      </td>
                      <td style={{ width: "10%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                        {analysis.exam_date}
                      </td>
                      <td style={{ width: "20%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                        {analysis.student_score}
                      </td>
                      <td style={{ width: "20%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                        {Math.floor(analysis.average_score)}
                      </td>
                      <td style={{ width: "20%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                        <Chip
                          variant="small"
                          sx={{
                            "bgcolor": "rgba(0,0,0,0.05)",
                            "color": "#F48C06",
                            "&:hover": { bgcolor: "rgba(0,0,0,0.09)", cursor: "pointer" },
                            "borderRadius": "3rem",
                            "cursor": "pointer",
                            "fontSize": isNonMobile ? undefined : "14px",
                            "fontWeight": isNonMobile ? undefined : 500,
                            "height": isNonMobile ? undefined : "3rem",
                            "width": isNonMobile ? undefined : "100%"
                          }}
                          label="View Analysis"
                          icon={<CursorClick size={20} />}
                          component={Link}
                          to={`/analyse-exam/${analysis._id}`}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <Typography width="100%" textAlign="center">
                    No Test History Found
                  </Typography>
                )
              ) : (
                <>Loading...</>
              )}
            </tbody>
          </table>
        </Box>
        <Box display="flex" flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <Box
            width="85%"
            sx={{
              background: "linear-gradient(to bottom, rgba(0,0,0,0), white)",
              height: "5rem",
              transform: "translateY(-70%)",
              position: "absolute",
              opacity: "1"
            }}
          />
          <Typography
            width="100%"
            textAlign="center"
            sx={{
              "zIndex": "1000",
              "color": "blue",
              "textDecoration": "underline",
              "&:hover": { cursor: "pointer" }
            }}
            variant="h5"
          >
            <Link to="/test-history">View More</Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TestHistoryStudent;
