import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import toast from "react-hot-toast";

export const userApiSlice = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/user`,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["User"],
  endpoints: builder => ({
    fetchCompleteData: builder.query({
      query: user => ({
        url: "/fetch-user-data",
        method: "POST",
        body: { userData: user }
      }),
      providesTags: ["User"],
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          toast.error("Failed to fetch user data");
        }
      }
    }),
    updateUserData: builder.mutation({
      query: userInfo => ({
        url: "/update-user",
        method: "POST",
        body: userInfo
      }),
      invalidatesTags: ["User"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Loading...", { id: "updateUser" });
          await queryFulfilled;
          toast.success("Fields updated", { id: "updateUser" });
        } catch ({ error }) {
          console.log(error);
          const message = error.data?.message || "Internal server error";
          toast.error(message, { id: "updateUser" });
        }
      }
    })
  })
});

export const { useFetchCompleteDataQuery, useUpdateUserDataMutation } = userApiSlice;
