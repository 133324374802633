import Send from "@mui/icons-material/Send";
import { Box, Checkbox, Dialog, TextField, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { CaretDown, Plus, User } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import TimeAgo from "react-timeago";
import { announcementImage } from "../../../Assets";
import { useFetchAnnouncementsByBatchIdQuery } from "../../../redux/api/announcementSlice";
import { useGetBatchesByFacultyQuery } from "../../../redux/api/batchSlice";
import {
  createAnnouncement,
  fetchAnnouncementsByBatchId
} from "../../../redux/features/Announcement/announcementSlice";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";
import CButton from "../../ui/Button";

const CTypography = styled(Typography)(({ theme }) => ({
  width: "100%",
  textAlign: "center",
  display: "flex",
  gap: "3px",
  alignItems: "center",
  justifyContent: "center"
}));

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const BatchAnnouncements = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    batch: { batches },
    auth: { user },
    announcements: { announcements }
  } = useSelector(state => state);
  const [name, setName] = useState("");

  const { batchId } = useParams();
  const { data: batchesData, isSuccess: isGetBatchesSuccess } = useGetBatchesByFacultyQuery({
    school_id: user.school_id,
    faculty_id: user._id
  });
  const { data: announcementData, isSuccess: isGetAnnouncementsSuccess } = useFetchAnnouncementsByBatchIdQuery(batchId);
  useEffect(() => {
    if (isGetBatchesSuccess && batchesData.batches) {
      const batchName = batchesData.batches.find(batch => batch._id === batchId);
      setName(batchName?.batch_name || "");
    }
  }, [batchesData?.batches, batchId]);

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Batches", link: "/manage-batch" },
    { name: `${name}`, link: `/view-batch/${batchId}` },
    { name: "announcements", link: "#" }
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const [announcementText, setAnnouncementText] = useState("");
  const [announcementDescription, setAnnouncementDescription] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (announcementText.trim()) {
      const obj = {
        topic: announcementText.trim(),
        description: announcementDescription.trim(),
        authorId: user._id,
        author: user._id,
        batchId: batchId,
        schoolId: user.school_id
      };
      dispatch(createAnnouncement(obj));
      handleRefresh();
      setAnnouncementText("");
      handleClose();
    }
  };

  useEffect(() => {
    if (batchId) {
      dispatch(fetchAnnouncementsByBatchId(batchId));
    }
  }, [batchId]);

  const handleRefresh = () => {
    if (batchId) {
      dispatch(fetchAnnouncementsByBatchId(batchId));
    }
  };

  return (
    <Box
      padding={isNonMobile ? "1rem 2rem" : "1rem"}
      display="flex"
      flexDirection="column"
      gap="1rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <div style={{ padding: "1rem 0" }}>
        <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
      </div>
      <Box
        display="flex"
        flexDirection={isNonMobile ? "row" : "column"}
        gap="0.75rem"
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Box display={"flex"} alignItems={"center"} flexGrow={1} gap="1rem">
          <Box>
            <Typography variant={isNonMobile ? "h2" : "h3"} fontWeight={600}>
              Announcements
            </Typography>
            <Typography variant="h6" color={theme.palette.primary.light}>
              View and create announcements here
            </Typography>
          </Box>
          {/* <CButton
            buttonText=""
            textOverflow="ellipsis"
            variant="primary"
            icon={<RefreshIcon />}
            onClickFun={handleRefresh}
            sx={{ height: "2.5rem" }}
          /> */}
        </Box>
        <Box>
          <Link style={{ textDecoration: "none" }} to={`${location.pathname}/create`}>
            <CButton
              buttonText="Create Announcement"
              variant="primary"
              icon={<Plus weight="bold" color="#fff" size={18} />}
              onClickFun={handleOpen}
              sx={{ height: "2.5rem" }}
            />
          </Link>
        </Box>
      </Box>
      <Dialog
        open={modalOpen}
        onClose={handleClose}
        PaperProps={{
          sx: { borderRadius: "32px" },
          component: "form",
          onSubmit: event => {
            event.preventDefault();
            handleClose();
          }
        }}
      >
        <Box overflow="hidden" display={"flex"} padding={""} borderRadius={"2rem"}>
          <Box width={"40%"}>
            <img src={announcementImage} style={{ objectFit: "cover", width: "100%", height: "100%" }} />
          </Box>
          <Box width={"60%"} display={"flex"} flexDirection={"column"} gap={"1rem"} padding={"2rem"}>
            <Box>
              <Typography variant="h2" fontWeight={700}>
                Create a new Announcement!
              </Typography>
            </Box>
            <Box>
              <Checkbox {...label} sx={{ padding: "0" }} defaultChecked /> This announcement will be sent to each user
              associated with this batch via email also.
            </Box>
            <Box>
              <TextField
                id="outlined-textarea"
                label="Announcement"
                placeholder="📢write your Announcement..."
                multiline
                fullWidth
                value={announcementText}
                onChange={e => setAnnouncementText(e.target.value)}
              />
            </Box>
            <Box>
              <TextField
                id="outlined-textarea"
                // label="Description"
                placeholder="📢write description..."
                multiline
                fullWidth
                minRows={2}
                value={announcementDescription}
                onChange={e => setAnnouncementDescription(e.target.value)}
              />
            </Box>
            <Box display={"flex"} justifyContent={"flex-end"}>
              <CButton
                buttonText="submit"
                textOverflow="ellipsis"
                variant="primary"
                sx={{ height: "2.5rem", position: "relative", right: "0px" }}
                icon={<Send weight="bold" color="#fff" size={18} />}
                onClickFun={handleSubmit}
              />
            </Box>
          </Box>
        </Box>
      </Dialog>
      <Box display="flex" alignItems="center" justifyContent="space-between" px="1rem" width="100%">
        <Typography variant="h6" width="65%" textAlign="center" display="flex" alignItems="center" gap="3px">
          Sr. No.
          <CaretDown weight="fill" />
        </Typography>
        <CTypography variant="h6">
          Topic
          <CaretDown weight="fill" />
        </CTypography>
        <CTypography variant="h6">
          Started_by
          <CaretDown weight="fill" />
        </CTypography>
        <CTypography variant="h6">
          Created_at
          <CaretDown weight="fill" />
        </CTypography>
      </Box>
      <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
        {isGetAnnouncementsSuccess &&
          announcementData?.announcements?.map((announcement, index) => (
            <Link
              to={`/view-batch/${batchId}/announcements/${announcement._id}`}
              key={index}
              style={{ textDecoration: "none" }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                py="0.5rem"
                px="0.6rem"
                pl={"2rem"}
                bgcolor="#fff"
                borderRadius="0.25rem"
                key={index + 1}
                tansition={"ease-in 1s"}
                scale={1}
                sx={{
                  "color": "black",
                  "&:hover": {
                    scale: "1",
                    boxShadow: "2"
                  }
                }}
              >
                <Typography variant="h6" width="65%" textAlign="left" style={{ textOverflow: "ellipsis" }}>
                  {index + 1}
                </Typography>

                <CTypography variant="h6">{announcement.topic}</CTypography>

                <CTypography variant="h6" sx={{ display: "flex", gap: "15px" }}>
                  <User weight="fill" color={theme.palette.secondary.main} size={18} />
                  {announcement.author.name}
                </CTypography>
                <CTypography variant="h6" textAlign={"center"} sx={{ display: "flex", gap: "15px" }}>
                  <TimeAgo sx={{ textAlign: "center" }} date={announcement.updatedAt} />
                </CTypography>
              </Box>
            </Link>
          ))}
      </Box>
    </Box>
  );
};

export default BatchAnnouncements;
