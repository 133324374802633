import { Button, styled } from "@mui/material";
import React from "react";

// custom styled button
const CButton = ({ buttonText, variant, onClickFun, icon, additionalProps, ref, ...rest }) => {
  const CustomButton = styled(Button)(({ theme }) => ({
    "backgroundColor": variant === "primary" ? theme.palette.buttons.default : theme.palette.buttons.secondary,
    "color": variant === "primary" ? theme.palette.buttons.defaultText : theme.palette.buttons.secondaryText,
    "fontWeight": "700",
    "fontSize": "14px",
    "cursor": "pointer",
    "padding": "0.5rem 1.25rem",
    "borderRadius": "7px",
    "textTransform": "none",
    "display": "flex",
    "gap": "1rem",
    "border": "2px solid transparent",
    "&:hover": {
      backgroundColor: variant === "primary" ? "rgb(50, 103, 255, 0.6)" : theme.palette.buttons.secondaryText,
      color: variant === "primary" ? "#fff" : theme.palette.buttons.secondary,
      borderColor: "primary" ? theme.palette.buttons.default : theme.palette.buttons.secondary
    }
  }));

  return (
    <CustomButton onClick={onClickFun} {...rest} ref={ref}>
      {icon}
      {buttonText}
      {additionalProps}
    </CustomButton>
  );
};

// render
export default CButton;
