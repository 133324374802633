import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Box,
  Checkbox,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { LockSimple } from "@phosphor-icons/react";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import Carousel from "react-material-ui-carousel";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logo } from "../../Assets";
import { useLoginMutation } from "../../redux/api/authSlice";
import { setCredentials } from "../../redux/features/auth/authSlice";
import CButton from "../ui/Button";
import TypewriterEffect from "./TypeWriteEffect";

// functions and variables
const InputBox = styled(FormControl)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "blue"
}));

// const LoginCarousel = () => {
//   const items = [
//     {
//       id: 1,
//       name: "Scaleyou Insights",
//       description:
//         "Precision Analysis, Performance Enhancement, ScaleYou Insights for students teachers and Institutes!"
//     },
//     {
//       id: 2,
//       name: "Detailed Reports and Analysis",
//       description: "Access detailed reports and analysis instantly with just one click using Scaleyou Insights!"
//     },
//     {
//       id: 3,
//       name: "Test and Courses Management System",
//       description:
//         "Experience the best versatile offline/online test format and manage all your courses on Scaleyou Insights."
//     }
//   ];

//   return (
//     <Box marginTop="1rem" marginBottom="1rem">
//       <Carousel
//         autoPlay="true"
//         animation="slide"
//         loop
//         duration="2000"
//         interval="5000"
//         activeIndicatorIconButtonProps={{
//           style: {
//             color: "#3267FF"
//           }
//         }}
//       >
//         {items.map(item => (
//           <Box
//             key={item.id}
//             display="flex"
//             flexDirection="column"
//             gap="0.5rem"
//             alignItems="center"
//             justifyContent="center"
//           >
//             <Typography variant="h4" fontWeight={500} textAlign="center">
//               {item.name}
//             </Typography>
//             <Typography variant="h5" textAlign="center">
//               {item.description}
//             </Typography>
//           </Box>
//         ))}
//       </Carousel>
//     </Box>
//   );
// };

const LoginMobile = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [login, { isLoading, isSuccess, isError }] = useLoginMutation();

  // useState
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  // functions and variables
  const handleChange = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleLogin = async () => {
    setLoading(true);
    const userData = {
      email: formData.email,
      password: formData.password
    };
    try {
      const response = await login(userData);
      dispatch(setCredentials(response?.data));
      if (response?.data) {
        navigate("/");
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (formData.email.trim() === "") {
      return toast.error("Please enter your email");
    }

    if (formData.password.trim() === "") {
      return toast.error("Please enter your password");
    }

    handleLogin();
  };

  useEffect(() => {
    document.title = "Insights | Login";
  }, []);

  const imgRef = useRef(null);
  const directionRef = useRef("left"); // Initial direction

  return (
    <Box width="100%">
      {/* {page === "LANDING" && (
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-evenly"
          alignItems="center"
          gap=".5rem"
        >
          <Box
            // className="analysis"
            width="100%"
            height="350px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            position="relative"
          >
            <Box position="absolute" width="100%" height="100%">
              <Box position="absolute" width="100%" height="100%" zIndex="-2">
                <img
                  src={loginPageImage}
                  alt="Login"
                  style={{
                    zIndex: "4",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    animation: "moveRight 30s linear infinite"
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box width="90%" bottom="1rem">
            <LoginCarousel />
          </Box>
          <Box
            width="100%"
            flexDirection="column"
            gap="1rem"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ marginBottom: "1rem" }}
          >
            <CButton
              buttonText="Login"
              variant="primary"
              sx={{ borderRadius: "2rem", width: "90%" }}
              onClickFun={() => navigate("/login")}
            />
            <CButton
              buttonText="Sign Up"
              variant="primary"
              sx={{
                borderRadius: "2rem",
                width: "90%",
                backgroundColor: "#fff",
                color: theme.palette.secondary.main,
                border: `1px solid ${theme.palette.secondary.main}`
              }}
              onClickFun={() => navigate("/signup")}
            />
          </Box>
        </Box>
      )} */}
      <Box
        width="100vw"
        padding="0.75rem"
        height="100dvh"
        display="flex"
        justifyContent="start"
        alignItems="start"
        flexDirection="column"
      >
        {isLoading && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <TypewriterEffect />
          </Box>
        )}
        <Box width="100%" height="100%" display="flex" justifyContent="start" alignItems="start" flexDirection="column">
          <Box onClick={() => navigate("/")}>
            <img src={logo} width="80%" />
          </Box>
          <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box width={isNonMobile ? "60%" : "90%"}>
              <Box display="flex" justifyContent="center" alignItems="center" gap=".5rem">
                {/* <X size={24} color={theme.palette.primary.light} onClick={() => setPage("LANDING")} /> */}
                <Box
                  color={theme.palette.primary.light}
                  onClick={() => navigate("/")}
                  sx={{ cursor: "pointer " }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <ChevronLeftIcon fontSize="large" />
                </Box>
                <Box width="100%" display="flex" justifyContent="start" alignItems="center" gap=".5rem">
                  <Typography variant="h2" fontWeight={600}>
                    Login
                  </Typography>
                </Box>
              </Box>
              <Box
                width="100%"
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems="start"
                gap=".5rem"
                marginTop="1rem"
              >
                <Typography variant="h6" fontWeight={500}>
                  Welcome to Scaleyou’s exam analysis software, please put your login credentials below to start using
                  the software
                </Typography>
              </Box>
              <FormControl fullWidth>
                <Box mt="3rem" display="flex" flexDirection="column" gap="0.75rem">
                  <InputBox>
                    <TextField
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      size="medium"
                      required
                      placeholder="Enter email"
                      type="email"
                      sx={{ width: "100%", color: theme.palette.secondary.main }}
                      InputProps={{ inputProps: { style: { color: theme.palette.secondary.main } } }}
                    />
                  </InputBox>
                  <InputBox>
                    <TextField
                      id="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      size="medium"
                      required
                      className="sentry-mask"
                      placeholder="Enter password"
                      type={passwordVisible ? "text" : "password"}
                      sx={{ width: "100%", color: theme.palette.secondary.main }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockSimple color={theme.palette.secondary.main} weight="fill" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Checkbox
                              checked={passwordVisible}
                              onChange={() => setPasswordVisible(!passwordVisible)}
                              sx={{
                                "color": theme.palette.secondary.main,
                                "p": 0,
                                "&.Mui-checked": {
                                  color: theme.palette.secondary.main
                                }
                              }}
                            />
                          </InputAdornment>
                        ),
                        inputProps: { style: { color: theme.palette.secondary.main } }
                      }}
                    />
                  </InputBox>
                </Box>
                <Box
                  display="flex"
                  mt="0.5rem"
                  justifyContent="end"
                  color={theme.palette.primary.main}
                  component={Link}
                  to="/forgot-password"
                >
                  Forgot Password?
                </Box>
                <Box display="flex" mt="1rem" justifyContent="flex-end">
                  <CButton
                    buttonText={"Sign In"}
                    variant={"primary"}
                    onClickFun={handleSubmit}
                    sx={{ width: "100%", borderRadius: "2rem" }}
                  />
                </Box>
              </FormControl>
            </Box>
            <Box width="100%" display="flex" justifyContent="center" alignItems="end" padding="1rem" gap="4px">
              <Typography>Don’t have an account ?</Typography>
              <Link to="/signup" color="#fff" variant="h5" fontWeight={500} sx={{ height: "0" }}>
                Sign Up
              </Link>
            </Box>

            <Box display="flex" justifyContent="end" height="50%" flexDirection="column" alignItems="center">
              <Typography variant="h6" textAlign="center">
                For sales and queries visit <br />
                <Link to="/https://www.scaleyou-insights.com/" target="_blank">
                  www.scaleyou-insights.com
                </Link>
              </Typography>
              <Typography variant="h6" textAlign="center">
                © 2023 Scaleyou. Powered and secured by Scaleyou
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginMobile;
