import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  TextField,
  useMediaQuery,
  useTheme
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState, useMemo } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCreateATaskSectionMutation, useGetAllSectionsQuery } from "../../../redux/api/taskSlice";

import IndividualLessons from "./IndividualLessons";

const CourseContent = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const { user } = useSelector(state => state.auth);
  const { batchId } = useParams();
  const [open, setOpen] = useState(false);
  const [sectionName, setSectionName] = useState("");
  const [sections, setSections] = useState([]);
  const [subjectName, setSubjectName] = useState([]);
  const [createASection, { isLoading }] = useCreateATaskSectionMutation();
  const { data: allSections = [], isLoading: loadingSections } = useGetAllSectionsQuery({
    schoolId: user?.school_id,
    batchId
  });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const inputRefFile = useRef();
  const navigate = useNavigate();

  const [sectionToUpload, setSectionToUpload] = useState("");

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [sectionFiles, setSectionFiles] = useState([]);
  const [fileExtension, setFileExtention] = useState("");
  const [showTestCreation, setShowTestCreation] = useState(false);
  const options = [
    "bold",
    "italic",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "|",
    "outdent",
    "indent",
    "align",
    "|",
    "hr",
    "|",
    "fullsize",
    "brush",
    "|",
    "table",
    "link",
    "|",
    "undo",
    "redo"
  ];
  const allowedFileTypesRegex = /\.(pdf|mp4|ppt|txt|pptx)$/i;

  const checkFileExtension = selectedFile => {
    if (selectedFile && allowedFileTypesRegex.test(selectedFile.name)) {
      return true;
    } else {
      return false;
    }
  };

  const handleFileChange = e => {
    if (checkFileExtension(e.target.files[0])) {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
      setFileExtention(e.target.files[0]?.name.split(".").pop());
    } else {
      toast.error("Please upload a file with allowed extensions: PDF, MP4, PPT");
    }
  };

  useEffect(() => {
    const fetchSectionFiles = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/task/files/${user?.school_id}/${batchId}`);
        // const fileNames = response.data.contents.map(content => {
        //   const parts = content.Key.split('/');
        //   return parts[parts.length - 1];
        // });
        setSectionFiles(response.data.contents);
      } catch (error) {
        console.error("Error fetching section files:", error);
        toast.error("Error fetching section files");
      }
    };

    fetchSectionFiles(sectionToUpload);
  }, [user?.school_id, batchId]);

  const handleDrop = e => {
    e.preventDefault();
    if (checkFileExtension(e.dataTransfer.files[0])) {
      setFile(e.dataTransfer.files[0]);
      setFileName(e.dataTransfer.files[0].name);
    } else {
      toast.error("Please upload a file with allowed extensions: PDF, MP4, PPT");
    }
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("school_id", user?.school_id);
    formData.append("batch_id", batchId);
    formData.append("section_id", sectionToUpload);
    formData.append("file_extention", fileExtension);

    toast.loading("Uploading...", { id: "uploadToast" });
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/task/files-upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      toast.success("File uploaded successfully", { id: "uploadToast" });
      setSectionToUpload("");
      setFile(null);
      setFileName("");
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Error uploading file", { id: "uploadToast" });
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateSection = async () => {
    // navigate(`/add-section/${batchId}`);
    try {
      const taskSectionData = {
        name: sectionName,
        subject_name: subjectName,
        school_id: user?.school_id,
        batch_id: batchId
      };
      const createdSection = await createASection(taskSectionData);
      setSections([...sections, createdSection.tasksection]);
      setSectionName("");
      handleClose();
    } catch (error) {
      console.error("Error creating task section:", error);
    }
  };

  const handleFileClick = async fileKey => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/task/file-download/presigned`, { fileKey });
      const downloadUrl = response.data.url;
      const fileName = fileKey.split("/").pop();
      const fileExtension = fileName.split(".").pop().toLowerCase();

      // Check if the file is a video (e.g., .mp4)
      if (fileExtension === "mp4") {
        // Redirect to video streaming page
        window.location.href = `/view-batch/${batchId}/tasks/stream-video?url=${encodeURIComponent(downloadUrl)}`;
      } else {
        // Handle non-video files (download)
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = fileName; // Get the file name from the key
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("Error downloading file");
    }
  };

  const handleDrawerOpen = sectionId => {
    setSectionToUpload(sectionId);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSectionToUpload("");
  };
  const handleCreateTestClick = () => {
    // Redirect to the desired URL
    window.location.href = `/view-batch/${batchId}/tasks/test`;
  };

  const hasSections = allSections && allSections.sections && allSections.sections.length > 0;
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
        margin={"1rem"}
        width={"100%"}
      >
        <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"} width={"100%"}>
          <Box display="flex" flexDirection="column" width="93.5%" gap="1rem">
            {allSections?.sections?.length > 0 ? (
              allSections.sections.map((section, index) => (
                <IndividualLessons Section={section} User={user} BatchId={batchId} index={index} key={index} />
              ))
            ) : (
              <p style={{ alignSelf: "center" }}>No Sections</p>
            )}
          </Box>
        </Box>
        <Box bottom={16} right={16} marginTop="2rem" alignSelf="end" marginRight="2.2rem">
          <Fab
            // onClick={handleOpen}
            onClick={() => {
              setOpen(true);
            }}
            variant="extended"
            sx={{
              "backgroundColor": "#3267ff",
              "color": "white",
              ":hover": { backgroundColor: "#3267ff", scale: "1.05" }
            }}
          >
            <AddIcon sx={{ mr: 1 }} />
            Add Section
          </Fab>
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "500px",
              height: "250px"
            }
          }}
        >
          <DialogTitle sx={{ fontSize: "24px", paddingBottom: "3px" }}>Add Section</DialogTitle>
          <DialogContent sx={{ fontSize: "18px", paddingBottom: "15px" }}>
            <TextField
              autoFocus
              margin="dense"
              id="sectionName"
              label="Section Name"
              type="text"
              fullWidth
              value={sectionName}
              onChange={e => setSectionName(e.target.value)}
              sx={{
                "& .MuiInputBase-root": {
                  fontSize: "16px",
                  backgroundColor: "#fff"
                },
                "& .MuiInputLabel-root": {
                  fontSize: "15px"
                }
              }}
            />

            <TextField
              margin="dense"
              id="SubjectName"
              label="Subject Name"
              type="text"
              fullWidth
              value={subjectName}
              onChange={e => setSubjectName(e.target.value)}
              sx={{
                "& .MuiInputBase-root": {
                  fontSize: "16px",
                  backgroundColor: "#fff"
                },
                "& .MuiInputLabel-root": {
                  fontSize: "15px"
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={isLoading}
              onClick={handleClose}
              color="primary"
              sx={{
                fontsize: "25px"
              }}
            >
              Cancel
            </Button>
            <Button disabled={isLoading} onClick={handleCreateSection} color="primary">
              {isLoading ? "Creating..." : "Create"}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default CourseContent;
