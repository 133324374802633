import { Box, Button, Divider, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Folder, WarningCircle } from "@phosphor-icons/react";
import axios from "axios";
import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { read, utils } from "xlsx";
import { useBulkUserUploadMutation } from "../../../redux/api/supervisorSlice";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1
});

const getRowClassName = params => {
  return "row-color";
};

const AddMultiple = ({ type, excelData, setExcelData }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();
  const role = type === 2 ? "FACULTY" : "STUDENT";
  const [selectedExcelSheet, setSelectedExcelSheet] = useState(null);
  const { user } = useSelector(state => state.auth);
  const allowedFileTypesRegex = /\.(csv|xls[x]?)$/i; // Regex for csv, xlsx, xls

  const inputRef = useRef();

  const [bulkUserUpload, { data }] = useBulkUserUploadMutation();

  const readXlsxFile = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        const data = e.target.result;
        const workbook = read(data, { type: "binary" });
        resolve(workbook);
      };

      reader.onerror = error => {
        reject(error);
      };

      reader.readAsBinaryString(file);
    });
  };

  const handleChange = async event => {
    const selectedFile = event.target.files[0];
    setSelectedExcelSheet(selectedFile);
    if (selectedFile) {
      const fileName = selectedFile.name;
      if (allowedFileTypesRegex.test(fileName)) {
        try {
          const workbook = await readXlsxFile(selectedFile);
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const data = utils.sheet_to_json(sheet, { header: 1 });

          const firstRow = data[0].map(element => element.toLowerCase());

          // Check if "name" and "email" are present in the first element
          if (!firstRow || !firstRow.includes("name") || !firstRow.includes("email")) {
            toast.error("Please upload a file with 'Name' and 'Email' as headings");
            event.target.value = null;
            return;
          }

          if (role === "STUDENT" && !firstRow.includes("roll no")) {
            toast.error("Please upload a file with 'Roll No' as heading");
            event.target.value = null;
            return;
          }

          // Convert array of arrays to array of objects with lowercase keys
          const convertedData = data.slice(1).map((row, ind) => {
            const obj = { id: ind + 1 }; // Add an "id" field with a unique value
            firstRow.forEach((key, index) => {
              obj[key.toLowerCase()] = row[index];
            });
            return obj;
          });
          setExcelData(convertedData);

          // Your logic for processing the valid file can go here
        } catch (error) {
          console.error("Error reading Excel file:", error);
          toast.error("Error reading the Excel file.");
        }
      } else {
        // File type is not allowed
        toast.error("Please upload a CSV, XLSX, or XLS file");
        event.target.value = null;
      }
    }
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("userFile", selectedExcelSheet);
    formData.append("role", type);
    formData.append("school_id", user.school_id);
    const response = await bulkUserUpload(formData);
  };

  const handleDownloadTemplate = async () => {
    try {
      const fileKey = role === "FACULTY" ? "assets/FacultyAdderTemplate.xlsx" : "assets/StudentAdderTemplate.xlsx";
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/task/file-download/presigned`, {
        fileKey
      });
      const downloadUrl = response.data.url;
      const fileName = fileKey.split("/").pop();
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileName; // Get the file name from the key
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("Error downloading file");
    }
  };

  const columns =
    type === 2
      ? [
          {
            field: "name",
            headerName: "Name",
            editable: true,
            flex: 1,
            headerClassName: "datagrid-header-color"
          },
          { field: "email", headerName: "Email", editable: true, flex: 1, headerClassName: "datagrid-header-color" }
        ]
      : [
          {
            field: "roll no",
            headerName: type === 2 ? "ID" : "Roll No",
            editable: false,
            flex: 0.2,
            headerClassName: "datagrid-header-color"
          },
          {
            field: "name",
            headerName: "Name",
            editable: true,
            flex: 1,
            headerClassName: "datagrid-header-color"
          },
          { field: "email", headerName: "Email", editable: true, flex: 1, headerClassName: "datagrid-header-color" }
        ];

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center" gap="3rem">
      <Box width="100%" display="flex" gap="2rem" flexDirection="column">
        <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
          <Typography variant="h4" mb="0.2rem">
            Upload {role === "FACULTY" ? "Faculty" : "Student"} list in Excel sheet
          </Typography>
          <Box width="100%" display="flex" alignItems="center" gap="15px">
            <Button
              variant="contained"
              sx={{
                "bgcolor": theme.palette.secondary.main,
                "height": "24px",
                "display": "flex",
                "gap": "5px",
                "padding": "20px",
                "&:hover": { bgcolor: theme.palette.secondary.light }
              }}
              onClick={handleDownloadTemplate}
            >
              Download Template
            </Button>
            <Box display="flex" alignItems="center" gap="10px">
              <WarningCircle size={24} style={{ minWidth: "24px" }} weight="fill" color="#F48C06" />
              <Typography variant="subtitle1" fontWeight={400}>
                Please follow the template to upload {role === "FACULTY" ? "faculty" : "student"} list.{" "}
                {role === "FACULTY" ? "Name and Email column is needed." : "Roll No,Name and Email column is needed."}
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ width: "100%" }} />
          <Box
            width="100%"
            height="20vh"
            padding="1rem"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="#F7F6F9"
            border="1px dashed #AFAFAF"
            borderRadius="1rem"
          >
            <Folder size={100} color={theme.palette.primary.light} weight="thin" />
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap="3px">
              <input
                ref={inputRef}
                type="file"
                onChange={handleChange}
                accept=".xlsx,.xls,.csv"
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                onClick={() => inputRef.current.click()}
                sx={{
                  "bgcolor": theme.palette.secondary.main,
                  "height": "24px",
                  "display": "flex",
                  "gap": "5px",
                  "padding": "15px",
                  "&:hover": { bgcolor: theme.palette.secondary.light }
                }}
              >
                Browse Files
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <div className="datagrid-container-batch">
          <DataGrid
            className="custom-datagrid"
            autoHeight
            rows={excelData}
            editMode="row"
            columns={columns}
            getRowClassName={getRowClassName}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10 } // Set initial page size to 10 rows
              }
            }}
            pageSizeOptions={[10, 20, 50]}
            getRowId={row => row.id}
          />
        </div>
      </Box>
      <Box width="95%" display="flex" justifyContent="flex-end">
        <Button
          sx={{
            "backgroundColor": theme.palette.buttons.default,
            "color": theme.palette.buttons.defaultText,
            "fontWeight": "700",
            "fontSize": "14px",
            "cursor": "pointer",
            "padding": "0.5rem 1.25rem",
            "borderRadius": "7px",
            "textTransform": "none",
            "display": "flex",
            "width": "fit-content",
            "gap": "1rem",
            "border": "2px solid transparent",
            "&:hover": {
              backgroundColor: "rgb(50, 103, 255, 0.6)",
              color: "#fff",
              borderColor: theme.palette.buttons.default
            }
          }}
          onClick={handleUpload}
          disabled={excelData.length === 0}
        >
          Add all {role === "FACULTY" ? "Faculties" : "Students"}
        </Button>
      </Box>
    </Box>
  );
};

export default AddMultiple;
