import React from "react";
import Header from "./Header";
import Pricing from "./Pricing";
import Footer from "./Footer";

const PricingPage = () => {
  return (
    <main className="flex flex-col justify-center items-center w-full gap-12 lg:gap-0">
      <Header home={false} />
      <Pricing />
      <Footer />
    </main>
  );
};

export default PricingPage;
