import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useChangeTicketStatusMutation, useGetTicketsForAdminQuery } from "../../../redux/api/raiseTicketSlice";

const colorMap = {
  LOW: "#20B058",
  MEDIUM: "#F48C06",
  HIGH: "#EA1819"
};

const ViewTickets = () => {
  const theme = useTheme();
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const { data, isSuccess } = useGetTicketsForAdminQuery();
  const [changeTicketStatus] = useChangeTicketStatusMutation();
  const { user } = useSelector(state => state.auth);

  const handleChange = ticketId => {
    changeTicketStatus(ticketId);
  };

  return (
    <Box
      padding="1rem 2rem"
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <Box display="flex" gap="0.75rem" justifyContent="space-between" flexDirection={isNonMobile ? "row" : "column"}>
        <Box>
          <Typography variant={isNonMobile ? "h2" : "h3"} fontWeight={600}>
            Raise Ticket
          </Typography>
          <Typography variant="h6" color={theme.palette.primary.light}>
            Create and view your issue tickets here
          </Typography>
        </Box>
      </Box>
      <Box width="100%" display="flex" flexDirection="column" gap="1rem">
        <Typography width="100%" variant="subtitle1">
          Create issue tickets here or directly contact us at{" "}
          <a href="mailto:contact.scaleyou@gmail.com">contact.scaleyou@gmail.com</a>
        </Typography>
        <Grid container spacing={2} paddingTop="1rem">
          {isSuccess && data && data.tickets && data.tickets.length ? (
            data.tickets?.map((ticket, index) => (
              <Grid item key={ticket._id} xs={12} sm={6} md={4} xl={3}>
                <Card
                  sx={{
                    "borderRadius": "1rem",
                    "&:hover": { backgroundColor: "rgba(0,0,0,0.04)", transition: "ease-in 200ms" },
                    "height": "100%",
                    "border": "1px solid #E6EDFF",
                    "display": "flex",
                    "flexDirection": "column"
                  }}
                >
                  <CardContent sx={{ display: "flex", flexDirection: "column", flexGrow: 1, pb: "1rem !important" }}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                      <Typography variant="h4" color="textPrimary" style={{ fontWeight: 500 }}>
                        {ticket.title}
                      </Typography>
                      <Box display="flex" gap="0.25rem" alignItems="center">
                        <div
                          style={{
                            padding: "1px 10px",
                            borderRadius: "1rem",
                            backgroundColor: colorMap[ticket.priority] || "orange",
                            color: "#fff",
                            fontWeight: 600
                          }}
                        >
                          {ticket.priority}
                        </div>
                      </Box>
                    </Box>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      style={{
                        fontWeight: 400,
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis", // Optional: This can be used to show ellipsis if text overflows
                        padding: "1rem 0rem"
                      }}
                    >
                      {ticket.description}
                    </Typography>
                    <Box mt="auto" display="flex" flexDirection="column" gap="0.5rem">
                      <Box gap="0.5rem" display="flex" alignItems="center">
                        <Box gap="0.10px" display="flex" alignItems="center">
                          <Typography variant="h6" color="textSecondary" fontWeight={400} fontSize={12}>
                            Status: <b>{ticket?.status}</b>
                          </Typography>
                          <Checkbox
                            // eslint-disable-next-line no-unneeded-ternary
                            checked={ticket?.status === "Open" ? false : true}
                            onChange={() => handleChange(ticket._id)}
                          />
                        </Box>
                        <hr style={{ height: "20px" }} />
                        <Typography variant="h6" color="textSecondary" fontWeight={400} fontSize={12}>
                          Created on {new Date(ticket?.createdAt).toLocaleDateString()}
                        </Typography>
                      </Box>
                      <Box
                        gap="0.5rem"
                        display="flex"
                        alignItems="center"
                        sx={{
                          overflowX: "auto"
                        }}
                      >
                        {ticket.tags.map(tag => (
                          <Typography
                            key={tag}
                            variant="h6"
                            color="textSecondary"
                            fontWeight={400}
                            fontSize={10}
                            style={{
                              padding: "0.5rem",
                              backgroundColor: "#E6EDFF",
                              borderRadius: "1rem"
                            }}
                          >
                            {tag}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Box p="1rem 2rem">No tickets to display</Box>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default ViewTickets;
