import { createSlice } from "@reduxjs/toolkit";

// Get user from local storage
const userFromLocalStorage = localStorage.getItem("user");
const parsedUser = userFromLocalStorage ? JSON.parse(userFromLocalStorage) : null;

const tokenFromLocalStorage = localStorage.getItem("token");

const initialState = {
  user: parsedUser || {},
  token: tokenFromLocalStorage || null
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      if (action.payload?.user) {
        localStorage.setItem("user", JSON.stringify(action.payload?.user));
        localStorage.setItem("token", action.payload?.token); // Save token directly as a string
        state.user = action.payload?.user;
      }
    },
    clearLocalStorage: state => {
      document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      state.user = null;
      state.token = null; // Clear token from Redux state
    }
  }
});

export const { setCredentials, clearLocalStorage } = authSlice.actions;
export default authSlice.reducer;
