// inbuilt modules
import React, { useEffect } from "react";

// external modules
import { Box, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// internal modules
import TotalStats from "../../../components/Student/Dashboard/Totalstats";
import TestHistoryStudent from "../../../components/Student/Dashboard/testhistory";
import DashboardDisplay from "../../../components/Teacher/ManageBatch/DashboardDisplay";

const StudentDashboard = () => {
  // hooks
  const theme = useTheme();
  const dispatch = useDispatch();

  // redux
  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    document.title = "Insights | Dashboard";
  }, []);

  // render
  return (
    <Box
      padding="1rem 2rem"
      display="flex"
      flexDirection="column"
      gap="1rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <Box display="flex" flexDirection="column">
        <Typography variant="h2" fontWeight={600} color={theme.palette.primary.dark} style={{ padding: "7px" }}>
          Welcome Back, {user.name}
        </Typography>
        <Typography
          variant="subtitle1"
          color={theme.palette.primary.light}
          style={{ padding: "7px", fontSize: "17px" }}
        >
          Here you can get all the information of yourself
        </Typography>
      </Box>
      <TotalStats />
      {/* <LineGraph /> */}
      <TestHistoryStudent />
      <DashboardDisplay who="student" />
    </Box>
  );
};

// render
export default StudentDashboard;
