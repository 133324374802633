import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, IconButton, Typography, useMediaQuery } from "@mui/material";
import { CircleNotch, PaperPlaneTilt, Plus } from "@phosphor-icons/react";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import { useGetAllHistoryFacultyQuery, useFacultyChatMutation } from "../../../redux/api/chatAiSlice";

const SingleFacultyAnalysis = ({ handleOpenNewSessionDialog, setSelectedChoice, facultyId, setSelectedFaculty }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const [selectedButton, setSelectedButton] = useState("");

  console.log("FID: ", facultyId);

  const [question, setQuestion] = useState("");
  const { user } = useSelector(state => state.auth);

  const [facultyChat, { isLoading: isSendingChat }] = useFacultyChatMutation();
  const { data, isLoading, isSuccess, isError } = useGetAllHistoryFacultyQuery({
    user_id: facultyId,
    active_user: user?._id
  });

  const [loading, setLoading] = useState(false);

  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [data]);

  const handleButtonClick = button => {
    setSelectedButton(button);
  };

  const isButtonSelected = !!selectedButton;

  const getButtonStyles = button => ({
    "backgroundColor": selectedButton === button ? "#3267FF" : "inherit",
    "color": selectedButton === button ? "white" : "inherit",
    "&:hover": {
      backgroundColor: selectedButton === button ? "#3267FF" : "#EAECF0",
      color: selectedButton === button ? "white" : "inherit"
    }
  });

  const handleSendMessage = async () => {
    if (!question) {
      toast.error("Please enter a query");
      return;
    }

    const data = {
      school_id: user?.school_id,
      user_id: facultyId,
      active_user: user?._id,
      session_type: "supervisor",
      selected_option: selectedButton.replace(/\s+/g, "").toLowerCase(),
      question
    };
    console.log(data);

    setLoading(true);

    try {
      const res = await facultyChat(data).unwrap();
      // Process the response if needed
    } catch (error) {
      console.log(error);
      // Handle the error if needed
    } finally {
      setLoading(false);
      setQuestion("");
    }

    console.log(data);
  };

  const handleBackClick = () => {
    setSelectedChoice(null);
    setSelectedFaculty(null);
  };

  return (
    <>
      {isNonMobile && (
        <Box width={"20%"} bgcolor={"rgb(250,250,251,1)"} display={"flex"} flexDirection={"column"} padding={1}>
          <Box display={"flex"} justifyContent={"start"}>
            <IconButton
              color="primary"
              aria-label="refresh"
              sx={{
                "&:hover": {
                  backgroundColor: "transparent", // Ensure background color doesn't change
                  cursor: "default"
                }
              }}
              onClick={handleBackClick}
            >
              <ChevronLeftIcon
                size={36}
                color="#3267FF"
                sx={{
                  cursor: "default"
                }}
              />
              <Typography variant="subtitle2">Choose something else</Typography>
            </IconButton>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography variant="h6" marginLeft={"0.3rem"} marginTop={"0.5rem"}>
              New Session
            </Typography>
            <IconButton
              color="primary"
              aria-label="refresh"
              sx={{
                "&:hover": {
                  backgroundColor: "transparent" // Ensure background color doesn't change
                },
                "cursor": "pointer"
              }}
              onClick={handleOpenNewSessionDialog}
            >
              <Plus
                size={28}
                color="white"
                style={{
                  backgroundColor: "#3267FF",
                  borderRadius: 5,
                  backgroundSize: 5,
                  padding: "0.2rem",
                  cursor: "pointer"
                }}
              />
            </IconButton>
          </Box>
          <Box display={"flex"} flexDirection={"column"} rowGap={"2rem"}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              columnGap={"2rem"}
              marginTop={"1rem"}
              rowGap={"0.5rem"}
              marginLeft={"0.3rem"}
            >
              <Typography variant="h8" color={"#3267FF"}>
                Today
              </Typography>
              <Box display={"flex"} flexDirection={"column"} rowGap={"0.2rem"}>
                {data?.facultyHistory && (
                  <Typography>Prompts remaining: {20 - (data.facultyHistory.limit_count || 0)}</Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Box width={"100%"} display={"flex"} flexDirection={"column"}>
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          marginTop={"1rem"}
          height="calc(100% - 120px)"
          overflow="auto"
          padding={2}
          ref={chatContainerRef}
          className="chat-container"
          position="relative" // Add this to allow absolute positioning of children
        >
          {isSuccess &&
            !loading &&
            data?.facultyHistory &&
            data?.facultyHistory?.history?.map((message, index) => (
              <Box
                key={index}
                alignSelf={message.role === "user" ? "flex-end" : "flex-start"}
                backgroundColor={message.role === "user" ? "#E3F2FD" : "#F5F5F5"}
                padding={2}
                borderRadius={2}
                maxWidth="70%"
              >
                <ReactMarkdown>{message.parts[0].text}</ReactMarkdown>
              </Box>
            ))}
          {loading && (
            <Box position="absolute" top="50%" left="50%" style={{ transform: "translate(-50%, -50%)" }}>
              <CircleNotch size={28} color="#3267FF" style={{ animation: "spin 2s linear infinite" }} />
            </Box>
          )}
          {isError && <Typography>Error fetching data</Typography>}
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box display="flex" flexDirection="column" mt="auto" mb={2} overflow={"hidden"}>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" columnGap="0.5%">
            <Button
              variant="outlined"
              sx={{
                marginBottom: 1,
                border: "1px solid #EAECF0",
                ...getButtonStyles("Last Test Analysis"),
                cursor: selectedButton === "Last Test Analysis" ? "default" : "pointer"
              }}
              onClick={() => handleButtonClick("Last Test Analysis")}
            >
              Last Test Analysis
            </Button>
            <Button
              variant="outlined"
              sx={{
                marginBottom: 1,
                border: "1px solid #EAECF0",
                ...getButtonStyles("Last Month Analysis"),
                cursor: selectedButton === "Last Month Analysis" ? "default" : "pointer"
              }}
              onClick={() => handleButtonClick("Last Month Analysis")}
            >
              Last Month Analysis
            </Button>
            <Button
              variant="outlined"
              sx={{
                marginBottom: 1,
                border: "1px solid #EAECF0",
                ...getButtonStyles("Complete Analysis"),
                cursor: selectedButton === "Complete Analysis" ? "default" : "pointer"
              }}
              onClick={() => handleButtonClick("Complete Analysis")}
            >
              Complete Analysis
            </Button>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "80%",
                border: "1px solid #EAECF0",
                backgroundColor: !isButtonSelected ? "#EAECF0" : "rgba(0,0,0,0.036)",
                borderRadius: 12,
                height: "fit-content",
                padding: "10px"
              }}
            >
              <input
                style={{
                  width: "100%",
                  outline: "none",
                  paddingLeft: 10,
                  backgroundColor: "rgba(0,0,0,0)",
                  paddingRight: 40, // Add padding to the right to make space for the icon
                  border: "none"
                }}
                placeholder={isButtonSelected ? "Type your question here" : "Please select an option from above"}
                type="text"
                readOnly={!isButtonSelected}
                value={question}
                onChange={e => setQuestion(e.target.value)}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    handleSendMessage();
                  }
                }}
              />
              <Box zIndex={20}>
                <PaperPlaneTilt
                  size={20}
                  style={{
                    zIndex: 10,
                    right: 24,
                    cursor: isButtonSelected ? "pointer" : "default",
                    pointerEvents: isButtonSelected ? "auto" : "none" // Ensure the icon doesn't block input interactions if not selected
                  }}
                  onClick={handleSendMessage}
                />
              </Box>
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SingleFacultyAnalysis;
