import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import toast from "react-hot-toast";

export const meetApiSlice = createApi({
  reducerPath: "meetApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/meet`,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["Meets"],
  endpoints: build => ({
    saveCredentials: build.mutation({
      query: credentials => ({
        url: "/saveCredentials",
        method: "POST",
        body: credentials
      }),
      invalidatesTags: ["Meets"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Saving Credentials", { id: "saveCredentials" });
          await queryFulfilled;
          toast.success("Credentials Saved Successfully", { id: "saveCredentials" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "saveCredentials" });
        }
      }
    }),
    createMeeting: build.mutation({
      query: meetingDetails => ({
        url: "/createMeetings",
        method: "POST",
        body: meetingDetails
      }),
      invalidatesTags: ["Meets"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Creating Meeting", { id: "createMeeting" });
          await queryFulfilled;
          toast.success("Meeting Created Successfully", { id: "createMeeting" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "createMeeting" });
        }
      }
    }),
    getMeetings: build.query({
      query: batch_id => ({
        url: `/getFacultyMeetings/${batch_id}`
      }),
      providesTags: ["Meets"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    addRecordingLink: build.mutation({
      query: ({ meeting_id, recording_link }) => ({
        url: "/addRecordingLink",
        method: "POST",
        body: { meeting_id, recording_link }
      }),
      invalidatesTags: ["Meets"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Adding Recording Link", { id: "addRecordingLink" });
          await queryFulfilled;
          toast.success("Recording Link Added Successfully", { id: "addRecordingLink" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "addRecordingLink" });
        }
      }
    }),
    getAllRecordings: build.query({
      query: batch_id => ({
        url: `/getAllRecordings/${batch_id}`
      }),
      providesTags: ["Meets"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    })
  })
});

export const {
  useSaveCredentialsMutation,
  useCreateMeetingMutation,
  useGetMeetingsQuery,
  useAddRecordingLinkMutation,
  useGetAllRecordingsQuery
} = meetApiSlice;
