import { toast } from "react-hot-toast";

let toastId;

export const toastStart = message => {
  toastId = toast.loading(message);
};

export const toastSuccess = message => {
  toast.success(message);
};

export const toastEnd = message => {
  toast.dismiss(toastId);
};

export const toastError = error => {
  toast.error(error);
};
