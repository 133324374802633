import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import DownloadAppNow from "../DownloadAppNow";

const Jobs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    position: "",
    availableStartDate: "",
    file: null
  });

  const handleChange = e => {
    const { id, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [id]: value
    }));
  };

  const handleFileChange = e => {
    setFormData(prevData => ({
      ...prevData,
      file: e.target.files[0]
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.phone ||
      !formData.position ||
      !formData.availableStartDate ||
      !formData.file
    ) {
      toast.error("Please fill in all required fields");
    }

    const newFormData = new FormData();
    newFormData.append("firstName", formData.firstName);
    newFormData.append("lastName", formData.lastName);
    newFormData.append("email", formData.email);
    newFormData.append("phone", formData.phone);
    newFormData.append("position", formData.position);
    newFormData.append("availableStartDate", formData.availableStartDate);
    newFormData.append("file", formData.file);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/telegram/sendContactDetails`, newFormData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      toast.success("Form submitted successfully");
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        position: "",
        availableStartDate: "",
        file: null
      });
    } catch (error) {
      toast.error("Something went wrong. Please try again later.");
    }
  };

  return (
    <div className="mt-8 lg:mt-12 flex flex-col gap-8 lg:gap-20">
      <div className="container px-4 lg:px-20 flex flex-col justify-center items-center gap-8 lg:gap-12 w-full">
        <div className="flex flex-col justify-center items-center text-center w-full lg:w-1/2 gap-4 lg:gap-8">
          <h1 className="m-0 text-2xl lg:text-4xl font-semibold text-[#4A3AFF]">Work at ScaleYou</h1>
          <h2 className="m-0 font-normal text-xl lg:text-2xl">
            There are always opportunities for talented people to join our epic team
          </h2>
          <p className="m-0 text-sm">
            At Scale You, we are always on the lookout for talented developers to join our team. We provide an
            innovative and dynamic work environment where you can grow your skills and take on exciting challenges. Our
            team is passionate about creating cutting-edge applications that make a positive impact on people's lives.
          </p>
          <h3 className="m-0 text-2xl lg:text-4xl">Think you have what it takes? Send us your resume</h3>
        </div>
        <section className="w-full lg:w-1/2">
          <form onSubmit={handleSubmit} className="grid grid-cols-1 lg:grid-cols-2 w-full gap-4">
            <div className="space-y-1">
              <p htmlFor="firstName" className="m-0">
                First Name
              </p>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                className="w-full px-2 py-3 rounded border"
                onChange={handleChange}
              />
            </div>
            <div className="space-y-1">
              <p htmlFor="lastName" className="m-0">
                Last Name
              </p>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                className="w-full px-2 py-3 rounded border"
                onChange={handleChange}
              />
            </div>
            <div className="space-y-1">
              <p htmlFor="email" className="m-0">
                Email *
              </p>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                className="w-full px-2 py-3 rounded border"
                onChange={handleChange}
              />
            </div>
            <div className="space-y-1">
              <p htmlFor="phone" className="m-0">
                Phone
              </p>
              <input
                type="number"
                id="phone"
                name="phone"
                value={formData.phone}
                className="w-full px-2 py-3 rounded border"
                onChange={handleChange}
              />
            </div>
            <div className="space-y-1">
              <p htmlFor="position" className="m-0">
                Position you are applying to
              </p>
              <select
                id="position"
                name="position"
                value={formData.position}
                className="w-full px-2 py-3 rounded border"
                onChange={handleChange}
              >
                <option value="">Select Position</option>
                <option value="Full Stack Developer">Full Stack Developer</option>
                <option value="AI Engineer">AI Engineer</option>
                <option value="Python Developer">Python Developer</option>
                <option value="UI/UX designer">UI/UX designer</option>
                <option value="Product Manager">Product Manager</option>
                <option value="Sales & Marketing">Sales & Marketing</option>
                <option value="Social Media Manager">Social Media Manager</option>
              </select>
            </div>
            <div className="space-y-1">
              <p htmlFor="availableStartDate" className="m-0">
                Available start date *
              </p>
              <input
                type="date"
                id="availableStartDate"
                name="availableStartDate"
                value={formData.availableStartDate}
                className="w-full px-2 py-3 rounded border"
                onChange={handleChange}
              />
            </div>
            <div className="space-y-1">
              <p htmlFor="file" className="m-0">
                Link to your Resume
              </p>
              <input
                type="file"
                id="file"
                name="file"
                className="w-full px-2 py-3 rounded border"
                onChange={handleFileChange}
              />
            </div>
            <div className="flex justify-end items-end h-full">
              <button
                type="submit"
                className="bg-black text-white px-2 py-3 w-full outline-none border-none rounded cursor-pointer"
              >
                Submit
              </button>
            </div>
          </form>
        </section>
        <div>
          <h4 className="m-0 text-sm">
            For college internships, please contact us at interns@scaleyou.in with your resume and the position you're
            interested in.
          </h4>
        </div>
        <section>
          <DownloadAppNow />
        </section>
      </div>
    </div>
  );
};

export default Jobs;
