import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  result: {},
  resultInEdit: undefined,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: ""
};

export const resultSlice = createSlice({
  name: "result",
  initialState,
  reducers: {
    setResultInEdit: (state, action) => {
      state.resultInEdit = action.payload;
    }
  }
});

export const { setResultInEdit } = resultSlice.actions;
export default resultSlice.reducer;
