import { Box, Typography } from "@mui/material";
import React from "react";
import BatchTabs from "./BatchTabs";

const BatchManager = () => {
  return (
    <Box
      width="100%"
      borderRadius="1rem"
      bgcolor="#fff"
      border="1px solid #E6EDFF"
      p="1rem"
      display="flex"
      flexDirection="column"
      gap="1rem"
    >
      <Typography variant="h4" fontWeight={500}>
        Batches
      </Typography>
      <Typography variant="h6" fontWeight={400}>
        All batches in your institute
      </Typography>
      <BatchTabs />
    </Box>
  );
};

export default BatchManager;
