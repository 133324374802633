// inbuilt modules
import React, { useEffect, useState } from "react";

// external modules
import { Box, Chip, Typography, styled, useMediaQuery } from "@mui/material";
import { CaretDown, Check, CheckCircle, CircleNotch, Warning, WarningCircle } from "@phosphor-icons/react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useGetAllExamsQuery } from "../../../redux/api/examSlice";
import { useGetAllAnalysisQuery } from "../../../redux/api/analysisSlice";

// functions and variables
const CTypography = styled(Typography)(({ theme }) => ({
  width: "100%",
  textAlign: "center",
  display: "flex",
  gap: "3px",
  alignItems: "center",
  justifyContent: "center"
}));

const TestHistory = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const { user } = useSelector(state => state.auth);
  const { data: examsData, isSuccess: isGetExamsSuccess } = useGetAllExamsQuery(user.school_id);
  const { data: examsAnalysis, isSuccess: isGetExamAnalysisSuccess } = useGetAllAnalysisQuery(user.school_id);

  let [testDetails, setTestDetails] = useState([]);
  useEffect(() => {
    setTestDetails(
      isGetExamsSuccess
        ? examsData?.exams?.slice(0, 3).map((exam, index) => {
            const analysisFound = examsAnalysis?.analysisArray?.find(examA => examA.exam_id === exam._id);
            return {
              serial: index + 1,
              examName: exam.exam_name,
              pattern: exam.type_of_exam,
              examCode: exam.unique_code,
              status: exam.status,
              examFor: exam.exam_for,
              totalStudents: analysisFound ? analysisFound.total_students : "NAN",
              highestScore: analysisFound ? `${analysisFound.highest_score}/${analysisFound.average_score}` : "NAN"
            };
          })
        : []
    );
  }, [examsData]);

  return (
    <Box width="100%" mt="1rem" display="flex" flexDirection="column" gap="0.75rem">
      <Box
        width="100%"
        display="flex"
        flexDirection={isNonMobile ? "row" : "column"}
        justifyContent="space-between"
        alignItems={isNonMobile ? "center" : "start"}
        gap="1rem"
      >
        <Typography variant="h4" fontWeight={600}>
          Test History
        </Typography>
        <Box display="flex" gap="1rem" alignItems="center">
          <Box display="flex" gap="5px" alignItems="center">
            <div style={{ height: "15px", width: "15px", borderRadius: "50%", backgroundColor: "#3A974C" }} /> Completed
          </Box>
          <Box display="flex" gap="5px" alignItems="center">
            <div style={{ height: "15px", width: "15px", borderRadius: "50%", backgroundColor: "#F48C06" }} />{" "}
            Incomplete
          </Box>
          <Box display="flex" gap="5px" alignItems="center">
            <div style={{ height: "15px", width: "15px", borderRadius: "50%", backgroundColor: "#3267FF" }} /> Analysing
          </Box>
          <Box display="flex" gap="5px" alignItems="center">
            <span style={{ fontWeight: 500 }}>NAN</span>: Not analysed
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: "relative",
          overflowX: "auto",
          maxWidth: "100%"
        }}
      >
        <table style={{ width: "100%", textAlign: "center", borderCollapse: "seperate", borderSpacing: "0 1rem" }}>
          <thead>
            <tr>
              <th
                style={{
                  width: "10%",
                  padding: isNonMobile ? "0.5rem" : "1rem",
                  gap: "8px"
                }}
              >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant="subtitle2" fontWeight={400}>
                    Sl. No
                  </Typography>
                  <CaretDown weight="fill" />
                </div>
              </th>
              <th
                style={{
                  width: "15%",
                  padding: isNonMobile ? "0.5rem" : "1rem",
                  gap: "8px"
                }}
              >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant="subtitle2" fontWeight={400}>
                    Exam Name
                  </Typography>
                  <CaretDown weight="fill" />
                </div>
              </th>
              <th
                style={{
                  width: "20%",
                  padding: isNonMobile ? "0.5rem" : "1rem",
                  gap: "8px"
                }}
              >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant="subtitle2" fontWeight={400}>
                    Pattern
                  </Typography>
                  <CaretDown weight="fill" />
                </div>
              </th>
              <th
                style={{
                  width: "10%",
                  padding: isNonMobile ? "0.5rem" : "1rem",
                  gap: "8px"
                }}
              >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant="subtitle2" fontWeight={400}>
                    Exam For
                  </Typography>
                  <CaretDown weight="fill" />
                </div>
              </th>
              <th
                style={{
                  width: "10%",
                  padding: isNonMobile ? "0.5rem" : "1rem",
                  gap: "8px"
                }}
              >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant="subtitle2" fontWeight={400}>
                    Students Appeared
                  </Typography>
                  <CaretDown weight="fill" />
                </div>
              </th>
              <th
                style={{
                  width: "15%",
                  padding: isNonMobile ? "0.5rem" : "1rem",
                  gap: "8px"
                }}
              >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant="subtitle2" fontWeight={400}>
                    Highest Score/Average Score
                  </Typography>
                  <CaretDown weight="fill" />
                </div>
              </th>
              <th
                style={{
                  width: "95%",
                  textAlign: "center",
                  padding: isNonMobile ? "0.5rem" : "1rem",
                  gap: "8px"
                }}
              >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant="subtitle2" fontWeight={400}>
                    Status
                  </Typography>
                  <CaretDown weight="fill" />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {testDetails.map(test => (
              <tr
                key={test.serial}
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "1rem",
                  borderSpacing: "1rem"
                }}
              >
                <td
                  style={{
                    width: "10%",
                    gap: "8px",
                    height: "4rem",
                    borderLeft: `5px solid ${
                      test.status === "COMPLETED"
                        ? "#3A974C" // Green for completed
                        : test.status === "DRAFT"
                          ? "#F48C06" // Orange for draft
                          : test.status === "ANALYSING"
                            ? "#3267FF" // Blue for analysing
                            : test.status === "ERROR"
                              ? "red" // Red for error
                              : "transparent" // Transparent if none of the above
                    }`,
                    borderRadius: "0.5rem"
                  }}
                >
                  {test.serial}
                </td>
                <td style={{ width: "15%", gap: "8px", height: "4rem" }}>{test.examName}</td>
                <td style={{ width: "20%", gap: "8px", height: "4rem" }}>{test.pattern}</td>
                <td style={{ width: "10%", gap: "8px", height: "4rem" }}>{test.examFor}</td>
                <td style={{ width: "10%", gap: "8px", height: "4rem" }}>{test.totalStudents}</td>
                <td style={{ width: "15%", gap: "8px", height: "4rem" }}>{test.highestScore}</td>
                <td style={{ width: "95%", gap: "8px", height: "4rem", textAlign: "center" }}>
                  {test.status === "COMPLETED" ? (
                    <Box display="flex" gap="2rem" width="60%" margin="auto" alignItems="center">
                      <CheckCircle size={28} weight="fill" color="#3A974C" /> Analysis Done
                    </Box>
                  ) : test.status === "DRAFT" ? (
                    <Box display="flex" gap="2rem" width="60%" margin="auto" alignItems="center">
                      <WarningCircle size={28} weight="fill" color="#F48C06" />
                      Incomplete
                    </Box>
                  ) : test.status === "ANALYSING" ? (
                    <Box display="flex" gap="2rem" width="60%" margin="auto" alignItems="center">
                      <CircleNotch size={28} color="#3267FF" style={{ animation: "spin 2s linear infinite" }} />{" "}
                      Analysing
                    </Box>
                  ) : test.status === "ERROR" ? (
                    <Box display="flex" gap="2rem" width="60%" margin="auto" alignItems="center">
                      <Warning size={28} weight="fill" color="red" /> Error
                    </Box>
                  ) : (
                    <>Loading...</>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Box display="flex" flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <Box
            width="100%"
            sx={{
              background: "linear-gradient(to bottom, rgba(0,0,0,0), white)",
              height: "5rem",
              transform: "translateY(-70%)",
              position: "absolute",
              opacity: "1"
            }}
          />
          <Typography
            width="100%"
            textAlign="center"
            sx={{
              "zIndex": "1000",
              "color": "blue",
              "textDecoration": "underline",
              "&:hover": { cursor: "pointer" }
            }}
            variant="h5"
          >
            <Link to="/test-history">View More</Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

// render
export default TestHistory;
