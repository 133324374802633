import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import {
  DataGrid,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridToolbar,
  gridFilteredSortedRowIdsSelector,
  selectedGridRowsSelector,
  useGridApiRef
} from "@mui/x-data-grid";
import { FloppyDisk, PencilSimpleLine } from "@phosphor-icons/react";
import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetFacultiesQuery } from "../../../../redux/api/supervisorSlice"; // functions and variables

const getSelectedRowsToExport = ({ apiRef }) => {
  const selectedRowIds = selectedGridRowsSelector(apiRef);
  if (selectedRowIds.size > 0) {
    return Array.from(selectedRowIds.keys());
  }

  return gridFilteredSortedRowIdsSelector(apiRef);
};

const FacultySelectorTable = ({ batchFacultyList, setBatchFacultyList, setListOrFile }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const apiRef = useGridApiRef();
  const theme = useTheme();
  const { user } = useSelector(state => state.auth);

  const { data: facultiesData, isSuccess } = useGetFacultiesQuery(user.school_id);

  const [isFacultiesSelected, setIsFacultiesSelected] = useState(false);

  const getRowId = row => row._id;

  // Define a function to get the class for each row
  const getRowClassName = params => {
    return "row-color";
  };

  const users = isSuccess && facultiesData && facultiesData.faculties ? facultiesData.faculties : [];

  const columns = [
    { field: "_id", headerName: "ID", flex: 0.4, headerClassName: "datagrid-header-color" },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
      headerClassName: "datagrid-header-color"
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.5,
      headerClassName: "datagrid-header-color"
    }
  ];

  const styledColumns = React.useMemo(
    () => [{ ...GRID_CHECKBOX_SELECTION_COL_DEF, headerClassName: "datagrid-header-color", flex: 0.1 }, ...columns],
    [columns]
  );

  const handleFacultyTagged = () => {
    const taggedFaculties = Array.from(apiRef.current.getSelectedRows()?.values());
    setBatchFacultyList(taggedFaculties);
    setIsFacultiesSelected(true);
    setListOrFile("list");
  };

  return (
    <div className="datagrid-batch-container">
      {!isFacultiesSelected ? (
        <DataGrid
          className="custom-datagrid"
          autoHeight
          rows={users}
          disableRowSelectionOnClick
          apiRef={apiRef}
          columns={styledColumns}
          getRowId={getRowId}
          getRowClassName={getRowClassName} // Add this line
          checkboxSelection
          components={{
            Toolbar: props => (
              <Box display="flex" justifyContent="space-between" p="15px">
                <GridToolbar {...props} />
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleFacultyTagged}
                  disabled={!apiRef.current.getSelectedRows()?.size}
                  sx={{
                    "color": "#000",
                    "bgcolor": "lightgreen",
                    "border": `1px solid ${theme.palette.primary.light}`,
                    "fontWeight": 600,
                    "fontSize": "14px",
                    "display": "flex",
                    "alignItems": "center",
                    "gap": "5px",
                    "&:hover": {
                      bgcolor: "rgba(0,0,0,0.1)"
                    }
                  }}
                >
                  Select Faculties
                  <FloppyDisk size={20} />
                </Button>
              </Box>
            )
          }}
          slotProps={{
            toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } }
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10
              }
            },
            columns: {
              columnVisibilityModel: {
                _id: false
              }
            }
          }}
          pageSizeOptions={[10, 20, 50]}
          sx={!isNonMobile ? { width: "750px" } : {}}
        />
      ) : (
        <DataGrid
          className="custom-datagrid"
          autoHeight
          rows={batchFacultyList}
          disableRowSelectionOnClick
          apiRef={apiRef}
          columns={columns}
          getRowId={getRowId}
          getRowClassName={getRowClassName} // Add this line
          components={{
            Toolbar: props => (
              <Box display="flex" justifyContent="space-between" p="15px">
                <GridToolbar {...props} />
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setIsFacultiesSelected(false);
                    setListOrFile("none");
                  }}
                  disabled={!batchFacultyList?.length}
                  sx={{
                    "color": "#fff",
                    "bgcolor": theme.palette.secondary.main,
                    "border": `1px solid ${theme.palette.primary.light}`,
                    "fontWeight": 600,
                    "fontSize": "14px",
                    "display": "flex",
                    "alignItems": "center",
                    "gap": "5px",
                    "&:hover": {
                      bgcolor: "rgba(0,0,0,0.1)"
                    }
                  }}
                >
                  Change Selection
                  <PencilSimpleLine size={20} />
                </Button>
              </Box>
            )
          }}
          slotProps={{
            toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } }
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10
              }
            },
            columns: {
              columnVisibilityModel: {
                _id: false
              }
            }
          }}
          pageSizeOptions={[10, 20, 50]}
          sx={!isNonMobile ? { width: "750px" } : {}}
        />
      )}
    </div>
  );
};
export default FacultySelectorTable;
