import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import toast from "react-hot-toast";

export const schoolApiSlice = createApi({
  reducerPath: "schoolApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/school`,
    credentials: "include",
    prepareHeaders: headers => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["Schools"],
  endpoints: build => ({
    createASchool: build.mutation({
      query: schoolData => ({
        url: "/create",
        method: "POST",
        body: schoolData
      }),
      invalidatesTags: ["Schools"],
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          toast.loading("Creating School...", { id: "createSchool" });
          await queryFulfilled;
          toast.success("School created successfully", { id: "createSchool" });
        } catch (err) {
          const message = err.error?.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "createSchool" });
        }
      }
    }),
    deleteASchool: build.mutation({
      query: id => ({
        url: `/delete/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Schools"],
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          toast.loading("Deleting School...", { id: "deleteSchool" });
          await queryFulfilled;
          toast.success("School deleted successfully", { id: "deleteSchool" });
        } catch (err) {
          const message = err.error?.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "deleteSchool" });
        }
      }
    }),
    getAllSchool: build.query({
      query: () => ({
        url: "/getall"
      }),
      providesTags: ["Schools"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    setUserLimit: build.mutation({
      query: data => ({
        url: "/setUserLimit",
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Schools"],
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          toast.loading("Setting User Limit...", { id: "setUserLimit" });
          await queryFulfilled;
          toast.success("User Limit set successfully", { id: "setUserLimit" });
        } catch (err) {
          const message = err.error?.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "setUserLimit" });
        }
      }
    })
  })
});

export const { useCreateASchoolMutation, useDeleteASchoolMutation, useGetAllSchoolQuery, useSetUserLimitMutation } =
  schoolApiSlice;
