import { Box, ToggleButton, ToggleButtonGroup, Typography, styled } from "@mui/material";
import { CaretRight } from "@phosphor-icons/react";
import React, { useState } from "react";
import { Roles } from "../../../utils/Helper";
import AddMultiple from "../CreateUser/AddMultiple";
import AddSingle from "../CreateUser/AddSingle";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff"
  }
}));

const StudentAdder = () => {
  const [alignment, setAlignment] = useState("addSingle");
  const [excelData, setExcelData] = useState([]);

  const handleToggle = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center">
      <Box width="100%" display="flex" alignItems="center" gap="5px">
        <CaretRight />
        <Typography variant="h4" fontWeight={500}>
          Add Student
        </Typography>
      </Box>
      <Box width={"100%"} mt="1rem" display={"flex"} justifyContent={"center"}>
        <ToggleButtonGroup
          sx={{
            bgcolor: "#fff",
            width: "80%"
          }}
          value={alignment}
          exclusive
          onChange={handleToggle}
          aria-label="add-or-manage"
        >
          <StyledToggleButton value={"addSingle"} sx={{ width: "100%" }}>
            <Typography variant="h6" lineHeight="14px">
              Add single student
            </Typography>
          </StyledToggleButton>
          <StyledToggleButton value={"addMultiple"} sx={{ width: "100%" }}>
            <Typography variant="h6" lineHeight="14px">
              Add multiple student
            </Typography>
          </StyledToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box width="100%" mt="2rem">
        {alignment === "addSingle" && <AddSingle type={Roles.STUDENT} />}
        {alignment === "addMultiple" && (
          <AddMultiple type={Roles.STUDENT} excelData={excelData} setExcelData={setExcelData} />
        )}
      </Box>
    </Box>
  );
};

export default StudentAdder;
