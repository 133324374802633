// inbuilt modules
import React from "react";

// external modules
import { useTheme } from "@emotion/react";
import { Box, Typography, styled, useMediaQuery } from "@mui/material";
import { ChalkboardTeacher, Exam, ToteSimple, Users } from "@phosphor-icons/react";
import { useSelector } from "react-redux";
import { useGetBatchesByStudentQuery } from "../../../redux/api/batchSlice";
import { useGetFacultiesQuery, useGetStudentsQuery } from "../../../redux/api/supervisorSlice";
// functions and variables
const HeroBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "10px"
}));

const TotalStats = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  // theme
  const { user } = useSelector(state => state.auth);
  const theme = useTheme();
  const { data: students, isSuccess: isGetStudentSuccess } = useGetStudentsQuery(user.school_id);
  const { data: faculties, isSuccess: isGetFacultiesSuccess } = useGetFacultiesQuery(user.school_id);
  // const { data: exams, isSucess : isGetExamsSucce
  const { data: batches, isSuccess: isGetBatchesSuccess } = useGetBatchesByStudentQuery({
    schoolId: user.school_id,
    studentId: user._id
  });

  const heroBoxDetails = [
    {
      icon: <ChalkboardTeacher size={24} color={theme.palette.secondary.main} />,
      value: isGetFacultiesSuccess && faculties.faculties?.length,
      label: "Total Faculty"
    },
    {
      icon: <Users size={24} color={theme.palette.secondary.main} />,
      value: isGetStudentSuccess && students.students?.length,
      label: "Total Students"
    },
    {
      icon: <Exam size={24} color={theme.palette.secondary.main} />,
      value: 5,
      label: "Exams Created"
    },
    {
      icon: <ToteSimple size={24} color={theme.palette.secondary.main} />,
      value: isGetBatchesSuccess && batches?.batches?.length,
      label: "Total batch"
    }
  ];

  return (
    <Box
      width="100%"
      bgcolor="#fff"
      borderRadius="1rem"
      border="1px solid #E6EDFF"
      p="1rem 2rem"
      display="grid"
      gridTemplateColumns={!isNonMobile ? "repeat(2, 1fr)" : "repeat(auto-fit, minmax(250px, 1fr))"}
      gap="2rem"
    >
      {heroBoxDetails.map((box, index) => (
        <HeroBox key={box.label}>
          <Box bgcolor="#fff" padding="0.5rem" borderRadius="0.75rem" boxShadow="0 0 10px 2px rgba(0,0,0,0.05)">
            {box.icon}
          </Box>
          <Box>
            <Typography variant="h2" fontWeight={500}>
              {box.value}
            </Typography>
            <Typography variant="subtitle2" color={theme.palette.primary.light}>
              {box.label}
            </Typography>
          </Box>
        </HeroBox>
      ))}
    </Box>
  );
};

// render
export default TotalStats;
