import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import toast from "react-hot-toast";

export const chatAiApiSlice = createApi({
  reducerPath: "chatAiApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/chatai`,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["StudentHistory", "ExamHistory"],
  endpoints: build => ({
    facultyChat: build.mutation({
      query: data => ({
        url: "/faculty/chat",
        method: "POST",
        body: data
      }),
      invalidatesTags: ["FacultyHistory"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message);
        }
      }
    }),
    getAllHistoryFaculty: build.query({
      query: ({ user_id, active_user }) => ({
        url: `/getFacultyHistory/${user_id}/${active_user}`
      }),
      providesTags: ["FacultyHistory"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    studentChat: build.mutation({
      query: data => ({
        url: "/student/chat",
        method: "POST",
        body: data
      }),
      invalidatesTags: ["StudentHistory"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message);
        }
      }
    }),
    getAllHistoryStudent: build.query({
      query: ({ user_id, active_user }) => ({
        url: `/getStudentHistory/${user_id}/${active_user}`
      }),
      providesTags: ["StudentHistory"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    createNewStudentSession: build.mutation({
      query: data => ({
        url: "/student/createNewSession",
        method: "POST",
        body: data
      }),
      invalidatesTags: ["StudentHistory"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Creating New Session", { id: "createNewStudentSession" });
          await queryFulfilled;
          toast.success("New Session created successfully", { id: "createNewStudentSession" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "createNewStudentSession" });
        }
      }
    }),
    getAllHistoryExam: build.query({
      query: ({ active_user }) => ({
        url: `/getExamHistory/${active_user}`
      }),
      providesTags: ["ExamHistory"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    examChat: build.mutation({
      query: data => ({
        url: "/exam/chat",
        method: "POST",
        body: data
      }),
      invalidatesTags: ["ExamHistory"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message);
        }
      }
    })
  })
});

export const {
  useStudentChatMutation,
  useGetAllHistoryStudentQuery,
  useCreateNewStudentSessionMutation,
  useGetAllHistoryExamQuery,
  useExamChatMutation,
  useFacultyChatMutation,
  useGetAllHistoryFacultyQuery
} = chatAiApiSlice;
