import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { ArrowSquareOut, CaretDown, Plus } from "@phosphor-icons/react";
import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CButton from "../../../components/ui/Button";
import { useCreateMeetingMutation, useGetMeetingsQuery } from "../../../redux/api/meetManagerSlice";

const formatMeetingTime = meetingTime => {
  const options = { year: "numeric", month: "long", day: "numeric", hour: "2-digit", minute: "2-digit" };
  return new Date(meetingTime).toLocaleString(undefined, options);
};

const Meetings = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");
  const theme = useTheme();
  const { batchId } = useParams();

  const [meetingName, setMeetingName] = useState("");
  const [meetingTime, setMeetingTime] = useState(null);
  const [open, setOpen] = useState(false);

  const { user } = useSelector(state => state.auth);
  const [createMeeting] = useCreateMeetingMutation();
  const { data: facultyMeetings, isSuccess } = useGetMeetingsQuery(batchId);

  return (
    <Box
      padding="1rem 2rem"
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <Box display="flex" gap="0.75rem" flexDirection={isNonMobile ? "row" : "column"} justifyContent="space-between">
        <Box>
          <Typography variant={isNonMobile ? "h2" : "h3"} fontWeight={600}>
            Manage Meetings
          </Typography>
          <Typography variant="h6" color={theme.palette.primary.light}>
            Create and manage meetings here
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          maxHeight: "85vh", // Set a maximum height for the scrollable container
          overflowY: "auto", // Enable vertical scrolling
          position: "relative",
          overflowX: "auto", // Enable horizontal scrolling if needed
          maxWidth: "100%"
        }}
      >
        <table style={{ width: "100%", textAlign: "center", borderCollapse: "seperate", borderSpacing: "0 1rem" }}>
          <thead>
            <tr>
              <th
                style={{
                  width: "10%",
                  padding: isNonMobile ? "0.5rem" : "1rem",
                  gap: "8px"
                }}
              >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant="subtitle2" fontWeight={400}>
                    Meeting Name
                  </Typography>
                  <CaretDown weight="fill" />
                </div>
              </th>
              <th
                style={{
                  width: "10%",
                  padding: isNonMobile ? "0.5rem" : "1rem",
                  gap: "8px"
                }}
              >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant="subtitle2" fontWeight={400}>
                    Meeting Time
                  </Typography>
                  <CaretDown weight="fill" />
                </div>
              </th>
              <th
                style={{
                  width: "10%",
                  padding: isNonMobile ? "0.5rem" : "1rem",
                  gap: "8px"
                }}
              >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant="subtitle2" fontWeight={400}>
                    Meeting Link
                  </Typography>
                  <CaretDown weight="fill" />
                </div>
              </th>
              <th
                style={{
                  width: "10%",
                  padding: isNonMobile ? "0.5rem" : "1rem",
                  gap: "8px"
                }}
              >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant="subtitle2" fontWeight={400}>
                    Go
                  </Typography>
                  <CaretDown weight="fill" />
                </div>
              </th>
              <th
                style={{
                  width: "10%",
                  padding: isNonMobile ? "0.5rem" : "1rem",
                  gap: "8px"
                }}
              >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant="subtitle2" fontWeight={400}>
                    Recording Link
                  </Typography>
                  <CaretDown weight="fill" />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {isSuccess ? (
              facultyMeetings?.meetings?.map(meet => (
                <tr
                  key={meet.meeting_id}
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "1rem",
                    borderSpacing: "1rem",
                    cursor: "pointer", // Ensure the cursor is always a pointer
                    transition: "box-shadow 0.3s ease" // Smooth transition for the shadow
                  }}
                  onClick={() => window.open(meet.meetingUri)}
                  onMouseEnter={e => {
                    e.currentTarget.style.boxShadow = "0 0 15px rgba(0, 0, 0, 0.2)";
                  }}
                  onMouseLeave={e => {
                    e.currentTarget.style.boxShadow = "0 0 0 rgba(0, 0, 0, 0)";
                  }}
                >
                  <td style={{ width: "20%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                    {meet.meeting_name}
                  </td>
                  <td style={{ width: "20%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                    {formatMeetingTime(meet.meeting_time)}
                  </td>
                  <td style={{ width: "20%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                    {meet.meetingUri}
                  </td>
                  <td style={{ width: "20%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                    <ArrowSquareOut
                      aria-label="redirect"
                      weight="fill"
                      size={20}
                      color="blue"
                      onClick={() => window.open(meet.meetingUri, "_blank")}
                    />
                  </td>
                  <td style={{ width: "20%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                    <ArrowSquareOut
                      aria-label="redirect"
                      weight="fill"
                      size={20}
                      color="blue"
                      onClick={() => window.open(meet.meetingUri, "_blank")}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <>No meetings found</>
            )}
          </tbody>
        </table>
      </Box>
    </Box>
  );
};

export default Meetings;
