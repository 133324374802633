import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  styled
} from "@mui/material";
import React, { useState } from "react";
import { useAddRecordingLinkMutation } from "../../../redux/api/meetManagerSlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "& .MuiInputBase-input": {
    "borderRadius": 4,
    "position": "relative",
    "backgroundColor": theme.palette.background.paper,
    "border": "1px solid #ced4da",
    "fontSize": 16,
    "padding": "10px 26px 10px 12px",
    "transition": theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    "fontFamily": [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const RecordMeeting = ({ sectionId, meetings }) => {
  const [open, setOpen] = React.useState(false);
  const [meetingSelected, setMeetingSelected] = useState("");
  const [recordingLink, setRecordingLink] = useState("");
  const [addRecordingLink] = useAddRecordingLinkMutation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = event => {
    const {
      target: { value }
    } = event;
    setMeetingSelected(value);
  };

  const handleRecordingLinkChange = event => {
    setRecordingLink(event.target.value);
  };

  const handleSave = async () => {
    const selectedMeeting = meetings.meetings.find(meet => meet.meeting_name === meetingSelected);
    if (selectedMeeting) {
      const meeting_id = selectedMeeting._id;
      try {
        await addRecordingLink({ meeting_id, recording_link: recordingLink }).unwrap();
        handleClose();
      } catch (error) {
        console.error("Failed to add recording link: ", error);
      }
    }
  };

  return (
    <>
      <Button
        onClick={handleClickOpen}
        sx={{
          "color": "#ffffff",
          "marginTop": "1rem",
          "backgroundColor": "#3267ff",
          "&:hover": { backgroundColor: "#3267ff" }
        }}
      >
        <svg
          id="ereGhp2XwYn1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="28"
          height="28"
          viewBox="84 68 150 150"
          shapeRendering="geometricPrecision"
          textRendering="geometricPrecision"
        >
          <ellipse rx="50" ry="50" transform="translate(155.753911 142.617291)" fill="#fdf6f2" strokeWidth="0" />
          <ellipse rx="24" ry="24" transform="translate(155.753911 142.617291)" fill="#f40707" strokeWidth="0" />
        </svg>
        Add Recording
      </Button>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add Recording
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content"
            }}
          >
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-checkbox-label">Meetings</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                value={meetingSelected}
                onChange={handleChange}
                input={<OutlinedInput label="Meetings" />}
                MenuProps={MenuProps}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {meetings?.meetings
                  ?.filter(meet => meet.section_id === sectionId)
                  ?.map((meet, index) => (
                    <MenuItem key={index} value={meet.meeting_name}>
                      <ListItemText primary={meet.meeting_name} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {meetingSelected && (
              <FormControl sx={{ m: 1 }} variant="standard">
                <InputLabel htmlFor="customized-textbox-Record-Link">Record Link</InputLabel>
                <BootstrapInput
                  id="customized-textbox-Record-Link"
                  onChange={handleRecordingLinkChange}
                  placeholder="Record Link"
                />
              </FormControl>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button autoFocus onClick={handleSave}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default RecordMeeting;
