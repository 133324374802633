// inbuilt modules
import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// internal modules
import BatchAnnouncementsStudent from "./components/Student/ViewBatchStudent/BatchAnnouncementsStudent";
import BatchViewer from "./components/Student/ViewBatchStudent/BatchViewer";
import IndividualAnnouncementStudent from "./components/Student/ViewBatchStudent/IndividualAnnouncementStudent";
import StudentIndividualBatchTable from "./components/Student/ViewBatchStudent/IndividualBatchTable";
import AddMultyFaculty from "./components/Supervisor/ViewBatch/AddMultyFaculty";
import AddMultyStudent from "./components/Supervisor/ViewBatch/AddMultyStudent";
import ExcelTest from "./components/Supervisor/ViewBatch/ExcelTest";
import BatchAnnouncements from "./components/Teacher/ViewBatchTeacher/BatchAnnouncements";
import BatchTask from "./components/Teacher/ViewBatchTeacher/BatchTask";
import TestCreationComponent from "./components/Teacher/ViewBatchTeacher/BatchTestCreate";
import CreateAnnouncement from "./components/Teacher/ViewBatchTeacher/CreateAnnouncement";
import IndividualAnnouncement from "./components/Teacher/ViewBatchTeacher/IndividualAnnouncement";
import IndividualBatchTable from "./components/Teacher/ViewBatchTeacher/IndividualBatchTable";
import StreamVideo from "./components/Teacher/ViewBatchTeacher/StreamVideo";
import AuthError from "./components/ui/AuthError";
import ComingSoon from "./components/ui/ComingSoon";
import Error from "./components/ui/Error";
import ForgotPassword from "./pages/auth/ForgotPassword";
import LoginMain from "./pages/auth/LoginMain";
import PasswordChangePage from "./pages/auth/PasswordChangePage";
import Signup from "./pages/auth/SignupMain";
import Landing from "./pages/Landing";
import JobsPage from "./pages/Landing/JobsPage";
import PricingPage from "./pages/Landing/PricingPage";
import SolutionsPage from "./pages/Landing/SolutionsPage";
import StudentDashboard from "./pages/Student/DashBoard";
import IndividualResult from "./pages/Student/IndividualResult";
import MeetingsStudent from "./pages/Student/Meetings";
import ScaleyouAiStudent from "./pages/Student/ScaleyouAiStudent";
import TestAnalysisStudent from "./pages/Student/Testanalysis";
import TestHistoryStudent from "./pages/Student/TestHistory";
import ViewBatchStudent from "./pages/Student/ViewBatchStudent";
import ViewResultsStudents from "./pages/Student/ViewResults";
import CreateCollege from "./pages/SuperAdmin/Colleges";
import ManageCollege from "./pages/SuperAdmin/ManageCollege";
import CreateSupervisor from "./pages/SuperAdmin/Supervisors";
import ViewTickets from "./pages/SuperAdmin/ViewTickets";
import CreateBatch from "./pages/supervisor/CreateBatch";
import CreateResults from "./pages/supervisor/CreateResults";
import Dashboard from "./pages/supervisor/Dashboard";
import DownloadResults from "./pages/supervisor/DownloadResults";
import EditTest from "./pages/supervisor/EditTest";
import Analyser from "./pages/supervisor/ExamAnalyser";
import Layout from "./pages/supervisor/Layout";
import ManageBatch from "./pages/supervisor/ManageBatch";
import ManageFaculty from "./pages/supervisor/ManageFaculty";
import ManageStudent from "./pages/supervisor/ManageStudent";
import RaiseTicket from "./pages/supervisor/RaiseTicket";
import SyllabusManager from "./pages/supervisor/SyllabusManager";
import ExamHistory from "./pages/supervisor/TestHistory";
import ViewBatch from "./pages/supervisor/ViewBatch";
import ViewResults from "./pages/supervisor/ViewResults";
import AnalyseStudent from "./pages/Teacher/AnalyseStudent";
import TeacherBatch from "./pages/Teacher/Batches";
import MyCoursesPage from "./pages/Teacher/Batches/Sections/EditSection";
import TeacherDashboard from "./pages/Teacher/DashBoard";
import DownloadResultsTeacher from "./pages/Teacher/DownloadResults";
import Meetings from "./pages/Teacher/Meetings";
import OAuth2Callback from "./pages/Teacher/OAuth2Callback";
import TestAnalysisTeacher from "./pages/Teacher/StudentAnalysis";
import TestAnalysis from "./pages/Teacher/TestAnalysis";
import TestHistoryTeacher from "./pages/Teacher/TestHistory";
import ViewBatchTeacher from "./pages/Teacher/ViewBatchTeacher";

import CreateAnnouncementByBatch from "./components/Teacher/ViewBatchTeacher/CreateAnnouncementByBatch";
import EditResults from "./pages/supervisor/EditResults";
import ScaleyouAiSupervisor from "./pages/supervisor/ScaleYouAiSupervisor";
import ScaleyouAiTeacher from "./pages/Teacher/ScaleyouAiTeacher";
import { Roles } from "./utils/Helper";
// import MathTest from "./pages/supervisor/MathTest";

// functions and variables
// Authentication Routes
const authRoutes = createBrowserRouter([
  {
    id: "auth-landing",
    path: "/",
    element: <Landing />,
    errorElement: <AuthError />
  },
  {
    id: "solutions",
    path: "/solutions",
    element: <SolutionsPage />,
    errorElement: <AuthError />
  },
  {
    id: "pricing",
    path: "/pricing",
    element: <PricingPage />,
    errorElement: <AuthError />
  },
  {
    id: "jobs",
    path: "/jobs",
    element: <JobsPage />,
    errorElement: <AuthError />
  },
  {
    id: "auth-login",
    path: "/login",
    element: <LoginMain />,
    errorElement: <AuthError />
  },
  {
    id: "auth-signup",
    path: "/signup",
    element: <Signup />,
    errorElement: <AuthError />
  },
  {
    id: "forgot-password",
    path: "/forgot-password",
    element: <ForgotPassword />,
    errorElement: <AuthError />
  },
  {
    id: "password-change-page",
    path: "/change-password/:token",
    element: <PasswordChangePage />
  },
  { path: "*", element: <AuthError /> }
]);

const superadminRoutes = createBrowserRouter([
  {
    id: "layout",
    element: <Layout />,
    children: [
      {
        id: "Colleges",
        path: "/",
        element: <CreateCollege />,
        index: true
      },
      {
        id: "manage-college",
        path: "/manage-college/:school_id",
        element: <ManageCollege />
      },
      {
        id: "Supervisors",
        path: "/supervisor-list",
        element: <CreateSupervisor />,
        index: true
      },
      {
        id: "view-ticket",
        path: "/view-tickets",
        element: <ViewTickets />
      },
      {
        id: "tutorial",
        path: "/tutorial",
        element: <h1>Tutorial</h1>
      }
    ]
  },
  { path: "*", element: <Error /> }
]);

const supervisorRoutes = createBrowserRouter([
  {
    id: "layout",
    element: <Layout />,
    children: [
      {
        id: "dashboard",
        path: "/",
        element: <Dashboard />,
        index: true
      },
      {
        id: "analyser",
        path: "/analyser",
        element: <Analyser />
      },
      {
        id: "test-history",
        path: "/test-history",
        element: <ExamHistory />
      },
      {
        id: "edit-test",
        path: "/edit-test/:testId",
        element: <EditTest />
      },
      { id: "scaleyou-ai", path: "/chat-with-scaleyou-ai", element: <ScaleyouAiSupervisor /> },
      {
        id: "manage-faculty",
        path: "/manage-faculty",
        element: <ManageFaculty />
      },
      {
        id: "manage-student",
        path: "/manage-student",
        element: <ManageStudent />
      },
      {
        id: "manage-batch",
        path: "/manage-batch",
        element: <ManageBatch />
      },
      {
        id: "create-batch",
        path: "/create-batch",
        element: <CreateBatch />
      },
      { id: "view-batch", path: "/view-batch/:batchId", element: <ViewBatch /> },
      { id: "add-multiple-faculty", path: "/add-multy-faculty/:batchId", element: <AddMultyFaculty /> },
      { id: "add-multiple-student", path: "/add-multy-student/:batchId", element: <AddMultyStudent /> },
      {
        id: "syllabus",
        path: "/syllabus",
        element: <SyllabusManager />
      },
      {
        id: "create-results",
        path: "/create-results",
        element: <CreateResults />
      },
      {
        id: "manage-result",
        path: "/manage-result",
        element: <ExcelTest />
      },
      {
        id: "view-results",
        path: "/view-results",
        element: <ViewResults />
      },
      {
        id: "edit-results",
        path: "/edit-results/:result_id",
        element: <EditResults />
      },
      {
        id: "download-results",
        path: "/download-results",
        element: <DownloadResults />
      },
      // {
      //   id: "math-test",
      //   path: "/math-test",
      //   element: <MathTest />
      // },
      {
        id: "raise-ticket",
        path: "/raise-ticket",
        element: <RaiseTicket />
      },
      {
        id: "tutorial",
        path: "/tutorial",
        element: <ComingSoon />
      }
    ]
  },
  { path: "*", element: <Error /> }
]);

// Teacher routes
const teacherRoutes = createBrowserRouter([
  {
    id: "layout",
    element: <Layout />,
    children: [
      {
        id: "dashboard",
        path: "/",
        element: <TeacherDashboard />,
        index: true
      },
      {
        id: "test-history",
        path: "/test-history",
        element: <TestHistoryTeacher />
      },
      {
        id: "analyse-exam",
        path: "/analyse-exam/:analysisId",
        element: <TestAnalysis />
      },
      {
        id: "manage-batch",
        path: "/view-batch",
        element: <TeacherBatch />
      },
      { id: "view-batch", path: "/view-batch/:batchId", element: <ViewBatchTeacher /> },
      { id: "view-batch-details", path: "/view-batch/:batchId/batch-details", element: <IndividualBatchTable /> },
      { id: "view-batch-announcements", path: "/view-batch/:batchId/announcements", element: <BatchAnnouncements /> },
      { id: "view-batch-tasks", path: "/view-batch/:batchId/tasks", element: <BatchTask /> },
      { id: "view-batch-tasks-test", path: "/view-batch/:batchId/tasks/test", element: <TestCreationComponent /> },
      { id: "stream-video", path: "/view-batch/:batchId/tasks/stream-video", element: <StreamVideo /> },
      {
        id: "view-individual-announcement",
        path: "/view-batch/:batchId/announcements/:announcementId",
        element: <IndividualAnnouncement />
      },
      {
        id: "create-new-announcement",
        path: "/create-announcements",
        element: <CreateAnnouncement />
      },
      {
        id: "create-announcement-by-batch",
        path: "/view-batch/:batchId/announcements/create",
        element: <CreateAnnouncementByBatch />
      },
      {
        id: "add-section",
        path: "/edit-section/:batchId/:sectionId",
        element: <MyCoursesPage />
      },
      { id: "handle-meetings", path: "/view-batch/:batchId/handle-meetings", element: <Meetings /> },
      { id: "oauth2callback", path: "/oauth2callback", element: <OAuth2Callback /> },
      {
        id: "manage-student",
        path: "/manage-student",
        element: <TestAnalysisTeacher />
      },
      { id: "analyse-student", path: "analyse-student/:analysisId", element: <AnalyseStudent /> },
      { id: "download-results", path: "download-results", element: <DownloadResultsTeacher /> },
      { id: "scaleyou-ai", path: "/chat-with-scaleyou-ai", element: <ScaleyouAiTeacher /> },
      {
        id: "raise-ticket",
        path: "/raise-ticket",
        element: <RaiseTicket />
      },
      {
        id: "tutorial",
        path: "/tutorial",
        element: <ComingSoon />
      }
    ]
  },
  { path: "*", element: <Error /> }
]);

const studentRoutes = createBrowserRouter([
  {
    id: "layout",
    element: <Layout />,
    children: [
      {
        id: "dashboard",
        path: "/",
        element: <StudentDashboard />,
        index: true
      },
      {
        id: "test-history",
        path: "/test-history",
        element: <TestHistoryStudent />
      },
      {
        id: "analyse-exam",
        path: "/analyse-exam/:analysisId",
        element: <TestAnalysisStudent />
      },
      {
        id: "view-batch",
        path: "/view-batch",
        element: <ViewBatchStudent />
      },
      { id: "view-individual-batch", path: "/view-batch/:batchId", element: <BatchViewer /> },
      {
        id: "view-batch-details",
        path: "/view-batch/:batchId/batch-details",
        element: <StudentIndividualBatchTable />
      },
      {
        id: "view-batch-announcements",
        path: "/view-batch/:batchId/announcements",
        element: <BatchAnnouncementsStudent />
      },
      {
        id: "view-individual-announcement",
        path: "/view-batch/:batchId/announcements/:announcementId",
        element: <IndividualAnnouncementStudent />
      },
      {
        id: "view-results",
        path: "/view-results",
        element: <ViewResultsStudents />
      },
      {
        id: "view-ind-result",
        path: "/view-result/:resultId",
        element: <IndividualResult />
      },
      { id: "view-meetings", path: "/view-batch/:batchId/view-meetings", element: <MeetingsStudent /> },
      { id: "scaleyou-ai", path: "/chat-with-scaleyou-ai", element: <ScaleyouAiStudent /> },
      {
        id: "raise-ticket",
        path: "/raise-ticket",
        element: <RaiseTicket />
      },
      {
        id: "tutorial",
        path: "/tutorial",
        element: <ComingSoon />
      }
    ]
  },
  { path: "*", element: <Error /> }
]);

const App = () => {
  // hooks
  // const navigate = useNavigate();

  // destructuring auth state
  const { user } = useSelector(state => state.auth);

  // functions
  const whichRouter = () => {
    if (user) {
      if (user.role === Roles.FACULTY) {
        return teacherRoutes;
      }
      if (user.role === Roles.SUPERVISOR) {
        return supervisorRoutes;
      }
      if (user.role === Roles.STUDENT) {
        return studentRoutes;
      }
      if (user.role === Roles.SUPERADMIN) {
        return superadminRoutes;
      }
    }

    return authRoutes;
  };

  useEffect(() => {
    if (user) {
      whichRouter();
    }
  }, []);

  return <RouterProvider router={whichRouter()} />;
};

// render
export default App;
