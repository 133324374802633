import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  batch: {},
  batchInEdit: {},
  batches: [],
  batchUserDetails: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: ""
};

const batchSlice = createSlice({
  name: "batch",
  initialState,
  reducers: {
    setBatch: (state, action) => {
      state.batch = action.payload;
    },
    setBatchUserDetails: (state, action) => {
      state.batchUserDetails = action.payload;
    },
    setBatchInEdit: (state, action) => {
      state.batchInEdit = action.payload;
    }
  }
});

export const { setBatch, setBatchUserDetails, setBatchInEdit } = batchSlice.actions;
export default batchSlice.reducer;
