import { Close, CloudUpload } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Download, Eye, FilePdf, FilePpt, Trash, Video } from "@phosphor-icons/react";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";

import { useDeleteLinkMutation, useFetchSectionFilesQuery } from "../../../redux/api/taskSlice";

const IndividualFileSection = ({ Section, User, BatchId, onVideoSelect, onFileSelect, alignment }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const [sectionFiles, setSectionFiles] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [sectionToUpload, setSectionToUpload] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileExtension, setFileExtension] = useState("");
  const [file, setFile] = useState(null);
  const [sectionFilesInThisSession, setSectionFilesInThisSession] = useState(Section?.pre_session || []);
  const [deleteLink] = useDeleteLinkMutation();
  const { data: FetchedSectionFiles, isFetching: isFetchingSectionFiles } = useFetchSectionFilesQuery({
    schoolId: User.school_id,
    batchId: BatchId
  });
  const inputRefFile = useRef();
  const theme = useTheme();

  console.log("FETCHY: ", FetchedSectionFiles);

  const handleDrawerOpen = sectionId => {
    setSectionToUpload(sectionId);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSectionToUpload("");
  };

  const allowedFileTypesRegex = /\.(pdf|mp4|ppt|txt|pptx)$/i;

  const checkFileExtension = selectedFile => {
    if (selectedFile && allowedFileTypesRegex.test(selectedFile.name)) {
      return true;
    } else {
      return false;
    }
  };

  const handleDeleteLink = async indiLink => {
    const details = {
      section_id: Section._id,
      filename: indiLink
    };
    await deleteLink(details);
  };

  console.log("SECTTION: ", Section);

  const handleFileChange = e => {
    if (checkFileExtension(e.target.files[0])) {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
      setFileExtension(e.target.files[0]?.name.split(".").pop());
    } else {
      toast.error("Please upload a file with allowed extensions: PDF, MP4, PPT");
    }
  };

  const handleDrop = e => {
    e.preventDefault();
    if (checkFileExtension(e.dataTransfer.files[0])) {
      setFile(e.dataTransfer.files[0]);
      setFileName(e.dataTransfer.files[0].name);
    } else {
      toast.error("Please upload a file with allowed extensions: PDF, MP4, PPT");
    }
  };

  const handleFileClick = async fileKey => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/task/file-download/presigned`, { fileKey });
      const downloadUrl = response.data.url;
      const fileName = fileKey.split("/").pop();
      const fileExtension = fileName.split(".").pop().toLowerCase();

      // Check if the file is a video (e.g., .mp4)
      if (fileExtension === "mp4") {
        // Redirect to video streaming page
        window.location.href = `/view-batch/${BatchId}/tasks/stream-video?url=${encodeURIComponent(downloadUrl)}`;
      } else {
        // Handle non-video files (download)
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = fileName; // Get the file name from the key
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("Error downloading file");
    }
  };

  const handleVideoClick = async fileKey => {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/task/file-download/presigned`, { fileKey });
    const downloadUrl = response.data.url;
    onVideoSelect(downloadUrl);
    // eslint-disable-next-line
    onFileSelect(fileKey?.split("/")?.pop()?.split(".")?.slice(0, -1)?.join(" "));
  };

  const handleLinkClick = link => {
    const youtubeLink = link?.split(" ");
    const lastElement = youtubeLink[youtubeLink.length - 1];
    onVideoSelect(lastElement);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("school_id", User?.school_id);
    formData.append("batch_id", BatchId);
    formData.append("section_id", sectionToUpload);
    formData.append("file_extension", fileExtension);

    toast.loading("Uploading...", { id: "uploadToast" });
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/task/files-upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      toast.success("File uploaded successfully", { id: "uploadToast" });
      setSectionToUpload("");
      setFile(null);
      setFileName("");
      setSectionFiles(prevFiles => [...prevFiles, response.data.file]); // Update sectionFiles state
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Error uploading file", { id: "uploadToast" });
    }
  };

  const handleDeleteClick = async fileKey => {
    const details = {
      school_id: User?.school_id,
      batch_id: BatchId,
      section_id: Section._id,
      file_key: fileKey
    };

    toast.loading("Deleting...", { id: "deleteToast" });
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/task/delete-file`, details);
      toast.success("File deleted successfully", { id: "deleteToast" });
    } catch (error) {
      console.error("Error deleting file:", error);
      toast.error("Error deleting file", { id: "deleteToast" });
    }
  };

  useEffect(() => {
    if (alignment === "Pre Session") {
      setSectionFilesInThisSession(Section?.pre_session || []);
    } else if (alignment === "Post Session") {
      setSectionFilesInThisSession(Section?.post_session || []);
    } else if (alignment === "In Session") {
      setSectionFilesInThisSession(Section?.in_session || []);
    }
  }, [alignment]);

  if (isFetchingSectionFiles) {
    return (
      <Box display="flex" justifyContent="center" sx={{ display: "flex", width: "100%" }}>
        <CircularProgress sx={{ alignSelf: "center", color: "#3267ff" }} />
      </Box>
    );
  } else {
    return (
      <Box sx={{ width: isNonMobile ? "100%" : "110%", marginLeft: isNonMobile ? "0" : "-5%" }}>
        <Accordion
          elevation={0}
          sx={{ backgroundColor: "#fafafb", borderRadius: "2rem", border: "1px dashed rgba(0,0,0,0.3)" }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
            <Box>
              <Typography variant="h5">Lectures</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <Typography variant="h6" gutterBottom>
                Media and Notes
              </Typography>
              <List dense={false}>
                {FetchedSectionFiles?.contents
                  ?.filter(
                    file =>
                      file.Key.includes(Section._id) &&
                      file.Key.includes(BatchId) &&
                      sectionFilesInThisSession?.includes(file.Key.split("/").pop())
                  ) // Filter files for the current section
                  .map((file, index) => (
                    <ListItem key={index}>
                      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                        <Box display="flex" alignItems="center" sx={{ marginLeft: isNonMobile ? "0" : "-2%" }}>
                          <ListItemIcon>
                            {(file.Key.split("/").pop().split(".").pop() === "ppt" ||
                              file.Key.split("/").pop().split(".").pop() === "pptx") && (
                              <FilePpt size={28} style={{ padding: "0.2rem" }} color="#3267ff" />
                            )}
                            {file.Key.split("/").pop().split(".").pop() === "mp4" && (
                              <Video size={28} style={{ padding: "0.2rem" }} color="#3267ff" />
                            )}
                            {file.Key.split("/").pop().split(".").pop() === "pdf" && (
                              <FilePdf size={28} style={{ padding: "0.2rem" }} color="#3267ff" />
                            )}
                          </ListItemIcon>
                          <ListItemText sx={{ marginLeft: "-1.75rem" }} primary={`${file.Key.split("/").pop()}`} />
                        </Box>
                        <ListItemIcon>
                          <Box>
                            {file.Key.split("/").pop().split(".").pop() === "mp4" ? (
                              <Tooltip title="Watch">
                                <Eye
                                  size={28}
                                  className="hover:bg-[#f2f2f2] hover:rounded-lg"
                                  style={{ cursor: "pointer", padding: "0.2rem", marginRight: "1rem" }}
                                  onClick={() => handleVideoClick(file.Key)}
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Download">
                                <Download
                                  size={28}
                                  className="hover:bg-[#f2f2f2] hover:rounded-lg"
                                  style={{ cursor: "pointer", padding: "0.2rem", marginRight: "1rem" }}
                                  onClick={() => handleFileClick(file.Key)}
                                />
                              </Tooltip>
                            )}
                          </Box>
                          <Box>
                            <Tooltip title="Delete" sx={{ display: "flex", justifyContent: "center" }}>
                              <Trash
                                color="#ff0000"
                                size={28}
                                className="hover:bg-[#f2f2f2] hover:rounded-lg"
                                style={{ cursor: "pointer", padding: "0.2rem" }}
                                onClick={() => handleDeleteClick(file.Key)}
                              />
                            </Tooltip>
                          </Box>
                        </ListItemIcon>
                      </Box>
                    </ListItem>
                  ))}
              </List>
              <List dense={false} sx={{ width: "100%" }}>
                {Section?.links.length > 0 &&
                  Section?.links
                    .filter(link => sectionFilesInThisSession.includes(link))
                    .map((Individuallink, index) => {
                      return (
                        <ListItem key={index} sx={{ marginTop: "-0.9rem" }}>
                          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                            <Box display="flex" alignItems="center" sx={{ marginLeft: isNonMobile ? "0" : "-2%" }}>
                              <ListItemIcon>
                                <Video size={28} style={{ padding: "0.2rem" }} color="#3267ff" />
                              </ListItemIcon>
                              <ListItemText
                                sx={{ marginLeft: "-1.75rem" }}
                                primary={`${Individuallink?.split(" ")?.slice(0, -1)?.join(" ")}`}
                              />
                            </Box>
                            <ListItemIcon>
                              <Box>
                                <Tooltip title="Watch">
                                  <Eye
                                    size={28}
                                    className="hover:bg-[#f2f2f2] hover:rounded-lg"
                                    style={{ cursor: "pointer", padding: "0.2rem", marginRight: "1rem" }}
                                    onClick={() => handleLinkClick(Individuallink)}
                                  />
                                </Tooltip>
                              </Box>
                              <Box>
                                <Tooltip title="Delete" sx={{ display: "flex", justifyContent: "center" }}>
                                  <Trash
                                    color="#ff0000"
                                    size={28}
                                    className="hover:bg-[#f2f2f2] hover:rounded-lg"
                                    style={{ cursor: "pointer", padding: "0.2rem" }}
                                    onClick={() => handleDeleteLink(Individuallink)}
                                  />
                                </Tooltip>
                              </Box>
                            </ListItemIcon>
                          </Box>
                        </ListItem>
                      );
                    })}
              </List>
            </div>
          </AccordionDetails>
        </Accordion>

        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleDrawerClose}
          PaperProps={{
            style: {
              width: "400px",
              padding: "20px"
            }
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%"
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 2
              }}
            >
              <IconButton onClick={handleDrawerClose}>
                <Close />
              </IconButton>
            </Box>
            <Typography variant="h5" gutterBottom>
              Drop any file, video or ppt here
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%"
              }}
            >
              <Box
                width="100%"
                padding="1rem"
                display="flex"
                alignItems="center"
                gap="15px"
                bgcolor="#F7F6F9"
                border="1px dashed #AFAFAF"
                borderRadius="1rem"
                onDragOver={e => e.preventDefault()}
                onDrop={handleDrop}
              >
                <CloudUpload color={theme.palette.secondary.main} />
                <Box display="flex" flexDirection="column">
                  {fileName ? (
                    <Typography variant="h6">{fileName}</Typography>
                  ) : (
                    <Box display="flex" alignItems="center" gap="3px">
                      <Typography variant="h6">Drop your file here, or </Typography>
                      <label htmlFor="file-upload">
                        <Typography
                          variant="h6"
                          component="span"
                          color={theme.palette.secondary.main}
                          style={{ cursor: "pointer" }}
                        >
                          Browse
                        </Typography>

                        <input
                          type="file"
                          id="file-upload"
                          ref={inputRefFile}
                          onChange={handleFileChange}
                          name="file"
                          hidden
                        />
                      </label>
                    </Box>
                  )}
                  <Typography variant="subtitle2" color={theme.palette.primary.light}>
                    Allowed file types: PDF, MP4, PPT
                  </Typography>
                  <Button variant="contained" onClick={handleUpload}>
                    Upload File
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Drawer>
      </Box>
    );
  }
};

export default IndividualFileSection;
