import axios from "axios";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const OAuth2Callback = () => {
  const { user } = useSelector(state => state.auth);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleOAuthCallback = async () => {
      const query = new URLSearchParams(location.search);
      const code = query.get("code");

      if (code) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/meet/oauth2callback`,
            {
              school_id: user.school_id,
              code
            },
            {
              withCredentials: true
            }
          );
          const { data } = response;
          console.log(data.message);

          // Redirect or display a success message
        } catch (error) {
          console.log(error);
        }
      } else {
        console.error("Authorization code not found");
      }
    };

    handleOAuthCallback();
  }, [location, user.school_id]);

  return <div>Authorizing...</div>;
};

export default OAuth2Callback;
