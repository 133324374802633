// inbuilt modules
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";

// external modules
import "./index.css";

// internal modules
import AppWrapper from "./AppWrapper";

// css/styles

Sentry.init({
  environment: process.env.SENTRY_ENVIRONMENT,
  dsn: "https://b71fd82066e91f4a46597f59cd4972e8@o4507210686070784.ingest.us.sentry.io/4507210985897984",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({ maskAllText: false, maskAllInputs: false })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const rootElement = document.getElementById("root");

const root = ReactDOM.createRoot(document.getElementById("root"));

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrateRoot(rootElement, <AppWrapper />);
} else {
  root.render(<AppWrapper />);
}
