import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Trash } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetEntireSyllabusQuery } from "../../../../redux/api/syllabusSlice";
import { useDeleteAQuestionMutation } from "../../../../redux/api/examSlice";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "width": "100%",
  "&.MuiToggleButton-root": {
    border: "1px solid #E2E8F0",
    borderRadius: "8px"
  },
  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: "#16B364",
    color: "#fff",
    fontWeight: 600
  }
}));

const IndividualQuestion = ({
  question,
  setQuestionInEdit,
  alignment,
  setAlignment,
  subject,
  posMarks,
  setPosMarks,
  negMarks,
  setNegMarks,
  queSelected,
  setQueSelected
}) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();
  const { user } = useSelector(state => state.auth);

  const { data: syllabus, isSuccess: isGetSyllabusSuccess } = useGetEntireSyllabusQuery(user.school_id);
  const [deleteAQuestion, { isSuccess, isError }] = useDeleteAQuestionMutation();

  const [sections, setSections] = useState({});
  const [sectionNames, setSectionNames] = useState([]);

  useEffect(() => {
    if (isGetSyllabusSuccess) {
      setSections(Object.keys(syllabus).length ? syllabus[subject] : {});
      const names = [];
      Object.keys(syllabus[subject]).map(key => names.push(key));
      setSectionNames(names);
    }
  }, [isGetSyllabusSuccess]);

  const handleChange = e => {
    const { name, value } = e.target;
    setQuestionInEdit(prev => ({ ...prev, [name]: value }));
  };

  const handleToggle = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const handleDelete = () => {
    if (question.id !== "") {
      deleteAQuestion(question.id);
      setQueSelected(prev => (prev > 1 ? prev - 1 : null));
    }
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" gap="1rem">
      <Box width="100%" display="flex" justifyContent="space-between">
        <Typography variant="h3">Q. {question?.question_no}</Typography>
        <Trash color="red" size={22} style={{ cursor: "pointer" }} onClick={handleDelete} />
      </Box>
      <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
        <Typography variant="h5" mb="0.2rem">
          Section
        </Typography>
        <Divider sx={{ width: "80%" }} />
        <FormControl>
          <InputLabel id="section" sx={{ color: theme.palette.secondary.main }}>
            Select
          </InputLabel>
          <Select
            labelId="section"
            id="section"
            name="section"
            value={question?.section || ""}
            onChange={handleChange}
            label="Type"
            sx={{
              width: "80%",
              borderRadius: "1rem",
              bgcolor: "#F7F6F9",
              color: theme.palette.secondary.main
            }}
          >
            <MenuItem value="" sx={{ color: theme.palette.primary.light }}>
              <em>None</em>
            </MenuItem>
            {sectionNames?.map(section => (
              <MenuItem key={section} value={section} sx={{ color: theme.palette.secondary.main }}>
                {section}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
        <Typography variant="h5" mb="0.2rem">
          Chapter
        </Typography>
        <Divider sx={{ width: "80%" }} />
        <FormControl>
          <InputLabel id="chapter" sx={{ color: theme.palette.secondary.main }}>
            Select
          </InputLabel>
          <Select
            labelId="chapter"
            id="chapter"
            name="chapter"
            value={question?.chapter || ""}
            onChange={handleChange}
            label="Type"
            sx={{
              width: "80%",
              borderRadius: "1rem",
              bgcolor: "#F7F6F9",
              color: theme.palette.secondary.main
            }}
          >
            <MenuItem value="" sx={{ color: theme.palette.primary.light }}>
              <em>None</em>
            </MenuItem>
            {sections[question?.section]?.length &&
              sections[question?.section]?.map(chapter => (
                <MenuItem key={chapter} value={chapter} sx={{ color: theme.palette.secondary.main }}>
                  {chapter}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
        <Typography variant="h5" mb="0.2rem">
          Difficulty
        </Typography>
        <Divider sx={{ width: "80%" }} />
        <FormControl>
          <InputLabel id="chapter" sx={{ color: theme.palette.secondary.main }}>
            Select
          </InputLabel>
          <Select
            labelId="difficulty"
            id="difficulty"
            name="difficulty"
            value={question?.difficulty || ""}
            onChange={handleChange}
            label="Type"
            sx={{
              width: "80%",
              borderRadius: "1rem",
              bgcolor: "#F7F6F9",
              color: theme.palette.secondary.main
            }}
          >
            <MenuItem value="" sx={{ color: theme.palette.primary.light }}>
              <em>None</em>
            </MenuItem>
            <MenuItem value={"Easy"} sx={{ color: theme.palette.secondary.main }}>
              Easy
            </MenuItem>
            <MenuItem value={"Medium"} sx={{ color: theme.palette.secondary.main }}>
              Medium
            </MenuItem>
            <MenuItem value={"Hard"} sx={{ color: theme.palette.secondary.main }}>
              Hard
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
        <Typography variant="h5" mb="0.2rem">
          Answer
        </Typography>
        <Divider sx={{ width: "80%" }} />
        <ToggleButtonGroup
          sx={{
            bgcolor: "#fff",
            width: "100%",
            gap: "10px"
          }}
          value={alignment}
          exclusive
          onChange={handleToggle}
        >
          <StyledToggleButton value={"A"}>A</StyledToggleButton>
          <StyledToggleButton value={"B"}>B</StyledToggleButton>
          <StyledToggleButton value={"C"}>C</StyledToggleButton>
          <StyledToggleButton value={"D"}>D</StyledToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
        <Typography variant="h5" mb="0.2rem">
          Positive Marks
        </Typography>
        <Divider sx={{ width: "80%" }} />
        <Box width="100%" display="flex" alignItems="center" gap="5px">
          <Button
            variant="ghost"
            fullWidth
            sx={{ border: "1px solid rgba(0,0,0,0.18)", height: "45px" }}
            onClick={
              () =>
                setPosMarks(prev => {
                  if (prev > 0) {
                    return prev - 1;
                  } else return prev;
                })
              // eslint-disable-next-line no-console
            }
          >
            -
          </Button>
          <Button
            variant="ghost"
            fullWidth
            sx={{ border: "1px solid rgba(0,0,0,0.18)", backgroundColor: "#ACC3FF", height: "45px" }}
          >
            {posMarks}
          </Button>
          <Button
            variant="ghost"
            fullWidth
            sx={{ border: "1px solid rgba(0,0,0,0.18)", height: "45px" }}
            onClick={
              () =>
                setPosMarks(prev => {
                  return prev + 1;
                })
              // eslint-disable-next-line no-console
            }
          >
            +
          </Button>
        </Box>
      </Box>
      <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
        <Typography variant="h5" mb="0.2rem">
          Negative Marks
        </Typography>
        <Divider sx={{ width: "80%" }} />
        <Box width="100%" display="flex" alignItems="center" gap="5px">
          <Button
            variant="ghost"
            fullWidth
            sx={{ border: "1px solid rgba(0,0,0,0.18)", height: "45px" }}
            onClick={
              () =>
                setNegMarks(prev => {
                  return prev - 1;
                })
              // eslint-disable-next-line no-console
            }
          >
            -
          </Button>
          <Button
            variant="ghost"
            fullWidth
            sx={{ border: "1px solid rgba(0,0,0,0.18)", backgroundColor: "#FFC6C6", height: "45px" }}
          >
            {negMarks}
          </Button>
          <Button
            variant="ghost"
            fullWidth
            sx={{ border: "1px solid rgba(0,0,0,0.18)", height: "45px" }}
            onClick={
              () =>
                setNegMarks(prev => {
                  if (prev < 0) {
                    return prev + 1;
                  } else return prev;
                })
              // eslint-disable-next-line no-console
            }
          >
            +
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default IndividualQuestion;
