import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputBase,
  Menu,
  MenuItem,
  TextField,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { CaretDown, User, Users } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BatchDetails from "../../../components/Supervisor/ViewBatch/BatchDetails";
import BatchStats from "../../../components/Supervisor/ViewBatch/BatchStats";
import CButton from "../../../components/ui/Button";
import { useAddToBatchMutation, useGetBatchQuery, useGetBatchesQuery } from "../../../redux/api/batchSlice";
import { setBatch, setBatchUserDetails } from "../../../redux/features/batch/batchSlice";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "color": "inherit",
  "width": "100%",
  "fontSize": theme.typography.pxToRem(15),
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      "width": "12ch",
      "&:focus": {
        width: "20ch"
      }
    }
  }
}));

const ViewBatch = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const { batchId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [rollNo, setRollNo] = useState("");
  const [email, setEmail] = useState("");
  const [alignment, setAlignment] = useState("STUDENT");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAddToBatch, setOpenAddToBatch] = useState(false);
  const [deleteBatchId, setDeleteBatchId] = useState("");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { data: batchData, isSuccess } = useGetBatchesQuery(user.school_id);
  const { data: batchUserData, isSuccess: isBatchUserDataSuccess } = useGetBatchQuery(batchId);
  const [addToBatch] = useAddToBatchMutation();

  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = sortBy => {
    setAnchorEl(null);

    if (sortBy === "singleStudent") {
      setAlignment("STUDENT");
    } else if (sortBy === "singleFaculty") {
      setAlignment("FACULTY");
    } else if (sortBy === "multipleFaculty") {
      navigate(`/add-multy-faculty/${batchId}`);
    } else if (sortBy === "multipleStudent") {
      navigate(`/add-multy-student/${batchId}`);
    }
    setOpenAddToBatch(true);
  };

  useEffect(() => {
    if (isSuccess && batchData && batchData.batches) {
      const batch = batchData.batches.find(batch => batch._id === batchId);
      setName(batch?.batch_name || "");
      dispatch(setBatch(batch));
      dispatch(setBatchUserDetails(batchUserData?.batch));
    }
  }, [batchData, batchId, batchUserData]);

  const handleOpenDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteBatchId(null);
    setIsDeleteDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (deleteBatchId) {
      handleCloseDeleteDialog();
    }
  };

  const handleOpenAddUserDialog = () => {
    setOpenAddToBatch(true);
  };

  const handleCloseAddUserDialog = () => {
    setOpenAddToBatch(false);
  };

  const handleAddUserSubmit = () => {
    let userDetails = {};

    if (alignment === "STUDENT") {
      userDetails = {
        batch_id: batchId,
        roll_no: rollNo,
        school_id: user.school_id,
        type: "student"
      };
    } else if (alignment === "FACULTY") {
      userDetails = {
        batch_id: batchId,
        email: email,
        school_id: user.school_id,
        type: "faculty"
      };
    }

    addToBatch(userDetails);
    setRollNo("");
    setEmail("");
    handleCloseAddUserDialog();
  };

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Batches", link: "/manage-batch" },
    { name: `${name}`, link: `/view-batch/${batchId}` }
  ];

  return (
    <Box
      padding="1rem 2rem"
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <div style={{ padding: "1rem 0", marginTop: "-1rem" }}>
        <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
      </div>
      <Box
        display="flex"
        gap="0.75rem"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={isNonMobile ? "row" : "column"}
      >
        <Box>
          <Typography variant="h2" fontWeight={600}>
            Batch Name: {name}
          </Typography>
          <Typography variant="h6" color={theme.palette.primary.light}>
            Manage individual batch here
          </Typography>
        </Box>
        <Box width={isNonMobile ? "auto" : "100%"}>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{
              "display": "flex",
              "gap": "5px",
              "alignItems": "center",
              "bgcolor": theme.palette.buttons.default,
              "color": "#fff",
              "&:hover": {
                bgcolor: theme.palette.buttons.default,
                opacity: 0.7
              },
              "width": isNonMobile ? "auto" : "100%"
            }}
          >
            Add Members
            <CaretDown size={20} />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button"
            }}
          >
            <MenuItem
              onClick={() => handleClose("singleStudent")}
              sx={{
                display: "flex",
                gap: "15px",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              Single Student
              <User size={18} color="blue" />
            </MenuItem>
            <MenuItem
              onClick={() => handleClose("multipleStudent")}
              sx={{
                display: "flex",
                gap: "15px",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              Multiple Students
              <Users size={18} color="blue" />
            </MenuItem>
            <MenuItem
              onClick={() => handleClose("singleFaculty")}
              sx={{
                display: "flex",
                gap: "15px",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              Single Faculty
              <User size={18} color="blue" />
            </MenuItem>
            <MenuItem
              onClick={() => handleClose("multipleFaculty")}
              sx={{
                display: "flex",
                gap: "15px",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              Multiple Faculties
              <Users size={18} color="blue" />
            </MenuItem>
          </Menu>
        </Box>
        <Dialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete this batch?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="error">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="error">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <BatchStats />
      <BatchDetails />
      <Dialog
        open={openAddToBatch}
        onClose={handleCloseAddUserDialog}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "2rem"
        }}
      >
        <DialogTitle>Add User</DialogTitle>
        {alignment === "STUDENT" ? (
          <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem" }}>
            <FormControl>
              <TextField
                placeholder="Enter the students roll number here"
                value={rollNo}
                onChange={e => setRollNo(e.target.value)}
                type="text"
                sx={{
                  bgcolor: "#F7F6F9"
                }}
                InputProps={{ inputProps: { style: { color: theme.palette.secondary.main } } }}
              />
            </FormControl>
          </DialogContent>
        ) : (
          <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem" }}>
            <FormControl>
              <TextField
                placeholder="Enter teacher's email here"
                value={email}
                onChange={e => setEmail(e.target.value)}
                type="text"
                sx={{
                  bgcolor: "#F7F6F9"
                }}
                InputProps={{ inputProps: { style: { color: theme.palette.secondary.main } } }}
              />
            </FormControl>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleCloseAddUserDialog}>Cancel</Button>
          <CButton buttonText="Add User to batch" variant="primary" onClickFun={handleAddUserSubmit} />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ViewBatch;
