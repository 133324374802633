import { Close, CloudUpload } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { Plus } from "@phosphor-icons/react";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { batch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Hero } from "../../../Assets/index";
import { useCreateATaskSectionMutation, useGetAllSectionsQuery } from "../../../redux/api/taskSlice";

const BatchTask = () => {
  const { user } = useSelector(state => state.auth);
  const [open, setOpen] = useState(false);
  const [sectionName, setSectionName] = useState("");
  const [sections, setSections] = useState([]);
  const [createASection, { isLoading }] = useCreateATaskSectionMutation();
  const { data: allSections = [], isLoading: loadingSections } = useGetAllSectionsQuery(user?.school_id);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const inputRefFile = useRef();

  const { batchId } = useParams();

  const [sectionToUpload, setSectionToUpload] = useState("");

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileExtension, setFileExtension] = useState("");
  const [sectionFiles, setSectionFiles] = useState([]);
  const [showTestCreation, setShowTestCreation] = useState(false);
  const allowedFileTypesRegex = /\.(pdf|mp4|ppt|txt|pptx)$/i;

  const checkFileExtension = selectedFile => {
    if (selectedFile && allowedFileTypesRegex.test(selectedFile.name)) {
      const fileExtension = selectedFile.name.split(".").pop();
      setFileExtension(fileExtension);
      return true;
    } else {
      return false;
    }
  };

  const handleFileChange = e => {
    if (checkFileExtension(e.target.files[0])) {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    } else {
      toast.error("Please upload a file with allowed extensions: PDF, MP4, PPT");
    }
  };

  useEffect(() => {
    const fetchSectionFiles = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/task/files/${user?.school_id}/${batchId}`);
        // const fileNames = response.data.contents.map(content => {
        //   const parts = content.Key.split('/');
        //   return parts[parts.length - 1];
        // });
        setSectionFiles(response.data.contents);
      } catch (error) {
        console.error("Error fetching section files:", error);
        toast.error("Error fetching section files");
      }
    };

    fetchSectionFiles(sectionToUpload);
  }, [user?.school_id, batchId]);

  const handleDrop = e => {
    e.preventDefault();
    if (checkFileExtension(e.dataTransfer.files[0])) {
      setFile(e.dataTransfer.files[0]);
      setFileName(e.dataTransfer.files[0].name);
    } else {
      toast.error("Please upload a file with allowed extensions: PDF, MP4, PPT");
    }
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("school_id", user?.school_id);
    formData.append("batch_id", batchId);
    formData.append("section_id", sectionToUpload);
    formData.append("file_extention", fileExtension);

    toast.loading("Uploading...", { id: "uploadToast" });
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/task/files-upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      toast.success("File uploaded successfully", { id: "uploadToast" });
      setSectionToUpload("");
      setFile(null);
      setFileName("");
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Error uploading file", { id: "uploadToast" });
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateSection = async () => {
    try {
      const taskSectionData = {
        name: sectionName,
        school_id: user?.school_id,
        batch_id: batchId
      };
      const createdSection = await createASection(taskSectionData);
      setSections([...sections, createdSection.tasksection]);
      setSectionName("");
      handleClose();
    } catch (error) {
      console.error("Error creating task section:", error);
    }
  };

  const handleFileClick = async fileKey => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/task/file-download/presigned`, { fileKey });
      const downloadUrl = response.data.url;
      const fileName = fileKey.split("/").pop();
      const fileExtension = fileName.split(".").pop().toLowerCase();

      // Check if the file is a video (e.g., .mp4)
      if (fileExtension === "mp4") {
        // Redirect to video streaming page
        window.location.href = `/view-batch/${batchId}/tasks/stream-video?url=${encodeURIComponent(downloadUrl)}`;
      } else {
        // Handle non-video files (download)
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = fileName; // Get the file name from the key
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("Error downloading file");
    }
  };

  const handleDrawerOpen = sectionId => {
    setSectionToUpload(sectionId);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSectionToUpload("");
  };
  const handleCreateTestClick = () => {
    // Redirect to the desired URL
    window.location.href = `/view-batch/${batchId}/tasks/test`;
  };
  const hasSections = allSections && allSections.sections && allSections.sections.length > 0;

  return (
    <div style={{ position: "relative", height: "100%" }}>
      {!hasSections && (
        <div
          style={{
            backgroundColor: "rgba(230, 237, 255, 0.8)",
            width: "87%",
            height: "600px",
            margin: "80px auto 0",
            borderRadius: "15px",
            position: "relative",
            overflow: "hidden"
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -70%)",
              width: "50%",
              height: "50%",
              borderRadius: "15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <img src={Hero} alt="Hero Image" style={{ maxWidth: "100%", maxHeight: "100%" }} />
          </div>
        </div>
      )}
      <div style={{ marginTop: "30px" }}>
        {allSections && allSections?.sections ? (
          allSections?.sections?.map((section, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${index}-content`}
                id={`panel-${index}-header`}
              >
                <Typography
                  sx={{
                    flex: "1",
                    fontFamily: "Helvetica, sans-serif",
                    color: "#00000",
                    fontSize: "15px",
                    fontWeight: 550
                  }}
                >
                  {`Section ${index + 1}: ${section.name}`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <Typography variant="h6" gutterBottom>
                    Files in Section:
                  </Typography>
                  <ul>
                    {sectionFiles
                      .filter(file => file.Key.includes(section._id)) // Filter files for the current section
                      .map((file, index) => (
                        <li key={index}>
                          <a href="#" onClick={() => handleFileClick(file.Key)}>
                            {file.Key.split("/").pop()}
                          </a>
                        </li>
                      ))}
                  </ul>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "rgba(79, 186, 88, 0.77)",
                      color: "white",
                      borderRadius: "4px",
                      marginTop: hasSections ? "0" : "-10px",
                      fontSize: "17px",
                      width: hasSections ? "100%" : "auto"
                    }}
                    onClick={() => handleDrawerOpen(section._id)}
                  >
                    <Plus style={{ marginRight: "5px", fontSize: "17px" }} />
                    Add Lesson
                  </Button>
                </div>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <p style={{ alignSelf: "center" }}>No Sections</p>
        )}
      </div>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        PaperProps={{
          style: {
            width: "400px",
            padding: "20px"
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%"
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2
            }}
          >
            <IconButton onClick={handleDrawerClose}>
              <Close />
            </IconButton>
          </Box>
          <Typography variant="h5" gutterBottom>
            Drop any file,video or ppt here
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%"
            }}
          >
            <Box
              width="100%"
              padding="1rem"
              display="flex"
              alignItems="center"
              gap="15px"
              bgcolor="#F7F6F9"
              border="1px dashed #AFAFAF"
              borderRadius="1rem"
              onDragOver={e => e.preventDefault()}
              onDrop={handleDrop}
            >
              <CloudUpload color={theme.palette.secondary.main} />
              <Box display="flex" flexDirection="column">
                {fileName ? (
                  <Typography variant="h6">{fileName}</Typography>
                ) : (
                  <Box display="flex" alignItems="center" gap="3px">
                    <Typography variant="h6">Drop your file here, or </Typography>
                    <label htmlFor="file-upload">
                      <Typography
                        variant="h6"
                        component="span"
                        color={theme.palette.secondary.main}
                        style={{ cursor: "pointer" }}
                      >
                        Browse
                      </Typography>

                      <input
                        type="file"
                        id="file-upload"
                        ref={inputRefFile}
                        onChange={handleFileChange}
                        name="file"
                        hidden
                      />
                    </label>
                  </Box>
                )}
                <Typography variant="subtitle2" color={theme.palette.primary.light}>
                  Allowed file types: PDF, MP4, PPT
                </Typography>
                <Button variant="contained" onClick={handleUpload}>
                  Upload File
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
      <div
        style={{
          position: "absolute",
          bottom: "20px",
          left: "50%",
          transform: "translate(-50%,-120%)",
          textAlign: "center",
          color: "#333",
          fontFamily: "Arial, sans-serif"
        }}
      >
        {!hasSections && (
          <>
            <p style={{ fontWeight: "bold", fontSize: "21px", marginBottom: "5px" }}>Add Section</p>
            <p style={{ fontSize: "18px", marginTop: "10px" }}>Start adding sections to build your course in simple.</p>
          </>
        )}
        <Button
          variant="contained"
          style={{
            backgroundColor: "rgba(56, 92, 255, 0.63)",
            color: "white",
            borderRadius: "4px",
            marginTop: hasSections ? "0" : "-10px",
            fontSize: "17px",
            width: hasSections ? "100%" : "auto"
          }}
          onClick={handleOpen}
        >
          <Plus style={{ marginRight: "5px", fontSize: "17px" }} />
          Add Section
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "500px",
              height: "250px"
            }
          }}
        >
          <DialogTitle sx={{ fontSize: "24px", paddingBottom: "3px" }}>Add Section</DialogTitle>
          <DialogContent sx={{ fontSize: "18px", paddingBottom: "15px" }}>
            <TextField
              autoFocus
              margin="dense"
              id="sectionName"
              label="Section Name"
              type="text"
              fullWidth
              value={sectionName}
              onChange={e => setSectionName(e.target.value)}
              sx={{
                "& .MuiInputBase-root": {
                  fontSize: "16px",
                  backgroundColor: "#fff"
                },
                "& .MuiInputLabel-root": {
                  fontSize: "15px"
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={isLoading}
              onClick={handleClose}
              color="primary"
              sx={{
                fontsize: "25px"
              }}
            >
              Cancel
            </Button>
            <Button disabled={isLoading} onClick={handleCreateSection} color="primary">
              {isLoading ? "Creating..." : "Create"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default BatchTask;
