import AWS from "aws-sdk";

const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? "";
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? "";
export const s3 = new AWS.S3({
  region: "us-east-2",
  credentials: {
    accessKeyId,
    secretAccessKey
  }
});
