import { Box, Typography } from "@mui/material";
import React from "react";
import StudentTable from "./StudentTable";

const StudentManager = () => {
  return (
    <Box width="100%" borderRadius="1rem" bgcolor="#fff" border="1px solid #E6EDFF">
      <Typography variant="h4" fontWeight={500} p="1rem">
        Manage Student
      </Typography>
      <StudentTable />
    </Box>
  );
};

export default StudentManager;
