import { Box, Button, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CaretRight, Check, Folder, Prohibit, WarningCircle } from "@phosphor-icons/react";
import axios from "axios";
import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import { read, utils } from "xlsx";

const columns = [
  { field: "id", headerName: "ID", editable: false, flex: 0.2, headerClassName: "datagrid-header-color" },
  {
    field: "name",
    headerName: "Name",
    editable: true,
    flex: 1,
    headerClassName: "datagrid-header-color"
  },
  { field: "email", headerName: "Email", editable: true, flex: 1, headerClassName: "datagrid-header-color" }
];

const getRowClassName = params => {
  return "row-color";
};

const FacultyListUploader = ({ batchId, file, setFile }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();
  const inputRef = useRef();
  const allowedFileTypesRegex = /\.(csv|xls[x]?)$/i; // Regex for csv, xlsx, xls

  const [confirmFile, setConfirmFile] = useState(false);
  const [excelData, setExcelData] = useState([]);

  const checkFileExtension = selectedFile => {
    if (selectedFile) {
      const fileName = selectedFile.name;
      if (allowedFileTypesRegex.test(fileName)) {
        return true;
      } else {
        return false;
      }
    }
  };

  const readXlsxFile = fileD => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        const data = e.target.result;
        const workbook = read(data, { type: "binary" });
        resolve(workbook);
      };

      reader.onerror = error => {
        reject(error);
      };

      reader.readAsBinaryString(fileD);
    });
  };

  const parseExcel = async selectedFile => {
    try {
      const workbook = await readXlsxFile(selectedFile);
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const data = utils.sheet_to_json(sheet, { header: 1 });

      const firstRow = data[0].map(element => element.toLowerCase());

      // Check if "name" and "email" are present in the first element
      if (!firstRow || !firstRow.includes("email")) {
        toast.error("Please upload a file with 'Name' and 'Email' as headings");
        setFile(null);
        setConfirmFile(false);
        return;
      }

      // Convert array of arrays to array of objects with lowercase keys
      const convertedData = data.slice(1).map((row, ind) => {
        const obj = { id: ind + 1 }; // Add an "id" field with a unique value
        firstRow.forEach((key, index) => {
          obj[key.toLowerCase()] = row[index];
        });
        return obj;
      });
      setExcelData(convertedData);
      setConfirmFile(true);

      // Your logic for processing the valid file can go here
    } catch (error) {
      setConfirmFile(false);
      console.error("Error reading Excel file:", error);
      toast.error("Error reading the Excel file.");
    }
  };

  const handleDrop = e => {
    e.preventDefault();
    if (checkFileExtension(e.dataTransfer.files[0])) {
      setFile(e.dataTransfer.files[0]);
    } else {
      toast.error("Please upload an xlsx, xls or csv file");
    }
  };

  const handleFileChange = e => {
    if (checkFileExtension(e.target.files[0])) {
      setFile(e.target.files[0]);
    } else {
      toast.error("Please upload an xlsx, xls or csv file");
    }
  };

  const handleFileConfirm = () => {
    setConfirmFile(true);
    parseExcel(file);
  };

  const handleDownloadTemplate = async () => {
    try {
      const fileKey = "assets/facultyBatchTemplate.xlsx";
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/task/file-download/presigned`, {
        fileKey
      });
      const downloadUrl = response.data.url;
      const fileName = fileKey.split("/").pop();
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileName; // Get the file name from the key
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("Error downloading file");
    }
  };

  const handleSubmit = async () => {
    try {
      if (file) {
        toast.loading("Uploading faculty...", { id: "fileUpload" });
        const formData = new FormData();
        formData.append("batchFile", file);
        await axios.post(`${process.env.REACT_APP_BASE_URL}/batch/addFacultiesFile/${batchId}`, formData, {
          withCredentials: true
        });
        toast.success("Faculty uploaded successfully", { id: "fileUpload" });
      } else {
        toast.error("Please upload a file");
      }
    } catch (error) {
      toast.error("Error uploading file", { id: "fileUpload" });
    }
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center" gap="2rem">
      <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" gap="5px">
        <Box display="flex" alignItems="center" gap="10px">
          <CaretRight />
          <Typography variant="h4" fontWeight={500}>
            Upload the faculty list
          </Typography>
        </Box>
        <Button
          variant="contained"
          disabled={!confirmFile}
          sx={{
            "bgcolor": theme.palette.secondary.main,
            "height": "24px",
            "display": "flex",
            "gap": "5px",
            "padding": "15px",
            "&:hover": { bgcolor: theme.palette.secondary.light }
          }}
          onClick={() => {
            setFile(null);
            setConfirmFile(false);
            setExcelData([]);
          }}
        >
          <Prohibit weight="bold" size={24} color="black" />
          {"Change"}
        </Button>
      </Box>
      <Box width="100%" display="flex" alignItems="center" gap="15px">
        <Button
          variant="contained"
          sx={{
            "bgcolor": theme.palette.secondary.main,
            "height": "24px",
            "display": "flex",
            "gap": "5px",
            "padding": "20px 15px",
            "&:hover": { bgcolor: theme.palette.secondary.light }
          }}
          onClick={handleDownloadTemplate}
        >
          Download Template
        </Button>
        <Box display="flex" alignItems="center" gap="10px">
          <WarningCircle
            style={{
              minWidth: "24px"
            }}
            weight="fill"
            color="#F48C06"
          />
          <Typography variant="subtitle1" fontWeight={400}>
            Please follow the template to upload faculty list. Only email column is needed
          </Typography>
        </Box>
      </Box>
      {confirmFile ? (
        <Box>
          <div className="datagrid-exam-container-empty">
            <DataGrid
              className="custom-datagrid"
              autoHeight
              rows={excelData}
              editMode="row"
              columns={columns}
              getRowClassName={getRowClassName}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10 } // Set initial page size to 10 rows
                }
              }}
              pageSizeOptions={[10, 20, 50]}
              getRowId={row => row.id}
              sx={!isNonMobile ? { width: "750px" } : {}}
            />
          </div>
        </Box>
      ) : (
        <Box
          width="100%"
          height="40vh"
          padding="1rem"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bgcolor="#F7F6F9"
          border="1px dashed #AFAFAF"
          borderRadius="1rem"
          onDragOver={e => e.preventDefault()}
          onDrop={handleDrop}
        >
          <Folder size={100} color={theme.palette.primary.light} weight="thin" />
          {file ? (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap="3px">
              <Typography variant="h6">SELECTED FILE: {file.name}</Typography>
              <Box display="flex" gap="10px">
                <Button
                  variant="contained"
                  sx={{
                    "bgcolor": theme.palette.secondary.main,
                    "height": "24px",
                    "display": "flex",
                    "gap": "5px",
                    "padding": "15px",
                    "&:hover": { bgcolor: theme.palette.secondary.light }
                  }}
                  onClick={() => {
                    setFile(null);
                    setExcelData([]);
                    setConfirmFile(false);
                  }}
                >
                  <Prohibit weight="bold" size={24} color="black" />
                  {"Change"}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    "bgcolor": theme.palette.secondary.main,
                    "height": "24px",
                    "display": "flex",
                    "gap": "5px",
                    "padding": "15px",
                    "&:hover": { bgcolor: theme.palette.secondary.light }
                  }}
                  onClick={handleFileConfirm}
                >
                  <Check weight="bold" size={24} color="black" />
                  {"Confirm"}
                </Button>
              </Box>
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap="3px">
              <Typography variant="h5">Drag and drop file here</Typography>
              <Typography variant="h6">OR</Typography>
              <input
                ref={inputRef}
                type="file"
                onChange={handleFileChange}
                accept=".xlsx,.xls,.csv"
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                onClick={() => inputRef.current.click()}
                sx={{
                  "bgcolor": theme.palette.secondary.main,
                  "height": "24px",
                  "display": "flex",
                  "gap": "5px",
                  "padding": "15px",
                  "&:hover": { bgcolor: theme.palette.secondary.light }
                }}
              >
                Browse Files
              </Button>
            </Box>
          )}
        </Box>
      )}
      <Box width="100%" display="flex" alignItems="center" justifyContent="flex-end" gap="10px">
        <Button
          variant="contained"
          sx={{
            "bgcolor": theme.palette.secondary.main,
            "height": "24px",
            "display": "flex",
            "gap": "5px",
            "padding": "15px",
            "&:hover": { bgcolor: theme.palette.secondary.light }
          }}
          onClick={handleSubmit}
        >
          Add to batch
        </Button>
      </Box>
    </Box>
  );
};

export default FacultyListUploader;
