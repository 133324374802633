import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import toast from "react-hot-toast";

export const announcementApiSlice = createApi({
  reducerPath: "announcementApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/announcements`,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["BatchAnnouncments", "SchoolAnnouncements", "Announcement", "Comments"],
  endpoints: builder => ({
    getBatches: builder.query({
      query: schoolId => ({
        url: `/batches/${schoolId}`
      }),
      providesTags: ["Batches"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    fetchAnnouncementsByBatchId: builder.query({
      query: batchId => ({
        url: `/batches/${batchId}`
      }),
      providesTags: ["BatchAnnouncments"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    // fetchAnnouncementsBySchoolId: builder.query({
    //   query: schoolId => ({
    //     url: `/schools/${schoolId}`
    //   }),
    //   providesTags: ["SchoolAnnouncments"],
    //   async onQueryFulfilled(_, { queryFulfilled }) {
    //     try {
    //       await queryFulfilled;
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   }
    // }),
    fetchAnnouncementById: builder.query({
      query: _id => ({
        url: `/${_id}`
      }),
      providesTags: ["Announcment"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    createAnnouncement: builder.mutation({
      query: announcement => ({
        url: "/",
        method: "POST",
        body: announcement
      }),
      invalidatesTags: ["BatchAnnouncments"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("creating Announcement...", { id: "createAnnouncement" });
          await queryFulfilled;
          toast.success("Announcement created Successfully", { id: "createAnnouncement" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "createAnnouncement" });
        }
      }
    }),
    // updateAnnouncement: builder.mutation({
    //   query: ({ _id, ...updatedFields }) => ({
    //     url: `/${_id}`,
    //     method: "PUT",
    //     body: updatedFields
    //   }),
    //   invalidatesTags: ["BatchAnnouncments"],
    //   async onQueryStarted(_, { queryFulfilled }) {
    //     try {
    //       toast.loading("updating Announcement...", { id: "updateAnnouncement" });
    //       await queryFulfilled;
    //       toast.success("Announcement updated Successfully", { id: "updateAnnouncement" });
    //     } catch ({ error }) {
    //       const message = error.data?.message || "Server not reachable. Please contact admins";
    //       toast.error(message, { id: "updateAnnouncement" });
    //     }
    //   }
    // }),
    // deleteAnnouncement: builder.mutation({
    //   query: _id => ({
    //     url: `/${_id}`,
    //     method: "DELETE"
    //   }),
    //   invalidatesTags: ["BatchAnnouncments"],
    //   async onQueryStarted(_, { queryFulfilled }) {
    //     try {
    //       toast.loading("removing Announcement...", { id: "deleteAnnouncement" });
    //       await queryFulfilled;
    //       toast.success("Announcement removed Successfully", { id: "deleteAnnouncement" });
    //     } catch ({ error }) {
    //       const message = error.data?.message || "Server not reachable. Please contact admins";
    //       toast.error(message, { id: "deleteAnnouncement" });
    //     }
    //   }
    // }),
    fetchCommentsByAnnouncementId: builder.query({
      query: announcmentId => ({
        url: `/comments/${announcmentId}`
      }),
      providesTags: ["AnnouncementComments"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    createComment: builder.mutation({
      query: comment => ({
        url: "/comments",
        method: "POST",
        body: comment
      }),
      invalidatesTags: ["AnnouncementComments"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("creating Comment...", { id: "createComment" });
          await queryFulfilled;
          toast.success("Comment created Successfully", { id: "createComment" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "createComment" });
        }
      }
    })
    // deleteComment: builder.mutation({
    //   query: _id => ({
    //     url: `/comments/${_id}`,
    //     method: "DELETE"
    //   }),
    //   invalidatesTags: ["AnnouncementComments"],
    //   async onQueryStarted(_, { queryFulfilled }) {
    //     try {
    //       toast.loading("deleting Comment...", { id: "deleteComment" });
    //       await queryFulfilled;
    //       toast.success("Comment deleted Successfully", { id: "deleteComment" });
    //     } catch ({ error }) {
    //       const message = error.data?.message || "Server not reachable. Please contact admins";
    //       toast.error(message, { id: "deleteComment" });
    //     }
    //   }
    // })
  })
});

export const {
  useFetchAnnouncementsByBatchIdQuery,
  useFetchAnnouncementByIdQuery,
  useCreateAnnouncementMutation,
  useFetchCommentsByAnnouncementIdQuery,
  useCreateCommentMutation
} = announcementApiSlice;
