import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
  useTheme
} from "@mui/material";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useCreateIssueTicketMutation } from "../../../redux/api/raiseTicketSlice";
import CButton from "../../ui/Button";

const BpIcon = styled("span")(({ theme }) => ({
  "borderRadius": 3,
  "width": 16,
  "height": 16,
  "boxShadow":
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  "backgroundColor": theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  "backgroundImage":
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5"
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: theme.palette.mode === "dark" ? "rgba(57,75,89,.5)" : "rgba(206,217,224,.5)"
  }
}));

const BpCheckedIcon = styled(BpIcon)({
  "backgroundColor": "#137cbd",
  "backgroundImage": "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: "''"
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3"
  }
});

// Inspired by blueprintjs
const BpCheckbox = props => {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" }
      }}
      disableRipple
      style={{
        color: "black"
      }}
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
};

const TicketCreator = ({ openCreateIssue, setOpenCreateIssue }) => {
  const [ticketDetails, setTicketDetails] = useState({
    title: "",
    description: "",
    priority: "LOW",
    tags: []
  });

  const theme = useTheme();

  const [alignment, setAlignment] = useState("STUDENT");
  const [anchorEl, setAnchorEl] = useState(null);

  const { user } = useSelector(state => state.auth);
  const [createIssueTicket, { isError, isLoading, isSuccess }] = useCreateIssueTicketMutation();

  const handleOpenAddUserDialog = () => {
    setOpenCreateIssue(true);
  };

  const handleCloseCreateIssueDialog = () => {
    setOpenCreateIssue(false);
  };

  const handleCreateIssueSubmit = async () => {
    const { title, description, priority, tags } = ticketDetails;

    if (!title || !description || !priority || tags.length === 0) {
      toast.error("Please fill all the fields");
    } else {
      await createIssueTicket({ ...ticketDetails, user_id: user._id, school_id: user.school_id });
      handleCloseCreateIssueDialog();
    }
  };

  const handleDescriptionChange = e => {
    const { value } = e.target;
    if (value.length <= 200) {
      setTicketDetails({ ...ticketDetails, description: value });
    }
  };

  const handleTagChange = event => {
    const { id, checked } = event.target;
    setTicketDetails(prevDetails => {
      const newTags = checked ? [...prevDetails.tags, id] : prevDetails.tags.filter(tag => tag !== id);
      return { ...prevDetails, tags: newTags };
    });
  };

  return (
    <Dialog
      open={openCreateIssue}
      onClose={handleCloseCreateIssueDialog}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "2rem"
      }}
    >
      <DialogTitle>Add User</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem" }}>
        <FormControl>
          <Box width="100%">
            <Typography shrink htmlFor="issue-title">
              Issue Title
            </Typography>
            <TextField
              id="issue-title"
              placeholder="Issue Title"
              value={ticketDetails.title}
              onChange={e => setTicketDetails({ ...ticketDetails, title: e.target.value })}
              type="text"
              sx={{
                width: "100%",
                bgcolor: "#F7F6F9"
              }}
              InputProps={{ inputProps: { style: { color: theme.palette.secondary.main } } }}
            />
          </Box>
          <Box width="100%">
            <Typography shrink htmlFor="issue-description" sx={{ marginTop: "1rem" }}>
              Issue Description
              <span style={{ marginLeft: "15px" }}>{ticketDetails.description?.length}/200</span>
            </Typography>
            <TextField
              id="issue-description"
              placeholder="Issue Description"
              value={ticketDetails.description}
              onChange={handleDescriptionChange}
              multiline={true}
              minRows={3}
              type="text"
              sx={{
                width: "100%",
                bgcolor: "#F7F6F9"
              }}
              InputProps={{ inputProps: { style: { color: theme.palette.secondary.main } } }}
            />
          </Box>
          <Box width="100%">
            <Typography shrink htmlFor="issue-tags" sx={{ marginTop: "1rem" }}>
              Issue Tags - Select where all you have issues
            </Typography>
            <Grid container spacing={1}>
              <Grid item key="test_history" xs={12} sm={6} md={4} xl={4}>
                <label htmlFor="test_history">
                  <BpCheckbox
                    id="test_history"
                    checked={ticketDetails.tags.includes("test_history")}
                    onChange={handleTagChange}
                  />
                  Test History
                </label>
              </Grid>
              <Grid item key="managing_students" xs={12} sm={6} md={4} xl={3}>
                <label htmlFor="managing_students">
                  <BpCheckbox
                    id="managing_students"
                    checked={ticketDetails.tags.includes("managing_students")}
                    onChange={handleTagChange}
                  />
                  Students
                </label>
              </Grid>
              <Grid item key="managing_faculty" xs={12} sm={6} md={4} xl={3}>
                <label htmlFor="managing_faculty">
                  <BpCheckbox
                    id="managing_faculty"
                    checked={ticketDetails.tags.includes("managing_faculty")}
                    onChange={handleTagChange}
                  />
                  Faculty
                </label>
              </Grid>
              <Grid item key="syllabus" xs={12} sm={6} md={4} xl={3}>
                <label htmlFor="syllabus">
                  <BpCheckbox
                    id="syllabus"
                    checked={ticketDetails.tags.includes("syllabus")}
                    onChange={handleTagChange}
                  />
                  Syllabus
                </label>
              </Grid>
              <Grid item key="tutorials" xs={12} sm={6} md={4} xl={3}>
                <label htmlFor="tutorials">
                  <BpCheckbox
                    id="tutorials"
                    checked={ticketDetails.tags.includes("tutorials")}
                    onChange={handleTagChange}
                  />
                  Tutorials
                </label>
              </Grid>
            </Grid>
          </Box>
          <Box width="100%">
            <Typography shrink htmlFor="issue-priority" sx={{ marginTop: "1rem" }}>
              Issue Priority
            </Typography>
            <Select
              id="issue-priority"
              name="issue-priority"
              value={ticketDetails.priority}
              onChange={e => setTicketDetails({ ...ticketDetails, priority: e.target.value })}
              size="small"
              placeholder="Select"
              required
              sx={{
                "width": "100%",
                "& .MuiSelect-icon": {
                  color: theme.palette.secondary.main
                },
                "& .MuiSelect-root": {
                  color: theme.palette.secondary.main
                }
              }}
            >
              <MenuItem value="" disabled>
                Select
              </MenuItem>
              <MenuItem value="LOW">LOW</MenuItem>
              <MenuItem value="MEDIUM">MEDIUM</MenuItem>
              <MenuItem value="HIGH">HIGH</MenuItem>
            </Select>
          </Box>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseCreateIssueDialog}>Cancel</Button>
        <CButton buttonText="Create issue ticket" variant="primary" onClickFun={handleCreateIssueSubmit} />
      </DialogActions>
    </Dialog>
  );
};

export default TicketCreator;
