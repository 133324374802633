import { Box } from "@mui/material";
import React, { useState } from "react";
import QuestionAnswerPaper from "./omrUpload/QuestionAnswerPaper";
import OmrFilesUpload from "./omrUpload/filesUpload";

const OmrUpload = ({ examId, startAnalysisRef }) => {
  const [questionPaper, setQuestionPaper] = useState(null);
  const [answerKey, setAnswerKey] = useState(null);
  const [omrFolder, setOmrFolder] = useState(null);

  return (
    <Box py="2rem" display="flex" flexDirection="column" gap="3rem">
      <Box width="100%" display="flex" flexDirection="column" gap="1.5rem">
        <QuestionAnswerPaper
          questionPaper={questionPaper}
          setQuestionPaper={setQuestionPaper}
          answerKey={answerKey}
          setAnswerKey={setAnswerKey}
        />
        <OmrFilesUpload
          omrFolder={omrFolder}
          setOmrFolder={setOmrFolder}
          examId={examId}
          startAnalysisRef={startAnalysisRef}
          answerKey={answerKey}
          questionPaper={questionPaper}
        />
      </Box>
    </Box>
  );
};

export default OmrUpload;
