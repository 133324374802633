import { Box, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import CButton from "../../ui/Button";
import { useSetUserLimitMutation } from "../../../redux/api/schoolSlice";

const SetUserLimit = ({ school_id, college }) => {
  const [limit, setLimit] = useState(college.user_limit);
  const [setUserLimitMutation] = useSetUserLimitMutation();

  const handleSetUserLimit = () => {
    setUserLimitMutation({ school_id, user_limit: limit });
  };

  return (
    <Box display="flex" flexDirection="column" gap="15px">
      <Typography variant="h5" fontWeight={600}>
        Manage User Limit
      </Typography>
      <TextField
        label="User Limit"
        fullWidth
        type="number"
        value={limit}
        onChange={event => setLimit(event.target.value)}
        sx={{
          "& .MuiInputBase-root": {
            fontSize: "16px",
            backgroundColor: "#fff"
          },
          "& .MuiInputLabel-root": {
            fontSize: "15px"
          }
        }}
      />
      <Box width="100%" display="flex" justifyContent="flex-end">
        <CButton buttonText="Save" variant="primary" onClickFun={handleSetUserLimit} />
      </Box>
    </Box>
  );
};

export default SetUserLimit;
