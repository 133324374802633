import { Box, Typography } from "@mui/material";
import { CaretRight } from "@phosphor-icons/react";
import React from "react";
import BatchStepper from "./BatchAdderStepper/BatchStepper";

const BatchAdder = ({ setIsManageOpen, viewBatchRef }) => {
  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center" gap="2rem">
      <Box width="100%" display="flex" alignItems="center" gap="5px">
        <CaretRight />
        <Typography variant="h4" fontWeight={500}>
          Add Batch
        </Typography>
      </Box>
      <BatchStepper setIsManageOpen={setIsManageOpen} viewBatchRef={viewBatchRef} />
    </Box>
  );
};

export default BatchAdder;
