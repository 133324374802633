import { Box, InputBase, Typography, alpha, styled, useMediaQuery, useTheme } from "@mui/material";
import {
  DataGrid,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  gridFilteredSortedRowIdsSelector,
  selectedGridRowsSelector,
  useGridApiRef
} from "@mui/x-data-grid";
import { MagnifyingGlass } from "@phosphor-icons/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDeleteFromBatchMutation } from "../../../redux/api/batchSlice";
import { useGetFacultiesQuery, useGetStudentsQuery } from "../../../redux/api/supervisorSlice";
import { MaterialUISwitch } from "../../ui/styledComponents";

const Search = styled("div")(({ theme }) => ({
  "position": "relative",
  "borderRadius": "2rem",
  "border": "1px solid #C7D7FF",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.03)
  },
  "width": "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto"
  }
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "color": "inherit",
  "width": "100%",
  "fontSize": theme.typography.pxToRem(15),
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      "width": "12ch",
      "&:focus": {
        width: "20ch"
      }
    }
  }
}));

const getSelectedRowsToExport = ({ apiRef }) => {
  const selectedRowIds = selectedGridRowsSelector(apiRef);
  if (selectedRowIds.size > 0) {
    return Array.from(selectedRowIds.keys());
  }

  return gridFilteredSortedRowIdsSelector(apiRef);
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const IndividualBatchTable = ({ userType }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const apiRef = useGridApiRef();
  const theme = useTheme();
  const { user } = useSelector(state => state.auth);

  const { data: students } = useGetStudentsQuery(user.school_id);
  const { data: faculties } = useGetFacultiesQuery(user.school_id);

  const { batch: individualBatch } = useSelector(state => state.batch);
  const { batchUserDetails } = useSelector(state => state.batch);

  const [deleteFromBatch] = useDeleteFromBatchMutation();

  const [data, setData] = useState([]);
  const [deleteUserId, setDeleteUserId] = useState("");

  useEffect(() => {
    if (batchUserDetails) {
      if (batchUserDetails.length > 0) {
        let indBatchWithSrNo = batchUserDetails?.map((batch, index) => {
          return { ...batch, sr_no: index + 1 };
        });

        indBatchWithSrNo =
          userType === "students"
            ? indBatchWithSrNo.filter(batch => batch.roll_no !== "Faculty")
            : indBatchWithSrNo.filter(batch => batch.roll_no === "Faculty" && batch.email);

        setData(indBatchWithSrNo);
      }
    }
  }, [batchUserDetails, userType]);

  const getRowId = row => row.sr_no;

  // Define a function to get the class for each row
  const getRowClassName = params => {
    return "row-color";
  };

  const columns = [
    { field: "sr_no", headerName: "Sr. No.", flex: 0.3, headerClassName: "datagrid-header-color", hide: true },
    {
      field: "name",
      headerName: "Student Name",
      flex: 0.5,
      headerClassName: "datagrid-header-color"
    },
    {
      field: "roll_no",
      headerName: "Roll Number",
      flex: 0.5,
      headerClassName: "datagrid-header-color"
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.5,
      headerClassName: "datagrid-header-color"
    }
  ];

  const styledColumns = React.useMemo(
    () => [{ ...GRID_CHECKBOX_SELECTION_COL_DEF, headerClassName: "datagrid-header-color", flex: 0.15 }, ...columns],
    [columns]
  );

  const [checked, setChecked] = useState(false);

  const handleSearchFieldChange = event => {
    setChecked(event.target.checked);
  };

  const handleSearch = event => {
    if (batchUserDetails) {
      if (batchUserDetails.length > 0) {
        let indBatchWithSrNo = batchUserDetails?.map((batch, index) => {
          return { ...batch, sr_no: index + 1 };
        });

        indBatchWithSrNo =
          userType === "students"
            ? indBatchWithSrNo.filter(batch => batch.roll_no !== "Faculty")
            : indBatchWithSrNo.filter(batch => batch.roll_no === "Faculty");

        setData(indBatchWithSrNo);
      }
    }
    setData(prevData =>
      !checked
        ? prevData.filter(item => item.name.toLowerCase().includes(event.target.value.toLowerCase()))
        : prevData.filter(item => item.email.toLowerCase().includes(event.target.value.toLowerCase()))
    );
  };

  return (
    <>
      <Box borderRadius="1rem" bgcolor="#fff" border="1px solid #E6EDFF" width="100%">
        <Box
          width="100%"
          display="flex"
          alignItems={isNonMobile ? "center" : "start"}
          p="1rem"
          justifyContent="space-between"
          flexDirection={isNonMobile ? "row" : "column"}
          gap="15px"
        >
          <Typography variant="h4" fontWeight={400}>
            Manage Students
          </Typography>
          <Box display="flex" gap="1rem" flexDirection={isNonMobile ? "row" : "column"}>
            <Box display="flex" gap="5px" alignItems="center">
              <Typography variant="h6" fontWeight={400}>
                Search with:
              </Typography>
              <Typography variant="h6">Name</Typography>
              <MaterialUISwitch checked={checked} onChange={handleSearchFieldChange} />
              <Typography variant="h6">Email</Typography>
            </Box>
            <Search>
              <SearchIconWrapper>
                <MagnifyingGlass color="#3267FF" weight="bold" size={20} />
              </SearchIconWrapper>
              <StyledInputBase placeholder="Search…" inputProps={{ "aria-label": "search" }} onChange={handleSearch} />
            </Search>
          </Box>
        </Box>
        <Box
          className="datagrid-container"
          sx={{
            "& .datagrid-header-color": {
              fontWeight: 500,
              fontSize: "1rem !important"
            }
          }}
        >
          <DataGrid
            className="custom-datagrid"
            autoHeight
            rows={data}
            disableRowSelectionOnClick
            apiRef={apiRef}
            columns={styledColumns}
            getRowId={getRowId}
            getRowClassName={getRowClassName} // Add this line
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10
                }
              },
              columns: {
                columnVisibilityModel: {
                  id: false
                }
              }
            }}
            pageSizeOptions={[10, 20, 50]}
            sx={!isNonMobile ? { width: "750px" } : {}}
          />
        </Box>
      </Box>
    </>
  );
};
export default IndividualBatchTable;
