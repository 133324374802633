import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const facultyApiSlice = createApi({
  reducerPath: "facultyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/faculty`,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["StudentsOfFaculty"],
  endpoints: build => ({
    fetchStudentsOfFaculty: build.query({
      query: ({ school_id, faculty_id }) => ({
        url: `/findStudentsOfFaculty/${school_id}/${faculty_id}`
      }),
      providesTags: ["StudentsOfFaculty"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    })
  })
});

export const { useFetchStudentsOfFacultyQuery } = facultyApiSlice;
