import {
  Box,
  Divider,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React from "react";

const BatchDetails = ({ batchName, setBatchName, batchTag, setBatchTag, batchDescription, setBatchDescription }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();

  const onTagChange = event => {
    setBatchTag(event.target.value);
  };

  const handleBatchNameChange = event => {
    const { value } = event.target;
    if (value.length <= 20) {
      setBatchName(value);
    }
  };

  return (
    <Box py="2rem" display="flex" flexDirection="column" gap="3rem">
      <Box width="100%" display="flex" flexDirection="column" gap="1.5rem">
        <Box
          width="100%"
          display="flex"
          gap={isNonMobile ? "5rem" : "2rem"}
          flexDirection={isNonMobile ? "row" : "column"}
        >
          <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
            <Box display="flex" justifyContent="space-between" alignItems="center" width="80%">
              <Typography variant="h4" mb="0.2rem">
                Name of the batch
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {batchName.length}/20
              </Typography>
            </Box>
            <Divider sx={{ width: isNonMobile ? "80%" : "100%" }} />
            <FormControl>
              <TextField
                placeholder="Enter batch name"
                value={batchName}
                onChange={handleBatchNameChange}
                type="text"
                sx={{
                  width: isNonMobile ? "80%" : "100%",
                  bgcolor: "#F7F6F9"
                }}
                InputProps={{ inputProps: { style: { color: theme.palette.secondary.main } } }}
              />
            </FormControl>
          </Box>
          <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
            <Typography variant="h4" mb="0.2rem">
              Batch Tag
            </Typography>
            <Divider sx={{ width: isNonMobile ? "80%" : "100%" }} />
            <FormControl>
              <Select
                labelId="batchTag"
                id="batchTag"
                value={batchTag}
                onChange={onTagChange}
                sx={{
                  width: isNonMobile ? "80%" : "100%",
                  borderRadius: "1rem",
                  bgcolor: "#F7F6F9",
                  color: theme.palette.secondary.main
                }}
              >
                <MenuItem value="JEE" sx={{ color: theme.palette.secondary.main }}>
                  JEE
                </MenuItem>
                <MenuItem value="NEET" sx={{ color: theme.palette.secondary.main }}>
                  NEET
                </MenuItem>
                <MenuItem value="KCET" sx={{ color: theme.palette.secondary.main }}>
                  KCET
                </MenuItem>
                <MenuItem value="Boards" sx={{ color: theme.palette.secondary.main }}>
                  Boards
                </MenuItem>
                <MenuItem value="Custom" sx={{ color: theme.palette.secondary.main }}>
                  Custom
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
          <Typography variant="h4" mb="0.2rem">
            Description of the batch
          </Typography>
          <Divider sx={{ width: "100%" }} />
          <FormControl>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={4}
              placeholder="Enter description of batch"
              value={batchDescription}
              onChange={e => setBatchDescription(e.target.value)}
              type="text"
              sx={{
                width: "100%",
                bgcolor: "#F7F6F9"
              }}
              InputProps={{ inputProps: { style: { color: theme.palette.secondary.main } } }}
            />
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default BatchDetails;
