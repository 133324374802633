import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import toast from "react-hot-toast";

export const batchApiSlice = createApi({
  reducerPath: "batchApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/batch`,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["Batches", "Batch", "IndividualBatch", "FavBatches"],
  endpoints: builder => ({
    getBatches: builder.query({
      query: schoolId => ({
        url: `/batches/${schoolId}`
      }),
      providesTags: ["Batches"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    getBatch: builder.query({
      query: batchId => ({
        url: `/getBatchById/${batchId}`
      }),
      providesTags: ["Batch"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    createABatch: builder.mutation({
      query: batch => ({
        url: "/create",
        method: "POST",
        body: { batch }
      }),
      invalidatesTags: ["Batch", "Batches"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Creating Batch...", { id: "createBatch" });
          await queryFulfilled;
          toast.success("Batch Created Successfully", { id: "createBatch" });
        } catch (error) {
          console.log(error);
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "createBatch" });
        }
      }
    }),
    updateABatch: builder.mutation({
      query: ({ id, batchData }) => ({
        url: `/${id}`,
        method: "PUT",
        body: { batchData }
      }),
      invalidatesTags: ["Batch", "Batches"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Updating Batch...", { id: "updateBatch" });
          await queryFulfilled;
          toast.success("Batch Updated Successfully", { id: "updateBatch" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "updateBatch" });
        }
      }
    }),
    deleteABatch: builder.mutation({
      query: id => ({
        url: `/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Batch", "Batches"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Deleting Batch...", { id: "deleteBatch" });
          await queryFulfilled;
          toast.success("Batch Deleted Successfully", { id: "deleteBatch" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "deleteBatch" });
        }
      }
    }),
    getBatchesByFaculty: builder.query({
      query: ({ school_id, faculty_id }) => ({
        url: `/${school_id}/${faculty_id}`
      }),
      providesTags: ["Batches"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    getBatchesByStudent: builder.query({
      query: ({ schoolId, studentId }) => ({
        url: "/getBatchesByStudent",
        method: "POST",
        body: { schoolId, studentId }
      }),
      providesTags: ["Batches"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    addFaculties: builder.mutation({
      query: ({ id, facultyData }) => ({
        url: `/addFacultiesList/${id}`,
        method: "POST",
        body: { facultyData }
      }),
      invalidatesTags: ["Batch", "Batches"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Adding Faculties...", { id: "addFaculties" });
          await queryFulfilled;
          toast.success("Faculties Added Successfully", { id: "addFaculties" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "addFaculties" });
        }
      }
    }),
    addStudents: builder.mutation({
      query: ({ id, studentData }) => ({
        url: `/addStudentsList/${id}`,
        method: "POST",
        body: { studentData }
      }),
      invalidatesTags: ["Batch", "Batches"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Adding Students...", { id: "addStudents" });
          await queryFulfilled;
          toast.success("Students Added Successfully", { id: "addStudents" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "addStudents" });
        }
      }
    }),
    addFacultiesFile: builder.mutation({
      query: ({ id, facultyData }) => ({
        url: `/addFacultiesFile/${id}`,
        method: "POST",
        body: facultyData
      }),
      invalidatesTags: ["Batch", "Batches"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Adding Faculties from File...", { id: "addFacultiesFile" });
          await queryFulfilled;
          toast.success("Faculties Added Successfully", { id: "addFacultiesFile" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "addFacultiesFile" });
        }
      }
    }),
    addStudentsFile: builder.mutation({
      query: ({ id, studentData }) => ({
        url: `/addStudentsFile/${id}`,
        method: "POST",
        body: studentData
      }),
      invalidatesTags: ["Batch", "Batches"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Adding Students from File...", { id: "addStudentsFile" });
          await queryFulfilled;
          toast.success("Students Added Successfully", { id: "addStudentsFile" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "addStudentsFile" });
        }
      }
    }),
    addToBatch: builder.mutation({
      query: userDetails => ({
        url: "/addToBatch",
        method: "POST",
        body: userDetails
      }),
      invalidatesTags: ["Batch", "Batches"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Adding Users to Batch...", { id: "addToBatch" });
          await queryFulfilled;
          toast.success("Users Added Successfully", { id: "addToBatch" });
        } catch (error) {
          console.log("ERROR: ", error);
          const message = error?.error?.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "addToBatch" });
        }
      }
    }),
    getUsersFavBatches: builder.query({
      query: user_id => ({
        url: `/getUsersFavBatches/${user_id}`,
        method: "GET"
      }),
      providesTags: ["FavBatches"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    setFavBatch: builder.mutation({
      query: ({ batch_id, user_id }) => ({
        url: "/setFavBatch",
        method: "POST",
        body: { batch_id, user_id }
      }),
      invalidatesTags: ["FavBatches"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "setFavBatch" });
        }
      }
    }),
    deleteFromBatch: builder.mutation({
      query: idObject => ({
        url: "/deleteFromBatch",
        method: "DELETE",
        body: idObject
      }),
      invalidatesTags: ["Batch", "Batches"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Deleting User from Batch...", { id: "deleteFromBatch" });
          await queryFulfilled;
          toast.success("User Deleted Successfully", { id: "deleteFromBatch" });
        } catch (error) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "deleteFromBatch" });
        }
      }
    })
  })
});

export const {
  useGetBatchQuery,
  useCreateABatchMutation,
  useGetBatchesQuery,
  useUpdateABatchMutation,
  useDeleteABatchMutation,
  useGetBatchesByFacultyQuery,
  useGetBatchesByStudentQuery,
  useAddFacultiesFileMutation,
  useAddFacultiesMutation,
  useAddStudentsFileMutation,
  useAddStudentsMutation,
  useAddToBatchMutation,
  useGetUsersFavBatchesQuery,
  useSetFavBatchMutation,
  useDeleteFromBatchMutation
} = batchApiSlice;
