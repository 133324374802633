import React from "react";
import { Link } from "react-router-dom";

import { downloadAppIcon1, downloadAppIcon2, downloadAppImage1, downloadAppQrCode } from "../../Assets/landing";

const DownloadAppNow = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full">
      <div className="container px-4 lg:px-20 flex flex-col justify-center items-center w-full">
        <div className="flex flex-col lg:flex-row justify-center items-center w-full gap-0 lg:gap-12">
          <section className="flex justify-center items-center w-full lg:w-1/2">
            <div className="flex flex-col justify-center items-center w-full gap-4">
              <h2 className="m-0 font-semibold text-xl lg:text-3xl text-[#232233] text-center w-full">
                Download App Now
              </h2>
              <p className="m-0 text-sm lg:text-base text-[#6C6C72] text-center">
                Download our Scaleyou Insights App to gain instant access to invaluable information about exams and
                their analysis, detailed insights about institutes, and much more—all right at your fingertips!
              </p>

              <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center w-full gap-4">
                <div className="flex justify-center items-center w-full gap-4">
                  <Link to={"/"}>
                    <div className="w-40">
                      <img
                        src={downloadAppIcon1}
                        alt="Image depicting to Download the mobile app of ScaleYou Insights"
                        className="object-contain w-full"
                      />
                    </div>
                  </Link>
                  <Link to={"/"}>
                    <div className="w-40">
                      <img
                        src={downloadAppIcon2}
                        alt="Image depicting to Download the mobile app of ScaleYou Insights"
                        className="object-contain w-full"
                      />
                    </div>
                  </Link>
                </div>
                <div className="w-32">
                  <img
                    src={downloadAppQrCode}
                    alt="Image of a QR code which navigates to the mobile app link"
                    className="object-contain w-full"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="flex justify-center items-center w-full lg:w-1/2">
            <div className="w-full lg:w-3/4">
              <img
                src={downloadAppImage1}
                alt="Image depicting how scaleYou insights looks in the mobile version"
                className="object-contain w-full"
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppNow;
