import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import toast from "react-hot-toast";

export const examApiSlice = createApi({
  reducerPath: "examApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["AllExams", "Exam", "AllQuestions"],
  endpoints: build => ({
    createAExam: build.mutation({
      query: examData => ({
        url: "/exam",
        method: "POST",
        body: { examData }
      }),
      invalidatesTags: [],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("creating Exam...", { id: "createExam" });
          await queryFulfilled;
          toast.success("Exam created Successfully", { id: "createExam" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "createExam" });
        }
      }
    }),
    getAllExams: build.query({
      query: schoolId => ({
        url: `/exam/getAllExams/${schoolId}`
      }),
      providesTags: ["AllExams"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    getAExam: build.query({
      query: id => ({
        url: `/exam/${id}`
      }),
      providesTags: ["Exam"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    updateAExam: build.mutation({
      query: ({ id, examData }) => ({
        url: `/exam/${id}`,
        method: "PUT",
        body: { examData }
      }),
      invalidatesTags: ["Exam"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("updating Exam...", { id: "updateExam" });
          await queryFulfilled;
          toast.success("Exam updated Successfully", { id: "updateExam" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "updateExam" });
        }
      }
    }),
    deleteAExam: build.mutation({
      query: id => ({
        url: `/exam/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["AllExams"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("deleting Exam...", { id: "deleteExam" });
          await queryFulfilled;
          toast.success("Exam deleted Successfully", { id: "deleteExam" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "deleteExam" });
        }
      }
    }),
    createAQuestion: build.mutation({
      query: questionData => ({
        url: "/question/single",
        method: "POST",
        body: { questionData }
      }),
      invalidatesTags: ["AllQuestions"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("creating Question...", { id: "createQuestion" });
          await queryFulfilled;
          toast.success("Question created Successfully", { id: "createQuestion" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "createQuestion" });
        }
      }
    }),
    createQuestionsBulk: build.mutation({
      query: questionData => ({
        url: "/question/bulk",
        method: "POST",
        body: { questionData }
      }),
      invalidatesTags: [],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("creating Questions...", { id: "createQuestions" });
          await queryFulfilled;
          toast.success("Questions created Successfully", { id: "createQuestions" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "createQuestions" });
        }
      }
    }),
    getAllQuestions: build.query({
      query: ({ exam_unique_code, school_id }) => ({
        url: `/question/getAllQuestions/${school_id}/${exam_unique_code}`
      }),
      providesTags: ["AllQuestions"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          console.log(message);
        }
      }
    }),
    getAllQuestionsByArray: build.mutation({
      query: questionsArray => ({
        url: "/question/getAllQuestionsByArray",
        method: "POST",
        body: { questionsArray }
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          console.log(message);
        }
      }
    }),
    getAQuestion: build.query({
      query: id => ({
        url: `/question/${id}`
      }),
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          console.log(message);
        }
      }
    }),
    updateAQuestion: build.mutation({
      query: ({ id, questionData }) => ({
        url: `/question/${id}`,
        method: "POST",
        body: { questionData }
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("updating Question...", { id: "updateQuestion" });
          await queryFulfilled;
          toast.success("Question updated Successfully", { id: "updateQuestion" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "updateQuestion" });
        }
      }
    }),
    deleteAQuestion: build.mutation({
      query: id => ({
        url: `/question/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["AllQuestions"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("deleting Question...", { id: "deleteQuestion" });
          await queryFulfilled;
          toast.success("Question deleted Successfully", { id: "deleteQuestion" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "deleteQuestion" });
        }
      }
    }),
    getQuestionCount: build.mutation({
      query: ({ exam_unique_code, school_id }) => ({
        url: "/question/getQuestionCount",
        method: "POST",
        body: { exam_unique_code, school_id }
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          console.log(message);
        }
      }
    })
  })
});

export const {
  useCreateAExamMutation,
  useCreateAQuestionMutation,
  useCreateQuestionsBulkMutation,
  useDeleteAExamMutation,
  useDeleteAQuestionMutation,
  useGetAExamQuery,
  useGetAQuestionQuery,
  useGetAllExamsQuery,
  useGetAllQuestionsByArrayMutation,
  useGetAllQuestionsQuery,
  useUpdateAExamMutation,
  useUpdateAQuestionMutation,
  useGetQuestionCountMutation
} = examApiSlice;
