import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useGetTicketsForUserQuery } from "../../../redux/api/raiseTicketSlice";

const colorMap = {
  LOW: "#20B058",
  MEDIUM: "#F48C06",
  HIGH: "#EA1819"
};

const TicketDisplay = () => {
  const { data, isSuccess } = useGetTicketsForUserQuery();
  const { user } = useSelector(state => state.auth);

  return (
    <Box width="100%" display="flex" flexDirection="column" gap="1rem">
      <Typography width="100%" variant="subtitle1">
        Create issue tickets here or directly contact us at{" "}
        <a href="mailto:contact.scaleyou@gmail.com">contact.scaleyou@gmail.com</a>
      </Typography>
      <Grid container spacing={2} paddingTop="1rem">
        {isSuccess && data && data.tickets && data.tickets.length ? (
          data.tickets?.map((ticket, index) => (
            <Grid item key={ticket._id} xs={12} sm={6} md={4} xl={3}>
              <Card
                sx={{
                  "borderRadius": "1rem",
                  "&:hover": { backgroundColor: "rgba(0,0,0,0.04)", transition: "ease-in 200ms" },
                  "height": "100%",
                  "border": "1px solid #E6EDFF",
                  "display": "flex",
                  "flexDirection": "column"
                }}
              >
                <CardContent sx={{ display: "flex", flexDirection: "column", flexGrow: 1, pb: "1rem !important" }}>
                  <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                    <Typography variant="h4" color="textPrimary" style={{ fontWeight: 500 }}>
                      {ticket.title}
                    </Typography>
                    <Box display="flex" gap="1rem" alignItems="center">
                      <div
                        style={{
                          padding: "1px 10px",
                          borderRadius: "1rem",
                          backgroundColor: colorMap[ticket.priority] || "orange",
                          color: "#fff",
                          fontWeight: 600
                        }}
                      >
                        {ticket.priority}
                      </div>
                    </Box>
                  </Box>
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    style={{
                      fontWeight: 400,
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                      overflow: "hidden",
                      textOverflow: "ellipsis", // Optional: This can be used to show ellipsis if text overflows
                      padding: "1rem 0rem"
                    }}
                  >
                    {ticket.description}
                  </Typography>
                  <Box mt="auto" display="flex" flexDirection="column" gap="0.5rem">
                    <Box gap="0.5rem" display="flex" alignItems="center">
                      <Typography variant="h6" color="textSecondary" fontWeight={400} fontSize={12}>
                        Status: <b>{ticket?.status}</b>
                      </Typography>
                      <hr style={{ height: "20px" }} />
                      <Typography variant="h6" color="textSecondary" fontWeight={400} fontSize={12}>
                        Created on {new Date(ticket?.createdAt).toLocaleDateString()}
                      </Typography>
                    </Box>
                    <Box
                      gap="0.5rem"
                      display="flex"
                      alignItems="center"
                      sx={{
                        overflowX: "auto"
                      }}
                    >
                      {ticket.tags.map(tag => (
                        <Typography
                          key={tag}
                          variant="h6"
                          color="textSecondary"
                          fontWeight={400}
                          fontSize={10}
                          style={{
                            padding: "0.5rem",
                            backgroundColor: "#E6EDFF",
                            borderRadius: "1rem"
                          }}
                        >
                          {tag}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Box sx={{ alignSelf: "center" }}>
            <Typography variant="subtitle1" fontWeight="500">
              No tickets to display
            </Typography>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default TicketDisplay;
