import { Box, ToggleButton, ToggleButtonGroup, Typography, styled, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import FacultyListUploader from "./FacultyListUploader";
import FacultySelectorTable from "./FacultySelectorTable";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff"
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const TagFaculty = ({
  batchFacultyList,
  setBatchFacultyList,
  facultyListOrFile,
  setFacultyListOrFile,
  file,
  setFile
}) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const [alignment, setAlignment] = useState("selectFaculties");

  const handleToggle = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  return (
    <Box width="100%">
      <Box width={"100%"} mt="1rem" display={"flex"} justifyContent={"center"}>
        <ToggleButtonGroup
          sx={{
            bgcolor: "#fff",
            width: isNonMobile ? "80%" : "100%"
          }}
          value={alignment}
          exclusive
          onChange={handleToggle}
          aria-label="add-or-manage"
        >
          <StyledToggleButton disabled={facultyListOrFile === "file"} value={"selectFaculties"} sx={{ width: "100%" }}>
            <Typography variant="h6" lineHeight="14px">
              Select Faculties
            </Typography>
          </StyledToggleButton>
          <StyledToggleButton disabled={facultyListOrFile === "list"} value={"uploadFaculties"} sx={{ width: "100%" }}>
            <Typography variant="h6" lineHeight="14px">
              Upload Faculty List
            </Typography>
          </StyledToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box width="100%" mt="2rem">
        {alignment === "selectFaculties" && (
          <Box py="2rem" display="flex" flexDirection="column" gap="1rem">
            <FacultySelectorTable
              batchFacultyList={batchFacultyList}
              setBatchFacultyList={setBatchFacultyList}
              setListOrFile={setFacultyListOrFile}
            />
          </Box>
        )}
        {alignment === "uploadFaculties" && (
          <FacultyListUploader setListOrFile={setFacultyListOrFile} file={file} setFile={setFile} />
        )}
      </Box>
    </Box>
  );
};

export default TagFaculty;
