import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetIndividualResultsQuery } from "../../../redux/api/resultsSlice";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";

const IndividualResult = () => {
  const theme = useTheme();
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const apiRef = useGridApiRef();

  const { resultId } = useParams();
  const { user } = useSelector(state => state.auth);

  const { data, isSuccess } = useGetIndividualResultsQuery({ student_id: user._id, result_id: resultId });

  const getTotalMarks = studentResults => {
    const totalMarks = studentResults.reduce((sum, result) => sum + result.totalMarks, 0);
    return totalMarks;
  };

  const getTotalMarksObtained = studentResults => {
    const totalMarksObtained = studentResults.reduce((sum, result) => sum + result.score, 0);
    return totalMarksObtained;
  };

  const getPercentage = studentResults => {
    if (!studentResults || studentResults.length === 0) return "N/A";

    const totalMarksObtained = getTotalMarksObtained(studentResults);
    const totalMarks = getTotalMarks(studentResults);

    return ((totalMarksObtained / totalMarks) * 100).toFixed(2) + "%";
  };

  const getPassOrFail = studentResults => {
    if (!studentResults || studentResults.length === 0) return "N/A";

    const isPassed = studentResults.every(result => result.isPassed);

    return isPassed ? "PASS" : "FAIL";
  };

  const getRowId = row => row.subject;

  // Define a function to get the class for each row
  const getRowClassName = params => {
    return "row-color";
  };

  const logicalColumns = [
    { field: "subject", headerName: "Subject", flex: 0.4, headerClassName: "datagrid-header-color" },
    {
      field: "totalMarks",
      headerName: "Maximum Marks",
      flex: 0.5,
      headerClassName: "datagrid-header-color"
    },
    {
      field: "passingMarks",
      headerName: "Passing Marks",
      flex: 0.5,
      headerClassName: "datagrid-header-color"
    },
    {
      field: "score",
      headerName: "Marks Obtained",
      flex: 0.5,
      headerClassName: "datagrid-header-color"
    },
    {
      field: "averageScore",
      headerName: "Class Average",
      flex: 0.5,
      headerClassName: "datagrid-header-color"
    }
  ];

  const downloadPdf = () => {
    const resultBox = document.getElementById("result-box");
    if (!resultBox) {
      console.error("Result box not found");
      return;
    }

    html2canvas(resultBox)
      .then(canvas => {
        const imgData = canvas.toDataURL("image/png");
        // eslint-disable-next-line new-cap
        const pdf = new jsPDF("p", "mm", "a4");
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("result.pdf");
      })
      .catch(error => {
        console.error("Error generating PDF", error);
      });
  };

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "View Result", link: "/view-results" },
    { name: `${data.result.resultName}`, link: `` }
  ];

  return (
    <Box
      padding="1rem 2rem"
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <div style={{ padding: "1rem 0" }}>
        <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
      </div>
      <Box display="flex" gap="0.75rem" justifyContent="space-between">
        <Box>
          <Typography variant="h2" fontWeight={600}>
            Your Result
          </Typography>
          <Typography variant="h6" color={theme.palette.primary.light}>
            View your result here
          </Typography>
        </Box>
      </Box>
      {data && data.result && data.studentResults ? (
        <>
          <Box
            id="result-box"
            sx={{
              borderRadius: "1rem",
              backgroundColor: "white",
              border: "1px solid #E6EDFF",
              padding: "1rem 3rem",
              width: "100%",
              height: "max-content",
              display: "flex",
              flexDirection: "column",
              gap: "2rem"
            }}
          >
            <Typography variant="h4" textAlign="center" fontWeight={500}>
              {data.result.resultName}
            </Typography>
            <Box width="100%" display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="h6" fontWeight={400}>
                  <b>Student Name:</b> {user.name}
                </Typography>
                <Typography variant="h6" fontWeight={400}>
                  <b>Roll Number:</b> {user.roll_no}
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6" fontWeight={400}>
                  <b>Percentage:</b> {getPercentage(data.studentResults.results)}
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight={400}
                  color={getPassOrFail(data.studentResults.results) === "FAIL" ? "red" : "green"}
                >
                  <b>Result:</b> {getPassOrFail(data.studentResults.results)}
                </Typography>
              </Box>
            </Box>
            <div className="datagrid-container">
              <DataGrid
                className="custom-datagrid"
                autoHeight
                rows={data.studentResults.results}
                disableRowSelectionOnClick
                apiRef={apiRef}
                columns={logicalColumns}
                getRowId={getRowId}
                getRowClassName={getRowClassName} // Add this line
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10
                    }
                  }
                }}
                sx={!isNonMobile ? { width: "800px" } : {}}
              />
            </div>
            <Box width="100%" display="flex" justifyContent="space-between">
              <Typography variant="h6" fontWeight={400}>
                <b>Total Marks:</b> {getTotalMarks(data.studentResults.results)}
              </Typography>
              <Typography variant="h6" fontWeight={400}>
                <b>Total Marks Obtained:</b> {getTotalMarksObtained(data.studentResults.results)}
              </Typography>
              <Typography variant="h6" fontWeight={400}>
                <b>Total Average Marks:</b> {data.overallAverageScore}
              </Typography>
            </Box>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={downloadPdf}
            sx={{ marginTop: "1rem", alignSelf: "center" }}
          >
            Download PDF
          </Button>
        </>
      ) : (
        <>You have no results here</>
      )}
    </Box>
  );
};

export default IndividualResult;
