import { Switch, styled } from "@mui/material";

export const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  "width": 62,
  "height": 34,
  "padding": 7,
  "& .MuiSwitch-switchBase": {
    "margin": 0,
    "padding": 0,
    "transform": "translateX(6px) translateY(6px)",
    "&.Mui-checked": {
      "color": "#fff",
      "transform": "translateX(34px) translateY(6px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage:
          "url('data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='16' width='16' viewBox='0 0 24 24'><path fill='#000' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z'/></svg>')"
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be"
      }
    }
  },
  "& .MuiSwitch-thumb": {
    "backgroundColor": theme.palette.mode === "dark" ? "#003892" : "#3267FF",
    "width": 22,
    "height": 22,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`
    }
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2
  }
}));
