import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetAllExamsQuery } from "../../../redux/api/examSlice";
import { useCreateATestMutation } from "../../../redux/api/taskSlice";

const TestCreationComponent = () => {
  const [testName, setTestName] = useState("");
  const [selectedExam, setSelectedExam] = useState("");
  const [startTime, setStartTime] = useState("");
  const [expiryTime, setExpiryTime] = useState("");
  const [duration, setDuration] = useState("");
  const [questionPaper, setQuestionPaper] = useState(null);
  const [answerKey, setAnswerKey] = useState(null);
  const [omr, setOMR] = useState(null);
  const { user } = useSelector(state => state.auth);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const { batchId } = useParams();
  const { data: exams, isLoading, isError } = useGetAllExamsQuery(user?.school_id);
  const [createTest, { isLoading: isCreatingTest }] = useCreateATestMutation();

  const handlePublish = async () => {
    try {
      await createTest({
        startTime,
        expiryTime,
        duration,
        questionPaper: questionPaper,
        answerKey: answerKey,
        omr: omr
      });

      toast.success("Test created successfully");
    } catch (error) {
      console.error("Error publishing test:", error);
      toast.error("Error publishing test");
    }
  };

  const handleQuestionPaperUpload = event => {
    const file = event.target.files[0];
    setQuestionPaper(file);
  };

  const handleOMRUpload = event => {
    const file = event.target.files[0];
    setOMR(file);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("files", questionPaper);
    formData.append("files", omr);
    formData.append("examId", selectedExam._id);
    formData.append("school_id", user?.school_id);
    formData.append("batch_id", batchId);
    // formData.append("section_id", sectionToUpload);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/task/test-file-upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      toast.success("File uploaded successfully");
      // setSectionToUpload("");
      setFile(null);
      setFileName("");
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Error uploading file");
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching exams</div>;

  return (
    <div>
      <Typography variant="h5">Create Test</Typography>
      <TextField
        label="Test Name"
        value={testName}
        onChange={e => setTestName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="exam-select-label">Select Exam</InputLabel>
        <Select labelId="exam-select-label" value={selectedExam} onChange={e => setSelectedExam(e.target.value)}>
          {exams &&
            exams?.exams &&
            exams?.exams?.map(exam => (
              <MenuItem key={exam._id} value={exam}>
                {" "}
                {/* Pass the entire exam object as the value */}
                {exam.exam_name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <TextField
        label="Start Time"
        type="datetime-local"
        value={startTime}
        onChange={e => setStartTime(e.target.value)}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true
        }}
      />
      <TextField
        label="Expiry Time"
        type="datetime-local"
        value={expiryTime}
        onChange={e => setExpiryTime(e.target.value)}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true
        }}
      />
      <TextField
        label="Duration (in minutes)"
        type="number"
        value={duration}
        onChange={e => setDuration(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Box mt={2} ml={5}>
        <Typography>Question Paper:</Typography>
        <input type="file" accept=".pdf" onChange={handleQuestionPaperUpload} />
        <Button variant="outlined" component="span" ml={2} onClick={handleUpload}>
          Upload File
        </Button>
      </Box>
      <Box mt={2} mb={2} ml={5}>
        <Typography>OMR Sheet:</Typography>
        <input type="file" accept=".pdf" onChange={handleOMRUpload} />
        <Button variant="outlined" component="span" ml={1} onClick={handleUpload}>
          Upload File
        </Button>
      </Box>
      <Button variant="contained" color="primary" onClick={handlePublish} mt={2} ml={5}>
        Publish
      </Button>
    </div>
  );
};

export default TestCreationComponent;
