import { Player } from "@lottiefiles/react-lottie-player";
import { Button, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Vector } from "../../../Assets";

const TeacherBanner = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        background: "linear-gradient(268.19deg, #77AAFF 21.99%, #4285F4 99.96%)",
        borderRadius: "10px",
        gap: "1rem",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: isNonMobile ? "row" : "column",
        position: "relative",
        overflow: "hidden"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "1rem",
          zIndex: 100
        }}
      >
        <div style={{ padding: "1rem" }}>
          <img src={Vector} />
        </div>
        <Typography variant="h3" paddingBottom={"1rem"} textAlign={"center"} color={"white"}>
          Exam Analyser Portal
        </Typography>
        <Typography variant="h5" paddingBottom={"0.5rem"} color={"white"} textAlign={"center"}>
          View the test analysis done by ScaleYou Insights
        </Typography>
        <div style={{ marginTop: "1rem" }}>
          <Button
            onClick={() => navigate("/test-history")}
            sx={{ padding: "0.5rem 2rem", borderRadius: "10px", border: "0", background: "white" }}
          >
            View
          </Button>
        </div>
      </div>
      <div style={{ display: "flex", zIndex: 100 }}>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <div style={{ flexGrow: "1", padding: "1rem" }}>
            <Typography variant="h3" padding={"0 0 1rem 0"} color={"white"}>
              12
            </Typography>
            <Typography variant="h6" color={"white"}>
              Students scored more than 95%
            </Typography>
          </div>
          <div style={{ flexGrow: "1", padding: "1rem" }}>
            <Typography variant="h3" padding={"0 0 1rem 0"} color={"white"}>
              24
            </Typography>
            <Typography variant="h6" color={"white"}>
              Students scored more than 85%
            </Typography>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <div style={{ flexGrow: "1", padding: "1rem" }}>
            <Typography variant="h3" padding={"0 0 1rem 0"} color={"white"}>
              112
            </Typography>
            <Typography variant="h6" color={"white"}>
              Was the highest score of the test
            </Typography>
          </div>
          <div style={{ flexGrow: "1", padding: "1rem" }}>
            <Typography variant="h3" padding={"0 0 1rem 0"} color={"white"}>
              95
            </Typography>
            <Typography variant="h6" color={"white"}>
              was the average score of the test
            </Typography>
          </div>
        </div>
      </div>
      <div style={isNonMobile ? {} : { position: "absolute", opacity: 0.2 }}>
        <Player
          src="https://lottie.host/e290c86b-10ea-4539-93c6-77a6533e7c4c/FMXEdPyLAc.json"
          className="player"
          loop
          autoplay
          style={{ maxHeight: "300px", maxWidth: "300px", height: "auto", width: "auto" }}
        />
      </div>
    </div>
  );
};

export default TeacherBanner;
