import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { CaretDown, User } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import TimeAgo from "react-timeago";
import { useFetchAnnouncementsByBatchIdQuery } from "../../../redux/api/announcementSlice";
import { useGetBatchesByStudentQuery } from "../../../redux/api/batchSlice";
import { fetchAnnouncementsByBatchId } from "../../../redux/features/Announcement/announcementSlice";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";
import CButton from "../../ui/Button";

const CTypography = styled(Typography)(({ theme }) => ({
  width: "100%",
  textAlign: "center",
  display: "flex",
  gap: "3px",
  alignItems: "center",
  justifyContent: "center"
}));

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const BatchAnnouncementsStudent = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    batch: { batches },
    auth: { user },
    announcements: { announcements }
  } = useSelector(state => state);
  const [name, setName] = useState("");

  const { batchId } = useParams();
  const { data: batchesData, isSuccess: isGetBatchesSuccess } = useGetBatchesByStudentQuery({
    schoolId: user.school_id,
    studentId: user._id
  });
  const { data: announcementData, isSuccess: isGetAnnouncementsSuccess } = useFetchAnnouncementsByBatchIdQuery(batchId);
  useEffect(() => {
    if (isGetBatchesSuccess && batchesData.batches) {
      const batchName = batchesData.batches.find(batch => batch._id === batchId);
      setName(batchName?.batch_name || "");
    }
  }, [batchesData?.batches, batchId]);

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Batches", link: "/view-batch" },
    { name: `${name}`, link: `/view-batch/${batchId}` },
    { name: "announcements", link: "#" }
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    if (batchId) {
      dispatch(fetchAnnouncementsByBatchId(batchId));
    }
  }, [batchId]);

  const handleRefresh = () => {
    if (batchId) {
      dispatch(fetchAnnouncementsByBatchId(batchId));
    }
  };
  return (
    <Box
      padding="1rem 2rem"
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <div style={{ padding: "1rem 0" }}>
        <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
      </div>
      <Box
        display="flex"
        flexDirection={isNonMobile ? "row" : "column"}
        gap="0.75rem"
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Box display={"flex"} alignItems={"center"} flexGrow={1} gap="1rem">
          <Box>
            <Typography variant={isNonMobile ? "h2" : "h3"} fontWeight={600}>
              Announcements
            </Typography>
            <Typography variant="h6" color={theme.palette.primary.light}>
              View and create announcements here
            </Typography>
          </Box>
          <CButton
            buttonText=""
            textOverflow="ellipsis"
            variant="primary"
            icon={<RefreshIcon />}
            onClickFun={handleRefresh}
            sx={{ height: "2.5rem" }}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" px="1rem" width="100%">
        <Typography variant="h6" width="65%" textAlign="center" display="flex" alignItems="center" gap="3px">
          Sr. No.
          <CaretDown weight="fill" />
        </Typography>
        <CTypography variant="h6">
          Topic
          <CaretDown weight="fill" />
        </CTypography>
        <CTypography variant="h6">
          Started_by
          <CaretDown weight="fill" />
        </CTypography>
        <CTypography variant="h6">
          Created_at
          <CaretDown weight="fill" />
        </CTypography>
      </Box>
      <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
        {isGetAnnouncementsSuccess &&
          announcementData?.announcements?.map((announcement, index) => (
            <Link
              to={`/view-batch/${batchId}/announcements/${announcement._id}`}
              key={index}
              style={{ textDecoration: "none" }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                py="0.5rem"
                px="0.6rem"
                pl={"2rem"}
                bgcolor="#fff"
                borderRadius="0.25rem"
                key={index + 1}
                tansition={"ease-in 1s"}
                scale={1}
                sx={{
                  "color": "black",
                  "&:hover": {
                    scale: "1",
                    boxShadow: "2"
                  }
                }}
              >
                <Typography variant="h6" width="65%" textAlign="left" style={{ textOverflow: "ellipsis" }}>
                  {index + 1}
                </Typography>

                <CTypography variant="h6">{announcement.topic}</CTypography>

                <CTypography variant="h6" sx={{ display: "flex", gap: "15px" }}>
                  <User weight="fill" color={theme.palette.secondary.main} size={18} />
                  {announcement.author.name}
                </CTypography>
                <CTypography variant="h6" textAlign={"center"} sx={{ display: "flex", gap: "15px" }}>
                  <TimeAgo sx={{ textAlign: "center" }} date={announcement.updatedAt} />
                </CTypography>
              </Box>
            </Link>
          ))}
      </Box>
    </Box>
  );
};

export default BatchAnnouncementsStudent;
