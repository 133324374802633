import { Box, ToggleButton, ToggleButtonGroup, Typography, styled, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import StudentListUploader from "./StudentListUploader";
import StudentSelectorTable from "./StudentSelectorTable";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff"
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const TagStudents = ({
  batchStudentList,
  setBatchStudentList,
  studentListOrFile,
  setStudentListOrFile,
  file,
  setFile
}) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const [alignment, setAlignment] = useState("selectStudents");

  const handleToggle = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  return (
    <Box width="100%">
      <Box width={"100%"} mt="1rem" display={"flex"} justifyContent={"center"}>
        <ToggleButtonGroup
          sx={{
            bgcolor: "#fff",
            width: isNonMobile ? "80%" : "100%"
          }}
          value={alignment}
          exclusive
          onChange={handleToggle}
          aria-label="add-or-manage"
        >
          <StyledToggleButton disabled={studentListOrFile === "file"} value={"selectStudents"} sx={{ width: "100%" }}>
            <Typography variant="h6" lineHeight="14px">
              Select Students
            </Typography>
          </StyledToggleButton>
          <StyledToggleButton disabled={studentListOrFile === "list"} value={"uploadStudents"} sx={{ width: "100%" }}>
            <Typography variant="h6" lineHeight="14px">
              Upload Student List
            </Typography>
          </StyledToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box width="100%" mt="2rem">
        {alignment === "selectStudents" && (
          <Box py="2rem" display="flex" flexDirection="column" gap="1rem">
            <StudentSelectorTable
              batchStudentList={batchStudentList}
              setBatchStudentList={setBatchStudentList}
              setListOrFile={setStudentListOrFile}
            />
          </Box>
        )}
        {alignment === "uploadStudents" && (
          <StudentListUploader file={file} setFile={setFile} setListOrFile={setStudentListOrFile} />
        )}
      </Box>
    </Box>
  );
};

export default TagStudents;
