import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  exams: [],
  exam: {},
  examInEdit: undefined,
  questions: [],
  question: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: ""
};

export const examSlice = createSlice({
  name: "exam",
  initialState,
  reducers: {
    setAExam: (state, action) => {
      state.exam = action.payload;
    },
    setQuestions: (state, action) => {
      state.questions = action.payload;
    },
    setExamInEdit: (state, action) => {
      state.examInEdit = action.payload;
    }
  }
});

export const { setQuestions, setAExam, setExamInEdit } = examSlice.actions;
export default examSlice.reducer;
