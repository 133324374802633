import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { logoBlue } from "../../Assets/index";

const TypewriterEffect = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const [displayText, setDisplayText] = useState("");
  const [index, setIndex] = useState(0);
  const text = "Insights...";

  useEffect(() => {
    const timer = setTimeout(() => {
      if (index < text.length) {
        setDisplayText(prevText => prevText + text[index]);
        setIndex(prevIndex => prevIndex + 1);
      } else {
        setDisplayText("");
        setIndex(0);
      }
    }, 150);
    return () => clearTimeout(timer);
  }, [index, text]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="fixed"
      top={0}
      bottom={0}
      left={0}
      right={0}
      zIndex={9999}
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.4)",
        WebkitBackdropFilter: "blur(5px)",
        backdropFilter: "blur(5px)"
      }}
    >
      <Box display="flex" justifyContent="center" alignItems="center" position="relative">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={isNonMobile ? 140 : 120}
          position="absolute"
          left={-120}
        >
          <img src={logoBlue} alt="Login" width={"100%"} />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          paddingTop={"12px"}
          position="absolute"
          left={isNonMobile ? -26 : -36}
        >
          <Typography variant={isNonMobile ? "h2" : "h3"} fontWeight={600}>
            {displayText}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TypewriterEffect;
