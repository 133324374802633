import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  TextField,
  ToggleButton,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { CaretDown, PencilSimple, Plus, Trash } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CButton from "../../../components/ui/Button";
import {
  useCreateAChapterMutation,
  useCreateASectionMutation,
  useCreateASubjectMutation,
  useDeleteAChapterMutation,
  useDeleteASectionMutation,
  useDeleteASubjectMutation,
  useGetEntireSyllabusQuery,
  useUpdateAChapterMutation,
  useUpdateASectionMutation,
  useUpdateASubjectMutation
} from "../../../redux/api/syllabusSlice";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";
import { toastError } from "../../../utils/ToastHandler";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "borderRadius": "0.5rem",
  "border": "none",
  "width": "100%",
  "height": "2.25rem",
  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    fontWeight: 600,
    borderRadius: "0.5rem",
    height: "2.25rem"
  },
  "&:hover": {
    borderRadius: "0.5rem !important"
  }
}));

const SupervisorDisplaySyllabus = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const { user } = useSelector(state => state.auth);
  const { data: syllabus, isSuccess: isGetSyllabusSuccess } = useGetEntireSyllabusQuery(user.school_id);

  const theme = useTheme();

  console.log("SYLL: ", syllabus);
  const [createASubject] = useCreateASubjectMutation();
  const [updateASubject] = useUpdateASubjectMutation();
  const [deleteASubject] = useDeleteASubjectMutation();

  const [createASection] = useCreateASectionMutation();
  const [updateASection] = useUpdateASectionMutation();
  const [deleteASection] = useDeleteASectionMutation();

  const [createAChapter] = useCreateAChapterMutation();
  const [updateAChapter] = useUpdateAChapterMutation();
  const [deleteAChapter] = useDeleteAChapterMutation();

  const [showsubjects, setSubjects] = useState([]);
  const [showsections, setSections] = useState([]);

  const [alignment, setAlignment] = useState("");

  const [subjectName, setSubjectName] = useState("");
  const [sectionName, setSectionName] = useState("");
  const [chapterName, setChapterName] = useState("");

  // State variables for updating subject name
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [updatedSubjectName, setUpdatedSubjectName] = useState("");
  const [subjectToUpdate, setSubjectToUpdate] = useState("");

  // State variables for updating section name
  const [openUpdateSectionDialog, setOpenUpdateSectionDialog] = useState(false);
  const [updatedSectionName, setUpdatedSectionName] = useState("");
  const [sectionToUpdate, setSectionToUpdate] = useState("");

  // State variables for updating chapter name
  const [openUpdateChapterDialog, setOpenUpdateChapterDialog] = useState(false);
  const [updatedChapterName, setUpdatedChapterName] = useState("");
  const [chapterToUpdate, setChapterToUpdate] = useState("");

  const [clickedSection, setClickedSectionName] = useState("");

  useEffect(() => {
    document.title = "Insights | Manage Syllabus";
  }, []);

  const handleNameChange = event => {
    setSubjectName(event.target.value);
  };

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleAddSubject = () => {
    const subjectData = {
      name: subjectName,
      school_id: user?.school_id
    };
    createASubject(subjectData);
  };
  const handleCreateSubject = async () => {
    try {
      // For demonstration purposes, just showing a toast with the name
      if (subjectName.trim() === "") {
        return toastError("Please fill all the fields");
      }

      await handleAddSubject(); // Create the subject
    } catch (error) {
      console.error("Error creating subject:", error);
    }
  };

  //update subject
  const handleUpdateSubject = subjectName => {
    setSubjectToUpdate(subjectName);
    setUpdatedSubjectName(subjectName);
    setOpenUpdateDialog(true);
  };

  const handleConfirmUpdateSubject = () => {
    // No need to convert to string if subject names are already strings
    const old_name = subjectToUpdate;
    const new_name = updatedSubjectName;

    updateASubject({ old_name, new_name, school_id: user.school_id });
    setOpenUpdateDialog(false);
  };

  //update done
  const [editingSubject, setEditingSubject] = useState(null);
  const [showEditIcons, setShowEditIcons] = useState(false);

  const handleEditButtonClick = () => {
    setShowEditIcons(!showEditIcons);
    if (!showEditIcons) {
      setEditingSubject(null); // Reset editing subject if icons are hidden
    }
  };

  const handleDeleteSubject = async subjectName => {
    try {
      deleteASubject(subjectName);
    } catch (error) {
      console.error("Error deleting subject:", error);
    }
  };

  const handleSectionNameChange = event => {
    setSectionName(event.target.value);
  };
  const [openAddSectionDialog, setOpenAddSectionDialog] = useState(false);

  const handleOpenAddSectionDialog = () => {
    setOpenAddSectionDialog(true);
  };

  const handleCloseAddSectionDialog = () => {
    setOpenAddSectionDialog(false);
  };

  const handleAddSection = () => {
    const sectionData = {
      name: sectionName,
      school_id: user.school_id,
      alignment: alignment
    };

    createASection(sectionData);
  };
  const handleCreateSection = () => {
    // For demonstration purposes, just showing a toast with the name
    if (sectionName.trim() === "") {
      return toastError("Please fill all the fields");
    }
    handleAddSection();
  };

  const handleDeleteSection = async sectionName => {
    try {
      // Dispatch the action to delete the section
      deleteASection(sectionName);
    } catch (error) {
      console.error("Error deleting section:", error);
    }
  };

  //update section part

  const handleUpdateSection = sectionName => {
    setSectionToUpdate(sectionName);
    setUpdatedSectionName(sectionName);
    setOpenUpdateSectionDialog(true);
  };

  const handleConfirmUpdateSection = () => {
    const old_name = sectionToUpdate;
    const new_name = updatedSectionName;

    updateASection({ old_name, new_name, school_id: user.school_id });
    setOpenUpdateSectionDialog(false);
  };

  const handleChapterNameChange = event => {
    setChapterName(event.target.value);
  };

  const [openAddChapterDialog, setOpenAddChapterDialog] = useState(false);

  const handleOpenAddChapterDialog = ssection => {
    setClickedSectionName(ssection);
    setOpenAddChapterDialog(true);
  };

  const handleCloseAddChapterDialog = () => {
    setOpenAddChapterDialog(false);
  };

  const handleAddChapter = () => {
    const chapterData = {
      name: chapterName,
      school_id: user.school_id,
      alignment: alignment,
      section: clickedSection
    };
    createAChapter(chapterData);
  };

  const handleCreateChapter = () => {
    // For demonstration purposes, just showing a toast with the name
    if (chapterName.trim() === "") {
      return toastError("Please fill all the fields");
    }

    handleAddChapter();
  };

  //update chapter
  const handleUpdateChapter = chapterName => {
    setChapterToUpdate(chapterName);
    setUpdatedChapterName(chapterName);
    setOpenUpdateChapterDialog(true);
  };

  const handleConfirmUpdateChapter = () => {
    const old_name = chapterToUpdate;
    const new_name = updatedChapterName;
    updateAChapter({ old_name, new_name, school_id: user.school_id });
    // Close the dialog
    setOpenUpdateChapterDialog(false);
  };

  //update chapter done

  const handleDeleteChapter = async (sectionName, chapterName) => {
    try {
      // Dispatch the action to delete the chapter
      deleteAChapter(chapterName);
      // setSections(prevSections => {
      //   if (!prevSections || !prevSections[sectionName]) {
      //     return prevSections; // Return the unchanged state if sections or sectionName is undefined
      //   }
      //   const updatedSections = { ...prevSections };
      //   return updatedSections;
      // });
    } catch (error) {
      console.error("Error deleting chapter:", error);
    }
  };

  ///done

  useEffect(() => {
    if (isGetSyllabusSuccess && syllabus) {
      if (Object.keys(syllabus).length) {
        setSubjects(Object.keys(syllabus));
        if (alignment === "") {
          const firstSubject = Object.keys(syllabus)[0];
          setAlignment(firstSubject);
        }
      }
    }
  }, [syllabus]);

  useEffect(() => {
    if (syllabus) {
      if (Object.keys(syllabus).length) {
        if (syllabus[alignment]) {
          if (Object.keys(syllabus[alignment]).length) {
            setSections(Object.keys(syllabus[alignment]));
          } else {
            setSections([]);
          }
        }
      }
    }
  }, [alignment, syllabus]);

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Manage Syllabus", link: "" }
  ];

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      p="1rem"
      flexDirection="column"
      gap="1rem"
      bgcolor="rgb(250,250,251,1)"
    >
      <div style={{ padding: "1rem 0", marginTop: "-1rem" }}>
        <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
      </div>
      <Box width="100%" display="flex" justifyContent="space-between" top="90px" right="10px" zIndex="999">
        <Box>
          <Typography variant="h2" fontWeight={600} marginLeft="1rem">
            Manage Syllabus
          </Typography>
          <Typography variant="h6" marginLeft="1rem" color={theme.palette.primary.light}>
            Manage your syllabus here
          </Typography>
        </Box>
        <CButton
          buttonText="Create Subject"
          variant="primary"
          icon={<Plus weight="bold" color="#fff" size={18} />}
          onClickFun={handleOpenDialog}
        />
      </Box>
      <Box width="100%" p={2}>
        <Grid container spacing={2} justifyContent="center">
          {showsubjects.length ? (
            showsubjects.map(ssubject => (
              <Grid item xs={12} sm={6} md={4} lg={2} key={ssubject}>
                <Button
                  fullWidth
                  variant={alignment === ssubject ? "contained" : "outlined"}
                  onClick={() => handleChange(null, ssubject)}
                  sx={{
                    "textTransform": "none",
                    "borderRadius": "0.5rem",
                    "height": "48px",
                    "justifyContent": "space-between",
                    "padding": "0 16px",
                    "bgcolor": alignment === ssubject ? theme.palette.secondary.main : "#fff",
                    "color": alignment === ssubject ? "#fff" : "#333333",
                    "&:hover": {
                      bgcolor: "#333333",
                      color: "#fff"
                    }
                  }}
                >
                  <span>{ssubject}</span>
                  {showEditIcons && (
                    <Box>
                      <PencilSimple
                        onClick={e => {
                          e.stopPropagation();
                          handleUpdateSubject(ssubject);
                        }}
                        size={18}
                        style={{ cursor: "pointer", marginRight: "8px" }}
                      />
                      <Trash
                        onClick={e => {
                          e.stopPropagation();
                          handleDeleteSubject(ssubject);
                        }}
                        size={18}
                        style={{ cursor: "pointer" }}
                      />
                    </Box>
                  )}
                </Button>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Button fullWidth variant="outlined" disabled>
                No Subjects
              </Button>
            </Grid>
          )}
        </Grid>
        {/* Dialog for updating subject name */}
        <Dialog
          open={openUpdateDialog}
          onClose={() => setOpenUpdateDialog(false)}
          PaperProps={{
            style: {
              width: "500px", // Adjust width as needed
              height: "300px" // Adjust height as needed
            }
          }}
        >
          <DialogTitle sx={{ fontSize: "24px", paddingBottom: "3px" }}>Update Subject Name</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ fontSize: "18px", paddingBottom: "15px" }}>
              Enter the updated name for the subject:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="updatedSubjectName"
              label="Updated Name"
              fullWidth
              variant="standard"
              value={updatedSubjectName}
              onChange={e => setUpdatedSubjectName(e.target.value)}
              sx={{
                "& .MuiInputBase-root": {
                  fontSize: "16px",
                  backgroundColor: "#fff"
                },
                "& .MuiInputLabel-root": {
                  fontSize: "15px"
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenUpdateDialog(false)}
              variant="contained"
              color="primary"
              sx={{
                "height": "44px",
                "width": "98px",
                "borderRadius": "7px",
                "bgcolor": "#fff",
                "opacity": 0.7,
                "color": "black",
                "&:hover": {
                  bgcolor: "#333333",
                  opacity: 0.7
                }
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmUpdateSubject}
              variant="contained"
              color="primary"
              sx={{
                "height": "44px",
                "width": "98px",
                "borderRadius": "7px",
                "bgcolor": "#1976d2", // Blue primary color
                "color": "white",
                "&:hover": {
                  bgcolor: "#1565c0" // Darker shade of blue on hover
                }
              }}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Box width="100%" display="flex" alignItems="center" justifyContent="flex-end" gap="1rem">
        <button
          onClick={handleEditButtonClick}
          style={{
            cursor: "pointer",
            border: "1px solid #E6EDFF", // Border color primary blue
            borderRadius: "5px", // Border radius
            backgroundColor: "#3267FF", // Primary blue background color
            color: "#ffffff", // Text color
            padding: "8px 16px", // Padding
            zIndex: 999,
            width: "80px"
          }}
          type="button"
        >
          {/* Toggle between "Edit" and "Close" text */}
          {showEditIcons ? "Close" : "Edit"}
        </button>
        <button
          onClick={handleOpenAddSectionDialog}
          style={{
            cursor: "pointer",
            border: "1px solid #E6EDFF", // Border color primary blue
            borderRadius: "5px", // Border radius
            backgroundColor: "#3267FF", // Primary blue background color
            color: "#ffffff", // Text color
            padding: "8px 16px", // Padding
            zIndex: 999
          }}
          type="button"
        >
          Add Sections
        </button>
      </Box>
      <Box>
        {showsections.length ? (
          showsections.map((ssection, index) => (
            <Box key={ssection} marginBottom="1rem">
              <Accordion
                key={ssection}
                sx={{
                  border: "1px solid #FFFFFF", // Add border
                  borderRadius: "1rem", // Add border radius
                  boxShadow: "none" // Remove the shadow
                }}
              >
                <AccordionSummary
                  expandIcon={<CaretDown />}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                  sx={{
                    color: "black",
                    bgcolor: "white",
                    fontWeight: 600,
                    position: "relative" // Add relative positioning
                  }}
                >
                  {/* Trash icon */}
                  {showEditIcons && (
                    <div>
                      {/* Trash icon */}
                      <Trash
                        onClick={() => handleDeleteSection(ssection)}
                        size={18}
                        style={{
                          position: "absolute",
                          right: "90px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer"
                        }}
                      />
                      {/* PencilSimple icon */}
                      <PencilSimple
                        onClick={() => handleUpdateSection(ssection)}
                        size={18}
                        style={{
                          position: "absolute",
                          right: "130px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer"
                        }}
                      />
                    </div>
                  )}
                  {/* Section title */}
                  <ChevronRightIcon />
                  <Typography sx={{ fontSize: "15px", fontWeight: 550 }}>
                    {`Section ${index + 1}: ${ssection}`}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Box width="100%" display="flex" p="0.5rem" flexDirection="column" gap="0.5rem">
                    <Box width="100%" display="flex" alignItems="center" justifyContent="flex-end">
                      <Link component="button" onClick={() => handleOpenAddChapterDialog(ssection)}>
                        Add Chapters
                      </Link>
                    </Box>
                    <AccordionDetails
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column"
                      }}
                    >
                      {/* Render chapters as separate blue boxes */}
                      {syllabus && syllabus[alignment] && syllabus[alignment][ssection] ? (
                        syllabus[alignment][ssection].map((chapter, chapterIndex) => (
                          <Box
                            key={chapterIndex}
                            marginBottom="0.5rem"
                            sx={{
                              width: "100%",
                              padding: "13px", // Adjust the padding as needed
                              bgcolor: "#D0DDFF", // Blue color
                              borderRadius: "8px", // Adjust the border radius as needed
                              display: "flex", // Add flex display
                              alignItems: "center", // Center items vertically
                              position: "relative" // Add relative positioning
                            }}
                          >
                            {/* Chapter title */}
                            <ChevronRightIcon />
                            <Typography
                              sx={{
                                flex: "1", // Take up remaining space
                                fontFamily: "Helvetica, sans-serif",
                                color: "#00000",
                                fontSize: "15px",
                                fontWeight: 550
                              }}
                            >
                              {`Chapter ${chapterIndex + 1}: ${chapter}`}
                            </Typography>

                            {/* Edit and delete icons */}
                            {showEditIcons && (
                              <div style={{ marginRight: "40px" }}>
                                {/* Trash icon */}
                                <PencilSimple
                                  onClick={() => handleUpdateChapter(chapter)}
                                  size={18}
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "20px"
                                  }}
                                />
                                <Trash
                                  onClick={() => handleDeleteChapter(ssection, chapter)}
                                  size={18}
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "20px"
                                  }}
                                />
                                {/* PencilSimple icon */}
                              </div>
                            )}
                          </Box>
                        ))
                      ) : (
                        <Typography variant="body1" sx={{ fontFamily: "Arial, sans-serif", alignSelf: "center" }}>
                          No Sections
                        </Typography>
                      )}
                      <Dialog
                        open={openUpdateChapterDialog}
                        onClose={() => setOpenUpdateChapterDialog(false)}
                        PaperProps={{
                          style: {
                            width: "500px",
                            height: "300px"
                          }
                        }}
                      >
                        <DialogTitle sx={{ fontSize: "24px", paddingBottom: "3px" }}>Update Chapter Name</DialogTitle>
                        <DialogContent>
                          <DialogContentText sx={{ fontSize: "18px", paddingBottom: "15px" }}>
                            Enter the updated name for the chapter:
                          </DialogContentText>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="updatedChapterName"
                            label="Updated Name"
                            fullWidth
                            variant="standard"
                            value={updatedChapterName}
                            onChange={e => setUpdatedChapterName(e.target.value)}
                            sx={{
                              "& .MuiInputBase-root": {
                                fontSize: "16px",
                                backgroundColor: "#fff"
                              },
                              "& .MuiInputLabel-root": {
                                fontSize: "15px"
                              }
                            }}
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => setOpenUpdateChapterDialog(false)}
                            variant="contained"
                            color="primary"
                            sx={{
                              "height": "44px",
                              "width": "98px",
                              "borderRadius": "7px",
                              "bgcolor": "#fff",
                              "opacity": 0.7,
                              "color": "black",
                              "&:hover": {
                                bgcolor: "#333333",
                                opacity: 0.7
                              }
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={handleConfirmUpdateChapter}
                            variant="contained"
                            color="primary"
                            sx={{
                              "height": "44px",
                              "width": "98px",
                              "borderRadius": "7px",
                              "bgcolor": "#1976d2", // Blue primary color
                              "color": "white",
                              "&:hover": {
                                bgcolor: "#1565c0" // Darker shade of blue on hover
                              }
                            }}
                          >
                            Update
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </AccordionDetails>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          ))
        ) : (
          <Typography sx={{ alignSelf: "center" }}>Sections not found</Typography>
        )}

        {/* dialog box to add sections */}
        <Dialog
          open={openUpdateSectionDialog}
          onClose={() => setOpenUpdateSectionDialog(false)}
          PaperProps={{
            style: {
              width: "500px",
              height: "300px"
            }
          }}
        >
          <DialogTitle sx={{ fontSize: "24px", paddingBottom: "3px" }}>Update Section Name</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ fontSize: "18px", paddingBottom: "15px" }}>
              Enter the updated name for the section:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="updatedSectionName"
              label="Updated Name"
              fullWidth
              variant="standard"
              value={updatedSectionName}
              onChange={e => setUpdatedSectionName(e.target.value)}
              sx={{
                "& .MuiInputBase-root": {
                  fontSize: "16px",
                  backgroundColor: "#fff"
                },
                "& .MuiInputLabel-root": {
                  fontSize: "15px"
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenUpdateSectionDialog(false)}
              variant="contained"
              color="primary"
              sx={{
                "height": "44px",
                "width": "98px",
                "borderRadius": "7px",
                "bgcolor": "#fff",
                "opacity": 0.7,
                "color": "black",
                "&:hover": {
                  bgcolor: "#333333",
                  opacity: 0.7
                }
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmUpdateSection}
              variant="contained"
              color="primary"
              sx={{
                "height": "44px",
                "width": "98px",
                "borderRadius": "7px",
                "bgcolor": "#1976d2", // Blue primary color
                "color": "white",
                "&:hover": {
                  bgcolor: "#1565c0" // Darker shade of blue on hover
                }
              }}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            width: "500px", // Adjust width as needed
            height: "300px" // Adjust height as needed
          }
        }}
      >
        <DialogTitle sx={{ fontSize: "24px", paddingBottom: "3px" }}>Create New Subject</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "18px", paddingBottom: "15px" }}>
            Please enter the name of the subject:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={subjectName}
            onChange={handleNameChange}
            sx={{
              "& .MuiInputBase-root": {
                fontSize: "16px",
                backgroundColor: "#fff"
              },
              "& .MuiInputLabel-root": {
                fontSize: "15px"
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCloseDialog}
            color="primary"
            sx={{
              "height": "44px", // Adjust the height
              "width": "98px",
              "borderRadius": "7px",
              "bgcolor": "#fff", // Set initial background color to black
              "opacity": 0.7, // Decrease opacity
              "color": "black",
              "&:hover": {
                bgcolor: "#333333", // Change background color to slight black on hover
                opacity: 0.7 // Increase opacity on hover
              }
            }}
          >
            Cancel
          </Button>

          <CButton
            buttonText="Create"
            variant="primary"
            onClickFun={() => {
              handleCreateSubject();
              handleCloseDialog();
            }}
          />
        </DialogActions>
      </Dialog>

      {/* section starts here */}
      <Dialog
        open={openAddSectionDialog}
        onClose={handleCloseAddSectionDialog}
        PaperProps={{
          style: {
            width: "500px", // Adjust width as needed
            height: "300px" // Adjust height as needed
          }
        }}
      >
        <DialogTitle sx={{ fontSize: "24px", paddingBottom: "3px" }}>Create New Section</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "18px", paddingBottom: "15px" }}>
            Please enter the name of the section:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={sectionName} // Connect value to the name state
            onChange={handleSectionNameChange}
            sx={{
              // Adjust styles here
              "& .MuiInputBase-root": {
                // Modify input base styles
                fontSize: "16px", // Adjust font size
                backgroundColor: "#fff" // Adjust background color
              },
              "& .MuiInputLabel-root": {
                // Modify input label styles
                fontSize: "15px" // Adjust font size
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCloseAddSectionDialog}
            color="primary"
            sx={{
              "height": "44px", // Adjust the height
              "width": "98px",
              "borderRadius": "7px",
              "bgcolor": "#fff", // Set initial background color to black
              "opacity": 0.7, // Decrease opacity
              "color": "black",
              "&:hover": {
                bgcolor: "#333333", // Change background color to slight black on hover
                opacity: 0.8 // Increase opacity on hover
              }
            }}
          >
            Cancel
          </Button>
          <CButton
            buttonText="Create"
            variant="primary"
            onClickFun={() => {
              handleCreateSection();
              handleCloseAddSectionDialog();
            }}
          />
        </DialogActions>
      </Dialog>

      {/* chapter present here  */}
      <Dialog
        open={openAddChapterDialog}
        onClose={handleCloseAddChapterDialog}
        PaperProps={{
          style: {
            width: "500px", // Adjust width as needed
            height: "300px" // Adjust height as needed
          }
        }}
      >
        <DialogTitle sx={{ fontSize: "24px", paddingBottom: "3px" }}>Create New Chapter</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "18px", paddingBottom: "15px" }}>
            Please enter the name of the chapter:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={chapterName} // Connect value to the name state
            onChange={handleChapterNameChange}
            sx={{
              // Adjust styles here
              "& .MuiInputBase-root": {
                // Modify input base styles
                fontSize: "16px", // Adjust font size
                backgroundColor: "#fff" // Adjust background color
              },
              "& .MuiInputLabel-root": {
                // Modify input label styles
                fontSize: "15px" // Adjust font size
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCloseAddChapterDialog}
            color="primary"
            sx={{
              "height": "44px",
              "width": "98px",
              "borderRadius": "7px",
              "bgcolor": "#fff",
              "opacity": 0.7,
              "color": "black",
              "&:hover": {
                bgcolor: "#333333",
                opacity: 0.7
              }
            }}
          >
            Cancel
          </Button>
          <CButton
            buttonText="Create"
            variant="primary"
            onClickFun={() => {
              handleCreateChapter();
              handleCloseAddChapterDialog();
            }}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SupervisorDisplaySyllabus;
