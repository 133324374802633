import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";
import OverAllStats from "../../../components/Teacher/ExamAnalysis/OverAllStats";
import Choice from "../../../components/Teacher/Tabs/Option";
import { useGetAllAnalysisQuery } from "../../../redux/api/analysisSlice";

const TestAnalysis = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const { analysisId } = useParams();
  const [examId, setExamId] = useState("");
  const [selectedValue, setSelectedValue] = useState("Batch1");

  const handleChange = event => {
    setSelectedValue(event.target.value);
  };

  const theme = useTheme();
  const { user } = useSelector(state => state.auth);
  const { data: analysisArray, isSuccess: isGetAnalysisSuccess } = useGetAllAnalysisQuery(user.school_id);
  const [analysis, setAnalysis] = useState({});

  useEffect(() => {
    if (isGetAnalysisSuccess) {
      const selectedAnalysis = analysisArray?.analysisArray?.find(item => item._id === analysisId);
      setExamId(selectedAnalysis?.exam_id);
      setAnalysis(selectedAnalysis || {});
    }
  }, [analysisArray, analysisId]);

  useEffect(() => {
    document.title = "Insights | Test Analysis";
  }, []);

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Test History", link: "/test-history" },
    { name: `${analysis.exam_for || "..."}-${analysis.exam_unique_code || "..."}`, link: "#" }
  ];
  return (
    <Box
      padding={isNonMobile ? "1rem 2rem" : "1rem"}
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <div style={{ padding: "1rem 0" }}>
        <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
      </div>
      <Box display="flex" gap="0.75rem" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography variant={isNonMobile ? "h2" : "h3"} fontWeight={600}>
            Exam Analysis
          </Typography>
          <Typography variant="subtitle1" color={theme.palette.primary.light}>
            Know about the progress report of your class
          </Typography>
        </Box>
      </Box>
      <OverAllStats analysis={analysis} />
      <Choice analysis={analysis} examId={examId} />
    </Box>
  );
};

export default TestAnalysis;
