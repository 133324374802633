import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Check, Pencil } from "@phosphor-icons/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useCreateAQuestionMutation } from "../../../../redux/api/examSlice";
import CButton from "../../../ui/Button";
import IndividualQuestion from "./IndividualQuestion";

const StyledInput = styled(TextField)(({ theme }) => ({
  width: "70px",
  padding: "5px",
  borderRadius: "5px"
}));

const CustomSubjectPanel = ({
  totalQuestions,
  subject,
  allQuestions,
  questionsRemaining,
  subjectQuestions,
  updateSubjectQuestions,
  startNumber,
  numQuestions,
  setNumQuestions
}) => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px) and (min-height:600px) and (orientation: landscape)");

  const { user } = useSelector(state => state.auth);
  const { examInEdit } = useSelector(state => state.exam);

  const [createAQuestion] = useCreateAQuestionMutation();
  const [isEdit, setIsEdit] = useState(false);
  const [queSelected, setQueSelected] = useState(startNumber);
  const [questionInEdit, setQuestionInEdit] = useState(null);
  const [alignment, setAlignment] = useState("");
  const [posMarks, setPosMarks] = useState(0);
  const [negMarks, setNegMarks] = useState(0);

  const data = useMemo(() => {
    return allQuestions ? allQuestions.filter(question => question.subject === subject) : [];
  }, [allQuestions, subject]);

  useEffect(() => {
    handleSelectQuestion(startNumber, true);
  }, [startNumber]);

  useEffect(() => {
    if (questionInEdit) {
      setAlignment(questionInEdit.answer || "");
      setPosMarks(questionInEdit.pos_marks || 0);
      setNegMarks(questionInEdit.neg_marks || 0);
    }
  }, [questionInEdit]);

  const handleSelectQuestion = async (questionNum, fromUseEffect = false, questionsBySubject = data) => {
    if (!fromUseEffect) {
      await handleQuestionSubmit();
    }
    const que = questionsBySubject.find(q => q.question_no === questionNum);
    setQuestionInEdit(
      que || {
        subject,
        section: "",
        chapter: "",
        question_no: questionNum,
        answer: "",
        difficulty: "",
        pos_marks: 0,
        neg_marks: 0,
        school_id: user.school_id,
        exam_unique_code: examInEdit?.unique_code,
        id: ""
      }
    );
    setQueSelected(questionNum);
  };

  const handleNumQuestionsChange = e => {
    const value = parseInt(e.target.value, 10);
    if (!Number.isNaN(value) && value >= 0 && value <= questionsRemaining + numQuestions) {
      setNumQuestions(value);
      updateSubjectQuestions(subject, value);
      setQueSelected(startNumber);
      handleSelectQuestion(startNumber, true);
    }
  };

  const handleQuestionSubmit = useCallback(async () => {
    if (!questionInEdit?.section || !questionInEdit?.chapter || !questionInEdit?.difficulty || !alignment) {
      toast.error("Please fill all fields");
      return;
    }
    const updatedQuestion = {
      ...questionInEdit,
      pos_marks: posMarks,
      neg_marks: negMarks,
      answer: alignment
    };
    try {
      await createAQuestion(updatedQuestion).unwrap();
    } catch (error) {
      toast.error("Error saving question");
    }
  }, [alignment, createAQuestion, negMarks, posMarks, questionInEdit]);

  const handleNextQuestion = () => handleSelectQuestion(queSelected + 1);
  const handlePrevQuestion = () => handleSelectQuestion(queSelected - 1);

  return (
    <Box width="100%" display="flex" gap="1rem" flexDirection={isNonMobile ? "row" : "column"}>
      <Box
        width={isNonMobile ? "70%" : "100%"}
        sx={{
          border: "1px solid rgba(0,0,0,0.09)",
          borderRadius: "1rem",
          padding: "1rem",
          order: isNonMobile ? 0 : 1
        }}
        display="flex"
        flexDirection="column"
        gap="1rem"
      >
        {numQuestions ? (
          <>
            <IndividualQuestion
              question={questionInEdit}
              setQuestionInEdit={setQuestionInEdit}
              alignment={alignment}
              setAlignment={setAlignment}
              subject={subject}
              posMarks={posMarks}
              setPosMarks={setPosMarks}
              negMarks={negMarks}
              setNegMarks={setNegMarks}
              handleQuestionSubmit={handleQuestionSubmit}
              queSelected={queSelected}
              setQueSelected={setQueSelected}
            />
            <Box width="100%" display="flex" justifyContent="space-between">
              <CButton
                sx={{
                  width: "max-content",
                  backgroundColor: "rgba(0,0,0,0.1)",
                  color: "black"
                }}
                buttonText={"Prev"}
                variant="primary"
                onClick={handlePrevQuestion}
              />
              <CButton
                sx={{ width: "max-content" }}
                buttonText={"Save and Next"}
                variant="primary"
                onClick={handleNextQuestion}
              />
            </Box>
          </>
        ) : (
          <Typography variant="h5">No questions to display</Typography>
        )}
      </Box>
      <Box
        width={isNonMobile ? "30%" : "100%"}
        sx={{
          border: "1px solid rgba(0,0,0,0.09)",
          borderRadius: "1rem",
          padding: "1rem"
        }}
      >
        <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
          <Typography variant="h6" mb="0.2rem">
            Number of questions (Remaining: {questionsRemaining})
          </Typography>
          <Divider sx={{ width: "100%" }} />
          <FormControl>
            <TextField
              placeholder="Enter number of questions"
              value={numQuestions}
              onChange={handleNumQuestionsChange}
              type="number"
              sx={{
                width: "100%",
                bgcolor: "rgba(0,0,0,0.03)"
              }}
              InputProps={{
                inputProps: {
                  style: { color: theme.palette.secondary.main }
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <Button variant="ghost" onClick={() => setIsEdit(!isEdit)}>
                      {isEdit ? <Check size={22} /> : <Pencil size={22} />}
                    </Button>
                  </InputAdornment>
                )
              }}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  setIsEdit(false);
                }
              }}
              disabled={!isEdit}
            />
          </FormControl>
        </Box>
        <Box
          width="100%"
          sx={{
            overflowX: "auto"
          }}
        >
          <Grid
            container
            spacing={1}
            paddingTop="1rem"
            sx={{
              flexWrap: isNonMobile ? "wrap" : "nowrap",
              width: isNonMobile || !numQuestions ? "100%" : `${numQuestions * 60}px`
            }}
          >
            {numQuestions ? (
              Array.from({ length: numQuestions }).map((_, index) => {
                const questionNum = startNumber + index;
                const question = allQuestions ? allQuestions.find(que => que.question_no === questionNum) : 1;
                const isSelected = queSelected === questionNum;
                const backgroundColor = question?.id ? "lightgreen" : "lightcoral";
                const borderColor = isSelected ? "blue" : "transparent";

                return (
                  <Grid item key={index} xs={isNonMobile ? "auto" : 12} sm={6} md={5} xl={2}>
                    <Box
                      sx={{
                        padding: "0.5rem",
                        border: `2px solid ${isSelected ? borderColor : "rgba(0, 0, 0, 0.07)"}`,
                        width: isNonMobile ? "50px" : "100%",
                        height: isNonMobile ? "50px" : "auto",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        backgroundColor: isSelected ? "lightgray" : backgroundColor
                      }}
                      onClick={() => handleSelectQuestion(questionNum)}
                    >
                      {questionNum}
                    </Box>
                  </Grid>
                );
              })
            ) : (
              <Typography p="1rem" variant="h6">
                Please set number of questions
              </Typography>
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomSubjectPanel;
