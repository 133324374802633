import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ResultStepperEdit from "../../../components/Supervisor/Results/EditResults/ResultStepperEdit";
import { useGetResultByIdQuery } from "../../../redux/api/resultsSlice";
import { setResultInEdit } from "../../../redux/features/results/resultSlice";

const EditResults = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const { result_id } = useParams();
  const [resultById, setResultById] = useState({});
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);

  console.log("PARAM: ", result_id);

  const { data: result, isSuccess: isGetResultSuccess } = useGetResultByIdQuery(result_id);

  console.log("RES: ", result);

  useEffect(() => {
    isGetResultSuccess ? setResultById(result?.result) : {};
    dispatch(setResultInEdit(result?.result));
  }, [result_id, result]);

  const theme = useTheme();

  return (
    <Box
      padding={isNonMobile ? "1rem 2rem" : "1rem 0.75rem"}
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      {Object.keys(resultById)?.length ? (
        <>
          <Box display="flex" gap="0.75rem" flexDirection="column">
            <Typography variant="h2" fontWeight={600}>
              Edit Results
            </Typography>
            <Typography variant="h6" color={theme.palette.primary.light}>
              Pick up from where you left off!
            </Typography>
          </Box>
          <Box width="100%" borderRadius="1rem" bgcolor="#fff" border="1px solid #E6EDFF" p="1rem">
            <ResultStepperEdit resultById={resultById} />
          </Box>
        </>
      ) : (
        <Box>Loading...</Box>
      )}
    </Box>
  );
};

export default EditResults;
