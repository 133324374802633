import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import ResultStepper from "../../components/Supervisor/Results/CreateResults/ResultStepper";
import BreadcrumbsComp from "../../utils/Breadcrumbs";

const CreateResults = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  // theme
  const theme = useTheme();
  useEffect(() => {
    document.title = "Insights | Create Result";
  }, []);

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Create Result", link: "" }
  ];

  return (
    <Box
      padding={isNonMobile ? "1rem 2rem" : "1rem 0.75rem"}
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <div style={{ padding: "1rem 0", marginTop: "-1rem" }}>
        <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
      </div>
      <Box display="flex" gap="0.75rem" flexDirection="column">
        <Typography variant="h2" fontWeight={600}>
          Create Result
        </Typography>
        <Typography variant="h6" color={theme.palette.primary.light}>
          Fill the options below to create a new result
        </Typography>
      </Box>
      <Box overflow="hidden" width="100%" borderRadius="1rem" bgcolor="#fff" border="1px solid #E6EDFF" p="0.4rem">
        <ResultStepper />
      </Box>
    </Box>
  );
};

export default CreateResults;
