import React from "react";
import ReactPlayer from "react-player";
import { Box, Typography } from "@mui/material";

const VideoPlayer = ({ videoUrl }) => {
  return (
    <Box sx={{ padding: "20px", width: "100%", height: "100%" }}>
      <ReactPlayer
        config={{ file: { attributes: { controlsList: "nodownload" } } }}
        url={videoUrl}
        controls
        width="100%"
        height="100%"
      />
    </Box>
  );
};

export default VideoPlayer;
