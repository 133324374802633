import { Box, Button, Card, CardContent, Grid, Menu, MenuItem, Typography, useMediaQuery } from "@mui/material";
import { DotsThreeOutlineVertical, Star } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  useGetBatchesByFacultyQuery,
  useGetBatchesByStudentQuery,
  useGetBatchesQuery
} from "../../../redux/api/batchSlice";
import { BatchCover1, BatchCover2, BatchCover3, BatchCover4, BatchCover5, BatchCover6 } from "../../../Assets";

const colorMap = {
  JEE: "#2E3AC4",
  NEET: "#20B058",
  KCET: "#EA1819",
  Boards: "#888888",
  Custom: "#F48C06"
};
const batchCoverImages = [BatchCover1, BatchCover2, BatchCover3, BatchCover4, BatchCover5, BatchCover6];

const MyBatches = ({ who }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const navigate = useNavigate();

  const { user } = useSelector(state => state.auth);

  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const { data: batchData, isSuccess } =
    who === "student"
      ? useGetBatchesByStudentQuery({
          schoolId: user.school_id,
          studentId: user._id
        })
      : useGetBatchesByFacultyQuery({
          school_id: user.school_id,
          faculty_id: user._id
        });

  useEffect(() => {
    if (isSuccess && batchData && batchData.batches) {
      const batchArray = batchData.batches.map((batch, index) => ({
        id: batch._id,
        sr_no: index + 1,
        name: batch.batch_name,
        tag: batch.batch_tag,
        description: batch.batch_description,
        num_students: batch.students?.length,
        num_faculty: batch.faculties?.length,
        created_at: Date.parse(batch.createdAt)
      }));

      // Sort batches by created_at date in descending order (newest first)
      batchArray.sort((a, b) => b.created_at - a.created_at);

      setData(batchArray);
    }
  }, [batchData]);

  useEffect(() => {
    setFilteredData(data || []);
  }, [data]);

  return (
    <Box width="100%" mt="1rem" display="flex" flexDirection="column" gap="0.75rem">
      <Box
        width="100%"
        display="flex"
        flexDirection={isNonMobile ? "row" : "column"}
        justifyContent="space-between"
        alignItems={isNonMobile ? "center" : "start"}
        gap="1rem"
      >
        <Typography variant="h4" fontWeight={600}>
          My Batches
        </Typography>
      </Box>
      <Grid container spacing={2} paddingTop="1rem">
        {isSuccess ? (
          filteredData?.length ? (
            filteredData.slice(0, 4)?.map((batch, index) => (
              <Grid item key={batch.id} xs={12} sm={6} md={4} xl={3}>
                <Card
                  sx={{
                    "borderRadius": "1rem",
                    "&:hover": { backgroundColor: "rgba(0,0,0,0.04)", transition: "ease-in 200ms" },
                    "height": "100%",
                    "border": "1px solid #E6EDFF"
                  }}
                >
                  <CardContent sx={{ display: "flex", flexDirection: "column", gap: "0.5rem", pb: "1rem !important" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: "auto",
                        position: "relative",
                        overflow: "hidden",
                        marginBottom: "10px"
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          paddingTop: "56.25%", // 16:9 aspect ratio
                          position: "relative"
                        }}
                        onClick={() => navigate(`/view-batch/${batch.id}`)}
                      >
                        <img
                          src={batchCoverImages[index % batchCoverImages.length]}
                          alt="Batch Cover"
                          style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            objectFit: "cover",
                            borderRadius: "1rem"
                          }}
                        />
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                      <Typography variant="h4" color="textPrimary" style={{ fontWeight: 500 }}>
                        {batch.name}
                      </Typography>
                      <Box display="flex" gap="1rem" alignItems="center">
                        <div
                          style={{
                            padding: "1px 10px",
                            borderRadius: "1rem",
                            backgroundColor: colorMap[batch.tag] || "orange",
                            color: "#fff",
                            fontWeight: 600
                          }}
                        >
                          {batch.tag}
                        </div>
                      </Box>
                    </Box>
                    {/* Description */}
                    <Box gap="0.5rem" display="flex" alignItems="center">
                      <Typography variant="h6" color="textSecondary" fontWeight={400} fontSize={12}>
                        {batch?.num_students} students
                      </Typography>
                      <hr style={{ height: "20px" }} />
                      <Typography variant="h6" color="textSecondary" fontWeight={400} fontSize={12}>
                        {batch?.num_faculty} faculty
                      </Typography>
                      <hr style={{ height: "20px" }} />
                      <Typography variant="h6" color="textSecondary" fontWeight={400} fontSize={12}>
                        Created on {new Date(batch?.created_at).toLocaleDateString()}
                        {batch.is_fav}
                      </Typography>
                    </Box>
                    {/* <Button
                    component={Link}
                    to={`/view-batch/${batch.id}`}
                    size="small"
                    color="primary"
                    style={{
                      fontSize: "14px",
                      fontWeight: "1000",
                      marginLeft: "-4px"
                    }}
                  >
                    View Details
                  </Button> */}
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Box p="1rem 2rem">No batches to display</Box>
          )
        ) : (
          <>Loading...</>
        )}
      </Grid>
      <Box display="flex" flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <Box
          width="85%"
          sx={{
            background: "linear-gradient(to bottom, rgba(0,0,0,0), white)",
            height: "5rem",
            transform: "translateY(-70%)",
            position: "absolute",
            opacity: "1"
          }}
        />
        <Typography
          width="100%"
          textAlign="center"
          sx={{
            "zIndex": "1000",
            "color": "blue",
            "textDecoration": "underline",
            "&:hover": { cursor: "pointer" }
          }}
          variant="h5"
        >
          <Link to="/view-batch">View More</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default MyBatches;
