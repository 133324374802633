// inbuilt modules
import React, { useEffect } from "react";

// external modules
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// internal modules
import TotalStats from "../../../components/Teacher/Dashboard/Totalstats";
import TestHistoryTeacher from "../../../components/Teacher/TestHistory/test";

import BoardResultsBanner from "../../../components/Teacher/Dashboard/BoardResultsBanner";
import TeacherBanner from "../../../components/Teacher/Dashboard/TeacherBanner";
import DashboardDisplay from "../../../components/Teacher/ManageBatch/DashboardDisplay";

const TeacherDashboard = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  // hooks
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    document.title = "Insights | Dashboard";
  }, []);

  // redux
  // render
  return (
    <Box
      padding={isNonMobile ? "1rem 2rem" : "1rem 1.25rem"}
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <Box display="flex" flexDirection="column">
        <Typography variant={isNonMobile ? "h2" : "h3"} fontWeight={600}>
          Welcome Back, {user.name}
        </Typography>
        <Typography variant="subtitle1" color={theme.palette.primary.light}>
          Here you can get all the information of your institute
        </Typography>
      </Box>
      <TotalStats />
      <TeacherBanner />
      {/* <LineGraph /> */}
      <Box display={"flex"} gap={"2rem"}>
        {/* <TopPerformersTeacher /> */}
        <TestHistoryTeacher />
      </Box>
      <DashboardDisplay />
      <BoardResultsBanner />
    </Box>
  );
};

// render
export default TeacherDashboard;
