import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import toast from "react-hot-toast";

export const taskApiSlice = createApi({
  reducerPath: "taskApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/task`,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["Sections", "Lessons", "Files"],
  endpoints: builder => ({
    createATaskSection: builder.mutation({
      query: task => ({
        url: "/section",
        method: "POST",
        body: { task }
      }),
      invalidatesTags: ["Sections"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Creating Section in Course...", { id: "createTaskSection" });
          await queryFulfilled;
          toast.success("Section in Course Created Successfully", { id: "createTaskSection" });
        } catch ({ error }) {
          console.log(error);
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "createTaskSection" });
        }
      }
    }),
    createALesson: builder.mutation({
      query: task => ({
        url: "/lesson",
        method: "POST",
        body: task
      }),
      invalidatesTags: ["Lessons"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Creating Lesson in Course...", { id: "createTaskLesson" });
          await queryFulfilled;
          toast.success("Creating Lesson in  Course Successfull", { id: "createTaskLesson" });
        } catch (error) {
          console.log({ error });
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "createTaskLesson" });
        }
      }
    }),
    getASections: builder.query({
      query: ({ batchId, sectionId }) => ({
        url: `/atasksection/${batchId}`,
        method: "GET",
        params: { section_id: sectionId }
      }),
      providesTags: ["Sections"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          console.log(error);
        }
      }
    }),
    getAllSections: builder.query({
      query: ({ schoolId, batchId }) => ({
        url: `/alltasksections/${schoolId}/${batchId}`
      }),
      providesTags: ["Sections"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          console.log(error);
        }
      }
    }),
    createATest: builder.mutation({
      query: task => ({
        url: "/test",
        method: "POST",
        body: task
      }),
      invalidatesTags: ["Sections"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Creating Test in Course...", { id: "createTest" });
          await queryFulfilled;
          toast.success("Test in Course Created Successfully", { id: "createTest" });
        } catch ({ error }) {
          console.log(error);
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "createTest" });
        }
      }
    }),
    updateAdescription: builder.mutation({
      query: ({ description, section_id }) => ({
        url: "/update-description",
        method: "POST",
        body: { description, section_id }
      }),
      invalidatesTags: ["Sections"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Updating...", { id: "updateToast" });
          await queryFulfilled;
          toast.success("Description updated successfully", { id: "updateToast" });
        } catch ({ error }) {
          console.error("Error updating description:", error);
          toast.error("Error updating description", { id: "updateToast" });
        }
      }
    }),
    deleteSection: builder.mutation({
      query: details => ({
        url: "/delete-section",
        method: "POST",
        body: details
      }),
      invalidatesTags: ["Sections", "Files"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Deleting...", { id: "deleteToast" });
          await queryFulfilled;
          toast.success("Section deleted successfully", { id: "deleteToast" });
        } catch ({ error }) {
          console.error("Error deleting section:", error);
          toast.error("Error deleting section", { id: "deleteToast" });
        }
      }
    }),
    fetchSectionFiles: builder.query({
      query: ({ schoolId, batchId }) => ({
        url: `/files/${schoolId}/${batchId}`
      }),
      providesTags: ["Files"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          console.log(error);
          toast.error("Error fetching section files");
        }
      }
    }),
    uploadFile: builder.mutation({
      query: ({ formData }) => ({
        url: "/files-upload",
        method: "POST",
        body: formData
      }),
      invalidatesTags: ["Files", "Sections"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Uploading...", { id: "uploadToast" });
          await queryFulfilled;
          toast.success("File uploaded successfully", { id: "uploadToast" });
        } catch (error) {
          console.error("Error uploading file:", error);
          toast.error("Error uploading file", { id: "uploadToast" });
        }
      }
    }),
    deleteFile: builder.mutation({
      query: details => ({
        url: "/delete-file",
        method: "POST",
        body: details
      }),
      invalidatesTags: ["Files"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Deleting...", { id: "deleteToast" });
          await queryFulfilled;
          toast.success("File deleted successfully", { id: "deleteToast" });
        } catch (error) {
          console.error("Error deleting file:", error);
          toast.error("Error deleting file", { id: "deleteToast" });
        }
      }
    }),
    uploadLink: builder.mutation({
      query: details => ({
        url: "/upload-link",
        method: "POST",
        body: details
      }),
      invalidatesTags: ["Sections"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Uploading...", { id: "uploadToast" });
          await queryFulfilled;
          toast.success("Link uploaded Successfully ", { id: "uploadToast" });
        } catch ({ error }) {
          console.error("Error Uploading link:", error);
          toast.error("Error Uploading link", { id: "uploadToast" });
        }
      }
    }),
    deleteLink: builder.mutation({
      query: details => ({
        url: "/delete-link",
        method: "POST",
        body: details
      }),
      invalidatesTags: ["Sections"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Deleting...", { id: "uploadToast" });
          await queryFulfilled;
          toast.success("Link deleted Successfully ", { id: "uploadToast" });
        } catch ({ error }) {
          console.error("Error deleted link:", error);
          toast.error("Error deleted link", { id: "uploadToast" });
        }
      }
    })
  })
});

export const {
  useCreateALessonMutation,
  useCreateATaskSectionMutation,
  useGetASectionsQuery,
  useGetAllSectionsQuery,
  useCreateATestMutation,
  useUpdateAdescriptionMutation,
  useDeleteSectionMutation,
  useFetchSectionFilesQuery,
  useUploadFileMutation,
  useDeleteFileMutation,
  useUploadLinkMutation,
  useDeleteLinkMutation
} = taskApiSlice;
