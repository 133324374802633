import { Box, ToggleButton, ToggleButtonGroup, styled, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import IndividualBatchTable from "./IndividualBatchTable";
import AnnouncementList from "./AnnouncementList";
import CourseContent from "./CourseContent";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "border": "none",
  "width": "100%",
  "height": "2.5rem",
  "borderBottom": "2px solid #eaecf0",
  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: "inherit",
    color: theme.palette.secondary.main,
    fontWeight: 600,
    height: "2.5rem",
    borderBottom: `2px solid ${theme.palette.secondary.main}`
  },
  "&:hover": {
    backgroundColor: "#fff !important"
  }
}));

const BatchDetails = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const [alignment, setAlignment] = useState("announcements");

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  return (
    <Box
      width="100%"
      display="flex"
      gap="2rem"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      bgcolor={"#fff"}
      border="1px solid #E6EDFF"
      borderRadius="1rem"
    >
      <ToggleButtonGroup
        sx={{
          width: "100%",
          display: isNonMobile ? "flex" : "block",
          justifyContent: "space-between",
          flexDirection: "row"
        }}
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="subject-selector"
      >
        <StyledToggleButton key="announcements" value="announcements" aria-label="announcements">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              gap: "1rem",
              padding: "1rem"
            }}
          >
            <div>Announcements</div>
          </div>
        </StyledToggleButton>
        <StyledToggleButton key="course content" value="course content" aria-label="course content">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              gap: "1rem"
            }}
          >
            <div>Course Content</div>
          </div>
        </StyledToggleButton>
        <StyledToggleButton key="students" value="students" aria-label="students">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              gap: "1rem"
            }}
          >
            <div>Students</div>
          </div>
        </StyledToggleButton>
        <StyledToggleButton key="faculties" value="faculties" aria-label="faculties">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              gap: "1rem"
            }}
          >
            <div>Faculties</div>
          </div>
        </StyledToggleButton>
      </ToggleButtonGroup>
      {alignment === "announcements" && <AnnouncementList />}
      {alignment === "course content" && <CourseContent />}
      {(alignment === "faculties" || alignment === "students") && <IndividualBatchTable userType={alignment} />}
    </Box>
  );
};

export default BatchDetails;
