import { Box, ToggleButton, ToggleButtonGroup, styled, useMediaQuery } from "@mui/material";
import { PencilSimple } from "@phosphor-icons/react";
import React, { useState } from "react";
import IndividualBatchTable from "./IndividualBatchTable";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "borderRadius": "0.5rem",
  "border": "none",
  "width": "100%",
  "height": "2.25rem",
  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    fontWeight: 600,
    borderRadius: "0.5rem 0.5rem !important",
    height: "2.25rem"
  },
  "&:hover": {
    borderRadius: "0.5rem !important"
  }
}));

const BatchDetails = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const [alignment, setAlignment] = useState("students");

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  return (
    <Box width="100%" display="flex" gap="2rem" flexDirection="column" justifyContent="center" alignItems="center">
      <ToggleButtonGroup
        sx={{
          width: "80%",
          borderRadius: "5px"
        }}
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="subject-selector"
      >
        <StyledToggleButton key="students" value="students" aria-label="students">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              gap: "1rem",
              borderRadius: "0.5rem"
            }}
          >
            <div>Students</div>
          </div>
        </StyledToggleButton>
        <StyledToggleButton key="faculties" value="faculties" aria-label="faculties">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              gap: "1rem",
              borderRadius: "0.5rem"
            }}
          >
            <div>Faculties</div>
          </div>
        </StyledToggleButton>
      </ToggleButtonGroup>
      <IndividualBatchTable userType={alignment} />
    </Box>
  );
};

export default BatchDetails;
