import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import toast from "react-hot-toast";

export const ticketApiSlice = createApi({
  reducerPath: "ticketApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/ticket`,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["Tickets"],
  endpoints: build => ({
    createIssueTicket: build.mutation({
      query: issueDetails => ({
        url: "/create",
        method: "POST",
        body: issueDetails
      }),
      invalidatesTags: ["Tickets"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Creating Issue", { id: "createIssue" });
          await queryFulfilled;
          toast.success("Issue Created Successfully", { id: "createIssue" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "createIssue" });
        }
      }
    }),
    getTicketsForUser: build.query({
      query: () => ({
        url: "/getTicketsForUser"
      }),
      providesTags: ["Tickets"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    getTicketsForAdmin: build.query({
      query: () => ({
        url: "/getTicketsForAdmin"
      }),
      providesTags: ["Tickets"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    changeTicketStatus: build.mutation({
      query: ticketId => ({
        url: "/changeTicketStatus",
        method: "POST",
        body: { ticket_id: ticketId }
      }),
      invalidatesTags: ["Tickets"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Updating Status", { id: "updateStatus" });
          await queryFulfilled;
          toast.success("Status Updated Successfully", { id: "updateStatus" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "updateStatus" });
        }
      }
    })
  })
});

export const {
  useCreateIssueTicketMutation,
  useGetTicketsForUserQuery,
  useGetTicketsForAdminQuery,
  useChangeTicketStatusMutation
} = ticketApiSlice;
