import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts/BarChart";

import { useSelector } from "react-redux";
import { useGetEntireSyllabusQuery } from "../../../../../redux/api/syllabusSlice";

const SectionAnalysis = ({ analysis, subject, section }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const { user } = useSelector(state => state.auth);
  const { data: syllabus, isSuccess: isGetSyllabusSuccess } = useGetEntireSyllabusQuery(user.school_id);

  const [chapterArray, setChapterArray] = useState([]);

  useEffect(() => {
    if (syllabus) {
      if (Object.keys(syllabus).length && subject !== "" && section !== "") {
        setChapterArray(syllabus[subject][section]);
      } else {
        setChapterArray("");
      }
    }
  }, [section, syllabus]);

  const getPercentage = (value, total) => {
    if (Number.isNaN(value) || Number.isNaN(total) || total === 0) {
      return 0;
    }
    const returnString = ((value / total) * 100).toFixed(2);
    return returnString;
  };

  const calculateChapterCorrectness = chapter => {
    if (analysis) {
      const { chapterAnalysis } = analysis;

      if (chapterAnalysis && chapter in chapterAnalysis) {
        const chapterData = chapterAnalysis[chapter];

        if (chapterData) {
          const correctAnswers = chapterData.correct;
          const totalPossibleAnswers = chapterData.total;

          if (correctAnswers !== undefined && totalPossibleAnswers !== undefined) {
            const percentage = getPercentage(correctAnswers, totalPossibleAnswers);
            if (Number.isNaN(percentage)) {
              return 0;
            } else {
              return percentage;
            }
          }
        }
      }
    }

    // Return a default value or handle the case where the data is not available
    return 0;
  };

  return (
    <Box
      display="flex"
      width="100%"
      gap="2rem"
      mt="1rem"
      justifyContent="center"
      flexDirection={isNonMobile ? "row" : "column"}
    >
      <Box
        border="1px solid #E6EDFF"
        p={isNonMobile ? "0.5rem" : "1rem"}
        height="max-content"
        outline="thin"
        bgcolor="#FFFFFF"
        borderRadius="1rem"
        width={isNonMobile ? "65%" : "100%"}
      >
        {/* Bar chart here */}
        <div>
          <BarChart
            yAxis={[{ label: "Average Correctness(%)", tickInterval: "auto" }]}
            xAxis={[
              {
                scaleType: "band",
                data: chapterArray?.length ? chapterArray.map((chapter, index) => `${index + 1}`) : ["NaN"],
                label: "Chapter wise"
                // valueFormatter: (code, context) => ( === "tick" ? code : 0)
              }
            ]}
            series={[
              {
                data: chapterArray?.length
                  ? chapterArray.map((chapter, index) => {
                      return calculateChapterCorrectness(chapter);
                    })
                  : [0]
              }
            ]}
            sx={{ width: "20%" }}
            height={300}
            colors={["#2970FF"]}
          />
        </div>
      </Box>

      {/* Text part starts here */}

      <Box width={isNonMobile ? "30%" : "100%"} gap="1rem">
        <Typography variant="h3">Chapters:</Typography>
        {chapterArray && chapterArray.length ? (
          <ol style={{ display: "flex", gap: "0.5rem", flexDirection: "column" }}>
            {chapterArray.map((chapterName, index) => (
              <li key={index}>
                <Typography variant="h4">{chapterName}</Typography>
              </li>
            ))}
          </ol>
        ) : (
          <>No Chapters found. Please contact supervisor to add chapters</>
        )}
      </Box>

      {/* Text part ends here */}
    </Box>
  );
};

export default SectionAnalysis;
