import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import OverAllStats from "../../../components/Student/ExamAnalysis/OverAllStats";
import Choice from "../../../components/Student/Tabs/Option";
import { useFetchStudentsOfFacultyQuery } from "../../../redux/api/facultySlice";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";

const AnalyseStudent = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const { analysisId } = useParams();
  const [examId, setExamId] = useState("");
  const [selectedValue, setSelectedValue] = useState("Batch1");

  const handleChange = event => {
    setSelectedValue(event.target.value);
  };

  const theme = useTheme();

  const { user } = useSelector(state => state.auth);

  const { data: studentsOfFaculty, isSuccess: isGetStudentsSuccess } = useFetchStudentsOfFacultyQuery({
    school_id: user.school_id,
    faculty_id: user._id
  });

  const [analysis, setAnalysis] = useState({});
  const [name, setName] = useState("");

  useEffect(() => {
    if (isGetStudentsSuccess) {
      const selectedAnalysis = studentsOfFaculty?.students?.find(item => item._id === analysisId);
      setExamId(selectedAnalysis?.exam_id);
      setAnalysis(selectedAnalysis || {});
      setName(selectedAnalysis?.student_name || "");
    }
  }, [studentsOfFaculty, analysisId]);

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Student", link: "/manage-student" },
    { name: `${name}`, link: "" }
  ];

  return (
    <Box
      padding={isNonMobile ? "1rem 2rem" : "1rem"}
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <div style={{ padding: "1rem 0", marginTop: "-1rem" }}>
        <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
      </div>
      <Box display="flex" gap="0.75rem" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography variant={isNonMobile ? "h2" : "h3"} fontWeight={600}>
            Exam Analysis
          </Typography>
          <Typography variant="subtitle1" fontSize={16} color={theme.palette.primary.light}>
            Know about the progress report of your class
          </Typography>
        </Box>
      </Box>
      <OverAllStats analysis={analysis} />
      <Choice analysis={analysis} examId={examId} />
    </Box>
  );
};

export default AnalyseStudent;
