import axios from "axios";

const API_URL = `${process.env.REACT_APP_BASE_URL}/comments`;

const fetchCommentsForAnnouncement = async announcementId => {
  const { data } = await axios.get(`${API_URL}/announcements/${announcementId}`);

  return data;
};

const addComment = async comment => {
  const { data } = await axios.post(`${API_URL}`, comment);

  return data;
};

const removeComment = async commentId => {
  const { data } = await axios.delete(`${API_URL}/${commentId}`);

  return data;
};

const commentService = {
  fetchCommentsForAnnouncement,
  addComment,
  removeComment
};

export default commentService;
