// inbuilt modules
import React, { useEffect, useState } from "react";

// external modules
import { Box, FormControl, TextField, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { ArrowLeft } from "@phosphor-icons/react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// internal modules
import { logo, logoPlain } from "../../../Assets/index";
import CButton from "../../../components/ui/Button";
import { useForgotPasswordMutation } from "../../../redux/api/authSlice";

// functions and variables
const InputBox = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
}));

const ForgotPassword = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  // theme
  const theme = useTheme();
  const [emailSent, setEmailSent] = useState(false);

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  // hooks
  const dispatch = useDispatch();

  // useState
  const [formData, setFormData] = useState({ email: "" });

  // functions and variables
  const handleChange = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSendResetEmail = async () => {
    forgotPassword(formData?.email);
    setEmailSent(true);
  };

  const handleOpenEmail = () => {
    const emailLink = `mailto:${formData.email}`;
    window.location.href = emailLink;
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (formData.email.trim() === "") {
      return toast.error("Please enter your email");
    }

    handleSendResetEmail();
  };

  useEffect(() => {
    document.title = "Insights | Forgot Password";
  }, []);

  return (
    <Box width="100vw" height="100vh" padding="0.75rem" display="flex">
      <Box width="100%">
        {isNonMobile && <img src={logo} alt="logoImage" />}
        <Box
          width="100%"
          display="flex"
          height="80%"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {!emailSent ? (
            <Box
              width={isNonMobile ? "60%" : "90%"}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h3" mb="0.75rem" fontWeight={500}>
                Forgot Password ?
              </Typography>
              <Typography variant="h5" textAlign="center">
                Don't worry, we'll send you a link to reset your password. <br />
                Enter your email below
              </Typography>
              <FormControl fullWidth>
                <Box mt="2rem" display="flex" flexDirection="column" gap="0.75rem">
                  <InputBox>
                    <TextField
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      size="small"
                      required
                      placeholder="Enter email"
                      type="email"
                      sx={{ width: "100%", color: theme.palette.secondary.main }}
                      InputProps={{ inputProps: { style: { color: theme.palette.secondary.main } } }}
                    />
                  </InputBox>
                </Box>
                <Box display="flex" mt="1rem" justifyContent="flex-end">
                  <CButton
                    buttonText={"Send Reset Email"}
                    variant={"primary"}
                    sx={{ width: "100%", height: "2.5rem" }}
                    onClickFun={handleSubmit}
                  />
                </Box>
              </FormControl>
              <Box width="100%" display="flex" alignItems="center" justifyContent="center" gap="0.7rem" mt="1rem">
                <ArrowLeft />
                <Link to="/" color="#fff" variant="h5" fontWeight={500} sx={{ height: "0" }}>
                  Back to login
                </Link>
              </Box>
            </Box>
          ) : (
            <Box
              width={isNonMobile ? "60%" : "90%"}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h3" mb="0.75rem" fontWeight={500}>
                Check Your Email
              </Typography>
              <Typography variant="h5" textAlign="center">
                We have sent a reset link to <br />
                {formData.email}
              </Typography>
              <Box display="flex" mt="1rem" justifyContent={isNonMobile ? "flex-end" : "center"} width="100%">
                <CButton
                  buttonText={"Open Email"}
                  variant={"primary"}
                  sx={{ width: isNonMobile ? "100%" : "80%", height: "2.5rem" }}
                  onClickFun={handleOpenEmail}
                />
              </Box>
              <Box width="100%" display="flex" alignItems="center" justifyContent="center" gap="0.7rem" mt="1rem">
                <Typography variant="h5" textAlign="center">
                  Didn't receive the email ?
                </Typography>
                <Typography
                  variant="h5"
                  textAlign="center"
                  color={theme.palette.secondary.main}
                  sx={{ textDecoration: "underline" }}
                >
                  Click to resend
                </Typography>
              </Box>
              <Box width="100%" display="flex" alignItems="center" justifyContent="center" gap="0.7rem" mt="1rem">
                <ArrowLeft />
                <Link to="/" color="#fff" variant="h5" fontWeight={500} sx={{ height: "0" }}>
                  Back to login
                </Link>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {isNonMobile && (
        <Box
          className="analysis"
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box width="60%">
            <img src={logoPlain} alt="logoImage" />
            <Typography variant="h5" textAlign="center" color="#fff">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa illo fugiat aut quisquam ex dolorum
              obcaecati ratione expedita, iste soluta porro saepe at facilis numquam provident minus fuga ipsam
              laudantium.
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

// render
export default ForgotPassword;
