import { Box, Button, Divider, FormControl, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { MagnifyingGlass } from "@phosphor-icons/react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { logo } from "../../../Assets";
import { useGetAllStudentResultsMutation } from "../../../redux/api/resultsSlice";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";

const IndividualResult = ({ result, studentResults, overallAverageScore, isNonMobile }) => {
  const apiRef = useGridApiRef();
  const { user } = useSelector(state => state.auth);

  const getTotalMarks = studentResults => {
    const totalMarks = studentResults.reduce((sum, result) => sum + result.totalMarks, 0);
    return totalMarks;
  };

  const getTotalMarksObtained = studentResults => {
    const totalMarksObtained = studentResults.reduce((sum, result) => sum + result.score, 0);
    return totalMarksObtained;
  };

  const getPercentage = studentResults => {
    if (!studentResults || studentResults.length === 0) return "N/A";
    const totalMarksObtained = getTotalMarksObtained(studentResults);
    const totalMarks = getTotalMarks(studentResults);
    return ((totalMarksObtained / totalMarks) * 100).toFixed(2) + "%";
  };

  const getPassOrFail = studentResults => {
    if (!studentResults || studentResults.length === 0) return "N/A";
    const isPassed = studentResults.every(result => result.isPassed);
    return isPassed ? "PASS" : "FAIL";
  };

  const getRowId = row => row.subject;

  const getRowClassName = params => {
    return "row-color";
  };

  const logicalColumns = [
    { field: "subject", headerName: "Subject", flex: 0.4, headerClassName: "datagrid-header-color" },
    { field: "totalMarks", headerName: "Maximum Marks", flex: 0.5, headerClassName: "datagrid-header-color" },
    { field: "passingMarks", headerName: "Passing Marks", flex: 0.5, headerClassName: "datagrid-header-color" },
    { field: "score", headerName: "Marks Obtained", flex: 0.5, headerClassName: "datagrid-header-color" },
    { field: "averageScore", headerName: "Class Average", flex: 0.5, headerClassName: "datagrid-header-color" }
  ];

  return (
    <Box
      id="result-box"
      sx={{
        "position": "relative",
        "borderRadius": "1rem",
        "border": "1px solid #E6EDFF",
        "padding": "1rem 3rem",
        "width": "100%",
        "height": "max-content",
        "display": "flex",
        "flexDirection": "column",
        "gap": "2rem",
        "marginBottom": "2rem",
        "overflow": "hidden",
        "&::before": {
          content: "''",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "90%",
          backgroundImage: `url(${logo})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          opacity: 0.1,
          zIndex: 0
        },
        "& > *": {
          position: "relative",
          zIndex: 1
        }
      }}
    >
      <Typography variant="h4" textAlign="center" fontWeight={500}>
        {result.resultName}
      </Typography>
      <Box width="100%" display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h6" fontWeight={400}>
            <b>Student Name:</b> {user.name}
          </Typography>
          <Typography variant="h6" fontWeight={400}>
            <b>Roll Number:</b> {user.roll_no}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6" fontWeight={400}>
            <b>Percentage:</b> {getPercentage(studentResults.results)}
          </Typography>
          <Typography
            variant="h6"
            fontWeight={400}
            color={getPassOrFail(studentResults.results) === "FAIL" ? "red" : "green"}
          >
            <b>Result:</b> {getPassOrFail(studentResults.results)}
          </Typography>
        </Box>
      </Box>
      <div className="datagrid-container">
        <DataGrid
          className="custom-datagrid"
          autoHeight
          rows={studentResults.results}
          disableRowSelectionOnClick
          apiRef={apiRef}
          columns={logicalColumns}
          getRowId={getRowId}
          getRowClassName={getRowClassName}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10
              }
            }
          }}
          sx={!isNonMobile ? { width: "800px" } : {}}
        />
      </div>
      <Box width="100%" display="flex" justifyContent="space-between">
        <Typography variant="h6" fontWeight={400}>
          <b>Total Marks:</b> {getTotalMarks(studentResults.results)}
        </Typography>
        <Typography variant="h6" fontWeight={400}>
          <b>Total Marks Obtained:</b> {getTotalMarksObtained(studentResults.results)}
        </Typography>
        <Typography variant="h6" fontWeight={400}>
          <b>Total Average Marks:</b> {overallAverageScore}
        </Typography>
      </Box>
    </Box>
  );
};

const DownloadResults = () => {
  const theme = useTheme();
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const [studentId, setStudentId] = useState("");
  const [getAllStudentsMutation] = useGetAllStudentResultsMutation();
  const { user } = useSelector(state => state.auth);

  const [data, setData] = useState({});

  const handleSearchResults = async () => {
    if (studentId.length !== 6) {
      toast.error("Roll Number should be of 6 digits");
    }

    try {
      const data = await getAllStudentsMutation({ school_id: user.school_id, studentId }).unwrap();
      setData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadPdf = () => {
    const resultBoxes = document.querySelectorAll("#result-box");
    if (!resultBoxes || resultBoxes.length === 0) {
      console.error("No result boxes found");
      return;
    }
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF("p", "mm", "a4");
    let pdfHeight = 0;

    Promise.all(
      Array.from(resultBoxes).map(box =>
        html2canvas(box).then(canvas => {
          const imgData = canvas.toDataURL("image/png");
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;
          if (pdfHeight + imgHeight > pdf.internal.pageSize.getHeight()) {
            pdf.addPage();
            pdfHeight = 0;
          }
          pdf.addImage(imgData, "PNG", 0, pdfHeight, pdfWidth, imgHeight);
          pdfHeight += imgHeight;
        })
      )
    )
      .then(() => {
        pdf.save("results.pdf");
      })
      .catch(error => {
        console.error("Error generating PDF", error);
      });
  };

  return (
    <Box
      padding={isNonMobile ? "1rem 2rem" : "1rem 1rem"}
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <div style={{ padding: "1rem 0", marginTop: "-1rem" }}>
        <BreadcrumbsComp
          items={{
            arr: [
              { name: "Dashboard", link: "/" },
              { name: "Download Result", link: "" }
            ]
          }}
        />
      </div>
      <Box display="flex" gap="0.75rem" justifyContent="space-between" flexDirection={isNonMobile ? "row" : "column"}>
        <Box>
          <Typography variant={isNonMobile ? "h2" : "h3"} fontWeight={600}>
            Download Results
          </Typography>
          <Typography variant="h6" color={theme.palette.primary.light}>
            Download a student's complete report
          </Typography>
        </Box>
      </Box>
      <Box width="100%" display="flex" flexDirection="column" gap="0.75rem" mt="1rem">
        <Typography variant="h5" mb="0.2rem">
          Roll Number of the student
        </Typography>
        <Divider sx={{ width: "50%" }} />
        <Box display="flex" gap="0.75rem">
          <FormControl>
            <TextField
              placeholder="Enter roll number"
              value={studentId}
              onChange={e => setStudentId(e.target.value)}
              type="text"
              sx={{
                width: "100%",
                bgcolor: "#F7F6F9"
              }}
              InputProps={{ inputProps: { style: { color: theme.palette.secondary.main } } }}
            />
          </FormControl>
          <Button
            startIcon={<MagnifyingGlass size={20} weight="bold" color="white" />}
            sx={{
              "display": "flex",
              "backgroundColor": "#3267FF",
              "&:hover": { bgcolor: "#3267FF", opacity: "0.8" },
              "px": "1rem"
            }}
            onClick={handleSearchResults}
            disabled={studentId.length !== 6}
          >
            <Typography variant="h6" fontWeight={400} color="white">
              Search
            </Typography>
          </Button>
        </Box>
      </Box>
      {data.results && data.studentResults && data.results.length > 0 && data.studentResults.length > 0 ? (
        <>
          {data.results.map((result, index) => (
            <IndividualResult
              key={result.id}
              result={result}
              studentResults={data.studentResults[index]} // Pass the studentResults from the correct index
              overallAverageScore={data.overallAverageScore}
              isNonMobile={isNonMobile}
            />
          ))}
          <Button
            variant="contained"
            color="secondary"
            onClick={downloadPdf}
            sx={{ marginTop: "1rem", alignSelf: "center" }}
          >
            Download PDF
          </Button>
        </>
      ) : (
        <>You have no results here</>
      )}
    </Box>
  );
};

export default DownloadResults;
