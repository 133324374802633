import { Box, useTheme } from "@mui/material";
import React from "react";
import Stats from "../StatsBar/Questionset";
import ScoreAnalysis from "./ScoreAnalysis";

const OverAllStats = ({ analysis }) => {
  const theme = useTheme();

  const calculateRanges = (lowestScore, highestScore, divisions) => {
    const rangeSize = Math.ceil((highestScore - lowestScore) / divisions);
    const ranges = [];

    for (let i = 0; i < divisions; i++) {
      const start = lowestScore + i * rangeSize;
      const end = start + rangeSize - 1;

      const label = `${start}-${end}`;
      ranges.push(label);
    }
    return ranges;
  };

  const getPercentage = (value, total) => {
    if (Number.isNaN(value) || Number.isNaN(total) || total === 0) {
      return "N/A";
    }
    const returnString = `${((value / total) * 100).toFixed(2)}` + "%";
    return returnString;
  };

  return (
    <>
      <Stats analysis={analysis} />
      <Box display="flex" flexDirection="row" justifyContent="space-between" gap="1rem">
        <ScoreAnalysis analysis={analysis} />
      </Box>
    </>
  );
};

export default OverAllStats;
