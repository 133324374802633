// inbuilt modules
import React, { useRef, useState } from "react";

// external modules
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCreateAExamMutation } from "../../../redux/api/examSlice";
import { getId } from "../../../utils/uniqueIdGen";
import CButton from "../../ui/Button";

// functions and variables
const possibleTestData = [
  {
    id: 1,
    title: "JEE Chapter Test",
    exam_for: "JEE",
    type_of_exam: "Chapter Test",
    total_questions: 30
  },
  {
    id: 2,
    title: "JEE Unit Test",
    exam_for: "JEE",
    type_of_exam: "Unit Test",
    total_questions: 60
  },
  {
    id: 3,
    title: "JEE Part Test",
    exam_for: "JEE",
    type_of_exam: "Part Test",
    total_questions: 90
  },
  {
    id: 4,
    title: "JEE Full Test",
    exam_for: "JEE",
    type_of_exam: "Full Test",
    total_questions: 90
  },
  {
    id: 5,
    title: "NEET Chapter Test",
    exam_for: "NEET",
    type_of_exam: "Chapter Test",
    total_questions: 60
  },
  {
    id: 6,
    title: "NEET Unit Test",
    exam_for: "NEET",
    type_of_exam: "Unit Test",
    total_questions: 120
  },
  {
    id: 7,
    title: "NEET Part Test",
    exam_for: "NEET",
    type_of_exam: "Part Test",
    total_questions: 180
  },
  {
    id: 8,
    title: "NEET Full Test",
    exam_for: "NEET",
    type_of_exam: "Full Test",
    total_questions: 180
  },
  {
    id: 9,
    title: "KCET Chapter Test",
    exam_for: "KCET",
    type_of_exam: "Chapter Test",
    total_questions: 30
  },
  {
    id: 10,
    title: "KCET Unit Test",
    exam_for: "KCET",
    type_of_exam: "Unit Test",
    total_questions: 120
  },
  {
    id: 11,
    title: "KCET Part Test",
    exam_for: "KCET",
    type_of_exam: "Part Test",
    total_questions: 180
  },
  {
    id: 12,
    title: "KCET Full Test",
    exam_for: "KCET",
    type_of_exam: "Full Test",
    total_questions: 180
  }
];

const colorMap = {
  JEE: "#3267FF",
  NEET: "#FF3434",
  KCET: "#F48C06"
};

const PossibleTests = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");
  const [arrowsVisible, setArrowsVisisble] = useState({ left: true, right: true });
  const navigate = useNavigate();

  const [examForFilter, setExamForFilter] = useState("JEE");

  // theme
  const theme = useTheme();
  const { user } = useSelector(state => state.auth);
  const [createAExam, { isSuccess }] = useCreateAExamMutation();

  const sliderRef = useRef(null);
  const [examName, setExamName] = useState("");
  const [date, setDate] = useState("");

  const [selectedTestId, setSelectedTestId] = useState(null);
  const [examStarterDetails, setExamStarterDetails] = useState(null);

  const [open, setOpen] = useState(false);

  const handleCreateTest = testId => {
    setSelectedTestId(testId);
    setOpen(true); // Open the dialog
  };

  const handleCloseTestDialog = () => {
    setOpen(false);
  };

  const handleTestSubmit = async () => {
    if (!examName || !date) {
      toast.error("Please fill all required details");
      return;
    }

    const testPattern = possibleTestData.find(test => test.id === selectedTestId);
    const unique_code = getId();

    const examDataObj = {
      school_id: user.school_id,
      unique_code,
      exam_name: examName,
      type_of_exam: testPattern.type_of_exam,
      exam_for: testPattern.exam_for,
      exam_date: date,
      subjects: [],
      sections: [],
      chapters: [],
      faculties: [],
      questions: [],
      status_step: 1,
      total_questions: testPattern.total_questions,
      status: "DRAFT"
    };

    try {
      await createAExam(examDataObj);
      navigate(`/edit-test/${unique_code}`);
    } catch (error) {
      toast.error("Error creating test");
    }
    setOpen(false);
  };

  const filteredTests = possibleTestData.filter(test => test.exam_for === examForFilter);

  return (
    <Box
      width="100%"
      mt="1rem"
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      borderRadius="1rem"
      border="1px solid #E6EDFF"
      p="1rem"
    >
      <Box
        width="100%"
        bgcolor="#FFF"
        borderRadius="0.75rem"
        p="0.5rem"
        border="1px solid #E6EDFF"
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap="1rem"
      >
        <Typography variant="h5">Saved Test Patterns</Typography>
        <Select
          value={examForFilter}
          onChange={e => setExamForFilter(e.target.value)}
          variant="outlined"
          sx={{ height: "2rem", borderRadius: "1rem", px: "10px", backgroundColor: "#F7F6F9" }}
        >
          <MenuItem value="JEE">JEE</MenuItem>
          <MenuItem value="NEET">NEET</MenuItem>
          <MenuItem value="KCET">KCET</MenuItem>
        </Select>
      </Box>
      <Box sx={{ position: "relative" }}>
        <Box
          display="flex"
          gap="1rem"
          ref={sliderRef}
          sx={{
            position: "relative",
            overflowX: "auto", // Use "auto" instead of "scroll" for automatic scrollbars when needed
            maxWidth: "100%", // Set a fixed height for the container
            alignItems: "center"
          }}
          position={"relative"}
          style={{ position: "relative" }}
          p="1rem"
        >
          {filteredTests.map((test, index) => (
            <Card
              sx={{
                "minWidth": isNonMobile ? 350 : 250,
                "minHeight": 170,
                "borderRadius": "0.75rem",
                "borderTop": `3px solid ${colorMap[test.exam_for]}`,
                "p": "0.75rem",
                "&:hover": {
                  backgroundColor: "#DDE6FF",
                  cursor: "pointer"
                }
              }}
              key={test.id}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  height: "100%",
                  gap: "1rem"
                }}
                onClick={() => handleCreateTest(test.id)}
              >
                <Box display="flex" width="100%" justifyContent="space-between">
                  <Typography variant="h3" color={colorMap[test.exam_for]}>
                    {test.exam_for}
                  </Typography>
                  <Typography variant="h6" textAlign="right">
                    Test mode <br />
                    Offline
                  </Typography>
                </Box>
                <Box width="100%">
                  <Typography variant="subtitle1" color={theme.palette.primary.light}>
                    {test.title}
                  </Typography>
                  <Typography variant="h5">{test.total_questions} Questions</Typography>
                </Box>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>
      <Dialog open={open} onClose={handleCloseTestDialog}>
        <DialogTitle>Create Test</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
          <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
            <Typography variant="h4" mb="0.2rem">
              Name of the test
            </Typography>
            <Divider sx={{ width: "100%" }} />
            <FormControl>
              <TextField
                placeholder="Enter test name"
                value={examName}
                onChange={e => setExamName(e.target.value)}
                type="text"
                sx={{
                  width: "100%",
                  bgcolor: "#F7F6F9"
                }}
                InputProps={{ inputProps: { style: { color: theme.palette.secondary.main } } }}
              />
            </FormControl>
          </Box>
          <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
            <Typography variant="h4" mb="0.2rem">
              Date of test
            </Typography>
            <Divider sx={{ width: "80%" }} />
            <input
              className="border-2  px-2 py-1 w-60 rounded-md bg-[#F7F6F9] text-[#3266FC]"
              value={date}
              onChange={e => setDate(e.target.value)}
              style={{
                color: theme.palette.secondary.main,
                width: "100%",
                height: "52px",
                padding: "10px",
                backgroundColor: "#F7F6F9",
                border: "1px solid rgba(0,0,0,0.2)",
                borderRadius: "5px"
              }}
              placeholder="Select a Date"
              type="date"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTestDialog}>Cancel</Button>
          <CButton buttonText="Create Test" variant="primary" onClickFun={handleTestSubmit} />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

// render
export default PossibleTests;
