// mui theme settings

const tokensColor = {
  primary: {
    normal: "#000000", // black color all over
    light: "#7C8DB5" // lighter version for search bar, non selected elements
  },
  secondary: {
    normal: "#3267FF"
  },
  alerts: {
    error: "red",
    warning: "orange",
    success: "green",
    info: "#3267FF"
  },
  buttons: {
    main: "#3267FF",
    text: "#FFFFFF",
    secondary: "#3A974C",
    secondaryText: "green"
  },
  background: {
    main: "#3264fcd7"
  }
};

export const themeSettings = mode => {
  return {
    palette: {
      primary: {
        main: tokensColor.primary.normal,
        light: tokensColor.primary.light
      },
      secondary: {
        main: tokensColor.secondary.normal
      },
      alerts: {
        error: tokensColor.alerts.error,
        warning: tokensColor.alerts.warning,
        success: tokensColor.alerts.success,
        info: tokensColor.alerts.info
      },
      buttons: {
        default: tokensColor.buttons.main,
        defaultText: tokensColor.buttons.text,
        secondary: tokensColor.buttons.secondary,
        secondaryText: tokensColor.buttons.secondaryText
      },
      background: {
        main: tokensColor.background.main
      }
    },

    typography: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 40
      },
      h2: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 32
      },
      h3: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 26
      },
      h4: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 20
      },
      h5: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 16
      },
      h6: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 14
      }
    }
  };
};
