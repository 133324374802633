import { Box, Typography } from "@mui/material";
import React from "react";
import AddSupervisor from "./Addsupervisor";

const SupervisorManager = () => {
  return (
    <Box width="100%" borderRadius="1rem" bgcolor="#fff" border="1px solid #E6EDFF">
      <Typography variant="h4" fontWeight={500} p="1rem">
        All Supervisors
      </Typography>
      <AddSupervisor />
    </Box>
  );
};

export default SupervisorManager;
