import { Box } from "@mui/material";
import React from "react";

const AuthError = () => {
  return (
    <Box width="100%" height="100vh" display="flex" flexDirection="row" alignItems="center" justifyContent="center">
      You are are already logged in! Please{" "}
      <a href="/" style={{ margin: "0 5px" }}>
        go to the dashboard to continue
      </a>
    </Box>
  );
};

export default AuthError;
