import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { Info } from "@phosphor-icons/react";
import "chart.js/auto";
import React, { useState } from "react";
import Stats from "../StatsBar/Questionset";

const OverAllStats = ({ analysis }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();
  const [dataset, setDataset] = useState([]);

  const chartSetting = {
    xAxis: [
      {
        label: "rainfall (mm)"
      }
    ],
    width: 500,
    height: 400
  };

  const valueFormatter = value => `${value}mm`;

  const calculateRanges = (lowestScore, highestScore, divisions) => {
    const rangeSize = Math.ceil((highestScore - lowestScore) / divisions);
    const ranges = [];

    for (let i = 0; i < divisions; i++) {
      const start = lowestScore + i * rangeSize;
      const end = start + rangeSize - 1;

      const label = `${" ".repeat(i)} ${start}-${end}`;
      ranges.push(label);
    }
    return ranges;
  };

  const getPercentage = (value, total) => {
    if (Number.isNaN(value) || Number.isNaN(total) || total === 0) {
      return "N/A";
    }
    const returnString = `${((value / total) * 100).toFixed(2)}` + "%";
    return returnString;
  };

  return (
    analysis && (
      <>
        <Box
          display="flex"
          flexDirection={isNonMobile ? "row" : "column"}
          justifyContent="space-between"
          mt={isNonMobile ? "1rem" : ""}
          gap="1rem"
        >
          <Box
            border="3px solid #E6EDFF"
            p="0.5rem"
            height="100%"
            justifyContent="center"
            outline="thin"
            bgcolor="#FFFFFF"
            width={isNonMobile ? "65%" : "100%"}
            borderRadius="1rem"
          >
            <Typography variant="h4" fontSize={26} fontWeight={600} padding="1rem">
              Overall analysis
            </Typography>
            <Box display="flex" justifyContent="center" alignItems="center" gap="1rem">
              <Info size={24} weight="bold" color="blue" />
              <Typography variant="subtitle2" fontWeight={400} px="1rem">
                Number of students in the given marks range
              </Typography>
            </Box>
            <Box sx={{ position: "relative" }} px={isNonMobile ? "2rem" : "0rem"} width="100%">
              <BarChart
                xAxis={[
                  {
                    scaleType: "band",
                    data: analysis ? calculateRanges(analysis?.lowest_score, analysis?.highest_score, 5) : ["NaN"]
                  }
                ]}
                series={[{ data: analysis?.range_scores_five?.length ? analysis?.range_scores_five : [0] }]}
                sx={{
                  width: "100%"
                }}
                height={500}
                colors={["#3267FF"]}
              />
            </Box>
          </Box>

          {/* second box */}

          <Box
            border="3px solid #E6EDFF"
            p="0.5rem"
            height="max-content"
            justifyContent="center"
            outline="thin"
            bgcolor="#FFFFFF"
            width={isNonMobile ? "35%" : "100%"}
            borderRadius="1rem"
          >
            <Typography variant="h4" fontSize={26} fontWeight={600} padding="1rem">
              Exam Score Analysis
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  transform: "translate(-5%,20%)"
                }}
              >
                <Box
                  sx={{
                    "width": "204px",
                    "height": "204px",
                    "borderRadius": "50%",
                    "backgroundColor": "rgba(52, 106, 255, 1)",
                    "color": "white",
                    "display": "flex",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "fontSize": "30px",
                    "position": "relative",
                    "border": "4px solid white",
                    "&:hover": {
                      border: "6px solid rgba(255,255,255,0.5)",
                      cursor: "pointer"
                    },
                    "transition": "ease-in 0.1s"
                  }}
                >
                  {analysis.highest_score}
                </Box>
                <Box
                  sx={{
                    "position": "absolute",
                    "top": "50%",
                    "left": "50%",
                    "transform": "translate(30%, -110%)",
                    "width": "140px",
                    "height": "140px",
                    "borderRadius": "50%",
                    "backgroundColor": "rgba(244, 140, 6, 1)",
                    "color": "white",
                    "display": "flex",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "fontSize": "24px",
                    "border": "4px solid white",
                    "&:hover": {
                      border: "6px solid rgba(255,255,255,0.5)",
                      cursor: "pointer"
                    },
                    "transition": "ease-in 0.1s"
                  }}
                >
                  {analysis.lowest_score}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    transform: "translate(100%, -100%)"
                  }}
                >
                  <Box
                    sx={{
                      "width": "100px",
                      "height": "100px",
                      "borderRadius": "50%",
                      "backgroundColor": "black",
                      "color": "white",
                      "display": "flex",
                      "alignItems": "center",
                      "justifyContent": "center",
                      "fontSize": "20px",
                      "border": "4px solid white",
                      "&:hover": {
                        border: "6px solid rgba(255,255,255,0.5)",
                        cursor: "pointer"
                      },
                      "transition": "ease-in 0.1s"
                    }}
                  >
                    {Math.floor(analysis.average_score)}
                  </Box>
                </Box>
              </Box>

              {/* New part */}
              {/* Line part starts here */}

              <Box p="1rem" display="flex" gap="2rem" flexDirection="column" alignItems="center" width="100%">
                <div
                  style={{
                    width: "95%",
                    height: "5px",
                    backgroundColor: "grey"
                  }}
                />

                <Box sx={{ textAlign: "center" }} display="flex" justifyContent="space-evenly" width="90%">
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                      gap: "5px"
                    }}
                  >
                    <div
                      style={{
                        width: "23px",
                        height: "23px",
                        borderRadius: "50%",
                        backgroundColor: "rgba(52, 106, 255, 1)",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "12px"
                      }}
                    />
                    <span style={{ fontSize: "16px" }}>Highest</span>
                  </Box>
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                      gap: "5px"
                    }}
                  >
                    {" "}
                    <div
                      style={{
                        width: "23px",
                        height: "23px",
                        borderRadius: "50%",
                        backgroundColor: "rgba(244, 140, 6, 1)",
                        color: "black",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "12px"
                      }}
                    />
                    <span style={{ fontSize: "16px" }}>Lowest</span>
                  </Box>
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                      gap: "5px"
                    }}
                  >
                    {" "}
                    <div
                      style={{
                        width: "23px",
                        height: "23px",
                        borderRadius: "50%",
                        backgroundColor: "black",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "12px"
                      }}
                    />
                    <span style={{ fontSize: "16px" }}>Average</span>
                  </Box>
                </Box>

                <Box display="flex" justifyContent="space-evenly" width={isNonMobile ? "90%" : "100%"}>
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap="0.5rem">
                    <Typography variant={isNonMobile ? "h4" : "h5"} fontWeight={500}>
                      {getPercentage(analysis.hard_questions, analysis.total_questions)}
                    </Typography>
                    <Typography variant="h5" width={isNonMobile ? "60%" : "100%"} textAlign="center">
                      of Hard Questions
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap="0.5rem">
                    <Typography variant={isNonMobile ? "h4" : "h5"} fontWeight={500}>
                      {getPercentage(analysis.medium_questions, analysis.total_questions)}
                    </Typography>
                    <Typography variant="h5" width={isNonMobile ? "60%" : "100%"} textAlign="center">
                      of Medium Questions
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap="0.5rem">
                    <Typography variant={isNonMobile ? "h4" : "h5"} fontWeight={500}>
                      {getPercentage(analysis.easy_questions, analysis.total_questions)}
                    </Typography>
                    <Typography variant="h5" width={isNonMobile ? "60%" : "100%"} textAlign="center">
                      of Easy Questions
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Stats analysis={analysis} />
      </>
    )
  );
};

export default OverAllStats;
