import { Box, TextField, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSaveCredentialsMutation } from "../../../redux/api/meetManagerSlice";
import { useGetAllSchoolQuery } from "../../../redux/api/schoolSlice";
import CButton from "../../ui/Button";

const GMeet = ({ school_id }) => {
  const [credentials, setCredentials] = useState({
    projectId: "",
    clientId: "",
    clientSecret: ""
  });

  const [saveCredentials] = useSaveCredentialsMutation();

  const handleCredentialsSave = async () => {
    const credentialsBody = {
      school_id: school_id,
      ...credentials
    };
    try {
      await saveCredentials(credentialsBody).unwrap();
      setCredentials({
        projectId: "",
        clientId: "",
        clientSecret: ""
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap="15px">
      <Typography variant="h5" fontWeight={600}>
        Manage GMeet Credentials
      </Typography>
      <TextField
        label="Project Id"
        fullWidth
        type="text"
        value={credentials.projectId}
        onChange={event => setCredentials({ ...credentials, projectId: event.target.value })}
        sx={{
          "& .MuiInputBase-root": {
            fontSize: "16px",
            backgroundColor: "#fff"
          },
          "& .MuiInputLabel-root": {
            fontSize: "15px"
          }
        }}
      />
      <TextField
        label="Client Id"
        fullWidth
        type="text"
        value={credentials.clientId}
        onChange={event => setCredentials({ ...credentials, clientId: event.target.value })}
        sx={{
          "& .MuiInputBase-root": {
            fontSize: "16px",
            backgroundColor: "#fff"
          },
          "& .MuiInputLabel-root": {
            fontSize: "15px"
          }
        }}
      />
      <TextField
        label="Client Secret"
        fullWidth
        type="text"
        value={credentials.clientSecret}
        onChange={event => setCredentials({ ...credentials, clientSecret: event.target.value })}
        sx={{
          "& .MuiInputBase-root": {
            fontSize: "16px",
            backgroundColor: "#fff"
          },
          "& .MuiInputLabel-root": {
            fontSize: "15px"
          }
        }}
      />
      <Box width="100%" display="flex" justifyContent="flex-end">
        <CButton buttonText="Save" variant="primary" onClickFun={handleCredentialsSave} />
      </Box>
    </Box>
  );
};

export default GMeet;
