import { Box } from "@mui/material";
import React from "react";
import FacultyTable from "./FacultyTable";

const FacultyManager = () => {
  return (
    <Box width="100%" borderRadius="1rem" bgcolor="#fff" border="1px solid #E6EDFF">
      <FacultyTable />
    </Box>
  );
};

export default FacultyManager;
