// inbuilt modules
import React from "react";

// external modules
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

// internal modules
import { chartLineUp } from "../../../Assets";

const Intro = () => {
  return (
    <Box className="intro" width="100%" height="fit-content">
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        py="1rem"
        gap="1rem"
      >
        <img src={chartLineUp} style={{ zIndex: 10010 }} />
        <Typography variant="h4" fontWeight={600} color="#fff" zIndex={10010}>
          Exam Analyser Portal
        </Typography>
        <Typography variant="body1" color="#fff" zIndex={10010}>
          Click the below button to create a new exam
        </Typography>
        <Button
          sx={{
            "bgcolor": "#fff",
            "color": "#475467",
            "zIndex": 10010,
            "&:hover": { bgcolor: "rgba(220, 220, 220, 1)" }
          }}
          variant="contained"
          component={Link}
          to="/analyser"
        >
          Start
        </Button>
      </Box>
    </Box>
  );
};

// render
export default Intro;
